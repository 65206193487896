import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const PrevTown: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Previous Town"/>
    <CustomInput
      name="prevPostTown"
      control={control}
      rules={{
        maxLength: maxLengthRule(25)
      }}
    />
  </div>
)

export default PrevTown
