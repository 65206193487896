import { FC, ReactElement, useEffect } from 'react'
import { useLayoutContext } from '../state/useLayoutMain'

type TProps = {
  children: ReactElement<any, any> | null,
}

const FullSizeLayoutWrap: FC<TProps> = ({ children }) => {
  const { resetFullSize, activateFullSize } = useLayoutContext()

  useEffect(() => {
    activateFullSize()

    return () => resetFullSize()
  }, [])

  return children
}

export default FullSizeLayoutWrap
