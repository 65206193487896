import { css, DefaultTheme } from 'styled-components/macro'
import { styledThemeDefault } from './styledTheme'
import { flex, fontFamily } from './mixins'

const mainBackgroundMixin = css`
  background: ${({ theme }) => theme.colors.darkBlue50};
`

const stepperMuiMixin = css`
  padding: 30px 0;
  background: ${({ theme }) => theme.colors.white100};
  margin-bottom: 0;
`

const circleMixin = css<{ isActive: boolean }>`
  background: ${({ theme, isActive }) => (isActive ? theme.colors.brown100 : theme.colors.ivory)};
`

const wrapperMixin = css`
  background: ${({ theme }) => theme.colors.white100};
`
const hideFiguresMixin = css`
  display: none;
  
  :before{
    content: 'none'
  }
`

const stepLabelMixin = css`
  ${fontFamily('Work Sans')};
`
const circleTextMixin = css`
  ${fontFamily('Open Sans')};
`

const stepperTitleMixin = css`
  ${fontFamily('Open Sans')};
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  text-transform: none;
`

const stepperActiveLineMixin = css`
  border: ${({ theme }) => theme.colors.brown100};
  background: ${({ theme }) => theme.colors.brown100};
`

const nextButtonMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.brown100};
  box-shadow: none;
  padding: 12px 20px;
  border-radius: unset;
  width: 100px;

  &:disabled {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.brown100};
    opacity: 0.5;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.brown200};
    box-shadow: none;
  }
`

const acceptButtonMixin = css`
  ${({ theme }) => theme?.mixins?.nextButtonMixin}
  max-width: none;
  width: 190px;
`
const connectButtonMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 12px 20px;
  background: ${({ theme }) => theme.colors.brown100};
  border-radius: unset;
  box-shadow: none;
  margin: 30px 0 30px 0;
  
  :hover{
    background: ${({ theme }) => theme.colors.brown200};
    box-shadow: none;
  }
`

const bankCheckTellMoreTextMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
`

const backCheckCardMixin = css`
  &:before{
    content: none;
  }
`
const bankCheckContentMixin = css`
  background: ${({ theme }) => theme.colors.darkBlue50};
`

const bankCheckHeaderContentMixin = css`
  padding-top: 20px;
  background: ${({ theme }) => theme.colors.white100};
`

const infoTitleMixin = css`
  ${fontFamily('Open Sans')};
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
`

const descriptionMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`
const TemplateTextMixin = css`
  ${fontFamily('Open Sans')};
`

const containerAccountsMixin = css`
  ${flex({ justify: 'center', align: 'center' })};
  background: ${({ theme }) => theme.colors.white100};
`

const messageAccountsMixin = css`
  ${flex({ justify: 'center', align: 'center' })}
  margin-top: 0;
  max-width: 600px;
`

const inputLabelTextMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
`

const fontFamilyMixin = css`
  ${fontFamily('Open Sans')}
`

const errorNotificationMixin = css`
  ${fontFamily('Open Sans')};
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const checkboxLabelMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  max-width: 992px;
`

const phoneIconTextMixin = css`
  ${fontFamily('Open Sans')};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const inputMixin = css`
  ${fontFamily('Open Sans')}
  font-weight: 400;
`

export const styledThemeRobertOwen: DefaultTheme = {
  ...styledThemeDefault,
  mixins: {
    fontFamilyMixin,
    nextButtonMixin,
    acceptButtonMixin,
    checkboxLabelMixin,
    mainBackgroundMixin,
    hideFiguresMixin,
    inputMixin,
    phoneIconTextMixin,
    TCMixin: TemplateTextMixin,
    stepper: {
      stepperMuiMixin,
      circleMixin,
      wrapperMixin,
      stepLabelMixin,
      circleTextMixin,
      stepperTitleMixin,
      stepperActiveLineMixin
    },
    accounts: {
      messageAccountsMixin,
      containerAccountsMixin
    },
    personalDetails: {
      inputLabelTextMixin
    },
    accordion: {
      infoTitleMixin,
      descriptionMixin
    },
    bankCheck: {
      header: {
        contentMixin: bankCheckHeaderContentMixin,
        connectButtonMixin
      },
      contentMixin: bankCheckContentMixin,
      tellMeMoreMixin: backCheckCardMixin,
      tellMeMoreTextMixin: bankCheckTellMoreTextMixin
    },
    notification: {
      error: {
        textMixin: errorNotificationMixin
      }
    }
  }
}
