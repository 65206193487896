import styled from 'styled-components/macro'

export const TextStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 10px;
  word-break: break-word;
`

export const BoldTextStyled = styled.div<{ noMargin?: boolean }>`
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
`

export const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

export const UnderlinedTextStyled = styled.span`
  text-decoration: underline;
`

export const ListStyled = styled.ul`
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 25px;
`
