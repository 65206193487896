import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ContainerStyled, TitleStyled } from './styled'
import { TState, TStatus } from '../../types'
import PendingLoan from './loanStatusTemplate/PendingLoan'

const statusMap: {[keyof in TStatus ]: ReactNode} = {
  hasPendingLoan: (
    <PendingLoan
      url="https://socialcredit.cymru/"
      orgName="SCC"
      teamName="SCC Team" />
  ),
  hasApprovedLoan: <></>,
  hasDeclinedLoan: <></>
}

const CardStyled = styled.div`
  max-width: 996px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  width: 100%;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.66px;
  color: ${({ theme }) => theme.colors.white};
  & a{
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`

const PendingLoanWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const CymruLoanStatus = () => {
  const { state } = useLocation() as TState
  return (
    <ContainerStyled>
      <CardStyled>
        <div>
          Hey
          {' '}
          {state?.borrowerName}
          ,
        </div>
        <PendingLoanWrap>
          {state?.currentStatus && statusMap[state?.currentStatus]}
        </PendingLoanWrap>
      </CardStyled>
    </ContainerStyled>
  )
}

export default CymruLoanStatus
