import { UseFormSetValue, useFormState, useWatch } from 'react-hook-form'
import { FC, useEffect, useRef, useState } from 'react'
import { useClickAway, useDebounce } from 'react-use'
import styled from 'styled-components/macro'
import {
  FieldAddressMap,
  TControls,
  TFieldAddressKey,
  TPermittedFields,
  TUser,
  TUserAddressResponse
} from '../../../../features/userInfoForm/types'
import { useOrganizationContext } from '../../../state/useOrganization'
import { useUserAddressFormattedMutation, useUserAddressMutation } from '../../../../features/userInfoForm/queries'
import { ArrowDownIcon } from '../../icons/ArrowDownIcon'
import { flex, fontFamily } from '../../../styles/mixins'

const IconArrowDownWrapper = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })}
  margin-right: 4px;
`

const MenuStyled = styled.ul`
  width: 100%;
  max-width: 500px;
  position: absolute;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white} ;
  max-height: 280px;
  overflow-y: auto;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`

const MenuListStyled = styled.li`
  ${flex({ justify: 'flex-start', align: 'center' })}
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  cursor: pointer;
  padding: 6px 16px;
  ${({ theme }) => theme?.mixins?.fontFamilyMixin}
  
  :hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
  `

type TProps = {
  fieldName: string
  setValue: UseFormSetValue<Partial<TUser>>
  PostCodeComponent: FC<any>
  fieldAddressMap: FieldAddressMap
  organizationPermittedFields: TPermittedFields
} & TControls

const PostCodeLoqate: FC<TProps> = ({
  control,
  setValue,
  fieldAddressMap,
  organizationPermittedFields,
  fieldName,
  PostCodeComponent
}) => {
  const { currentOrganization } = useOrganizationContext()
  const [addresses, setAddresses] = useState<TUserAddressResponse['Items']>([])
  const [isSelectedAddress, setIsSelectedAddress] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const modalRef = useRef<HTMLUListElement>(null)
  const postCodeValue = useWatch({ control, name: fieldName })
  const { touchedFields } = useFormState({ control })
  const isFieldTouched = !!touchedFields.postCode

  const { data: addressData, mutate: userAddressMutation } = useUserAddressMutation()
  const { mutateAsync: userAddressFormattedMutation } = useUserAddressFormattedMutation()
  useEffect(() => {
    if (!addressData) {
      return
    }
    if (addressData.Items.length === 1 && addressData.Items[0].Type !== 'Address') {
      const { Id } = addressData.Items[0]
      userAddressMutation({ Text: Id, Container: Id })
      return
    }
    setAddresses(addressData.Items)
    setIsModal(true)
  }, [addressData])

  const handleCloseModal = () => setIsModal(false)

  useClickAway(modalRef, handleCloseModal)

  useDebounce(
    () => {
      if (isFieldTouched && postCodeValue && postCodeValue.length >= 2 && !isSelectedAddress) {
        userAddressMutation({ Text: postCodeValue })
      }
    },
    250,
    [postCodeValue]
  )

  const handleOpenModal = () => {
    if (addresses.length) {
      setIsModal(true)
    }
  }
  const handleSetData = (type: string, id: string) => {
    if (type !== 'Address') {
      userAddressMutation({ Text: id, Container: id })
      return
    }
    setIsSelectedAddress(true)
    userAddressFormattedMutation(id).then((addressFormatted) => {
      if (addressFormatted.Items.length) {
        Object.entries(addressFormatted.Items[0]).forEach(([key, value]) => {
          const fieldName = fieldAddressMap[key as TFieldAddressKey]
          if (organizationPermittedFields[currentOrganization]?.includes(fieldName)) {
            setValue(fieldName, value)
          }
        })
        handleCloseModal()
      }
    })
  }
  return (
    <>
      <PostCodeComponent
        onFocus={handleOpenModal}
        onInput={() => setIsSelectedAddress(false)}
        control={control}
        value={postCodeValue}
      >
        {isModal && addresses.length
          ? (
            <MenuStyled ref={modalRef} >
              {
                addresses.map(({
                  Id: id,
                  Type: type,
                  Text: text
                }) => (
                  <MenuListStyled key={id} onClick={() => handleSetData(type, id)}>
                    {type !== 'Address'
                    && (
                      <IconArrowDownWrapper>
                        <ArrowDownIcon width={16} height={16}/>
                      </IconArrowDownWrapper>
                    )}
                    {text}
                  </MenuListStyled>
                ))
              }
            </MenuStyled>
          )
          : null}
      </PostCodeComponent>
    </>
  )
}
export default PostCodeLoqate
