import { Control } from 'react-hook-form'
import { FC } from 'react'
import CustomCheckBox from '../CustomCheckBox'
import { CheckboxLabel, UpdatesSubscription } from '../../../features/userInfoForm/styled'

type TProps = {
  control: Control<any>
  text?: string
}
const CheckBox: FC<TProps> = ({ control, text }) => (
  <UpdatesSubscription>
    <CheckboxLabel>
      <CustomCheckBox control={control} name="receiveNewsletter"/>
      {text || 'I want to stay up to date and receive offers, news and product updates from Plend'}
    </CheckboxLabel>
  </UpdatesSubscription>
)
export default CheckBox
