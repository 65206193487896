import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const PrevCountry: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Previous Country"/>
    <CustomInput
      name="prevLocality"
      control={control}
      rules={{
        maxLength: maxLengthRule(35)
      }}
    />
  </div>
)

export default PrevCountry
