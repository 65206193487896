import { memo, ReactNode } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import MakeMyHouseGreenTerms from './terms/MakeMyHouseGreenTerms'
import PlendTerms from './terms/PlendTerms'
import RobertOwensTerms from './terms/RobertOwensTerms'
import PurpleLendologyTerms from './terms/PurpleLendologyTerms'
import SocialCreditCymru from './terms/socialCredit/SocialCreditCymru'
import SocialCreditPlend from './terms/socialCredit/SocialCreditPlend'

const termsMap: {[key in TOrganization]: ReactNode} = {
  plend: <PlendTerms/>,
  makemyhousegreen: <MakeMyHouseGreenTerms/>,
  purpleshoots: <PurpleLendologyTerms/>,
  lendology: <PurpleLendologyTerms/>,
  robertowen: <RobertOwensTerms/>,
  socialcreditcymru: <SocialCreditCymru/>,
  socialcreditplend: <SocialCreditPlend/>
}

const Template = () => {
  const { currentOrganization } = useOrganizationContext()
  return (
    <>
      {termsMap[currentOrganization]}
    </>
  )
}

export default memo(Template)
