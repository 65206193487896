import React, { FC, memo, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Button } from '@mui/material'
import { useLocalStorage } from 'react-use'
import CustomSlider from '../../../core/components/CustomSlider'
import { useSocialCreditLoanCalculatorContext } from '../state/useSocialCreditLoanCalculatorState'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { useNavigateWithSavedSearch } from '../../../core/hooks/useNavigateWithSavedSearch'
import { useLastStepNavigationCheck } from '../hooks/useLastStepNavigationCheck'
import { useIsConnectSocialCreditCommunity } from '../../../core/hooks/useIsConnectSocialCreditCommunity'

const inColumnsBreakpoint = '880px'

export const CalculatorStyled = styled.div`
  max-width: 1092px;
  width: 100%;
  display: flex;
  padding: 32px 48px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 20px rgba(80, 18, 10, 0.1);
  backdrop-filter: blur(10px);
  ${flex({ justify: 'space-between' })};
  margin-top: 30px;

  @media (max-width: ${inColumnsBreakpoint}) {
    ${flex({ direction: 'column' })};
    padding: 32px 34px 32px 32px;
    margin-bottom: 50px;
    max-width: 100%;
    && {
      & .MuiSlider-markLabel {
        font-size: 10px!important;
      }
    }
  }

  @media (max-width: 375px) {
    max-width: 355px;
    padding: 24px 16px;
  }
  @media (max-width: 375px) {
    margin-bottom: 30px
  }
`

export const CalculatorTitleStyled = styled.div`
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  ${({ theme }) => theme?.mixins?.socialCreditCalculator?.title};
`

export const CalculatorSubTitleStyled = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 20px;
`

export const WrapperStyled = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center' })};
  width: 100%;
  ${fontFamily('Open Sans')};
  height: 100%;
  padding: 30px 20px 50px;
  background: ${({ theme }) => theme.colors.white100};
  ${({ theme }) => theme?.mixins?.socialCreditCalculator?.wrap};

  @media (max-width: 375px) {
    padding: 0 10px;
  }
`

export const AfterTextStyled = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  max-width: 996px;  
  margin-top: 30px;

  b {
    font-weight: 700;
  }
`

export const AnnotationTextStyled = styled.div`
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.boulder};
  margin-top: 16px;
`

export const TitleStyled = styled.div`
  margin-top: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 50px;
  }
  
  @media (max-width: 375px) {
    line-height: 56px;
  }
`

export const SliderStyled = styled.div`
  margin-right: 128px;
  flex-grow: 1;

  && {
    & .MuiSlider-root {
      margin-top: 24px;
    }

    & .MuiSlider-markLabel {
      margin-top: 4px;
      ${fontFamily('Open Sans')};
      font-style: normal;
      color: ${({ theme }) => theme.colors.blackDarkBlue};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  @media (max-width: ${inColumnsBreakpoint}) {
    margin-right: 0;
  }
`

export const SubtitleStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'flex-end' })};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  ${({ theme }) => theme?.mixins?.socialCreditCalculator?.subTitle};
`

export const BorrowAmount = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0;
  margin-left: 15px;
  ${({ theme }) => theme?.mixins?.socialCreditCalculator?.borrowAmount};
`

export const MonthlyPaymentAmount = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  margin-left: 15px;
  line-height: 43px;
  text-align: right;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

export const LoanPeriod = styled.div<{marginTop?: number}>`
  ${flex({ justify: 'space-between', align: 'baseline' })};
  ${fontFamily('Open Sans')};
  margin-top: ${({ marginTop }) => marginTop}px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

export const AmountCalculation = styled.div`
  width: 384px;
  
  @media (max-width: ${inColumnsBreakpoint}) {
    margin-top: 26px;
    width: 100%;
  }
`

export const TotalResultItem = styled.div`
  ${flex({ justify: 'space-between', align: 'flex-end' })};
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

export const MonthlyPaymentTitle = styled.div``

export const MonthlyPaymentSubtitle = styled.div``

export const TotalResult = styled.div`
  height: 112px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ButtonStyled = styled(Button)`
  && {
    ${fontFamily('Open Sans')};
    width: 100%;
    padding: 12px 20px;
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.seaBuckthorn};
    
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.seaBuckthorn};
    }

    ${({ theme }) => theme?.mixins?.socialCreditCalculator?.button};
  }
`

const periodMarks = [
  {
    value: 6,
    label: '6 months'
  },
  {
    value: 12,
    label: '12 months'
  },
  {
    value: 18,
    label: '18 months'
  },
  {
    value: 24,
    label: '24 months'
  }
]
const loanAmountMarks = [
  {
    value: 500,
    label: formatToPound(500)
  },
  {
    value: 1000,
    label: formatToPound(1000)
  },
  {
    value: 1500,
    label: formatToPound(1500)
  },
  {
    value: 2000,
    label: formatToPound(2000)
  }
]
const CymruPartnerPeriodMarks = [
  {
    value: 12,
    label: '12 months'
  },
  {
    value: 18,
    label: '18 months'
  },
  {
    value: 24,
    label: '24 months'
  }

]

function useRegistrationNavigate() {
  const navigate = useNavigateWithSavedSearch()

  return () => navigate('registration')
}

function useSetLocalStorageLoanValues() {
  const {
    period,
    loanDataQuery,
    monthlyPayment,
    loanAmount
  } = useSocialCreditLoanCalculatorContext()

  const [, setLocaleStorageLoan] = useLocalStorage('loan')

  useEffect(() => {
    if (!period || !loanAmount) {
      return
    }
    setLocaleStorageLoan({
      monthlyPaymentCalc: monthlyPayment,
      period,
      loanAmount
    })
  }, [loanDataQuery, loanAmount, period])
}

function useRegistrationRedirect() {
  const navigateToRegistration = useRegistrationNavigate()
  const { loanDataQuery } = useSocialCreditLoanCalculatorContext()
  const checkRedirect = useLastStepNavigationCheck()
  useEffect(() => {
    if (checkRedirect()) {
      navigateToRegistration()
    }
  }, [loanDataQuery.data])
}

type Props = {
  title: string,
  subTitle: string
}
const SocialCreditLoanCalculator: FC<Props> = ({ title, subTitle }) => {
  const {
    period,
    setPeriod,
    monthlyPayment,
    initCalculator,
    loanAmount,
    setLoanAmount,
    isCymruPartner,
    isSocialOpenConnect
  } = useSocialCreditLoanCalculatorContext()
  useEffect(() => initCalculator(), [])

  useRegistrationRedirect()
  useSetLocalStorageLoanValues()
  const isConnectSocialCredit = useIsConnectSocialCreditCommunity()

  const periods = () => {
    if (isConnectSocialCredit) {
      return periodMarks
    }
    return (isCymruPartner || isSocialOpenConnect) ? CymruPartnerPeriodMarks : periodMarks
  }

  return (
    <WrapperStyled>
      <CalculatorTitleStyled>
        {title}
      </CalculatorTitleStyled>
      <CalculatorSubTitleStyled>
        {subTitle}
      </CalculatorSubTitleStyled>
      <CalculatorStyled>
        <SliderStyled>
          <LoanPeriod marginTop={0}>
            <SubtitleStyled>I want to borrow</SubtitleStyled>
            <div>
              {formatToPound(loanAmount || 0)}
            </div>
          </LoanPeriod>
          <CustomSlider
            key={loanAmount}
            value={loanAmount}
            onChange={(event) => setLoanAmount((event.target as any).value)}
            step={500}
            defaultValue={loanAmount}
            marks={loanAmountMarks}
            min={500}
            max={2000}
          />
          <LoanPeriod marginTop={20}>
            <SubtitleStyled>For how long</SubtitleStyled>
            <div>
              {period}
              {' '}
              months
            </div>
          </LoanPeriod>
          <CustomSlider
            key={period}
            value={period}
            onChange={(event) => setPeriod((event.target as any).value)}
            defaultValue={period}
            step={6}
            marks={periods()}
            min={(isCymruPartner || (isSocialOpenConnect && !isConnectSocialCredit)) ? 12 : 6}
            max={24}
          />
        </SliderStyled>
        <AmountCalculation>
          <TotalResult>
            <TotalResultItem>
              <MonthlyPaymentTitle>Monthly payment:</MonthlyPaymentTitle>
              <MonthlyPaymentAmount>
                {monthlyPayment ? formatToPound(Number(monthlyPayment)) : '-'}
              </MonthlyPaymentAmount>
            </TotalResultItem>
            <TotalResultItem>
              <MonthlyPaymentSubtitle>Fixed APR:</MonthlyPaymentSubtitle>
              <div>
                0%
              </div>
            </TotalResultItem>
            <TotalResultItem>
              <div>Total interest:</div>
              <div>£0.00</div>
            </TotalResultItem>
          </TotalResult>
          <ButtonStyled
            onClick={useRegistrationNavigate()}>
            APPLY NOW
          </ButtonStyled>
        </AmountCalculation>
      </CalculatorStyled>

      <AfterTextStyled>
        <b>Representative Example:</b>
        {' '}
        Borrowing £1,000 over 24 months at an interest rate of 0% p.a.(fixed),
        you will repay 24 monthly payments of £41.67. Interest Payable £0.
        Total Repayable £1,000.00.
        <AnnotationTextStyled>
          *this loan bears no interest, meaning repayments
          are only made on the principal (ie; borrowed) amount.
        </AnnotationTextStyled>
      </AfterTextStyled>
    </WrapperStyled>
  )
}
export default memo(SocialCreditLoanCalculator)
