import React, { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'
import CustomLabel from '../../CustomLabel'
import { TControls } from '../../../../features/userInfoForm/types'
import { flex } from '../../../styles/mixins'
import CustomInput from '../../CustomInput'
import { maxLengthRule, minLengthRule, requiredRule } from '../../../utils/formRules'
import { QuestionMarkIcon } from '../../icons/QuestionMarkIcon'

const PostContainer = styled.div`
  position: relative;
`

const IconQuestionMarkWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })}
  color: ${({ theme }) => theme.colors.brandBlue};
`
type TProps = {
  onFocus: () => void
  onInput: () => void
  value: string,
  label: string,
  name: string,
  children: ReactNode
} & TControls

const PrevPostCode: FC<TProps> = ({
  control,
  onFocus,
  onInput,
  label,
  name,
  value,
  children
}) => (
  <PostContainer>
    <CustomLabel label={label}/>
    <CustomInput
      onFocus={onFocus}
      onInput={onInput}
      autoComplete="off"
      name={name}
      control={control}
      rules={{
        required: requiredRule(),
        minLength: minLengthRule(2),
        maxLength: maxLengthRule(100)
      }}
      InputProps={{
        endAdornment: value && value.length >= 2 && (
          <IconQuestionMarkWrapper>
            <QuestionMarkIcon/>
          </IconQuestionMarkWrapper>
        )
      }}
    />
    {children}
  </PostContainer>
)

export default PrevPostCode
