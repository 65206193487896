import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { QueryClientProvider } from 'react-query'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components/macro'
import { GlobalStyles } from './core/components/GlobalStyles'
import { styledThemeDefault } from './core/styles/styledTheme'
import { styledThemeMakerHouse } from './core/styles/styledThemeMakerHouse'
import { MultiStepStateProvider } from './features/userInfoForm/state/useToken'
import Routes from './Routes'
import { OrganizationProvider, TOrganization, useOrganizationContext } from './core/state/useOrganization'
import { queryClient } from './core/api/queryClient'
import { NotificationProvider } from './features/notifications/state/useNotification'
import './core/styles/fonts.css'
import { styledThemeRobertOwen } from './core/styles/styledThemeRobertOwen'
import { useInitIntercomChat } from './features/intercomChat/hooks/useInitIntercomChat'
import { useInitGoogleAnalytics } from './features/googleAnalytics/hooks/googleAnalyticsHoooks'
import { styledThemeCymru } from './core/styles/styledThemeCymru'
import { styledThemeSocialCreditPlend } from './core/styles/styledThemeSocialCreditPlend'

export const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
`

const organizationThemeMap: { [key in TOrganization]?: DefaultTheme } = {
  socialcreditcymru: styledThemeCymru,
  socialcreditplend: styledThemeSocialCreditPlend,
  makemyhousegreen: styledThemeMakerHouse,
  robertowen: styledThemeRobertOwen
}

const App = () => {
  const { title, favicon, currentOrganization } = useOrganizationContext()
  useInitIntercomChat(['makemyhousegreen', 'socialcreditcymru', 'socialcreditplend'])
  useInitGoogleAnalytics()

  const theme = organizationThemeMap[currentOrganization] || styledThemeDefault
  const isSCPOrganization = currentOrganization === 'socialcreditplend'
  return (
    <>
      <GlobalStyles/>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" type="image/x-icon" href={favicon}/>
        {
          isSCPOrganization && (
            <script
              id="ze-snippet"
              src="https://static.zdassets.com/ekr/snippet.js?key=d3989df9-6e71-4441-af7a-8ff9e5225e76"/>
          )
        }

      </Helmet>
      <ThemeProvider theme={theme}>
        <MultiStepStateProvider>
          <NotificationProvider>
            <QueryClientProvider client={queryClient}>
              <ContainerStyled>
                <Routes/>
              </ContainerStyled>
            </QueryClientProvider>
          </NotificationProvider>
        </MultiStepStateProvider>
      </ThemeProvider>
    </>
  )
}

ReactDOM.render(
  <OrganizationProvider>
    <App />
  </OrganizationProvider>,
  document.getElementById('root')
)
