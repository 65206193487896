import styled from 'styled-components/macro'
import { memo } from 'react'
import { flex } from '../../../../../core/styles/mixins'
import { TextStyled } from '../../ThankYou'

const TextWrapper = styled.div`
  ${flex({ justify: 'center' })};
`
const PurpleshootsThankYouContent = () => (
  <TextWrapper>
    <TextStyled>Thank you, we’ll get back to you as soon as we can.</TextStyled>
  </TextWrapper>
)
export default memo(PurpleshootsThankYouContent)
