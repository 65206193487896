import { DefaultTheme } from 'styled-components/macro'

export const styledThemeDefault: DefaultTheme = {
  colors: {
    secondary: 'rgb(10, 10, 10)',
    white: '#fff',
    white100: '#F5F5F5',
    white200: '#CDCDCD',
    white300: '#D9D9D9',
    red200: '#FCEDEE',
    blue200: '#E5EEFB',
    green: '#28a745',
    darkGreen: '#0F3331',
    brandBlue: '#0052D9',
    linkBlue: '#0D6EFD',
    mainBackground: '#eee',
    grey: '#757575',
    lightBlue: '#CCDCF7',
    darkBlack: '#242424',
    black500: '#0D0D0D',
    darkerWhite: '#eee',
    orange: '#FF604C',
    orange100: '#E55644',
    pink100: '#C8789A',
    violet: '#9190E8',
    pink: '#F987C5',
    brown100: '#E88633',
    boulder: '#797979',
    seaBuckthorn: '#F8B03A',
    brown200: '#D1792E',
    darkBlue100: '#1C204B',
    darkBlue50: '#16354B',
    blackDarkBlue: '#0E1424',
    deepSeaGreen: '#0E5166',
    portage: '#9594EC',
    mystic: '#478D79',
    ivory: '#F0ECEA',
    yellow: '#FFB515',
    green400: '#478979',
    greenMint: '#1EEF8E',
    greenMint100: '#21F199',
    green100: '#21F199',
    grey100: '#CCCCCC',
    mercury: '#E6E6E6',
    green200: '#1BD780',
    transparent: 'transparent'
  }
}
