/* eslint-disable react/no-unescaped-entities */
import { FC } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useStepperContext } from '../../../../../core/components/stepper/StepperContext'
import { SocialCreditStepperButton } from '../../../../../core/components/buttons/SocialCreditStepperButton'
import CustomCheckBox from '../../../../../core/components/CustomCheckBox'
import {
  useSocialCreditLoanCalculatorContext, useUTMSource
} from '../../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import {
  AfterTextStyled,
  BoldTextStyled,
  CardStyled, CheckboxesTitleStyled,
  ContentStyled, CustomCheckBoxContainerStyled, CustomLabelStyledStyled, LinkStyled,
  SubTitleStyled,
  TextStyled,
  TitleStyled,
  WrapStyled
} from './styles'
import { useUtmIntermediaryQuery } from '../../../querries'

type TForm = {
  checkbox1: boolean
  checkbox2: boolean
  checkbox3: boolean
  checkbox4: boolean
}

function useSubmitHandle({ watch }: UseFormReturn<TForm>) {
  const { nextStep } = useStepperContext()

  return {
    isSubmitDisabled: Object.values(watch()).some((value) => !value),
    handleSubmit: nextStep
  }
}

const SocialCreditPlend: FC = () => {
  const useFormReturn = useForm<TForm>({
    mode: 'onChange',
    defaultValues: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false
    }
  })
  const utmSource = useUTMSource()

  const { control } = useFormReturn

  const { isSubmitDisabled, handleSubmit } = useSubmitHandle(useFormReturn)

  const { monthlyPayment, period, loanAmount } = useSocialCreditLoanCalculatorContext()

  const totalRepayment = loanAmount
  const { data } = useUtmIntermediaryQuery(utmSource ?? undefined)
  return (
    <WrapStyled>
      <ContentStyled>
        <TitleStyled>Terms, conditions and pre-contract information</TitleStyled>
        <CardStyled marginTop={30}>
          <SubTitleStyled noMargin>
            PRE-CONTRACT CREDIT INFORMATION
          </SubTitleStyled>

          <BoldTextStyled>
            1. Contact details
            <br/>
            Creditor:
          </BoldTextStyled>

          <TextStyled noMargin>
            Plend Limited
          </TextStyled>

          <BoldTextStyled>
            Address:
          </BoldTextStyled>

          <TextStyled noMargin>
            Level 4, Aldgate Tower, London E1 8FA
          </TextStyled>

          <BoldTextStyled>
            E-mail address:
          </BoldTextStyled>

          <TextStyled noMargin>
            <LinkStyled href="mailto:support@socialcredit.community">
              support@socialcredit.community
            </LinkStyled>
          </TextStyled>
          {
            data && (
              <>
                <BoldTextStyled>
                  Credit Intermediary:
                </BoldTextStyled>

                <TextStyled noMargin>
                  {data.intermediary.name}
                </TextStyled>

                <BoldTextStyled>
                  Address:
                </BoldTextStyled>

                <TextStyled
                  noMargin
                  dangerouslySetInnerHTML={{ __html: data.intermediary?.address }}
                />

                <BoldTextStyled>
                  Telephone number(s):
                </BoldTextStyled>

                <TextStyled noMargin flexColumn>
                  {data.intermediary?.phoneNumbers?.map((phone) => (
                    <div key={phone}>{phone}</div>
                  ))}
                </TextStyled>

                <BoldTextStyled>
                  Email address:
                </BoldTextStyled>

                <TextStyled noMargin>
                  <LinkStyled href={`mailto:${data.intermediary?.email}`}>
                    {data.intermediary?.email}
                  </LinkStyled>
                </TextStyled>

              </>
            )
          }

          <BoldTextStyled>
            Web address:
          </BoldTextStyled>

          <TextStyled noMargin>
            <LinkStyled target="_blank" href={data?.intermediary?.webAddress}>
              {data?.intermediary?.webAddress}
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            2. Key features of the credit product
            <br/>
            The type of credit:
          </BoldTextStyled>

          <TextStyled noMargin>
            Fixed sum credit.
          </TextStyled>

          <BoldTextStyled>
            The total amount of credit.
          </BoldTextStyled>

          <TextStyled noMargin italic>
            This means the amount of credit to be provided
            under the proposed credit agreement or the credit limit.
          </TextStyled>

          <TextStyled noMargin>
            £
            {loanAmount}
          </TextStyled>

          <BoldTextStyled>
            Repayments.
          </BoldTextStyled>
          <TextStyled noMargin italic>
            {period}
            {' '}
            monthly payments of £
            {monthlyPayment}
            .
          </TextStyled>
          <TextStyled noMargin>
            Your first payment is due two months after the credit is provided
            to you. We will confirm the first repayment date after the agreement
            is made. All subsequent repayments will be on the same date each
            consecutive month thereafter. Where a repayments date falls on
            a non-working day the payment will be taken on the next working day.
          </TextStyled>

          <BoldTextStyled>
            The total amount you will have to pay.
          </BoldTextStyled>

          <TextStyled noMargin italic>
            This means the amount you have borrowed plus interest and other costs.
          </TextStyled>
          <TextStyled noMargin>
            £
            {totalRepayment}
          </TextStyled>

          <BoldTextStyled>
            3. Costs of the credit
            <br/>
            The rates of interest which apply to the credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            The interest rate is 0% per annum (fixed).
            <br/>
            Interest at the above annual rate will be calculated on the
            amount you owe from the date one
            month after the amount of credit is transferred to you.
            <br/>
            Interest will be added to your account every calendar month on your repayment due date.
          </TextStyled>

          <BoldTextStyled>
            Annual Percentage Rate of Charge (APR).
          </BoldTextStyled>

          <TextStyled noMargin italic>
            This is the total cost expressed as an annual percentage of the total amount of credit.
            The APR is there to help you compare different offers.
          </TextStyled>
          <TextStyled noMargin>
            0% APR.
          </TextStyled>

          <BoldTextStyled>
            Consequences of missing payments.
          </BoldTextStyled>
          <TextStyled noMargin>
            Missing payments could have serious consequences.
            We may terminate your agreement and require you
            to pay all sums payable under this agreement early.
            <br/>
            We may instruct a third party to recover the sums outstanding from you on our behalf.
            <br/>
            Adverse information may be passed to credit reference agencies about your
            missed payments, which could impact your credit rating and could also make
            access to credit more difficult and the cost
            of credit more expensive for you in the future.
          </TextStyled>

          <BoldTextStyled>
            4. Other important legal aspects
            <br/>
            Right of withdrawal
          </BoldTextStyled>

          <TextStyled noMargin>
            You have the right to withdraw from the credit agreement,
            without giving a reason, byemail to
            {' '}
            <LinkStyled href="mailto:support@socialcredit.community">
              support@socialcredit.community
            </LinkStyled>
            {' '}
            or by post to Plend Limited at Level 4, Aldgate Tower, London E1
            8FA within a period of 14 days beginning on the day after the
            later of (a) the day the agreement is made; (b) the date on
            which we tell you that the agreement has been executed or (c)
            the date on which you receive a copy of the executed agreement
            (if required).
          </TextStyled>

          <BoldTextStyled>
            Early repayment.
          </BoldTextStyled>

          <TextStyled noMargin>
            You have the right to repay the outstanding balance in full or in part at any time.
          </TextStyled>

          <BoldTextStyled>
            Consultation with a Credit Reference Agency.
          </BoldTextStyled>

          <TextStyled noMargin>
            If we decide to not proceed with your application for credit based on
            information obtained from a credit reference agency we will, when
            telling you that your application has been refused, tell you that it
            has been refused on that basis and give you details of the credit reference
            agency we consulted.
          </TextStyled>

          <BoldTextStyled>
            Right to a draft credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            You have the right, upon request, to obtain a copy of
            the draft credit agreement free of charge, unless we are
            unwilling to proceed to the conclusion of the credit agreement with you.

          </TextStyled>

          <BoldTextStyled>
            The period of time during which the creditor is
            bound by the pre-contractual information.
          </BoldTextStyled>

          <TextStyled noMargin>
            This information is valid for 4 days from the date it is provided to you.
          </TextStyled>

          <BoldTextStyled>
            5. Additional information in the case of distance marketing of financial services
            <br/>
            (a) concerning the creditor
            <br/>
            Registration number.
          </BoldTextStyled>

          <TextStyled noMargin>
            Firm Reference Number: 963328
            <br/>
            Company Registration Number: 12581855
            <br/>
            ICO Registration Reference: ZB037977
          </TextStyled>

          <BoldTextStyled>
            The supervisory authority.
          </BoldTextStyled>

          <TextStyled noMargin>
            The Financial Conduct Authority (FCA).
          </TextStyled>

          <BoldTextStyled>
            (b) concerning the credit agreement
            <br/>
            The law taken by the creditor as a basis for the establishment
            of relations with you before the conclusion of the credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            English, unless you are resident in Scotland or Northern Ireland,
            in which case the laws of the country in which you are resident
            in the United Kingdom will apply.
          </TextStyled>

          <BoldTextStyled>
            The law applicable to the credit agreement and/or the competent court.
          </BoldTextStyled>

          <TextStyled noMargin>
            This agreement shall be governed by the laws of England and Wales,
            unless you are resident in Scotland or Northern Ireland, in which
            case the laws of the country in which you are resident in the United
            Kingdom will apply. Any dispute which arises in relation to the agreement
            will be dealt with by the courts of England and Wales unless you are
            resident in Scotland or Northern Ireland, in which case it will be
            dealt with by the courts of the country in the United Kingdom in which
            you are resident.
          </TextStyled>

          <BoldTextStyled>
            Language to be used in connection with the credit agreement
          </BoldTextStyled>

          <TextStyled noMargin>
            English.
          </TextStyled>

          <BoldTextStyled>
            (c) concerning redress
            <br/>
            Access to out-of-court complaint and redress mechanism.
          </BoldTextStyled>

          <TextStyled noMargin>
            If you are dissatisfied then any complaints must be sent to us at: Level 6,
            Aldgate Tower,
            London E1 8FA or email:
            {' '}
            <LinkStyled href="mailto:support@socialcredit.community">
              support@socialcredit.community
            </LinkStyled>
            {' '}
            in the first instance.
            We will handle your complaint in accordance with our customer complaints process
            which can be found on our website. If we haven't issued our 'final response'
            within eight weeks from the date you first raised your complaint or if you are
            dissatisfied with our final response you can ask the Financial Ombudsman Service
            (FOS) for an independent review. You can write to FOS at The Financial Ombudsman
            Service, Exchange Tower, London E14 9SR, by email at
            {' '}
            <LinkStyled href="mailto:complaint.info@financial-ombudsman.org.uk">
              complaint.info@financial-ombudsman.org.uk
            </LinkStyled>
            {' '}
            or by telephone on 0800 023 4 567 or 0300 123 9 123.
          </TextStyled>

          <SubTitleStyled>
            Explanation of credit agreement:
            <br/>
            Explanation of your credit agreement.
          </SubTitleStyled>

          <TextStyled>
            This document is intended to provide you with an explanation
            of matters in relation to the proposed credit agreement
            so that you can assess whether the agreement is suitable
            for your needs and your financial situation. Please read
            it carefully.
            <br/>
            We are also providing you with a pre-contract information sheet
            (known as a PCCI) which you should read and consider.
            It contains important information about the credit agreement.
            <br/>
            If you have any questions about this document, the
            {' '}
            <BoldTextStyled inline noMargin>
              PCCI
            </BoldTextStyled>
            {' '}
            or the
            credit agreement or need some further information or explanation
            then please email us at
            {' '}
            <LinkStyled href="mailto:complaint.info@financial-ombudsman.org.uk">
              complaint.info@financial-ombudsman.org.uk
            </LinkStyled>
            <br/>
            You can also access some frequently asked questions on our website at
            {' '}
            <LinkStyled href="https://socialcredit.community/" target="_blank" rel="noreferrer">
              socialcredit.community
            </LinkStyled>

          </TextStyled>

          <BoldTextStyled>
            Purpose of agreement
          </BoldTextStyled>

          <TextStyled noMargin>
            The credit to be provided under the agreement is to be used
            for the purpose we have agreed in the credit application.
            It is not intended for and may be unsuitable for any
            other purpose.
          </TextStyled>

          <BoldTextStyled>
            Repayments
          </BoldTextStyled>

          <TextStyled noMargin>
            The amount of credit (the loan amount) is £
            {loanAmount}
            .
          </TextStyled>

          <TextStyled>
            Repayments are to be made by
            {' '}
            {period}
            {' '}
            monthly repayments of £
            {monthlyPayment}
            .
            The first repayment is due two months after the credit is provided
            to you. We will confirm the first payment date to you after the
            agreement is made. Subsequent repayments are due on the same
            date in each consecutive month thereafter. Payments must
            be made from an account to be nominated by you.
          </TextStyled>

          <TextStyled>
            The total amount payable is £
            {totalRepayment}
          </TextStyled>

          <TextStyled>
            Interest, at the rate set out in the agreement, will be calculated
            on the outstanding balance from one month after the amount of credit
            is transferred to you. Interest will be added to your account every
            calendar month on your payment due date. If you do not make the
            repayment due on the allocated due date you will continue to be
            charged interest on the unpaid amount until repaid. If this happens
            you will pay more and we may increase the number, or amount of
            your monthly repayments to reflect this.
          </TextStyled>

          <BoldTextStyled>
            The consequences that may arise if
            you fail to make payments under the credit agreement on time
          </BoldTextStyled>

          <TextStyled noMargin>
            Missing payments could have serious consequences.
            We may terminate your agreement and require you to
            pay all sums payable under this agreement early.
          </TextStyled>

          <TextStyled>
            Adverse information may be passed to credit reference
            agencies about your missed payments, which could impact
            your credit rating and could also make access to credit
            more difficult and the cost of credit more expensive
            for you in the future.
          </TextStyled>

          <TextStyled>
            We may instruct a third party to recover the sums outstanding from you on our behalf.
          </TextStyled>

          <BoldTextStyled>
            Right to withdraw
          </BoldTextStyled>
          <TextStyled noMargin>
            You have the right to withdraw from the credit agreement without having
            to give any reason. This right commences on the day after the later of
            either (a) the day on which the agreement is made or, (b) the date on which
            we tell you that the agreement has been executed or (c) the date on which
            you receive a copy of the executed agreement (if required) and continues
            for 14 days from that day (the “Withdrawal Period”). To withdraw you
            must notify us that you intend to do so within the Withdrawal Period.
            You can do this by email to
            {' '}
            <LinkStyled target="_blank" href="mailto:https://support@socialcredit.community">
              support@socialcredit.community
            </LinkStyled>
            {' '}
            or
            by post to Level 6, Aldgate Tower, London E1 8QN.
          </TextStyled>

          <TextStyled>
            If you withdraw from the credit agreement then you
            will need to repay the amount of credit advanced together
            with interest from the date the credit was provided to the
            day we receive full payment in cleared funds. This must be
            paid without delay and in any event within 30 days of your
            giving us notice of withdrawal. Payment can be made to us
            by bank transfer from your nominated account. Please contact
            us for our up to date bank details. If you do not withdraw
            you will be bound by the terms of the credit agreement.
          </TextStyled>
        </CardStyled>

        <CustomCheckBoxContainerStyled>
          <CheckboxesTitleStyled>
            A few things to check, you must be:
          </CheckboxesTitleStyled>
          <CustomLabelStyledStyled>
            <CustomCheckBox
              control={control}
              name="checkbox1"/>
            Aged over 18
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox2"/>
            Be employed with no probationary period, self-employed or have regular income,
            e.g., pension, regular benefits
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox3"/>
            A resident of England or Scotland, and only have tax residency in England or Scotland
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox4"/>
            Using this loan for personal (not business) purposes
          </CustomLabelStyledStyled>
        </CustomCheckBoxContainerStyled>

        <SocialCreditStepperButton text="Accept" onClick={handleSubmit} disabled={isSubmitDisabled}/>

        <AfterTextStyled>
          Please contact Plend’s Data Protection Officer (“DPO”) dpo@plend.co.uk
          in relation to any questions or concerns regarding UK GDPR considerations
          of the Plend Pilot. Plend Limited is registered with the Information
          Commissioner’s Office in compliance with the Data Protection
          Regulations 2018 under registration ZB037977.
        </AfterTextStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default SocialCreditPlend
