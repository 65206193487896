import { memo } from 'react'
import styled from 'styled-components/macro'
import { ButtonWrapper, ContentAgreementStyled } from './styled'
import CustomButton from '../../../../core/components/buttons/CustomButton'
import { useStepperContext } from '../../../../core/components/stepper/StepperContext'
import { fontFamily } from '../../../../core/styles/mixins'

const ContentStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  overflow-y: auto;
  padding: 30px;
  max-width: 800px
`

export const TextStyled = styled.p<{fontItalic?: boolean}>`
  ${fontFamily('Neue')}
  font-style: ${({ fontItalic }) => (fontItalic ? 'italic' : 'normal')};
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 24px;
  text-align: justify;
`

const PurpleLendologyTerms = () => {
  const { nextStep } = useStepperContext()
  return (
    <>
      <ContentStyled>
        <TextStyled>
          Thanks for your interest in getting a loan powered by Plend!
          These Terms of Use (these “Terms”) are our rules for our PLEND Score®
          Data Pilot (the “Plend Pilot”).
        </TextStyled>
        <TextStyled>
          These Terms are between Plend Limited (“Plend”), Plaid Technologies,
          Inc (“Plaid”) and an entity or person accessing Plaid (the “User”).
        </TextStyled>
        <TextStyled>
          By clicking “Accept”, you indicate your assent to be bound by these Terms.
          These Terms contain mandatory
          arbitration provisions that require the use of arbitration to resolve disputes.
          All data collection subject to the ‘Terms of Use’ provided by Plaid:
          <a
            target="_blank"
            rel="noreferrer"
            href="https://plaid.com/legal/terms-of-use/">
            {' '}
            https://plaid.com/legal/terms-of-use/.
            {' '}
          </a>
        </TextStyled>
        <TextStyled>
          These Terms will continue in effect unless terminated in accordance with these Terms.
          Either party may terminate these Terms in the event the other party materially breaches
          the terms of these Terms and fails to cure such breach within ten (10) days from receipt
          of written notice thereof. In addition, Plend may immediately suspend the Services in the
          event it determines or believes that (a) there is unauthorized access to the Services via
          Client’s account, (b) continued provision of the Services may do material harm to Plend or
          its networks or systems or reputation and subject Plend to liability. Plend may terminate
          these Terms for any reason and without cause upon written notice to Client. User Data will
          be held in the United Kingdom for the duration of the loan.
        </TextStyled>
        <TextStyled>
          For any questions or concerns regarding the Plend Pilot, contact
          <a href="mailto:support@plend.co.uk"> support@plend.co.uk.</a>
        </TextStyled>
        <TextStyled>
          Please contact Plend’s Data Protection Officer (“DPO”)
          {' '}
          <a href="mailto:dpo@plend.co.uk"> dpo@plend.co.uk</a>
          {' '}
          in relation to any questions or concerns regarding UK GDPR considerations
          of the Plend Pilot. Plend Limited is registered with the Information Commissioner’s
          Office in compliance with the Data Protection Regulations 2018 under registration
          ZB037977.
        </TextStyled>
      </ContentStyled>
      <ContentAgreementStyled>
        <ButtonWrapper>
          <CustomButton
            onClick={nextStep}
            text="Accept"/>
        </ButtonWrapper>
      </ContentAgreementStyled>

    </>
  )
}

export default memo(PurpleLendologyTerms)
