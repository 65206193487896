import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import ImageButterfly from '../assets/images/houseGreenButterfly.png'
import ImageTreeRotated from '../assets/images/houseGreenTreeRotated.png'

const ImageTreeStyled = styled.img<{treeBottom?: number}>`
  position: absolute;
  width: 311px;
  height: 223px;
  object-fit: cover;
  bottom: ${({ treeBottom }) => treeBottom || 0}px;
  right: 0;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`

const ImageButterFlyStyled = styled.img<{butterFlyTop?: number}>`
  position: absolute;
  width: 207px;
  height: 166px;
  object-fit: cover;
  top: ${({ butterFlyTop }) => butterFlyTop || 0}px;
  left: 0;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`

type TProps = {
  butterFlyTop?: number
  treeBottom?: number
}

const MakeMyHouseGreenImageBackground: FC<TProps> = ({ butterFlyTop, treeBottom }) => (
  <>
    <ImageButterFlyStyled data-testid="makeMyHouseGreenImageBackground" butterFlyTop={butterFlyTop} src={ImageButterfly} alt="butterFly"/>
    <ImageTreeStyled treeBottom={treeBottom} src={ImageTreeRotated} alt="treeRoted"/>
  </>
)

export default memo(MakeMyHouseGreenImageBackground)
