import styled from 'styled-components/macro'
import { fontFamily } from '../../styles/mixins'
import CustomButton from './CustomButton'

export const GreyButton = styled(CustomButton)`
  && {
    ${fontFamily('Open Sans')};
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    background: ${({ theme }) => theme.colors.mercury} !important;
    border-radius: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px 20px;
    
    :hover {
      background: ${({ theme }) => theme.colors.mercury} !important;
    }
  }
`
