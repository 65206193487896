import { BrowserRouter, Route, Routes as RouterRoutes, Navigate } from 'react-router-dom'
import React from 'react'
import Home from './features/home/components/Home'
import RegistrationStepper from './features/registrationStepper/components/RegistrationStepper'
import { useOrganizationContext } from './core/state/useOrganization'
import ScrollToTop from './core/utils/ScrollToTop'
import Notification from './features/notifications/components/Notification'
import ApiClient from './features/apiClient/components/ApiClient'
import MainLayout from './features/mainLayout/components/MainLayout'
import ThankYou from './features/thankYou/components/ThankYou'
import LoanStatus from './features/loanStatus/components/LoanStatus'
import { SocialCreditLoanCalculatorStateProvider } from './features/loanCalculator/state/useSocialCreditLoanCalculatorState'
import FullSizeLayoutWrap from './features/mainLayout/components/FullSizeLayoutWrap'
import ResubmitAddress from './features/resubmitAddress/components/ResubmitAddress'
import PrivacyPolicy from './features/privacyPolicy/components/PrivacyPolicy'
import TermsAndConditionsFull from './features/termsAndConditins/components/TermsAndConditionsFull'
import { ReconnectingStateProvider } from './features/reconnecting/state/useReconnectingState'

const Routes = () => {
  const { currentOrganization } = useOrganizationContext()

  const organization = {
    plend: <Home/>,
    lendology: <Navigate to="registration"/>,
    purpleshoots: <Navigate to="registration"/>,
    makemyhousegreen: <Home/>,
    robertowen: <Home/>,
    socialcreditcymru: <Home/>,
    socialcreditplend: <Home/>
  }

  return (
    <BrowserRouter>
      <ApiClient/>
      <Notification/>
      <ScrollToTop>
        <ReconnectingStateProvider>
          <SocialCreditLoanCalculatorStateProvider>
            <RouterRoutes>
              <Route path="/" element={<MainLayout/>}>
                <Route index element={organization[currentOrganization]}/>
                <Route path="registration" element={<RegistrationStepper/>}/>
                <Route
                  path="accounts"
                  element={(
                    <FullSizeLayoutWrap>
                      <ThankYou/>
                    </FullSizeLayoutWrap>
                  )}/>
                <Route
                  path="loan-status"
                  element={(
                    <FullSizeLayoutWrap>
                      <LoanStatus/>
                    </FullSizeLayoutWrap>
                  )}/>
                <Route path="resubmit-address" element={<ResubmitAddress/>}/>
                <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="terms-and-conditions" element={<TermsAndConditionsFull/>}/>
              </Route>
            </RouterRoutes>
          </SocialCreditLoanCalculatorStateProvider>
        </ReconnectingStateProvider>
      </ScrollToTop>
    </BrowserRouter>
  )
}
export default Routes
