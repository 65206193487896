import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { integerFormat } from '../../utils/formFormat'
import { maxLengthRule, minLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'
import { PhoneTextStyled } from '../../../features/userInfoForm/styled'

const PrimaryPhoneNumber: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Primary phone number *"/>
    <CustomInput
      name="primaryPhoneNumber"
      control={control}
      handleChange={integerFormat}
      rules={{
        required: requiredRule(),
        minLength: minLengthRule(10, 'Please provide valid phone number value'),
        maxLength: maxLengthRule(11, 'Please provide valid phone number value')
      }}
      icon={<PhoneTextStyled>+44</PhoneTextStyled>}
    />
  </div>
)

export default PrimaryPhoneNumber
