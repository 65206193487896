import React, { FC, useMemo } from 'react'
import { formatToOption } from '../../utils/formFormat'
import CustomSelect from '../CustomSelect'
import CustomLabel from '../CustomLabel'
import { TControls } from '../../../features/userInfoForm/types'
import { useLivingStatusQuery } from '../../../features/userInfoForm/queries'

const LivingStatusSelect: FC<TControls> = ({ control }) => {
  const { data: livingStatus } = useLivingStatusQuery()

  const livingStatusOption = useMemo(
    () => (livingStatus ? formatToOption(livingStatus) : []),
    [livingStatus]
  )

  return (
    <CustomSelect
      name="livingStatusId"
      label={<CustomLabel label="Living Status *"/>}
      control={control}
      options={livingStatusOption}
    />
  )
}

export default LivingStatusSelect
