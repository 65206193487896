import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { TControls } from '../../../features/userInfoForm/types'

const LoanPurposeInput: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Loan Purpose"/>
    <CustomInput
      disabled
      name="loanPurpose"
      control={control}
    />
  </div>
)
export default LoanPurposeInput
