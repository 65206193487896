import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { integerFormat } from '../../utils/formFormat'
import { requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const EmploymentLength: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Employment Length (months)*"/>
    <CustomInput
      name="employmentLength"
      control={control}
      handleChange={integerFormat}
      rules={{
        required: requiredRule()
      }}
    />
  </div>
)
export default EmploymentLength
