import { useMemo } from 'react'
import { useEmploymentStatusQuery } from '../queries'

const jobs = ['FULL_TIME', 'PART_TIME']
export function useStatusEmployer() {
  const { data: employmentStatus } = useEmploymentStatusQuery()
  return useMemo(
    () => {
      if (!employmentStatus) {
        return []
      }
      return employmentStatus
        .map(({ code, id }) => {
          if (jobs.includes(code)) {
            return id
          }
        })
        .filter(Boolean)
    },
    [employmentStatus]
  )
}
