import { css, DefaultTheme } from 'styled-components/macro'
import { styledThemeDefault } from './styledTheme'
import { fontFamily } from './mixins'
import { DescriptionStyled, InfoTitleStyled } from '../components/CustomAccordion'
import { LabelStyled } from '../components/CustomLabel'

const mainBackgroundMixin = css`
  background-color: ${({ theme }) => theme.colors.mystic};
`

const wrapperMixin = css`
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.ivory};

  .MuiStepLabel-iconContainer {
    ${fontFamily('Neue Haas Grotesk Display Pro', true)};
  }

  .MuiStepLabel-label {
    margin-top: 12px !important;
    ${fontFamily('Neue Haas Grotesk Display Pro', true)};
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
`

const stepperMuiMixin = css`
  margin: 0;
  background-color: transparent;
`

const inputMixin = css`
  border-radius: 0;
`

const stepperTitleMixin = css`
  ${fontFamily('League Spartan')};
  font-size: 40px;
  font-weight: 700;
`

const circleMixin = css<{ isActive: boolean }>`
  && {
    font-weight: 700;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.portage : theme.colors.white)};

    p {
      color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.portage)};
      ${fontFamily('League Spartan')};
      position: relative;
      bottom: -3px;
    }
  }
`

const tellMeMoreTextMixin = css`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  margin-top: 50px;
  text-transform: none;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 40px;
  }
`

const contentHeaderMixin = css`
    background-color: ${({ theme }) => theme.colors.mystic};
    &:before{
        content: none;
    }

    &:after{
        content: none;
    }
    
`

const bankCheckHeaderTextMixin = css`
    &:before{
        content: none;
    }

    &:after{
        content: none;
    }
`

const contentMixin = css`
  background-color: ${({ theme }) => theme.colors.mystic};
  
  ${InfoTitleStyled} {
    ${fontFamily('Neue Haas Grotesk Display Pro')};
    font-size: 22px;
    font-weight: 600;
  }
  
  ${DescriptionStyled} {
    ${fontFamily('Neue Haas Grotesk Display Pro')};
    font-weight: normal;
  }
`
const tellMeMoreMixin = css`
  @media (max-width: 375px) {
    margin-bottom: 50px;
  }
  
  :before {
    display: none;
  }
`

const hideFiguresMixin = css`
  display: none;
  
  :before{
    content: 'none'
  }
`

const termsAndConditionsContentMixin = css`
  max-width: 100%;
  width:100%;
  margin: 0;
  background-color: blue;
`

const resubmitAddressContentMixin = css`
  background-color: ${({ theme }) => theme.colors.mystic};
  padding-bottom: 50px;
  &&& {
    .MuiFormControl-root, .MuiPaper-root {
      border-radius: 0;
    }
  }
`

const socialCreditLoanCalculatorWrapMixin = css`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
`

const socialCreditLoanCalculatorTitleMixin = css`
  ${fontFamily('League Spartan')};
`

const socialCreditLoanCalculatorBorrowAmountMixin = css`
  ${fontFamily('Open Sans')};
`

const socialCreditLoanCalculatorButtonMixin = css`
  ${fontFamily('League Spartan')};
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  padding-top: 15px;
  background: ${({ theme }) => theme.colors.portage};
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  border-radius: 99px;

  &:hover {
    background: ${({ theme }) => theme.colors.portage};
    color: ${({ theme }) => theme.colors.blackDarkBlue};
  }
`

const socialCreditLoanCalculatorSubTitleMixin = css`
  font-weight: 500;
`

const customSliderMixin = css`
  color: ${({ theme }) => theme.colors.portage};

  .MuiSlider-thumb {
    background: ${({ theme }) => theme.colors.portage};
  }
`

const labelsMixin = css`
  ${LabelStyled} {
    ${fontFamily('Neue Haas Grotesk Display Pro')};
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
  }
`

const textFontMixin = css`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
`

const textMixin = css`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  font-weight: 400;
  line-height: 22.56px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};

`

const resubmitAddressTitleMixin = css`
  text-transform: none;
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  font-size: 48px;
  margin: 20px 0 50px;
`

const socialCreditStepperButtonMixin = css`
  ${fontFamily('League Spartan')};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  padding-top: 15px;
  border-radius: 99px;
  background: ${({ theme }) => theme.colors.portage} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.portage} !important;
  }
`

const resubmitAddressSubmitButtonMixin = css`
    background: ${({ theme }) => theme.colors.portage};
    ${fontFamily('League Spartan')};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    box-shadow: none;
    padding: 14px 30px 10px 30px;
    border-radius: 99px;
    margin-bottom: 0;
    &:hover {
        background: ${({ theme }) => theme.colors.portage} !important;
    }
`

const stepperActiveLineMixin = css`
  border: ${({ theme }) => theme.colors.portage};
  background: ${({ theme }) => theme.colors.portage};
`

const listMixin = css`
  ${textMixin}
`

const connectButtonMixin = css`
    background: ${({ theme }) => theme.colors.portage};
    ${fontFamily('League Spartan')};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    box-shadow: none;
    padding: 14px 30px 10px 30px;
    border-radius: 99px;
    margin-bottom: 0;
    &:hover {
        background: ${({ theme }) => theme.colors.portage} !important;
        box-shadow: none;
        color: ${({ theme }) => theme.colors.blackDarkBlue};
    }
    &:disabled {
        color: ${({ theme }) => theme.colors.blackDarkBlue};
        background: ${({ theme }) => theme.colors.portage};
        opacity: 0.5;
    }
`

const containerAccountsMixin = css`
    background: ${({ theme }) => theme.colors.mystic};
    
`
const textAccountsMixin = css`
    ${fontFamily('Neue Haas Grotesk Display Pro')};
    font-size: 22px;
    font-weight: 400;
    line-height: 24.82px;
    text-align: left;
    color: white;
    & >a {
        color: ${({ theme }) => theme.colors.white};
    }
`
const messageAccountsMixin = css`
    margin: 50px 0;
`

const nextButtonMixin = css`
    background: ${({ theme }) => theme.colors.portage};
    ${fontFamily('League Spartan')};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    box-shadow: none;
    padding: 14px 30px 10px 30px;
    border-radius: 99px;
    margin-bottom: 0;
    max-width: max-content;
    &:hover {
        background: ${({ theme }) => theme.colors.portage} !important;
        box-shadow: none;
        color: ${({ theme }) => theme.colors.blackDarkBlue};
    }
    &:disabled {
        color: ${({ theme }) => theme.colors.blackDarkBlue};
        background: ${({ theme }) => theme.colors.portage};
        opacity: 0.5;
    }
`

const personalDetailsContainerMixin = css`
  padding: 50px;
`
const personalDetailsWrapperMixin=css`
  max-width: 998px;
`

export const styledThemeSocialCreditPlend: DefaultTheme = {
  ...styledThemeDefault,
  mixins: {
    inputMixin,
    hideFiguresMixin,
    nextButtonMixin,
    termsAndConditions: {
      contentMixin: termsAndConditionsContentMixin
    },
    stepper: {
      listMixin,
      wrapperMixin,
      stepperMuiMixin,
      circleMixin,
      stepperTitleMixin,
      labelsMixin,
      textFontMixin,
      textMixin,
      submitButtonMixin: socialCreditStepperButtonMixin,
      stepperActiveLineMixin
    },
    mainBackgroundMixin,
    customSliderMixin,
    bankCheck: {
      tellMeMoreTextMixin,
      contentMixin,
      tellMeMoreMixin,
      header: {
        contentMixin: contentHeaderMixin,
        connectButtonMixin,
        headerTextMixin: bankCheckHeaderTextMixin
      }
    },
    personalDetails: {
      wrapperMixin: personalDetailsWrapperMixin,
      containerMixin: personalDetailsContainerMixin
    },
    socialCreditCalculator: {
      wrap: socialCreditLoanCalculatorWrapMixin,
      title: socialCreditLoanCalculatorTitleMixin,
      subTitle: socialCreditLoanCalculatorSubTitleMixin,
      borrowAmount: socialCreditLoanCalculatorBorrowAmountMixin,
      button: socialCreditLoanCalculatorButtonMixin
    },
    resubmitAddress: {
      content: resubmitAddressContentMixin,
      title: resubmitAddressTitleMixin,
      submitButton: resubmitAddressSubmitButtonMixin
    },
    accounts: {
      containerAccountsMixin,
      textAccountsMixin,
      messageAccountsMixin
    }
  }
}
