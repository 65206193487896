import { TextStyled, useFailedAttempt } from './DefaultBankCheckHeaderTop'

const textByFailedAttempts = [
  `By connecting your bank account, we’re able to make a smarter,
   more efficient application decision that allows us to offer you more affordable rates.`,
  `Thank you for your interest in a SCC loan. Unfortunately, your first application failed because 
  there wasn’t enough activity on the bank account you connected. Please connect your 
  main account - the one your salary is paid into.`,
  'Please connect the bank account which is linked to the main account you have provided where funds are being transferred.'

]
const CymruPartnerBankCheckHeaderTop = () => {
  const failedCount = useFailedAttempt()
  return (
    <TextStyled>
      {textByFailedAttempts[failedCount]}
    </TextStyled>
  )
}

export default CymruPartnerBankCheckHeaderTop
