import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { memo } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'
import SemicircleMedium from '../../../../core/components/icons/svg/SemicircleMedium.svg'

export const TextStyled = styled.div`
  ${fontFamily('Neue')};
  max-width: 840px;
  width: 100%;
  margin-top: 100px;
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: .4px;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    top: 190%;
    left: -48%;
    background: url(${SemicircleMedium})
    no-repeat center center/cover;
    width: 297px;
    height: 149px;

    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }


  @media (max-width: 768px) {
    max-width: 728px;
    margin-top: 50px;
  }
  @media (max-width: 375px) {
    max-width: 355px;
    margin-top: 30px;
    font-size: 20px;
    line-height: 23px;
  }

  ${({ theme }) => theme?.mixins?.bankCheck?.header?.headerTextMixin}
`

export function useFailedAttempt() {
  const [searchParams] = useSearchParams()
  const failedAttemptCount = searchParams.get('failedAttempt')
  if (!failedAttemptCount) {
    return 0
  }
  if (+failedAttemptCount >= 3) {
    return 2
  }
  return +failedAttemptCount
}

const textByFailedAttempts = [
  `By connecting your bank account, we’re able to make a smarter, more efficient
   application decision that allows us to offer you more affordable rates.`,
  `Thank you for your interest in a Plend loan. Unfortunately, your first application
   failed because there wasn’t enough activity on the bank account you connected to. Please 
   connect another bank account if you have one, to provide us with more financial information.`,
  'Please connect the bank account which is linked to the main account you have provided where funds are being transferred.'
]

const DefaultBankCheckHeaderTop = () => {
  const failedCount = useFailedAttempt()

  return (
    <TextStyled>
      {textByFailedAttempts[failedCount]}
    </TextStyled>
  )
}

export default memo(DefaultBankCheckHeaderTop)
