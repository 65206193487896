import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import SemicircleRight from '../../../../core/components/icons/svg/SemicircleRight.svg'

export const ContainerStyled = styled.div`
  position: relative;
  ${flex({ justify: 'center', align: 'center' })};
  ${fontFamily('Neue')};
  padding: 10px;
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  ${({ theme }) => theme?.mixins?.TCMixin};

  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    left: 0;
    top: 108px;
    background: url(${SemicircleRight}) no-repeat center center/cover;
    width: 173px;
    height: 346px;
    ${({ theme }) => theme?.mixins?.hideFiguresMixin};

    @media (max-width: 1070px) {
      display: none;
    }
  }
  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
  ${({ theme }) => theme.mixins?.loanStatus?.containerMixin}
`

export const CardStyled = styled.div`
  ${flex({ direction: 'column' })};
  max-width: 600px;
  width: 100%;
  margin: 70px 0;
  padding: 30px 24px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 20px rgba(14, 20, 36, 0.1);
  border-radius: 20px;
  z-index: 2;
  position: relative;
  
  ${({ theme }) => theme?.mixins?.home?.cardMixin}
`

export const LogoWrapper = styled.div`
  ${flex({ justify: 'center' })};
  margin-bottom: 30px;
`

export const ImageStyled = styled.div`
  z-index: 1;
`

export const SpiralStyled = styled.img`
  width: 285px;
  height: 36px;
  margin-left: -24px;
`

export const TitleStyled = styled.div`
  font-weight: 700;
`
export const TextStyled = styled.div`
  margin-top: 30px;
`
