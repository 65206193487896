import Checkbox from '@mui/material/Checkbox'
import { Control, useController } from 'react-hook-form'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { CheckedIcon } from './icons/CheckedIcon'

const CheckBoxStyled = styled(Checkbox)`
  &&&&{
    width: 32px;
    height: 32px;
    margin-right: 12px;
    outline: 1px solid ${({ theme }) => theme.colors.ivory};
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    & .MuiSvgIcon-root{
      fill: ${({ theme }) => theme.colors.white};
    }
    
  }
`

type TProps = {
  control: Control<any>
  name: string
  rules?: RegisterOptions
}

const CustomCheckBox: FC<TProps> = ({ name, control, rules }) => {
  const { field } = useController({ name, control, rules })

  return (
    <CheckBoxStyled aria-label={name} checkedIcon={<CheckedIcon/>} {...field} />
  )
}

export default CustomCheckBox
