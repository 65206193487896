import { useMutation, UseMutationResult, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { useMultiStepStateContext } from '../userInfoForm/state/useToken'
import { apiClient } from '../../core/api/apiClient'
import { useLinkCorrelationId } from '../../core/hooks/usePlaidHooks'

export function useLoanerMutation(): UseMutationResult<void, Error, { public_token: string }> {
  const { state } = useMultiStepStateContext()
  const [searchParams] = useSearchParams()
  const loanId = searchParams.get('loanApplicationId')
  return useMutation(async ({ public_token }) => {
    try {
      await apiClient.post(`/tokens/loans/${loanId || state?.loanApplicationId}/exchange`, {
        token: public_token
      })
    } catch (e) {
      throw new Error('Something went Wrong')
    }
  })
}

export function useCreateTokenQuery() {
  const { state } = useMultiStepStateContext()
  const [searchParams] = useSearchParams()
  const loanApplicationId = searchParams.get('loanApplicationId')
  const borrowerId = searchParams.get('borrowerId')
  const failedAttempt = searchParams.get('failedAttempt')
  const token = searchParams.get('token')
  const linkCorrelationId = useLinkCorrelationId()

  const { data, ...rest } = useQuery<string, Error>(
    'plaidReq',
    async () => {
      const url = loanApplicationId && borrowerId
        ? `update?loanApplicationId=${loanApplicationId}&borrowerId=${borrowerId}${failedAttempt ? `&failedAttempt=${failedAttempt}` : ''}${token ? `&accountRequestedToken=${token}` : ''}`
        : `create?loanApplicationId=${state?.loanApplicationId || loanApplicationId}`
      try {
        const { data } = await apiClient.post<{ token: string }>(`/tokens/link/${url}`)
        return data.token
      } catch (err: any) {
        if (axios.isAxiosError(err) && err.response) throw new Error(err.response.data.message || 'Something went wrong')
        else throw new Error('Something went wrong')
      }
    },
    {
      enabled: !linkCorrelationId
    }
  )
  return {
    token: data,
    ...rest
  }
}

export function useLinkValidationQuery(
  enabled: boolean,
  linkType: string,
  borrowerId?: string,
  loanApplicationId?: string,
  expiration?: string,
  token?: string,
  type?: string
) {
  return useQuery<boolean, Error>(
    ['LinkValidation', borrowerId, loanApplicationId, expiration, linkType],
    async () => {
      try {
        const { data } = await apiClient.get<{ valid: boolean }>('/borrowers/link/validation', {
          params: {
            borrowerId,
            loanApplicationId,
            expiration,
            linkType,
            token,
            type
          }
        })
        return data.valid
      } catch (err: any) {
        if (axios.isAxiosError(err) && err.response) throw new Error(err.response.data.message || 'Something went wrong')
        else throw new Error('Something went wrong')
      }
    },
    {
      enabled
    }
  )
}

type TLinkCorrelationResponse = {
  token: string
  expiration: Date
}

export function useLinkCorrelation() {
  const linkCorrelationId = useLinkCorrelationId()
  return useQuery<void, Error, TLinkCorrelationResponse>(
    ['LinkCorrelation', linkCorrelationId],
    async () => {
      try {
        const { data } = await apiClient.post(`tokens/link/correlation-id/exchange?linkCorrelationId=${linkCorrelationId}`)
        return data
      } catch (err: any) {
        if (axios.isAxiosError(err) && err.response) throw new Error(err.response.data.message || 'Something went wrong')
        else throw new Error('Something went wrong')
      }
    },
    {
      enabled: !!linkCorrelationId
    }
  )
}
