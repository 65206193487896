import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import ImageBackground from '../../../../core/components/MakeMyHouseGreenImageBackground'
import DeclinedLoan from './loanStatusTemplate/DeclinedLoan'
import PendingLoan from './loanStatusTemplate/PendingLoan'
import ApprovedLoan from './loanStatusTemplate/ApprovedLoan'
import { TState, TStatus } from '../../types'
import { ContainerStyled, CardStyled, ImageStyled, TitleStyled } from './styled'

const statusMap: {[keyof in TStatus ]: ReactNode} = {
  hasPendingLoan: <PendingLoan />,
  hasApprovedLoan: <ApprovedLoan/>,
  hasDeclinedLoan: <DeclinedLoan/>
}

const MakeMyHouseGreenLoanStatus = () => {
  const { state } = useLocation() as TState

  return (
    <ContainerStyled>
      <ImageStyled>
        <ImageBackground />
      </ImageStyled>
      <CardStyled>
        <TitleStyled>
          Hey
          {' '}
          {state?.borrowerName}
          ,
        </TitleStyled>
        {state?.currentStatus && statusMap[state?.currentStatus]}
      </CardStyled>
    </ContainerStyled>

  )
}

export default MakeMyHouseGreenLoanStatus
