/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { fontFamily } from '../../../../core/styles/mixins'
import CymruPrivacyPolicyText from './CymruPrivacyPolicyText'
import { useOrganizationContext } from '../../../../core/state/useOrganization'
import SocialCreditPlendPrivacyPolicyText from './SocialCreditPlendPrivacyPolicyText'

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 0 20px 50px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 0 10px 50px;
  }
  
  ${({ theme }) => theme.mixins?.mainBackgroundMixin};
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin};
`

const ContentStyled = styled.div`
  max-width: 996px;
`

const TitleStyled = styled.div`
  width: 100%;
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin};
`

const CardStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-top: 30px;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 10px;
  word-break: break-word;
`

const BoldTextStyled = styled.div<{ noMargin?: boolean }>`
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

type TProps = {
  noMenu?: boolean
}
const CymruTermsFull: FC<TProps> = ({ noMenu }) => (
  <WrapStyled>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <ContentStyled>
      <TitleStyled>Privacy Policy</TitleStyled>
      <CardStyled>
        {
          !noMenu && (
            <>
              <BoldTextStyled noMargin>
                Contents
              </BoldTextStyled>
              <TextStyled>
                <LinkStyled href="#whoWeAre">Who we are.</LinkStyled>
                <br/>
                <LinkStyled href="#collectionOfPersonalData">Collection of Personal Data.</LinkStyled>
                <br/>
                <LinkStyled href="#useOfYourInformation">
                  Use of Your information and Legal Bases for Processing Data.
                </LinkStyled>
                <br/>
                <LinkStyled href="#sharingYourInformation">Sharing your information.</LinkStyled>
                <br/>
                <LinkStyled href="#creditReference">Credit Reference Agencies (CRA).</LinkStyled>
                <br/>
                <LinkStyled href="#TransfersOutside">Transfers outside the UK.</LinkStyled>
                <br/>
                <LinkStyled href="#dataRetention">Data Retention.</LinkStyled>
                <br/>
                <LinkStyled href="#security">Security of Information.</LinkStyled>
                <br/>
                <LinkStyled href="#yourRights">Your rights under applicable data protection law.</LinkStyled>
                <br/>
                <LinkStyled href="#marketing">Marketing.</LinkStyled>
                <br/>
                <LinkStyled href="#dataController">Data Controller and Contact Information.</LinkStyled>
                <br/>
                <LinkStyled href="#complaints">Complaints.</LinkStyled>
                <br/>
                <LinkStyled href="#changes">Changes to this Privacy Policy.</LinkStyled>
              </TextStyled>
            </>
          )
        }

        {useOrganizationContext().currentOrganization === 'socialcreditplend'
          ? <SocialCreditPlendPrivacyPolicyText />
          : <CymruPrivacyPolicyText/>}
      </CardStyled>
    </ContentStyled>
  </WrapStyled>
)

export default CymruTermsFull
