import React from 'react'
import styled from 'styled-components/macro'
import ImageBackground from '../../../../core/components/MakeMyHouseGreenImageBackground'
import CustomAccordion from '../../../../core/components/CustomAccordion'
import { TOrganization, useOrganizationContext } from '../../../../core/state/useOrganization'
import { useHomePage } from '../../../../core/assets/faq/homePage'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import Ellipse from '../../../../core/components/icons/svg/Ellipse.svg'
import { TInfoData } from '../../../../core/assets/faq/types'

const TitleStyled = styled.div`
  ${fontFamily('Mongoose')};
  margin-top: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;
  text-transform: uppercase;
  ${({ theme }) => theme?.mixins?.home?.textMixin};
  ${({ theme }) => theme?.mixins?.home?.homeTitleMixin};
  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 50px;
  }
  @media (max-width: 375px) {
    line-height: 56px;
  }
`

const MainStyled = styled.div`
  position: relative;
  ${flex({ direction: 'column', align: 'center' })};
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.violet};
  ${({ theme }) => theme?.mixins?.mainBackgroundMixin};
  ${({ theme }) => theme?.mixins?.home?.mainMixin};
  @media (max-width: 375px) {
    padding: 0 10px;
  }
`

const CardStyled = styled.div`
  max-width: 996px;
  width: 100%;
  padding: 24px 24px 24px 21px;
  margin: 30px 0 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    left: -40%;
    bottom: 0;
    background: url(${Ellipse}) no-repeat center center/cover;
    width: 367px;
    height: 367px;
    @media (max-width: 1040px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    max-width: 728px;
    padding: 24px 16px;
  }
  @media (max-width: 375px) {
    padding: 24px 10px 24px 15px;
  }

  ${({ theme }) => theme?.mixins?.home?.cardMixin};
`

const useAccordionInfo = () => {
  const { currentOrganization } = useOrganizationContext()
  const additionalInfo = useHomePage()
  const defaultAdditionalInfo = additionalInfo.filter((info) => info.id !== 3)

  const accordionMap: {[key in TOrganization]?: TInfoData[] } = {
    plend: defaultAdditionalInfo,
    purpleshoots: defaultAdditionalInfo,
    lendology: defaultAdditionalInfo,
    makemyhousegreen: additionalInfo
  }

  return accordionMap[currentOrganization]
}

const DefaultContent = () => {
  const { currentOrganization } = useOrganizationContext()
  const accordionData = useAccordionInfo()
  const isMakeMyHouseGreen = currentOrganization === 'makemyhousegreen'

  return (
    <MainStyled>
      {isMakeMyHouseGreen && <ImageBackground/>}
      <TitleStyled>Tell me more about Plend</TitleStyled>
      <CardStyled>
        {accordionData?.map((info) => (<CustomAccordion key={info.id} info={info}/>))}
      </CardStyled>
    </MainStyled>
  )
}
export default DefaultContent
