import { FC } from 'react'
import styled from 'styled-components/macro'
import CitizensAdvice from '../../../core/assets/images/citizensAdvice.png'
import StepChangeLogo from '../../../core/assets/images/stepChangeLogo.png'
import { TitleStyled, TextStyled } from './styles'
import UnclaimedBenefits from './UnclaimedBenefits'

const CitizensAdviceLinkStyled = styled.a`
  display: block;
  margin-top: 20px;
  
  img {
    height: 76px;
    width: 92px;
  }
`

const StepChangeLogoLinkStyled = styled.a`
  display: block;
  margin-top: 20px;

  img {
    height: 66px;
    width: 186px;
  }
`

const Failed: FC = () => (
  <>
    <TitleStyled>
      We’re sorry but your application has not been successful
    </TitleStyled>
    <TextStyled>
      <strong>
        Your next steps:
      </strong>
      <br/>
      We know this is frustrating but we’d like to point you in the direction
      of some organisations who might be able to help.
    </TextStyled>
    <TextStyled>
      If you’re worried about debt and are looking to consolidate any arrears or find
      {' '}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      yourself overcommitted you can contact StepChange, the UK's leading debt charity,
      to get expert debt advice and fee-free debt management.
    </TextStyled>
    <StepChangeLogoLinkStyled target="_blank" href="https://www.stepchange.org/">
      <img src={StepChangeLogo} alt="step change Logo"/>
    </StepChangeLogoLinkStyled>
    <TextStyled>
      If you need some general support with your finances…
    </TextStyled>
    <CitizensAdviceLinkStyled target="_blank" href="https://www.citizensadvice.org.uk/">
      <img src={CitizensAdvice} alt="citizens advice"/>
    </CitizensAdviceLinkStyled>
    <UnclaimedBenefits />
  </>
)

export default Failed
