import React, { FC } from 'react'
import styled from 'styled-components/macro'
import CustomInput from '../CustomInput'
import { fontFamily } from '../../styles/mixins'
import { maxLengthRule, requiredRule } from '../../utils/formRules'
import CustomLabel from '../CustomLabel'
import { TControls } from '../../../features/userInfoForm/types'

const WrapperStyled = styled.div`
&&{
  && input {
    ::placeholder {
      ${fontFamily('Neue')};
      color: ${({ theme }) => theme.colors.darkBlack};
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`

const DebtConsolidationStyled = styled.div`
  width: 100%;
  grid-column: 1/3;
  @media (max-width: 768px) {
    max-width: 355px;
    grid-column: 1;
  }
`

type TProps = TControls & {
  label: string,
  placeholder: string
}

const LoanPurposeDescription: FC<TProps> = ({ control, placeholder, label }) => (
  <DebtConsolidationStyled>
    <WrapperStyled>
      <CustomLabel label={label}/>
      <CustomInput
        control={control}
        name="loanPurposeDescription"
        placeholder={placeholder}
        fullWidth
        rules={{
          required: requiredRule(),
          maxLength: maxLengthRule(200)
        }}
      />
    </WrapperStyled>
  </DebtConsolidationStyled>
)

export default LoanPurposeDescription
