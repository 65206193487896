import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const Employer: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Employer *"/>
    <CustomInput
      name="employer"
      control={control}
      rules={{
        required: requiredRule(),
        maxLength: maxLengthRule(100)
      }}
    />
  </div>
)
export default Employer
