import { css, DefaultTheme } from 'styled-components/macro'
import { styledThemeDefault } from './styledTheme'
import { flex, fontFamily } from './mixins'
import { DescriptionStyled, InfoTitleStyled } from '../components/CustomAccordion'

const mainBackgroundMixin = css`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
`

const wrapperMixin = css`
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};

  .MuiStepLabel-iconContainer {
    ${fontFamily('Open Sans', true)};
  }

  .MuiStepLabel-label {
    margin-top: 12px !important;
    ${fontFamily('Open Sans', true)};
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
`

const stepperMuiMixin = css`
  margin: 0;
  background-color: transparent;
`

const inputMixin = css`
  border-radius: 0;
`

const circleMixin = css<{ isActive: boolean }>`
  ${fontFamily('Open Sans')};
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.seaBuckthorn : theme.colors.ivory)};
`

const tellMeMoreTextMixin = css`
  ${fontFamily('Open Sans')};
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  margin-top: 50px;
  text-transform: none;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 40px;
  }
`

const contentMixin = css`
  background-color: ${({ theme }) => theme.colors.deepSeaGreen};
  
  ${InfoTitleStyled} {
    ${fontFamily('Open Sans')};
    font-size: 22px;
    font-weight: 600;
  }
  
  ${DescriptionStyled} {
    ${fontFamily('Open Sans')};
    font-weight: normal;
  }
`
const tellMeMoreMixin = css`
  @media (max-width: 375px) {
    margin-bottom: 50px;
  }
  
  :before {
    display: none;
  }
`

const hideFiguresMixin = css`
  display: none;
  
  :before{
    content: 'none'
  }
`

const termsAndConditionsContentMixin = css`
  max-width: 100%;
  width:100%;
  margin: 0;
  background-color: blue;
`

const resubmitAddressContentMixin = css`
  background-color: ${({ theme }) => theme.colors.deepSeaGreen};
  
  &&& {
    .MuiFormControl-root, .MuiPaper-root {
      border-radius: 0;
    }
  }
`

const loanStatusContainerMixin = css`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.deepSeaGreen};
  align-items: flex-start;
`

const resubmitAddressTitleMixin = css`
  text-transform: none;
  ${fontFamily('Neue')};
  font-size: 48px;
  margin: 20px 0 50px;
`

const resubmitAddressSubmitButtonMixin = css`
  && {
    ${fontFamily('Open Sans')};
    width: 190px;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    background: ${({ theme }) => theme.colors.seaBuckthorn} !important;
    border-radius: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px 0;
    margin-top: 30px;
    box-shadow: none;

    :hover {
      background: ${({ theme }) => theme.colors.seaBuckthorn} !important;
    }
  }
`

// bankCheck
const bankCheckHeaderContentMixin = css`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
`

const backCheckHeaderText = css`
  ${fontFamily('Open Sans')};
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  line-height: normal;
  max-width: 996px;
  &:before{
    content: none;
  }
`

const bankCheckConnectButtonMixin = css`
  ${fontFamily('Open Sans')};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 12px 20px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  background: ${({ theme }) => theme.colors.seaBuckthorn};
  box-shadow: none;
  border-radius: 0;
  text-transform: none;

  &:hover {
    background: ${({ theme }) => theme.colors.seaBuckthorn};
  }

  :disabled {
    color:rgba(0, 0, 0, 0.26);
    background: ${({ theme }) => theme.colors.seaBuckthorn};
  }

`

const checkboxLabelMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`

const inputLabelTextMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
`

const nextButtonMixin = css`
  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  background: ${({ theme }) => theme.colors.seaBuckthorn};
  box-shadow: none;
  padding: 12px 20px;
  border-radius: unset;
  max-width: 190px;

  &:disabled {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.brown100};
    opacity: 0.5;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.seaBuckthorn};
    box-shadow: none;
  }
`
const textAccountsMixin = css`
  ${fontFamily('Open Sans')};
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.66px;
  
  & >a {
    color: ${({ theme }) => theme.colors.white};
  }
`
const containerAccountsMixin = css`
  ${flex({ justify: 'center', align: 'center' })};
  background: ${({ theme }) => theme.colors.deepSeaGreen};
`

const messageAccountsMixin = css`
margin-top: 50px;
`

export const styledThemeCymru: DefaultTheme = {
  ...styledThemeDefault,
  mixins: {
    inputMixin,
    nextButtonMixin,
    checkboxLabelMixin,
    hideFiguresMixin,
    termsAndConditions: {
      contentMixin: termsAndConditionsContentMixin
    },
    stepper: {
      wrapperMixin,
      stepperMuiMixin,
      circleMixin
    },
    loanStatus: {
      containerMixin: loanStatusContainerMixin
    },
    mainBackgroundMixin,
    personalDetails: {
      inputLabelTextMixin
    },
    accounts: {
      textAccountsMixin,
      messageAccountsMixin,
      containerAccountsMixin
    },
    bankCheck: {
      tellMeMoreTextMixin,
      contentMixin,
      tellMeMoreMixin,
      header: {
        contentMixin: bankCheckHeaderContentMixin,
        headerTextMixin: backCheckHeaderText,
        connectButtonMixin: bankCheckConnectButtonMixin
      }
    },
    resubmitAddress: {
      content: resubmitAddressContentMixin,
      title: resubmitAddressTitleMixin,
      submitButton: resubmitAddressSubmitButtonMixin
    }
  }
}
