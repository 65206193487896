import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../core/styles/mixins'

const ContentStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.violet};
  ${({ theme }) => theme?.mixins?.bankCheck?.header?.contentMixin}
`

const NotValidStyled = styled.div`
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontFamily('Neue')};
  font-size: 24px;
  font-weight: 450;
  line-height: 27px;
`

const NotValidHeader = () => (
  <ContentStyled>
    <NotValidStyled>
      The link has expired
    </NotValidStyled>
  </ContentStyled>
)

export default NotValidHeader
