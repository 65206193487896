import React from 'react'
import styled from 'styled-components/macro'

const TableWrapper = styled.div`
overflow-x: auto;
`

const TableStyled = styled.table`
  border-collapse: collapse;
  text-align: center;

  & td, th {
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: center;
  }
`

const LoanTermsTable = () => (
  <>
    <div>
      <div>
        The final repayment terms will be dependent on the final amount
        quoted by MakeMyHouseGreen at a representative Annual Percentage Rate (“APR”)
        {' '}
        <b>of 8.99%* at your chosen term from 2-5 years (24, 36, 48 & 60 months)</b>
        .
      </div>
      <br/>
    </div>
    <TableWrapper>
      <TableStyled>
        <thead>
          <tr>
            <th>Term (months)</th>
            <th colSpan={3}>Borrower APR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>24</td>
            <td>8.99%</td>
          </tr>
          <tr>
            <td>36</td>
            <td>8.99%</td>
          </tr>
          <tr>
            <td>48</td>
            <td>8.99%</td>
          </tr>
          <tr>
            <td>60</td>
            <td>8.99%</td>
          </tr>
        </tbody>
      </TableStyled>
    </TableWrapper>
  </>
)

export default LoanTermsTable
