import constate from 'constate'
import { useForm } from 'react-hook-form'
import { usNilsDictionariesQuery } from '../querries'
import { TFormData } from '../types'

function useFormInit() {
  return useForm<TFormData>({
    shouldUnregister: true,
    defaultValues: {
      benefits: []
    }
  })
}

const useContext = () => ({
  useFormReturn: useFormInit(),
  nilsDictionariesQuery: usNilsDictionariesQuery()
})

export const [
  CymruPersonalInformationProvider,
  useCymruPersonalInformationContext
] = constate(useContext)
