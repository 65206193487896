import styled from 'styled-components/macro'

export const TitleStyled = styled.div`
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.stepper?.stepperTitleMixin}
`

export const TextStyled = styled.div<{ center?: boolean }>`
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.white};
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  strong {
    font-weight: 700;
  }

  b {
    font-weight: 600;
  }
`
