import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule, minLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

type TProps = {
  disabled?: boolean
} & TControls

const LastName: FC<TProps> = ({ control, disabled }) => (
  <div>
    <CustomLabel label="Last Name *"/>
    <CustomInput
      name="lastName"
      disabled={disabled}
      control={control}
      rules={{
        required: requiredRule(),
        minLength: minLengthRule(2),
        maxLength: maxLengthRule(100)
      }}
    />
  </div>
)
export default LastName
