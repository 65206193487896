import { useQuery } from 'react-query'
import { apiClient } from '../../core/api/apiClient'

type TAllExistingLoanPurposesQueryReturn = {[key: string]: string[]}

export function useAllExistingLoanPurposesQuery(enabled: boolean) {
  return useQuery<TAllExistingLoanPurposesQueryReturn, Error>(
    'AllExistingLoanPurposes',
    async () => {
      try {
        const response = await apiClient.get<TAllExistingLoanPurposesQueryReturn>('/dictionaries/loan-purpose')
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      enabled
    }
  )
}
