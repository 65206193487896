import React, { FC, Fragment, useEffect } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import styled from 'styled-components/macro'
import { TFormData } from '../types'
import CustomInput from '../../../core/components/CustomInput'
import { fontFamily } from '../../../core/styles/mixins'
import CustomLabel, { LabelStyled } from '../../../core/components/CustomLabel'
import { GreyButton } from '../../../core/components/buttons/GreyButton'
import { RemoveIcon } from '../../../core/components/icons/RemoveIcon'
import { maxLengthRule, requiredRule, validateFloat } from '../../../core/utils/formRules'
import { floatFormat } from '../../../core/utils/formFormat'

export const WrapStyled = styled.div`
  display: grid;
  grid-template-columns: 2.2fr auto;
  row-gap: 20px;
  column-gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }
  
  ${LabelStyled} {
    ${fontFamily('Open Sans')};
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    margin-top: 30px;
    height: 30px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }
`

export const GroupStyled = styled.div`
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  row-gap: 20px;
  column-gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }
`

const FieldWrapStyled = styled.div`
  
  @media (max-width: 768px) {
    ${LabelStyled} {
      display: block;
    }
  }
`

const AprFieldStyled = styled.div`
  && {
    @media (max-width: 768px) {
      .MuiFormControl-root {
        width: 150px;
      }
    }
  }
`

const DividerStyled = styled.div`
  display: none;
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 15px 0 5px;
  
  && {
    @media (max-width: 768px) {
      display: block;
    }
  }
`

const NameFieldStyled = styled.div<{ offsetMobile?: boolean }>`
  && {
    @media (max-width: 768px) {
      margin-top: ${({ offsetMobile }) => (offsetMobile ? '40px' : 'none')};
    }
  }
`

const InputWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
`

export const RemoveIconWrapStyled = styled.div<{ mobileShow: boolean, invisible?: boolean }>`
  cursor: pointer;
  display: ${({ mobileShow }) => (mobileShow ? 'none' : 'block')};
  visibility: ${({ invisible }) => (invisible ? 'hidden' : 'visible')};
  color: ${({ theme }) => theme.colors.boulder};
  @media (max-width: 768px) {
    display: ${({ mobileShow }) => (mobileShow ? 'block' : 'none')};
  }
`

const RemoveContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

type TProps = {
  useFormReturn: UseFormReturn<TFormData>
  showLabel?: boolean
}

const DebtConsolidation: FC<TProps> = ({ useFormReturn: { control }, showLabel }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'debtConsolidation'
  })

  const appendNew = () => append(
    { apr: undefined, outstandingDebt: undefined, name: undefined }
  )

  const addButtonVisible = fields.length < 3

  useEffect(() => appendNew(), [])

  return (
    <>
      <WrapStyled>
        {fields.map(({ id }, index) => {
          const showRemoveButton = fields.length > 1
          const isLabel = !index || showLabel
          return (
            <Fragment key={id}>
              {!!index && <DividerStyled />}
              <FieldWrapStyled>
                <NameFieldStyled offsetMobile={!index}>
                  {isLabel && <CustomLabel label="Loan provider"/>}
                  <InputWrapStyled>
                    <CustomInput
                      name={`debtConsolidation.${index}.name`}
                      rules={{
                        required: requiredRule(),
                        maxLength: maxLengthRule(100)
                      }}
                      control={control}
                    />
                    {showRemoveButton && (
                      <RemoveIconWrapStyled mobileShow onClick={() => remove(index)}>
                        <RemoveIcon/>
                      </RemoveIconWrapStyled>
                    )}
                  </InputWrapStyled>
                </NameFieldStyled>
              </FieldWrapStyled>
              <GroupStyled>
                <FieldWrapStyled>
                  {isLabel && <CustomLabel label="Outstanding debt"/>}
                  <InputWrapStyled>
                    <CustomInput
                      name={`debtConsolidation.${index}.outstandingDebt`}
                      handleChange={floatFormat}
                      rules={{
                        required: requiredRule(),
                        validate: validateFloat()
                      }}
                      control={control}
                    />
                    {showRemoveButton && (
                      <RemoveIconWrapStyled mobileShow invisible>
                        <RemoveIcon/>
                      </RemoveIconWrapStyled>
                    )}
                  </InputWrapStyled>
                </FieldWrapStyled>

                <FieldWrapStyled>
                  <AprFieldStyled>
                    {isLabel && <CustomLabel label="APR"/>}
                    <RemoveContainerStyled>
                      <CustomInput
                        name={`debtConsolidation.${index}.apr`}
                        handleChange={floatFormat}
                        rules={{
                          required: requiredRule(),
                          validate: validateFloat()
                        }}
                        control={control}
                      />

                      {showRemoveButton && (
                        <RemoveIconWrapStyled mobileShow={false} onClick={() => remove(index)}>
                          <RemoveIcon/>
                        </RemoveIconWrapStyled>
                      )}
                    </RemoveContainerStyled>
                  </AprFieldStyled>
                </FieldWrapStyled>
              </GroupStyled>
            </Fragment>
          )
        })}
      </WrapStyled>

      {addButtonVisible && (
        <ButtonWrapper>
          <GreyButton onClick={() => appendNew()} text="ADD"/>
        </ButtonWrapper>
      )}
    </>
  )
}

export default DebtConsolidation
