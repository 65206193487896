import styled from 'styled-components/macro'
import React, { FC } from 'react'
import CustomAccordion from '../../../core/components/CustomAccordion'
import { flex, fontFamily } from '../../../core/styles/mixins'
import Ellipse from '../../../core/components/icons/svg/Ellipse.svg'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import MakeMyHouseGreenImageBackground from '../../../core/components/MakeMyHouseGreenImageBackground'
import { TInfoData } from '../../../core/assets/faq/types'
import defaultTellMeMoreData from '../data/defaultTellMeMoreData'
import cymruTellMeMoreData from '../data/cymruTellMeMoreData'
import { useSocialCreditLoanCalculatorContext } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import socialCreditPlendTellMeMoreData from '../data/socialCreditPlendTellMeMoreData'
import cymruPartnerOrOpenTellMeMoreData from '../data/cymruPartnerOrOpenTellMeMoreData'
import cymruPartnerOrOpenBankCheckTellMeMoreData from '../data/cymruPartnerOrOpenBankCheckTellMeMoreData'
import connectSocialCreditPlendBankCheckTellMeMoreData from '../data/connectSocialCreditPlendBankCheckTellMeMoreData'

const ContentStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  width: 100%;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.pink};
  @media (max-width: 375px) {
    padding: 0 10px;
  }
  ${({ theme }) => theme?.mixins?.bankCheck?.contentMixin}
`
const TitleStyled = styled.div<{ noMarginTop?: boolean }>`
  ${fontFamily('Mongoose')};
  margin-top: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 50px;
  }
  @media (max-width: 375px) {
    line-height: 56px;
  }
  
  ${({ theme }) => theme?.mixins?.bankCheck?.tellMeMoreTextMixin}
  
  ${({ noMarginTop }) => (noMarginTop ? 'margin-top: 0 !important;' : '')}
`

const TextStyled = styled.p<{ marginBottom?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  ${fontFamily('Open Sans')};
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  max-width: 996px;
  margin-top: 30px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '30px' : '0')};
`

const CardStyled = styled.div`
  max-width: 996px;
  width: 100%;
  padding: 24px 24px 24px 21px;
  margin: 30px 0 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  position: relative;
  &:before{
    content:  '';
    position: absolute;
    z-index: 1000000;
    right:-351px;
    bottom:0;
    background: url(${Ellipse})
    no-repeat center center/cover;
    width: 257px;
    height: 257px;
    @media (max-width: 1040px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    max-width: 728px;
    padding: 24px 16px;
  }
  @media (max-width: 375px) {
    padding: 24px 10px 24px 15px;
    margin: 30px 0;
  }
  
  ${({ theme }) => theme?.mixins?.bankCheck?.tellMeMoreMixin}
`

const filteredBankCheckInfo = defaultTellMeMoreData.filter(({ id }) => id !== 5)

function useInfo(bankCheckContent?: boolean) {
  const { isCymruPartner, isSocialOpenConnect } = useSocialCreditLoanCalculatorContext()
  const { currentOrganization } = useOrganizationContext()

  const infoMap: {[key in TOrganization]: () => TInfoData[] } = {
    lendology: () => filteredBankCheckInfo,
    purpleshoots: () => filteredBankCheckInfo,
    plend: () => defaultTellMeMoreData,
    makemyhousegreen: () => defaultTellMeMoreData,
    robertowen: () => defaultTellMeMoreData,
    socialcreditcymru: () => {
      if (bankCheckContent) {
        return cymruPartnerOrOpenBankCheckTellMeMoreData
      }
      return (isCymruPartner || isSocialOpenConnect)
        ? cymruPartnerOrOpenTellMeMoreData
        : cymruTellMeMoreData
    },
    socialcreditplend: () => {
      if (bankCheckContent) {
        return connectSocialCreditPlendBankCheckTellMeMoreData
      }
      return socialCreditPlendTellMeMoreData
    }
  }

  return infoMap[currentOrganization]()
}

type TProps = {
  bankCheckContent?: boolean
  noMarginTop?: boolean
}
const TellMeMore: FC<TProps> = ({ bankCheckContent, noMarginTop }) => {
  const { currentOrganization } = useOrganizationContext()
  const { isCymruPartner, isSocialOpenConnect } = useSocialCreditLoanCalculatorContext()

  const isMakemyhousegreen = currentOrganization === 'makemyhousegreen'
  const isCymru = currentOrganization === 'socialcreditcymru'

  const showExtraCymruText = !bankCheckContent && isCymru && !isCymruPartner && !isSocialOpenConnect

  return (
    <ContentStyled>
      {isMakemyhousegreen && <MakeMyHouseGreenImageBackground/>}
      {showExtraCymruText && (
        <>
          <TextStyled>
            Welcome to Social Credit Cymru. We are a social initiative composed of Plend,
            Robert Owen Community Bank & Purple Shoots.
          </TextStyled>
          <TextStyled marginBottom>
            We do not take direct applications, you must be directly referred to the initiative
            in order to be eligible. For further information - please see our FAQs below.
          </TextStyled>
        </>
      )}
      <TitleStyled noMarginTop={showExtraCymruText || noMarginTop}>Tell me more</TitleStyled>
      <CardStyled>
        {useInfo(bankCheckContent).map((info) => <CustomAccordion key={info.id} info={info}/>)}
      </CardStyled>

    </ContentStyled>
  )
}

export default TellMeMore
