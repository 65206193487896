import React, { FC, useCallback } from 'react'
import { useStepperContext } from '../../../../core/components/stepper/StepperContext'
import { SocialCreditStepperButton } from '../../../../core/components/buttons/SocialCreditStepperButton'
import { useSocialCreditLoanCalculatorContext } from '../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import {
  BoldTextStyled,
  ButtonWrapStyled,
  CardStyled,
  ContentStyled,
  LinkStyled,
  ListStyled,
  SubTitleStyled,
  TableStyled,
  TableWrapStyled,
  TextStyled,
  TitleStyled,
  WrapStyled
} from './styles'

const SocialCreditStepperCymruPrivacyPolicy: FC = () => {
  const {
    loanApplicationQuery: { refetch, isLoading },
    isCymruPartner
  } = useSocialCreditLoanCalculatorContext()

  const { nextStep } = useStepperContext()

  const submit = useCallback(() => {
    if (!isCymruPartner) {
      refetch()
    }
    nextStep()
  }, [isCymruPartner])

  return (
    <WrapStyled>
      <ContentStyled>
        <TitleStyled>Privacy Policy</TitleStyled>
        <CardStyled>
          <SubTitleStyled noMargin>
            Privacy notice for participants in the no interest loan scheme pilot project
          </SubTitleStyled>

          <BoldTextStyled>
            Background
          </BoldTextStyled>

          <TextStyled noMargin>
            We are providing you with this privacy notice because you have been invited by
            Social Credit Cymru to take
            part in the no interest loan scheme pilot project (the Pilot). The Pilot is a
            test to see if a no interest
            loan (NIL) can help you or people like you improve their financial position.
            By sharing your information
            with us, you will help us understand if the no interest loan Pilot is working
            and how we could make it
            better, and help us to help more people like you in the future.
          </TextStyled>

          <TextStyled>
            The Pilot is being coordinated by a not-for-profit organisation called
            Fair4All Finance Limited. Fair4All
            Finance helps lenders like yours to offer more affordable credit.
          </TextStyled>

          <TextStyled>
            To run the Pilot, we will need to collect and process certain information
            about you. This privacy notice
            explains how we (Social Credit Cymru and Fair4All Finance Limited) will
            {' '}
            <BoldTextStyled inline>jointly</BoldTextStyled>
            {' '}
            use your personal data (information about you) in connection with the Pilot.
          </TextStyled>

          <BoldTextStyled>
            Who are we?
          </BoldTextStyled>

          <TextStyled>
            Social Credit Cymru and Fair4All Finance Limited are working together to
            deliver the Pilot and are both in
            charge of the way your personal data is used as part of the Pilot. For
            the purposes of data protection law
            therefore, Social Credit Cymru and Fair4All Finance Limited are known as
            “joint controllers”.
          </TextStyled>

          <BoldTextStyled>
            Fair4All Finance Limited
          </BoldTextStyled>

          <TextStyled>
            PO Box 2nd Floor,
            <br/>
            28 Commercial Street,
            <br/>
            London, E1 6LS
          </TextStyled>

          <BoldTextStyled>
            Social Credit Cymru
          </BoldTextStyled>

          <TextStyled>
            Royal Welsh Warehouse 3rd Floor
            <br/>
            17 Old Kerry Road
            <br/>
            Newton, Powys
            <br/>
            Wales
            <br/>
            SY16 1BH
          </TextStyled>

          <TextStyled>
            If you would like to contact us about any aspect of this privacy notice or
            would like to exercise your
            rights or obtain further information about the way that we process your
            personal data, then please contact:
            {' '}
            <LinkStyled href="mailto:data@fair4allfinance.org.uk">
              data@fair4allfinance.org.uk
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            What personal data are we collecting and why?
          </BoldTextStyled>

          <TextStyled>
            As part of the Pilot, we will collect and use different types of
            personal data about you at different
            stages. Below we set out further information about the types of
            personal data we will collect and use, as
            well as our reasons for this:
          </TextStyled>

          <TableWrapStyled>
            <TableStyled>
              <tr>
                <td>
                  <BoldTextStyled noMargin>
                    Why we are collecting your personal data
                  </BoldTextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    What personal data we are collecting
                  </BoldTextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Lawful basis (as explained below)
                  </BoldTextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    When you are invited to apply for a no interest loan, we will
                    process information about you to see if
                    you are eligible. This will help us understand who is eligible
                    for no interest loans and who isn’t,
                    and to make changes to try and help as many people as possible.
                    We may also use this data for case
                    studies to demonstrate the wider value of the pilot.
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>
                    This will include:
                  </TextStyled>
                  <ListStyled noMargin>
                    <li>
                      Information allowing us to identify you (such as your name,
                      address and customer reference
                      number)
                    </li>
                    <li>
                      Information about your personal and financial circumstances
                      (such as your income, expenditure,
                      employment status, credit score, whether you are receiving
                      benefits, information about previous
                      loans you may have applied for, the Legitimate interests
                      (see explanation below) 14603047.1reason
                      you want to take out a loan);
                    </li>
                    <li>
                      Other information we may receive from third parties if
                      you are referred to us – which may also
                      include the information above
                    </li>
                  </ListStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Legitimate interests (see explanation below)
                  </BoldTextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    We will collect demographic data to help us understand how
                    the Pilot is working overall, who it is
                    reaching and if we are not reaching groups of people who may
                    benefit from it
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>
                    This will include:
                    <ListStyled noMargin>
                      <li>
                        Information about your age, background (including your
                        ethnic origins and religion), gender,
                        sexual orientation, disabilities, single parent status,
                        refugee status, housing type, education
                        level, work experience and caring responsibilities.
                      </li>
                    </ListStyled>
                  </TextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Legitimate interests
                  </BoldTextStyled>
                  <BoldTextStyled noMargin>
                    (see explanation below)
                  </BoldTextStyled>

                  <TextStyled>
                    To the extent that the information you provide is a special category data
                    (which includes data
                    relating to ethnicity, religion, sexual orientation, health
                    (including disabilities)), we collect and
                    use this on the basis of your
                    {' '}
                    <BoldTextStyled inline>explicit consent</BoldTextStyled>
                    {' '}
                    or because it is
                    {' '}
                    <BoldTextStyled inline>necessary to ensure equal opportunity</BoldTextStyled>
                    {' '}
                    / treatment.
                  </TextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    We will collect certain information about you if you are selected
                    for financial coaching so that we
                    can assist you with achieving your financial goals and to provide
                    you with information and advice that
                    may be helpful to you in relation to debts, budgeting and other
                    financial matters.
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>

                    This will include:
                    <ListStyled noMargin>
                      <li>
                        Information about your personal and household income and expenditure
                      </li>
                      <li>
                        Information about financial products you hold or have held
                        in the past e.g. a bank account, credit
                        products, savings products etc.
                      </li>
                      <li>
                        Information about your levels of debt (including payment
                        or non-payment of bills), savings and/or
                        credit use
                      </li>
                      <li>
                        Information about your credit score
                      </li>
                      <li>
                        Information about your personal financial goals associated
                        with the NILS loan e.g. employment,
                        training, transport, housing etc.
                      </li>
                      <li>
                        Other information about your financial and personal
                        circumstances impacted by the NILS loan
                      </li>
                    </ListStyled>
                  </TextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Legitimate interests
                  </BoldTextStyled>
                  <BoldTextStyled noMargin>
                    (see explanation below)
                  </BoldTextStyled>

                  <TextStyled>
                    To the extent that the information you provide is a special
                    category data (which includes data
                    relating to ethnicity, religion, sexual orientation, health
                    (including disabilities)), we collect and
                    use this on the basis of your
                    {' '}
                    <BoldTextStyled inline>explicit consent</BoldTextStyled>
                  </TextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    We will collect certain information about you in the course
                    of tracking, monitoring and analysing how
                    you are repaying the loan. This will allow us to understand
                    the potential benefits of no interest
                    loans for people in different situations, to identify common
                    obstacles and to develop products that
                    may be helpful to you and other customers.
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>
                    This will include:

                    <ListStyled noMargin>
                      <li>
                        Information about your loan repayments;
                      </li>
                      <li>
                        Information about your financial and personal circumstances;
                      </li>
                      <li>
                        Changes to your credit score and how this has been
                        impacted by a no-interest loan.
                      </li>
                    </ListStyled>
                  </TextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Legitimate interests
                  </BoldTextStyled>
                  <BoldTextStyled noMargin>
                    (see explanation below)
                  </BoldTextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    After you have paid off the loan, we will collect further
                    information about your financial
                    circumstances (e.g. any commercial loans that you will take
                    out with the same lender) to assess how
                    effective the Pilot is in the long run.
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>
                    This will include:

                    <ListStyled noMargin>
                      <li>
                        Contact details (telephone number, email address, address);
                      </li>
                      <li>
                        Information about your financial circumstances, including
                        any further loans you apply for, and if
                        the Pilot has impacted your financial circumstances.
                      </li>
                      <li>
                        Changes to your credit score and how this has been impacted
                        by a no-interest loan
                      </li>
                    </ListStyled>
                  </TextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Legitimate interests
                  </BoldTextStyled>
                  <BoldTextStyled noMargin>
                    (see explanation below)
                  </BoldTextStyled>
                </td>
              </tr>

              <tr>
                <td>
                  <TextStyled noMargin>
                    In order to understand the full impact of the no interest loan
                    scheme and demonstrate the wider value
                    as well as gather vital customer feedback we will be asking
                    customers to complete surveys at different
                    stages of the loan processes.
                  </TextStyled>
                  <TextStyled>
                    Survey data will be pseudonymised (coded to prevent you being
                    easily identified) and will have no
                    impact on loan eligibility.
                  </TextStyled>
                  <TextStyled noMargin>
                    Survey completions will be optional and any question within can be declined.
                  </TextStyled>
                </td>
                <td>
                  <TextStyled noMargin>
                    This will include:

                    <ListStyled noMargin>
                      <li>
                        Scaled questions on how you feel about your financial confidence
                        and wellbeing
                      </li>
                      <li>
                        Agree and disagree selections to statements about borrowers
                        situation, opinions and experience.
                      </li>
                      <li>
                        Free text feedback and questions about the scheme
                      </li>
                      <li>
                        Category selection and tick boxes about personal finances and behaviour
                      </li>
                      <li>
                        Other additional questions that may arise from as the pilot
                        progresses such as changes
                      </li>
                    </ListStyled>
                  </TextStyled>
                </td>
                <td>
                  <BoldTextStyled noMargin>
                    Consent
                  </BoldTextStyled>

                  <TextStyled>
                    To the extent that the information you provide is special category
                    data (for example information about
                    your health as part of the questions on wellbeing), we collect and
                    use this on the basis of your
                    {' '}
                    <BoldTextStyled inline>
                      explicit consent
                    </BoldTextStyled>
                    .
                  </TextStyled>

                  <TextStyled italic>
                    Please note, it is not mandatory to complete the survey(s) in order
                    to take part in the pilot, you can
                    choose whether you would like to be contacted by us in future to
                    share your experiences.
                  </TextStyled>
                </td>
              </tr>

            </TableStyled>
          </TableWrapStyled>

          <TextStyled>
            Some of the personal data we need to collect in connection with the Pilot
            is more sensitive. We call this
            “special category personal data”. Special category personal data is
            defined in law as meeting any personal
            data that relates to health, racial or ethnic origin, political opinions,
            sexual orientation, sexual life,
            religious and philosophical beliefs, as well as genetic and biometric
            data (where used for identification
            purposes).
          </TextStyled>

          <TextStyled>
            We are processing special category data to understand the types of
            people are applying for, using and
            benefiting from the no interest loan scheme. We only process the
            following types of special category
            personal data:
          </TextStyled>

          <ListStyled>
            <li>
              <BoldTextStyled inline>Information about your race / ethnic origin</BoldTextStyled>
              {' '}
              and
              {' '}
              <BoldTextStyled inline>sexual orientation</BoldTextStyled>
              . This helps us to make sure that the Pilot is inclusive
              and reaches all who can benefit;
            </li>
            <li>
              Information about your
              {' '}
              <BoldTextStyled inline>health</BoldTextStyled>
              {' '}
              (including details of any disabilities) (for example, if you
              tell the lender that you need a loan because
              of an accident or illness); and
            </li>
            <li>
              Information about your
              {' '}
              <BoldTextStyled inline>religious or philosophical beliefs</BoldTextStyled>
              {' '}
              (for example, if you are Muslim and your loan must be managed
              in a way that complies with Sharia law).
            </li>
          </ListStyled>

          <TextStyled>
            For all of the above, we will also overlay additional demographic,
            product and lifestyle data from CACI
            (www.caci.co.uk) to help us understand how you, or other people
            like you, interact with financial services,
            your financial circumstances and your marketing preferences.
          </TextStyled>

          <TextStyled>
            We will then apply this to segmentations – this is often described
            as “profiling” and we create “profiles”
            using combinations of the information you provide, and data from
            other places, including publicly available
            data (such as the electoral register and the UK Census) or derived
            from surveys and questionnaires.
          </TextStyled>
          <TextStyled>
            This will help us design the right products and services, and
            deliver appropriate marketing, to customers
            who can most benefit from different products. While the segmentation
            will involve profiling, no
            organisations will make decisions on an individual from this data,
            instead it will be used to inform
            marketing, operational and product strategiesand could help us develop
            products that are better for you.
          </TextStyled>
          <TextStyled>
            Please note, in order for Social Credit Cymru to process your loan
            application, they will also need other
            information (such as your bank details). This will never be shared
            with anyone else, including Fair4All
            Finance. For more information, please see Social Credit Cymru ’s
            privacy notice.
          </TextStyled>

          <BoldTextStyled>
            Our lawful basis for processing your personal data
          </BoldTextStyled>

          <TextStyled noMargin>
            Data protection law requires us to have a valid legal reason (also
            known as a “lawful basis”) to process and
            use your personal data. In connection with the Pilot, we rely on the
            following lawful bases:
          </TextStyled>

          <ListStyled>
            <li>
              <BoldTextStyled inline>Legitimate interests:</BoldTextStyled>
              {' '}
              We need to process your personal data because it is necessary for
              our legitimate interests (or
              occasionally the interests of a third party), but provided those
              interests are not outweighed by the
              impact to your own rights.
            </li>
            <li>
              The purpose of processing your personal data is in order that Social Credit
              Cymru can fulfil your loan
              agreement and to help us help other people like you in the future, including
              other people who may benefit
              from a no interest loan. We also need to process your personal data to
              understand the way no interest
              loans may benefit society.
            </li>
            <li>
              Here, the relevant legitimate interests we have identified relate to the
              outcomes of the Pilot (i.e.
              aiming to provide access to fair, affordable and appropriate financial
              products and services to people in
              financially vulnerable circumstances). Specifically:
              <ListStyled noMargin>
                <li>
                  to establish whether it is possible to sustainably deliver a permanent
                  no interest loan product in the
                  UK;
                </li>
                <li>
                  to obtain evidence about customer experiences and the wider benefits of
                  no interest loans, including
                  who is eligible and who isn’t, and how we improve access to the widest
                  range of people who can
                  benefit;
                </li>
                <li>
                  understand how we can further develop the NIL or other products offering
                </li>
                <li>
                  understand which customer groups would still be excluded from a no
                  interest loan offering.
                </li>
              </ListStyled>
            </li>
            <li>
              <BoldTextStyled inline>Consent:</BoldTextStyled>
              {' '}
              In some cases, we may also rely on your consent for your personal data
              to be used in connection with the
              Pilot. For example, where we you provide us with information in
              evaluation surveys.
            </li>
          </ListStyled>

          <TextStyled>
            When processing special category personal data, we also need to identify
            an appropriate condition for
            processing this information (in addition to a lawful basis). These are:
          </TextStyled>

          <ListStyled>
            <li>
              <BoldTextStyled inline>Reasons of substantial public interest:</BoldTextStyled>
              {' '}
              We process special category personal data where it is necessary to
              do so for reasons of substantial public interest. For example, where
              it is necessary to ensure equal
              opportunity or treatment of people from different racial/ethnic origins,
              religious or philosophical
              beliefs, health or sexual orientations.
            </li>
            <li>
              <BoldTextStyled inline>Explicit consent: </BoldTextStyled>
              {' '}
              Where you have given us your explicit consent to use your special
              category personal
              data. For example, if you complete an evaluation survey and would like
              to provide information about your
              health (if this is relevant to how the Pilot has helped your wellbeing)
              then this will be on the basis of
              explicit consent. Whenever we rely on your consent, you can withdraw it
              at any time by contacting us using
              the information at in the “
              <BoldTextStyled inline>Who are we?</BoldTextStyled>
              ” section above.
            </li>
          </ListStyled>

          <TextStyled>
            Please note, Social Credit Cymru will also need to process your personal
            data in order to enter into a loan
            agreement with you. This falls outside the scope of the Pilot and is
            dealt with in Social Credit Cymru ’s
            privacy notice.
          </TextStyled>

          <TextStyled>
            We do not carry out any automated decision-making using personal data
            which produces legal effects or
            otherwise significantly affects individuals.
          </TextStyled>

          <BoldTextStyled>
            How we use your personal data and how we keep it secure
          </BoldTextStyled>

          <TextStyled noMargin>
            We understand the importance of security of your personal data and take
            appropriate steps to safeguard it.
          </TextStyled>

          <TextStyled>
            Social Credit Cymru is responsible for day-to-day contact with you as
            they will be also administering your
            loan. When we use your personal data for the Pilot, we will take steps
            to make sure it is more difficult to
            identify you from the information we use (for example, by using statistics
            instead of information on an
            individual level, using a code instead of your name, or in case studies
            changing any identifiable
            information such as name, age, location).
          </TextStyled>

          <TextStyled>
            We always ensure that only authorised persons have access to your personal
            data, which means only those who
            need to access your data to fulfil their roles. Everyone who has access
            to personal data is appropriately
            trained and aware of their obligations to ensure confidentiality and security
            of your personal data.
          </TextStyled>

          <BoldTextStyled>
            Sharing with third parties and transfers outside the UK
          </BoldTextStyled>

          <TextStyled>
            We may share your personal data with other third parties involved in the
            Pilot in order to monitor the
            performance of the loans and to help develop new products to offer to
            customers in the future. This may
            include:
          </TextStyled>

          <ListStyled>
            <li>
              Evaluation partners who will conduct evaluation work to understand
              if the pilot is working;
            </li>
            <li>
              Data analytic specialist organisations we appoint to help us
              analyse the pilot’s performance and, for
              example to consider what other support you have applied for
              with other lenders; and
            </li>
            <li>
              Credit Reference Agencies, who will examine your files post
              the loan being paid back in full in order to
              see if your file has improved over time on a sustained basis
              after the no interest loan and the extent to
              which your subsequent debt position has reduced or increased.
              Please see the Credit Reference Agency
              Information Notices (CRAIN) here:

              <ListStyled noMargin>
                <li>
                  <LinkStyled
                    target="_blank"
                    href="https://www.transunion.co.uk/legal/privacy-centre/pc-credit-reference">
                    www.transunion.co.uk/legal/privacy-centre/pc-credit-reference
                  </LinkStyled>
                </li>

                <li>
                  <LinkStyled target="_blank" href="https://www.equifax.co.uk/crain">
                    www.equifax.co.uk/crain
                  </LinkStyled>
                </li>
                <li>
                  <LinkStyled target="_blank" href="https://www.experian.co.uk/legal/crain/">
                    www.experian.co.uk/legal/crain/;
                  </LinkStyled>
                </li>
              </ListStyled>
            </li>

            <li>
              Contractors, suppliers, or other third parties that provide services on our behalf;
            </li>

            <li>
              Other lenders participating in the Pilot or other Pilots funded
              by Fair4All Finance in the event that
              there is any indication that you have applied for a second or
              multiple NILS loans with other Pilot lenders
              or applied for other Fair4All Finance funded pilots eg
              consolidation loans or other pilot schemes
            </li>

            <li>
              A third party as part of a sale, merger or acquisition, or
              other transfer of all or part of our assets
              including as part of a bankruptcy proceeding;
            </li>

            <li>
              Pursuant to a subpoena, court order, or other legal process
              or as otherwise required or requested by law,
              regulation, or government authority programs, or to protect
              our rights or the rights or safety of third
              parties;
            </li>

            <li>
              Our professional advisors, lawyers, accountants and auditors; or
            </li>

            <li>
              With your consent or as otherwise disclosed at the time of data collection or sharing.
            </li>
          </ListStyled>

          <TextStyled>
            We do not plan to transfer your personal data outside the UK at
            this stage, but we will let you know if this
            changes.
          </TextStyled>

          <BoldTextStyled>
            Source of your personal data
          </BoldTextStyled>

          <TextStyled noMargin>
            Usually, the personal data we process will come directly from
            you. In some cases, however, we may also
            collect your information from third parties, for example, if
            you have been referred to the Pilot by a third
            party such as a Housing Association, charity, or other
            organisation we work with.
          </TextStyled>

          <BoldTextStyled>
            How long we store your personal data
          </BoldTextStyled>

          <TextStyled>
            We delete or destroy personal data securely when the purpose
            for collecting it has been met and there are no
            other legal reasons for keeping it. For instance, we propose
            to retain most records relating to the Pilot
            until the evaluation phase has been completed, which is
            expected to be approximately 6 years.
          </TextStyled>

          <TextStyled>
            We continually review the personal data we hold and will
            securely delete or anonymise personal data which is
            no longer required. As part of this review, we will consider
            (i) the respective statutory retention period;
            (ii) our contractual and/or business relationships with you;
            (iii) (potential) disputes; and (iv) any
            guidelines issued by relevant regulators.
          </TextStyled>

          <TextStyled>
            If you would like further information about our retention periods
            for specific types of information, please
            get in touch.
          </TextStyled>

          <BoldTextStyled>
            Your rights
          </BoldTextStyled>

          <TextStyled noMargin>
            Under data protection law, you have various rights over the
            personal data we hold about you. In particular,
            you have the right to:
          </TextStyled>

          <ListStyled>
            <li>
              be informed about how we collect and use your personal data;
            </li>
            <li>
              ask for access to your personal data;
            </li>
            <li>
              require us to correct any mistakes in the personal data we hold about you;
            </li>
            <li>
              ask for the information we hold about you to be erased in certain circumstances;
            </li>
            <li>
              ask for our processing of your personal data to be restricted
              in certain circumstances;
            </li>
            <li>
              in certain circumstances, receive your data in a form allowing
              you to transit it to another data
              controller (portability); and
            </li>
            <li>
              object to us processing your information in certain circumstances.
            </li>
          </ListStyled>

          <TextStyled>
            Where we rely on your consent to process your personal data, you
            have the right to withdraw that consent at
            any time. Please note, if you decide to withdraw your consent,
            that doesn’t mean that our of your personal
            data before you withdrew your consent is against the law.
          </TextStyled>

          <TextStyled>
            If you wish to exercise any of your rights, please contact
            us using the information in the “
            <BoldTextStyled inline>
              Who are we?
            </BoldTextStyled>
            ” section above.
          </TextStyled>

          <BoldTextStyled>
            Complaints
          </BoldTextStyled>

          <TextStyled noMargin>
            We have high standards when it comes to protecting your
            personal data. If at any time you are not happy
            about the way we are processing your personal data then
            please contact us using the details in the “
            <BoldTextStyled inline>
              Who are we?
            </BoldTextStyled>
            ” section above.
          </TextStyled>

          <TextStyled>
            If you are not happy with our answer to your complaint then
            you can complain to the Information
            Commissioner’s Office, which regulates and enforces data
            protection law in the UK. Details of how to do this
            can be found at
            {' '}
            <LinkStyled target="_blank" href="https://www.ico.org.uk">
              www.ico.org.uk
            </LinkStyled>
            .
          </TextStyled>

          <BoldTextStyled>
            Changes to this privacy notice
          </BoldTextStyled>

          <TextStyled noMargin>
            This privacy notice was published on 21st December 2022. We will
            update and change this Privacy Notice from
            time to time to keep it up to date and accurate. If we make any
            major changes then Social Credit Cymru will
            let you know.
          </TextStyled>

        </CardStyled>

        <ButtonWrapStyled>
          <SocialCreditStepperButton text="Accept" onClick={submit} disabled={isLoading}/>
        </ButtonWrapStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default SocialCreditStepperCymruPrivacyPolicy
