import { FC, ReactNode } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import CymruPrivacyPolicy from './full/SocialCreditPrivacyPolicy'

const map: {[key in TOrganization]?: ReactNode} = {
  socialcreditplend: <CymruPrivacyPolicy noMenu />,
  socialcreditcymru: <CymruPrivacyPolicy />
}

const PrivacyPolicy: FC = () => {
  const { currentOrganization } = useOrganizationContext()

  return <>{map[currentOrganization]}</>
}

export default PrivacyPolicy
