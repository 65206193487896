import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router-dom'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import { flex, fontFamily } from '../../../core/styles/mixins'
import Spiral from '../../../core/components/icons/svg/Spiral.svg'
import Ellipse from '../../../core/components/icons/svg/EllipseOrange.svg'
import HouseGreenHeader from '../../mainLayout/components/header/HouseGreenHeader'
import MakemyhousegreenThankYouContent from './content/defaultContent/MakemyhousegreenThankYouContent'
import PlendThankYouContent from './content/defaultContent/PlendThankYouContent'
import LendologyThankYouContent from './content/defaultContent/LendologyThankYouContent'
import PurpleshootsThankYouContent from './content/defaultContent/PurpleshootsThankYouContent'
import RobertOwenThankYouContent from './content/defaultContent/RobertOwenThankYouContent'
import PlendThankTouResubmitContent from './content/resubmitContent/PlendThankTouResubmitContent'
import CymruThankYouContent from './content/defaultContent/CymruThankYouContent'
import ConnectSocialCreditPlendThankYouContent from './content/defaultContent/ConnectSocialCreditPlendThankYouContent'

const ContainerStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.ivory};
  @media (max-width: 375px) {
    padding: 0 10px
  }

  ${({ theme }) => theme?.mixins?.accounts?.containerAccountsMixin};
  
`

const LogoStyled = styled.img<{ width: number, height: number }>`
  display: block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  object-fit: contain;
  margin: 16px auto;
`

const LogoWrapper = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    right: 370px;
    top: 8px;
    background: url(${Spiral})
    no-repeat center center/cover;
    width: 581px;
    height: 66px;
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }

  ${({ theme }) => theme?.mixins?.hideFiguresMixin};
`

const MessageStyled = styled.div`
  max-width: 840px;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 1000000;
    right: -398px;
    bottom: 86px;
    background: url(${Ellipse})
    no-repeat center center/cover;
    width: 195px;
    height: 195px;
    ${({ theme }) => theme?.mixins?.hideFiguresMixin};
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media (max-width: 768px) {
    margin: 30px;
  }

  ${({ theme }) => theme?.mixins?.accounts?.messageAccountsMixin};
`

export const TextStyled = styled.p`
  ${fontFamily('Neue')};
  font-weight: 450;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: .4px;
  margin-bottom: 24px;
  text-align: justify;

  @media (max-width: 375px) {
    font-size: 20px;
  }

  ${({ theme }) => theme?.mixins?.accounts?.textAccountsMixin};
`

const headerMap: { [key in TOrganization]?: ReactNode } = {
  makemyhousegreen: <HouseGreenHeader/>
}

const resubmitContentMap: { [key in TOrganization]?: ReactNode } = {
  plend: <PlendThankTouResubmitContent/>,
  makemyhousegreen: <MakemyhousegreenThankYouContent/>,
  socialcreditplend: <ConnectSocialCreditPlendThankYouContent/>
}

const defaultContentMap: { [key in TOrganization]?: ReactNode } = {
  plend: <PlendThankYouContent/>,
  lendology: <LendologyThankYouContent/>,
  purpleshoots: <PurpleshootsThankYouContent/>,
  makemyhousegreen: <MakemyhousegreenThankYouContent/>,
  robertowen: <RobertOwenThankYouContent/>,
  socialcreditcymru: <CymruThankYouContent/>,
  socialcreditplend: <ConnectSocialCreditPlendThankYouContent/>
}

type TLocationState = {
  state: {
    contentType?: 'resubmit'
  }
}

const ThankYou = () => {
  const { state } = useLocation() as TLocationState
  const { logo: { url, width, height }, currentOrganization } = useOrganizationContext()
  const contentMap = state?.contentType === 'resubmit' ? resubmitContentMap : defaultContentMap
  return (
    <ContainerStyled>
      {headerMap[currentOrganization]}
      <LogoWrapper>
        <LogoStyled width={width} height={height} src={url} alt="logo"/>
      </LogoWrapper>
      <MessageStyled>
        {contentMap[currentOrganization]}
      </MessageStyled>
    </ContainerStyled>
  )
}

export default ThankYou
