import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useStepperContext } from '../../../core/components/stepper/StepperContext'
import { fontFamily } from '../../../core/styles/mixins'
import { SocialCreditStepperButton } from '../../../core/components/buttons/SocialCreditStepperButton'
import CustomSelect, { TOption } from '../../../core/components/CustomSelect'
import { useAllExistingLoanPurposesQuery } from '../querries'
import { useSocialCreditLoanCalculatorContext } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { maxLengthRule, requiredRule } from '../../../core/utils/formRules'
import CustomInput from '../../../core/components/CustomInput'
import DebtConsolidation from './DebtConsolidation'
import { TFormData } from '../types'
import { useOrganizationContext } from '../../../core/state/useOrganization'

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 50px;
  }

  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
`

const ContentStyled = styled.div`
  width: 996px;
`

const TitleStyled = styled.div`
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.stepper?.stepperTitleMixin}
`

const CardStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-top: 50px;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  min-height: 388px;
`

const TextHeaderStyled = styled.div<{ noMarginTop?: boolean }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? 0 : '20px')};

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

const ControlWrapStyled = styled.div`
  margin-top: 20px;
`

const ButtonWrapStyled = styled.ul`
  display: flex;
  justify-content: flex-end;
`

export function useOptions({ watch }: UseFormReturn<TFormData>) {
  const { isCalculatorInitialized } = useSocialCreditLoanCalculatorContext()
  const { data: allExistingLoanPurposes } = useAllExistingLoanPurposesQuery(isCalculatorInitialized)

  const reasonLevelOneValue = watch('reasonLevelOne')

  const reasonLevelOneOptions: TOption[] = useMemo(
    () => (allExistingLoanPurposes
      ? Object.keys(allExistingLoanPurposes).map((item) => ({
        label: item,
        value: item
      }))
      : []),
    [allExistingLoanPurposes]
  )
  const reasonLevelTwoOptions: TOption[] = useMemo(
    () => (allExistingLoanPurposes?.[reasonLevelOneValue] || []).map((item: string) => ({
      label: item,
      value: item
    })),
    [allExistingLoanPurposes, reasonLevelOneValue]
  )

  return {
    reasonLevelOneOptions,
    reasonLevelTwoOptions
  }
}

export function useFieldsReset({ watch, setValue }: UseFormReturn<TFormData>) {
  const reasonLevelOneValue = watch('reasonLevelOne')

  useEffect(() => {
    setValue('reasonLevelTwo', undefined)
    setValue('otherText', undefined)
  }, [reasonLevelOneValue])
}

export function useFieldsShowing({ watch }: UseFormReturn<TFormData>, levelTwoOptions: TOption[]) {
  const reasonLevelOneValue = watch('reasonLevelOne')

  const showOtherText = reasonLevelOneValue === 'Other (Please specify)'

  const levelTwoOptionsExist = levelTwoOptions.length && !!levelTwoOptions[0].value

  return {
    showOtherText,
    showDebtConsolidation: watch('reasonLevelTwo') === 'Debt consolidation',
    showAdditionalReason: reasonLevelOneValue && levelTwoOptionsExist && !showOtherText
  }
}

function useSubmit({ getValues, formState: { isValid } }: UseFormReturn<TFormData>) {
  const { setLoanPurpose, setDebtConsolidation } = useSocialCreditLoanCalculatorContext()
  const { nextStep } = useStepperContext()

  return {
    submitHandle: useCallback(() => {
      const { reasonLevelTwo, reasonLevelOne, otherText, debtConsolidation } = getValues()

      setLoanPurpose(otherText || reasonLevelTwo || reasonLevelOne)
      setDebtConsolidation(debtConsolidation)
      nextStep()
    }, []),
    isSubmitDisabled: !isValid
  }
}

const SocialCreditLoanPurpose: FC = () => {
  const useFormReturn = useForm<TFormData>({ shouldUnregister: true, mode: 'onChange' })
  const { control } = useFormReturn
  const { reasonLevelOneOptions, reasonLevelTwoOptions } = useOptions(useFormReturn)
  const {
    showOtherText,
    showAdditionalReason,
    showDebtConsolidation
  } = useFieldsShowing(useFormReturn, reasonLevelTwoOptions)
  const { submitHandle, isSubmitDisabled } = useSubmit(useFormReturn)
  useFieldsReset(useFormReturn)
  return (
    <WrapStyled>
      <ContentStyled>
        <TitleStyled>Loan Purpose</TitleStyled>
        <CardStyled>
          <TextHeaderStyled noMarginTop>
            {useOrganizationContext().currentOrganization === 'socialcreditplend'
              ? 'Please specify the reason you are applying for Social Credit loan:'
              : 'Please specify the reason you are applying for Social Credit Cymru loan:'}
          </TextHeaderStyled>

          <ControlWrapStyled>
            <CustomSelect
              name="reasonLevelOne"
              placeholder="Please select the reason"
              control={control}
              options={reasonLevelOneOptions}
            />
          </ControlWrapStyled>

          {showAdditionalReason && (
            <ControlWrapStyled>
              <CustomSelect
                name="reasonLevelTwo"
                placeholder="Please select the reason"
                control={control}
                options={reasonLevelTwoOptions}
              />
            </ControlWrapStyled>
          )}

          {showOtherText && (
            <ControlWrapStyled>
              <CustomInput
                multiline
                minRows={7}
                name="otherText"
                control={control}
                rules={{
                  required: requiredRule(),
                  maxLength: maxLengthRule(255)
                }}
              />
            </ControlWrapStyled>
          )}

          {showDebtConsolidation && (
            <DebtConsolidation useFormReturn={useFormReturn} />
          )}
        </CardStyled>

        <ButtonWrapStyled>
          <SocialCreditStepperButton key="CymruLoanPurpose" text="Continue" disabled={isSubmitDisabled} onClick={submitHandle} />
        </ButtonWrapStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default SocialCreditLoanPurpose
