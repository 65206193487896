/* eslint-disable react/no-unescaped-entities */
import { FC } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useStepperContext } from '../../../../../core/components/stepper/StepperContext'
import { SocialCreditStepperButton } from '../../../../../core/components/buttons/SocialCreditStepperButton'
import CustomCheckBox from '../../../../../core/components/CustomCheckBox'
import {
  useSocialCreditLoanCalculatorContext
} from '../../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import {
  AfterTextStyled,
  BoldTextStyled,
  CardStyled,
  CheckboxesTitleStyled,
  ContentStyled,
  CustomCheckBoxContainerStyled,
  CustomLabelStyledStyled,
  LinkStyled,
  ListStyled,
  SubTitleStyled,
  TextStyled,
  TitleStyled,
  WrapStyled
} from './styles'

type TForm = {
  checkbox1: boolean
  checkbox2: boolean
  checkbox3: boolean
  checkbox4: boolean
}

function useSubmitHandle({ watch }: UseFormReturn<TForm>) {
  const { nextStep } = useStepperContext()

  return {
    isSubmitDisabled: Object.values(watch()).some((value) => !value),
    handleSubmit: nextStep
  }
}

const CymruTerms: FC = () => {
  const useFormReturn = useForm<TForm>({
    mode: 'onChange',
    defaultValues: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false
    }
  })

  const { control } = useFormReturn

  const { isSubmitDisabled, handleSubmit } = useSubmitHandle(useFormReturn)

  const { monthlyPayment, period, loanAmount } = useSocialCreditLoanCalculatorContext()

  const totalRepayment = 0

  return (
    <WrapStyled>
      <ContentStyled>
        <TitleStyled>Terms, conditions and pre-contract information</TitleStyled>
        <CardStyled>
          <SubTitleStyled noMargin>
            PRE-CONTRACT CREDIT INFORMATION
          </SubTitleStyled>

          <BoldTextStyled noMargin>
            (Pre-contract Consumer Credit Information)
          </BoldTextStyled>

          <BoldTextStyled>
            1. Contact details
            <br/>
            Creditor:
          </BoldTextStyled>

          <TextStyled noMargin>
            Robert Owen Community Banking Fund Limited trading as Social Credit Cymru
          </TextStyled>

          <BoldTextStyled>
            Address:
          </BoldTextStyled>

          <TextStyled noMargin>
            Royal Welsh Warehouse 3rd Floor
            <br/>
            17 Old Kerry Road
            <br/>
            Newton, Powys
            <br/>
            Wales
            <br/>
            SY16 1BH
          </TextStyled>

          <BoldTextStyled>
            Telephone number(s):
          </BoldTextStyled>

          <TextStyled noMargin>
            +44 0808 1752530
          </TextStyled>

          <BoldTextStyled>
            E-mail address:
          </BoldTextStyled>

          <TextStyled noMargin>
            <LinkStyled href="mailto:support@socialcredit.cymru">
              support@socialcredit.cymru
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            Web address:
          </BoldTextStyled>

          <TextStyled noMargin>
            <LinkStyled target="_blank" href="https://socialcredit.cymru/">
              https://socialcredit.cymru/
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            2. Key features of the credit product
            <br/>
            The type of credit:
          </BoldTextStyled>

          <TextStyled noMargin>
            Fixed sum, unrestricted use, personal loan.
          </TextStyled>

          <BoldTextStyled>
            The Total Amount of Credit.
          </BoldTextStyled>

          <TextStyled noMargin italic>
            This means the amount of credit to be provided under the proposed
            credit agreement or the credit limit.
          </TextStyled>

          <TextStyled noMargin>
            £
            {loanAmount}
          </TextStyled>

          <BoldTextStyled>
            How and when credit would be provided
          </BoldTextStyled>

          <TextStyled noMargin>
            When both yourself and the Creditor has signed the Loan Agreement, a loan
            account will be opened in your
            name ('the account'). The Creditor will deposit the amount of the advance
            into your nominated bank account
            within three business days by faster payment or similar electronic method
            (unless you are taking out a new
            loan to part repay an existing loan with us in which case the balance will
            be transferred to your nominated
            account).
          </TextStyled>

          <BoldTextStyled>
            The duration of the credit agreement
          </BoldTextStyled>

          <TextStyled noMargin>
            {period}
            {' '}
            months.
          </TextStyled>

          <BoldTextStyled>
            Repayments
          </BoldTextStyled>

          <TextStyled noMargin>
            Your monthly repayments of £
            {monthlyPayment}
            , repayable on
            the first day of every month
            throughout the duration of the Loan Agreement.
          </TextStyled>

          <BoldTextStyled>
            The total amount you will have to pay.
          </BoldTextStyled>

          <TextStyled italic noMargin>
            This means the amount you have borrowed plus interest
          </TextStyled>

          <TextStyled noMargin>
            The Total Amount Payable: £
            {totalRepayment}
            , being the sum of:
            <br/>
            the Total Amount of Credit of £
            {loanAmount}
            ; and
            <br/>
            the Total Charge for Credit of £0.00 (comprising interest of £0.00)
          </TextStyled>

          <BoldTextStyled>
            3. Costs of the credit
            <br/>
            The rates of interest which apply to the credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            0.00% per annum (fixed)
            <br/>
            No interest is charged on this loan product. 'Total Loan Amount' means the
            sum of the amount of credit
            outstanding only.
          </TextStyled>

          <BoldTextStyled>
            Annual Percentage Rate of Charge (APR).
          </BoldTextStyled>

          <TextStyled noMargin italic>
            This is the total cost expressed as an annual percentage of the total amount of credit.
            <br/>
            The APR is there to help you compare different offers.
          </TextStyled>

          <TextStyled noMargin>
            0.00%.
            <br/>
            The assumptions applied in calculating the APR are that this Loan Agreement will
            remain valid for the period
            agreed and the Creditor and Debtor will fulfil their obligations under the terms
            and by the dates specified
            in this Loan Agreement.
          </TextStyled>

          <SubTitleStyled>
            RELATED COSTS:
          </SubTitleStyled>

          <BoldTextStyled noMargin>
            Consequences of missing payments
          </BoldTextStyled>

          <TextStyled noMargin>
            Missing payments could have severe consequences and make obtaining credit
            more expensive and/or difficult in
            the future if your default is reported to Credit Reference Agencies.
            <br/>
            Where payments are missed, we may take steps to recover the monies owed
            including but not limited to:
            <br/>
            <ListStyled>
              <li>
                reporting your default information with credit reference agencies;
              </li>
              <li>
                taking legal proceedings and court action against you; and
              </li>
              <li>
                if a court judgement is obtained, seeking an attachment of earnings order,
                property charging order or
                warrant of execution, as well as the costs of bringing such proceedings
                or applying for such orders.
              </li>
            </ListStyled>
          </TextStyled>

          <BoldTextStyled>
            4. Other important legal aspects
            <br/>
            Right of withdrawal
          </BoldTextStyled>

          <TextStyled noMargin>
            You have the right to withdraw from the Loan Agreement without giving any
            reason before the end of 14 days
            beginning with the day after the day on which:

            <ListStyled numbers>
              <li>
                the Loan Agreement is made, or
              </li>
              <li>
                you receive a copy of the Loan Agreement, or
              </li>
              <li>
                you are notified of its execution where you have an identical unexecuted
                copy of the Loan Agreement in
                accordance with section 61A(2) and (3) of the Consumer Credit Act 1974.
              </li>
            </ListStyled>
          </TextStyled>

          <BoldTextStyled>
            Early repayment
          </BoldTextStyled>

          <TextStyled noMargin>
            At any time before the final repayment is due, you have the right to pay
            off some or all that you owe us.
          </TextStyled>

          <BoldTextStyled>
            Consultation with a Credit Reference Agency.
          </BoldTextStyled>

          <TextStyled noMargin>
            If the Creditor decides not to proceed with a prospective regulated consumer
            credit agreement on the basis
            of information from a Credit Reference Agency we will inform you of this fact
            and provide you with details
            of the relevant Credit Reference Agency.
          </TextStyled>

          <BoldTextStyled>
            Right to a draft credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            You have the right, upon request, to obtain a copy of the draft agreement
            free of charge, unless the
            Creditor is unwilling at the time of the request to proceed to the conclusion
            of the Loan Agreement.
          </TextStyled>

          <BoldTextStyled>
            The period of time during which the Creditor is bound by the pre-contractual
            information.
          </BoldTextStyled>

          <TextStyled noMargin>
            This information is valid for 30 days from the date that it is provided to you.
          </TextStyled>

          <BoldTextStyled>
            5. Additional information in the case of distance marketing of financial services
            <br/>
            (a) concerning the creditor
            <br/>
            Registration number.
          </BoldTextStyled>

          <TextStyled noMargin>
            Social Credit Cymru is a trading name of Robert Owen Community Banking
            Fund Limited, with company
            registration number 08603690.
            <br/>
            We are registered as a Data Controller with the Information Commissioner,
            with registration number ZB037977.
          </TextStyled>

          <BoldTextStyled>
            The supervisory authority.
          </BoldTextStyled>

          <TextStyled noMargin>
            We are authorised and regulated by the Financial Conduct Authority, 12
            Endeavour Square, London, E20 1JN.
            Our FCA firm reference number is 726281.
          </TextStyled>

          <BoldTextStyled>
            (b) concerning the credit agreement
            <br/>
            The law taken by the creditor as a basis for the establishment of relations
            with you before the conclusion
            of the credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            The laws of the part of the United Kingdom in which you reside will be taken
            as a basis for the
            establishment of relations between you and the Creditor before the conclusion
            of the Loan Agreement.
          </TextStyled>

          <BoldTextStyled>
            The law applicable to the credit agreement and/or the competent court.
          </BoldTextStyled>

          <TextStyled noMargin>
            The loan agreement shall be governed by the laws of the part of the United
            Kingdom in which you reside whose
            courts shall be exclusive courts of jurisdiction over any claim or matter
            arising under or in connection
            with the Loan Agreement.
          </TextStyled>

          <BoldTextStyled>
            Language to be used in connection with the credit agreement.
          </BoldTextStyled>

          <TextStyled noMargin>
            The contractual terms and the information will be in English. The Creditor
            will, with your agreement,
            communicate with you in English for the duration of the Loan Agreement.
          </TextStyled>

          <BoldTextStyled>
            (c) concerning redress
            <br/>
            Access to out-of-court complaint and redress mechanism.
          </BoldTextStyled>

          <TextStyled noMargin>
            You can contact the Creditor on
            {' '}
            <LinkStyled href="mailto:support@socialcredit.cymru">
              support@socialcredit.cymru
            </LinkStyled>
            {' '}
            if you have a complaint. The Creditor will investigate the complaint and
            respond in writing with a final
            response.
            <br/>
            If you are not happy with the Creditor's final response you have the right
            to refer your complaint to the
            Financial Ombudsman Service in writing to Exchange Tower, London E14 9SR, email to
            {' '}
            <LinkStyled href="mailto:complaint.info@financial-ombudsman.org.uk">
              complaint.info@financial-ombudsman.org.uk
            </LinkStyled>
            {' '}
            or telephone on 0800 023 4567, within 6 months of the final response.
          </TextStyled>

          <SubTitleStyled>
            Robert Owen Community Banking Fund Limited trading as Social Credit Cymru
            <br/>
            An Explanation of the Fixed Sum Personal Loan
          </SubTitleStyled>

          <BoldTextStyled>
            Before we can process your loan application we must provide you with some
            important explanatory information
            about your proposed loan.
          </BoldTextStyled>

          <TextStyled noMargin>
            Your pre-contract information comprises this explanations document along with
            the Pre-Contract Consumer
            Credit Information, and you should read these documents carefully as they will
            help you to decide if our
            loans are suitable for you in your current circumstances.
            <br/>
            Please do not sign the loan agreement until you have considered all the information
            we have provided
            (including the terms and conditions of the loan agreement). You should only sign
            the loan agreement when you
            are happy to proceed to borrow from Social Credit Cymru.
            <br/>
            If you have any questions about the proposed loan, please contact us by:

            <ListStyled>
              <li>
                <BoldTextStyled inline>writing</BoldTextStyled>
                {' '}
                to us at Social Credit Cymru: Royal Welsh Warehouse 3rd Floor, 17 Old Kerry
                Road, Newton, Powys, Wales,
                SY16 1BH
              </li>
              <li>
                <BoldTextStyled inline>calling</BoldTextStyled>
                {' '}
                +44 0808 1752530; or
              </li>
              <li>
                <BoldTextStyled inline>emailing</BoldTextStyled>
                {' '}
                us at
                {' '}
                <LinkStyled href="mailto:support@socialcredit.cymru">
                  support@socialcredit.cymru
                </LinkStyled>
              </li>
            </ListStyled>
          </TextStyled>

          <BoldTextStyled>
            Communicating with you
          </BoldTextStyled>

          <TextStyled noMargin>
            If you decide to take out the proposed loan, we will communicate with you using
            a variety of methods
            including by telephone to the number or numbers you provided within your loan
            application, email, SMS and
            letter. It is important that you respond to any attempt we make to get in touch
            with you as this helps us to
            provide you with a better service and to understand at an early stage if your
            financial circumstances have
            changed.
          </TextStyled>

          <BoldTextStyled>
            Is this loan suitable for me?
          </BoldTextStyled>

          <TextStyled noMargin>
            A medium term, unrestricted use unsecured personal loan of this type is designed
            to provide you with a cash
            advance payable over
            {' '}
            {period}
            {' '}
            months.
            <br/>
            The loan may not be suitable for you if you intend to use the money advanced
            to pay off any existing loans
            with other providers when there are costs associated with doing so.
          </TextStyled>

          <BoldTextStyled>
            Loan amount and costs
          </BoldTextStyled>

          <TextStyled noMargin>
            We offer loans of between £500 and £2,000 over periods of between 6 and 24 months.
            The total cost of credit
            is £0.00 and the annual rate of interest is 0.00%.
            <br/>
            You have requested a loan from Social Credit Cymru for £
            {loanAmount}
            . You must repay
            this loan over
            {' '}
            {period}
            {' '}
            months by making monthly repayments of £
            {monthlyPayment}
            . The total that you
            will repay under the loan
            agreement will be £
            {totalRepayment}
            .
            <br/>
            We conduct an affordability assessment based on various sources of information.
            We only lend to you if we
            are satisfied that you can afford to make the repayments. However, if you do
            not think you can afford the
            monthly payments over the duration of the loan, you should not enter the
            loan agreement.
            <br/>
            Your loan advance will be transferred into your nominated bank account via
            Faster Payment transfer or by
            similar electronic method (unless you are taking out a new loan to part repay
            an existing loan with us in
            which case the balance will be transferred to your nominated account). This
            advance should usually arrive
            within the agreed accounts on the same day of the loan application being
            approved before 5pm, but no later
            than within three working days of the loan agreement being signed by you,
            and subject to all requested
            documents and/or Open Banking reviews being accepted.
          </TextStyled>

          <BoldTextStyled>
            Repayments
          </BoldTextStyled>

          <TextStyled noMargin>
            When you take out the loan, you provide us with a formal method of making the
            repayments due under the loan
            agreement:
            <br/>
            <ListStyled>
              <li>
                <BoldTextStyled inline>Direct Debit</BoldTextStyled>
              </li>
              <li>
                You will be asked to complete a Direct Debit Mandate (the
                {' '}
                <BoldTextStyled inline>Direct Debit Mandate</BoldTextStyled>
                ) as part of the loan application process. This allows us to collect the amount due
                on the relevant due
                date for each monthly payment; this will be the primary method that we will use to
                obtain repayments.
              </li>
            </ListStyled>
            If you prefer to pay by any other method, please get in touch with us using the contact
            details provided in
            this document.
            <br/>
            If your circumstances change and you cannot meet the full repayment on the scheduled
            repayment dates, or you
            are experiencing financial difficulties, please get in touch with us as soon as
            possible using the contact
            details provided in this document, so that we can discuss the options available to you.
            <br/>
            You may cancel the Direct Debit Mandate that you provide us under the loan agreement
            by contacting your bank
            directly, or by contacting us, using the contact details provided in this document.
            If you do cancel, you
            will still owe any outstanding debt and will need to provide us with an alternative
            method of repayment on
            the agreed payment date to avoid going into default and incurring unnecessary
            default charges.
          </TextStyled>

          <BoldTextStyled>
            Payment Attempts
          </BoldTextStyled>

          <TextStyled noMargin>
            We will make one attempt to collect the amount of repayment due, including
            any applicable charges that you
            may incur under the agreement, using the Direct Debit Mandate on the agreed payment
            dates set out in your
            loan agreement. To help you prepare for each repayment, we will contact you before
            the agreed monthly
            repayment dates, to remind you when each repayment is due. We may do so by email
            and/or SMS.
            <br/>
            If the attempt to collect repayment by Direct Debit on the scheduled payment date is
            unsuccessful, we will
            try to contact you by telephone, email and/or SMS message to discuss your situation
            and the reasons for the
            missed payment. Please note that there may be a period of up to 5 days before we
            receive notification of the
            failure of the Direct Debit payment.
            <br/>
            If at any point you inform us that you are unable to make the repayment due to a
            change in your financial
            circumstances, or where making the repayment would cause you financial hardship,
            we will endeavour to agree
            a reasonable repayment plan and treat you with forbearance.
            <br/>
            If we are able to contact you by telephone, and you agree to make immediate repayment,
            we will attempt to
            collect the full outstanding amount through a one off payment with GoCardless using
            details that you provide
            during our telephone conversation and will inform you immediately whether the
            transaction has been
            successful.
            <br/>
            If you believe that, as a result of our attempts to collect repayment that you
            have been unable to meet
            priority debts, such as rent, mortgage or utility bills, you should contact us
            as soon as possible using the
            contact details provided above. We may be able to refund the repayments taken.
            <br/>
            If any payment dates set out in the loan agreement should fall on a Saturday,
            Sunday or a public holiday,
            then payment may be taken on the next available working day.
          </TextStyled>

          <BoldTextStyled>
            What are the consequences if I miss a payment?
          </BoldTextStyled>

          <TextStyled noMargin>
            We report your missed payments and default to credit reference agencies in
            real-time. This information may
            be accessed by organisations making decisions in respect of other products
            or services you are interested
            in. Missing payments can also make it more difficult and/or more expensive
            for either of you to obtain
            credit in the future.
          </TextStyled>

          <BoldTextStyled>
            Can I withdraw from the loan agreement?
          </BoldTextStyled>

          <TextStyled noMargin>
            Once the loan agreement is made, you have the right to withdraw without giving
            any reason before the end of
            14 days beginning with the latest of the day after the day on which the Loan
            Agreement is made or you
            receive a copy of the Loan Agreement, or you are notified of its execution where
            you have an identical
            unexecuted copy in accordance with section 61A (2) and (3) of the Consumer
            Credit Act 1974.
            <br/>
            You can exercise this right by contacting us in writing, by telephone or email,
            as listed below. If you
            withdraw you must repay any money we have already advanced under the loan
            agreement within 30 days of
            providing your notice to withdraw. You can repay by BACS, electronic funds
            transfers, or online payment.
          </TextStyled>

          <BoldTextStyled>
            How can I contact you?
          </BoldTextStyled>

          <TextStyled noMargin>
            Please contact us via the telephone number, postal or email address given below
            if you have any questions or
            would like further explanation or clarification about our loans products. On
            our website, we have a number
            of frequently asked questions.
            <br/>
            You may also contact us if you have any concerns in relation to our services
            or if either of you wish to
            make a complaint. We always endeavour to respond promptly.
            <br/>
            Our contact details are:
            <ListStyled>
              <li>
                Social Credit Cymru: Royal Welsh Warehouse 3rd Floor, 17 Old Kerry Road,
                Newton, Powys, Wales, SY16 1BH
              </li>
              <li>
                <BoldTextStyled inline>
                  calling
                </BoldTextStyled>
                {' '}
                +44 0808 1752530; or
              </li>
              <li>
                <BoldTextStyled inline>
                  emailing
                </BoldTextStyled>
                {' '}
                us at
                {' '}
                <LinkStyled href="mailto:support@socialcredit.cymru">
                  support@socialcredit.cymru
                </LinkStyled>
              </li>
            </ListStyled>
            We are authorised and regulated by the Financial Conduct Authority, who are
            the supervisory authority under
            the Financial Services and Markets Act 2000 for credit businesses, with firm
            reference number 726281.
          </TextStyled>

          <BoldTextStyled>
            Other Sources of Help and Advice
          </BoldTextStyled>

          <TextStyled noMargin>
            We are always available to discuss any difficulties should they arise, but there
            are also a number of
            independent free debt-counselling organisations who can help you. These include:
            <br/>
            Citizens Advice –
            {' '}
            <LinkStyled target="_blank" href="https://www.citizensadvice.org.uk">
              www.citizensadvice.org.uk
            </LinkStyled>
            {' '}
            (to find your local office)
            <ListStyled>
              <li>
                Step Change –
                {' '}
                <LinkStyled target="_blank" href="https://www.stepchange.org">
                  www.stepchange.org
                </LinkStyled>
                {' '}
                Tel: 0800 138 1111
              </li>
              <li>
                National Debtline –
                {' '}
                <LinkStyled target="_blank" href="https://www.nationaldebtline.org">
                  www.nationaldebtline.org
                </LinkStyled>
                {' '}
                Tel: 0808 808 4000
              </li>
              <li>
                Money Advice Service –
                {' '}
                <LinkStyled target="_blank" href="https://www.moneyadviceservice.org.uk">
                  www.moneyadviceservice.org.uk
                </LinkStyled>
                {' '}
                Tel: 0800 138 7777
              </li>
            </ListStyled>
          </TextStyled>
        </CardStyled>

        <CustomCheckBoxContainerStyled>
          <CheckboxesTitleStyled>
            A few things to check, you must be:
          </CheckboxesTitleStyled>
          <CustomLabelStyledStyled>
            <CustomCheckBox
              control={control}
              name="checkbox1"/>
            Aged over 18
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox2"/>
            Be employed with no probationary period, self-employed or have regular income,
            e.g., pension, regular benefits
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox3"/>
            A Welsh resident and only have tax residency in Wales
          </CustomLabelStyledStyled>
          <CustomLabelStyledStyled marginTop>
            <CustomCheckBox
              control={control}
              name="checkbox4"/>
            Using this loan for personal (not business) purposes
          </CustomLabelStyledStyled>
        </CustomCheckBoxContainerStyled>

        <SocialCreditStepperButton text="Accept" onClick={handleSubmit} disabled={isSubmitDisabled}/>

        <AfterTextStyled>
          Please contact Plend’s Data Protection Officer (“DPO”) dpo@plend.co.uk
          in relation to any questions or concerns regarding UK GDPR considerations
          of the Plend Pilot. Plend Limited is registered with the Information
          Commissioner’s Office in compliance with the Data Protection
          Regulations 2018 under registration ZB037977.
        </AfterTextStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default CymruTerms
