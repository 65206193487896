import { useForm, UseFormReturn } from 'react-hook-form'
import React, { ReactNode, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Alert } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TResubmitAddressMutationError, useResubmitAddressMutation } from '../querries'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import { TAddress, TFormData, TPrevAddress, TResubmitAddressParams } from '../types'
import PlendResubmitForm from './form/PlendResubmitForm'
import MakeMyHouseGreenResubmitForm from './form/MakeMyHouseGreenResubmitForm'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { BaseButton } from '../../../core/components/buttons/CustomButton'
import PlendHeader from './header/PlendResubmitHeader'
import ImageBackground from '../../../core/components/MakeMyHouseGreenImageBackground'
import SemicircleRight from '../../../core/components/icons/svg/SemicircleRight.svg'
import { removeObjectEmptyValues } from '../utils/resubmitUtils'
import { useInitIntercomChat } from '../../intercomChat/hooks/useInitIntercomChat'
import AlreadyResubmittedModal from './AlreadyResubmittedModal'
import { useNotificationContext } from '../../notifications/state/useNotification'
import ErrorNotification from '../../notifications/components/ErrorNotification'
import { useLoanData } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import ConnectSocialCreditPlendResubmitForm from './form/ConnectSocialCreditPlendResubmitForm'

const WrapperStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  width: 100%;
  background: ${({ theme }) => theme.colors.violet};
  ${({ theme }) => theme.mixins?.mainBackgroundMixin};
  position: relative;
  z-index: 1;
  @media (max-width: 375px) {
    padding: 0 10px
  }
`

const ContentStyled = styled.div`
  ${flex({ justify: 'center', align: 'center', direction: 'column' })};
  width: 100%;
  height: 100%;
  position: relative;
  ${({ theme }) => theme?.mixins?.resubmitAddress?.content};

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top:128px;
    background: url(${SemicircleRight})
    no-repeat center center/cover;
    width: 173px;
    height: 346px;
    ${({ theme }) => theme?.mixins?.hideFiguresMixin};

    @media (max-width: 1070px) {
      display: none;
    }
  }
`

const FormStyled = styled.form`
  ${flex({ direction: 'column' })}
  max-width: 710px;
`

const FormContentStyled = styled.div`
  max-width: 710px;
  display: grid;
  align-items: center;
  grid-gap: 30px;
  grid-template-columns: repeat(2, minmax(340px, auto));
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }

`
const TitleStyled = styled.h1`
  ${fontFamily('Mongoose')};
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  margin: 50px 0;
  color:${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.resubmitAddress?.title}
`

const SubmitButtonMixin = styled(BaseButton)`
&&{
  ${fontFamily('Neue')}
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.orange};
  box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);
  border-radius: 10px;
  padding: 12px 20px;
  align-self: flex-end;
  margin:30px 0 50px 0;
  &:hover {
    background: ${({ theme }) => theme.colors.orange100};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.5);
  }
  &:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.pink100};
  }
  
  ${({ theme }) => theme.mixins?.resubmitAddress?.submitButton}
}
`

const AlertStyled = styled(Alert)`
  && {
    &.MuiAlert-standardError {
      ${flex({ justify: 'center', align: 'center' })};
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.orange};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`

const headerMap: { [key in TOrganization]?: ReactNode } = {
  plend: <PlendHeader/>
}

const formMap:
  {[key in TOrganization]?: (formMethods: UseFormReturn<TFormData>) => ReactNode} = {
    plend: (formMethods) => <PlendResubmitForm formMethods={formMethods}/>,
    socialcreditcymru: (formMethods) => <PlendResubmitForm formMethods={formMethods}/>,
    makemyhousegreen: (formMethods) => <MakeMyHouseGreenResubmitForm formMethods={formMethods}/>,
    socialcreditplend: (formMethods) =>
      <ConnectSocialCreditPlendResubmitForm formMethods={formMethods}/>
  }

function useGetSearchParams() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const borrowerIdSearchParams = searchParams.get('borrowerId')
  const loanApplicationId = searchParams.get('loanApplicationId')
  useEffect(() => {
    if (!borrowerIdSearchParams || !loanApplicationId) {
      navigate('/registration')
    }
  }, [borrowerIdSearchParams, loanApplicationId])

  return {
    borrowerIdSearchParams,
    loanApplicationId
  }
}

function useAlreadyResubmittedModalHandle(
  reset: () => void,
  error: TResubmitAddressMutationError | null
) {
  const { showErrorNotification } = useNotificationContext()

  const hasCorrectErrorStatus = Number(error?.stack?.status) === 422

  useEffect(() => {
    if (error && !hasCorrectErrorStatus) {
      showErrorNotification(<ErrorNotification message={error.message} />)
      reset()
    }
  }, [error])

  return {
    isOpened: useMemo(() => (error ? hasCorrectErrorStatus : false), [error]),
    handleClose: () => reset()
  }
}

const ResubmitAddress = () => {
  const { data: loanData } = useLoanData(true)
  const { currentOrganization } = useOrganizationContext()
  const {
    mutate,
    isLoading,
    isIdle,
    reset, error
  } = useResubmitAddressMutation()
  const {
    isOpened: isAlreadyResubmittedModalOpened,
    handleClose: alreadyResubmittedModalCloseHandle
  } = useAlreadyResubmittedModalHandle(reset, error)
  const formMethods = useForm<TFormData>({ shouldUnregister: true })
  const { handleSubmit, formState: { errors } } = formMethods
  const isOrganizationMakeMyHouseGreen = currentOrganization === 'makemyhousegreen'
  const { borrowerIdSearchParams, loanApplicationId } = useGetSearchParams()
  useInitIntercomChat(['plend'])

  const onSubmit = async (data: TFormData) => {
    const {
      postCode,
      buildingNo,
      buildingName,
      locality,
      postTown,
      street,
      prevBuildingName,
      prevBuildingNo,
      prevLocality,
      prevPostCode,
      prevPostTown,
      prevStreet
    } = data

    const address: TAddress = {
      postCode,
      buildingNo,
      buildingName,
      locality,
      postTown,
      street
    }
    const prevAddress: TPrevAddress = {
      buildingName: prevBuildingName,
      buildingNo: prevBuildingNo,
      locality: prevLocality,
      postCode: prevPostCode,
      postTown: prevPostTown,
      street: prevStreet
    }
    const removedEmptyValuesPrevAddress = removeObjectEmptyValues(prevAddress)
    const preparedData: TResubmitAddressParams = {
      borrowerId: borrowerIdSearchParams!,
      formData: {
        loanApplicationId: loanData?.noInterestLoanApplicationId || +loanApplicationId!,
        address: removeObjectEmptyValues(address) as TAddress,
        prevAddress: Object.values(removedEmptyValuesPrevAddress).length
          ? removedEmptyValuesPrevAddress as TPrevAddress
          : undefined
      }
    }
    mutate(preparedData)
  }

  const uniqueErrors = Array.from(new Set(Object.values(errors)
    .map((item) => item.message)))

  return (
    <WrapperStyled>
      <AlreadyResubmittedModal
        isOpen={isAlreadyResubmittedModalOpened}
        handleClose={alreadyResubmittedModalCloseHandle}/>
      {headerMap[currentOrganization]}
      {isOrganizationMakeMyHouseGreen && <ImageBackground/>}
      <ContentStyled>
        <TitleStyled>Resubmit your address</TitleStyled>
        <FormStyled id="form" onSubmit={handleSubmit(onSubmit)}>
          <FormContentStyled>
            {formMap[currentOrganization]?.(formMethods)}
          </FormContentStyled>
          {(errors && uniqueErrors.length)
            ? uniqueErrors.map((error) => (
              <AlertStyled
                key={error}
                data-testid="errorBlock"
                sx={{ marginTop: 3 }}
                severity="error">
                {error}
              </AlertStyled>
            ))
            : null}
          <SubmitButtonMixin
            disabled={isLoading || !isIdle}
            data-testid="Submit"
            type="submit"
            form="form">
            Submit
          </SubmitButtonMixin>
        </FormStyled>
      </ContentStyled>
    </WrapperStyled>
  )
}

export default ResubmitAddress
