import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useCallback } from 'react'

export function useNavigateWithSavedSearch() {
  const navigate = useNavigate()
  const { search } = useLocation()

  return useCallback((pathname: string) => navigate({ pathname, search }), [])
}
