import { useCallback, useState } from 'react'
import constate from 'constate'

export const useLayoutMain = () => {
  const [isFullSize, setIsFullSize] = useState(false)

  return {
    isFullSize,
    activateFullSize: useCallback(() => setIsFullSize(true), []),
    resetFullSize: useCallback(() => setIsFullSize(false), [])
  }
}

export const [LayoutProvider, useLayoutContext] = constate(useLayoutMain)
