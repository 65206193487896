import { useForm, UseFormReturn } from 'react-hook-form'
import { format } from 'date-fns'
import { ReactNode, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { YEAR_FORMAT } from '../../../core/const/date'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import { useBorrowerMutation, useLoanerMutation, useLoanerReconnectMutation } from '../queries'
import { TLoaner, TLoanerReconnect, TPrevAddress, TUser } from '../types'
import ImageBackground from '../../../core/components/MakeMyHouseGreenImageBackground'
import PlendForm from './form/PlendForm'
import MakeMyHouseGreenForm from './form/MakeMyHouseGreenForm'
import LendologyForm from './form/LendologyForm'
import RobertOwenForm from './form/RobertOwenForm'
import { TLoanValues } from '../../loanCalculator/components/DefaultLoanCalculator'
import { useReconnectingContext } from '../../reconnecting/state/useReconnectingState'
import { TReconnectingResponse } from '../../reconnecting/type'
import PurpleShootForm from './form/PurpleShootForm'
import { AlertStyled, ButtonStyled, ButtonWrapper, ContainerStyled, FormWrapper } from '../styled'
import { uniqueErrors } from '../utils/infoFormUtils'
import { useLoanStatusNavigation } from '../hooks/useLoanStatusNavigation'
import { useOrganizationId } from '../hooks/useOrganizationId'
import { useIsConnectSocialCreditCommunity } from '../../../core/hooks/useIsConnectSocialCreditCommunity'
import { useSocialCreditLoanCalculatorContext } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { useStepperContext } from '../../../core/components/stepper/StepperContext'
import ConnectSocialCreditForm from './form/ConnectSocialCreditForm'

const countryCode = '+44'

const formMap: {[key in TOrganization]:
  (formMethods: UseFormReturn<Partial<TUser>>) => ReactNode} = {
    plend: (formMethods) => <PlendForm formMethods={formMethods}/>,
    purpleshoots: (formMethods) => <PurpleShootForm formMethods={formMethods}/>,
    lendology: (formMethods) => <LendologyForm formMethods={formMethods}/>,
    makemyhousegreen: (formMethods) => <MakeMyHouseGreenForm formMethods={formMethods}/>,
    robertowen: (formMethods) => <RobertOwenForm formMethods={formMethods}/>,
    socialcreditcymru: () => null,
    socialcreditplend: (formMethods) => <ConnectSocialCreditForm formMethods={formMethods}/>
  }

export function useGetLoanLocaleStorage() {
  const [localeStorageLoan] = useLocalStorage<TLoanValues>('loan')
  return localeStorageLoan
}

function useLoanerMutationDataPrepare() {
  const { loan, currentOrganization } = useOrganizationContext()
  const isOrganizationPlend = currentOrganization === 'plend'
  const loanValues = useGetLoanLocaleStorage()
  return useCallback((data: Partial<TUser>, borrowerId: number): TLoaner => ({
    ...loan,
    interestRate: isOrganizationPlend
      ? Number(loanValues?.fixedRate)
      : loan?.interestRate as number,
    monthlyRepayment: isOrganizationPlend
      ? Number(loanValues?.monthlyPaymentCalc)
      : loan?.monthlyRepayment as number,
    loanAmount: isOrganizationPlend ? Number(data.loanAmount) : loan?.loanAmount as number,
    loanLength: isOrganizationPlend ? Number(data.loanLength) : loan?.loanLength as number,
    loanPurpose: data.loanPurpose as string,
    loanPurposeDescription: data.loanPurposeDescription,
    source: data.source,
    borrowerId
  }), [isOrganizationPlend, loanValues, loan])
}

export function usePrepareBorrowerMutationDataPrepare() {
  const organizationId = useOrganizationId()

  return useCallback((data: Partial<TUser>) => {
    const {
      livingStatusId,
      employmentStatusId,
      incomeAnnual,
      employmentLength,
      buildingName,
      buildingNo,
      locality,
      postCode,
      postTown,
      street,
      email,
      firstName,
      lastName,
      employer,
      dateOfBirth,
      receiveNewsletter,
      prevAbodeNo,
      prevBuildingName,
      prevBuildingNo,
      prevLocality,
      prevPostCode,
      prevPostTown,
      prevStreet,
      prevSubLocality,
      primaryPhoneNumber,
      secondaryPhoneNumber
    } = data

    const prevAddress: TPrevAddress = {
      abodeNo: prevAbodeNo,
      buildingName: prevBuildingName,
      buildingNo: prevBuildingNo,
      locality: prevLocality,
      postCode: prevPostCode,
      postTown: prevPostTown,
      street: prevStreet,
      subLocality: prevSubLocality
    }

    const address = {
      buildingName,
      buildingNo: buildingNo || undefined,
      locality,
      postCode,
      postTown,
      street
    }

    return {
      address,
      dateOfBirth: format(dateOfBirth as Date, YEAR_FORMAT),
      email,
      livingStatusId,
      employmentStatusId,
      firstName,
      lastName,
      employmentLength: Number(employmentLength) || undefined,
      incomeAnnual: Number(incomeAnnual),
      organizationId,
      employer,
      prevAddress: Object.values(prevAddress)
        .filter((value) => value !== undefined).length
        ? prevAddress
        : undefined,
      receiveNewsletter,
      primaryPhoneNumber: `${countryCode}${primaryPhoneNumber}`,
      secondaryPhoneNumber: secondaryPhoneNumber ? `${countryCode}${secondaryPhoneNumber}` : undefined
    }
  }, [organizationId])
}

function useLoanerReconnectMutationDataPrepare() {
  const { loan } = useOrganizationContext()
  const loanValues = useGetLoanLocaleStorage()
  const { token } = useReconnectingContext()

  return useCallback((
    data: Partial<TUser>,
    borrowerId: number
  ): TLoanerReconnect => ({
    application: {
      ...loan,
      borrowerId,
      interestRate: Number(loanValues?.fixedRate),
      loanAmount: Number(data.loanAmount),
      loanLength: Number(data.loanLength),
      monthlyRepayment: Number(loanValues?.monthlyPaymentCalc),
      source: data.source
    },
    token
  }), [token, loanValues, loan])
}

function useOnSubmit() {
  const navigate = useNavigate()
  const navigateLoanStatus = useLoanStatusNavigation()
  const prepareLoanerMutationData = useLoanerMutationDataPrepare()
  const prepareBorrowerMutationData = usePrepareBorrowerMutationDataPrepare()
  const prepareLoanerReconnectMutationData = useLoanerReconnectMutationDataPrepare()
  const {
    isReconnectingProcess,
    hasReconnectingBankCheck
  } = useReconnectingContext()
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  const borrowerMutationReturn = useBorrowerMutation()
  const loanerMutationReturn = useLoanerMutation()
  const [searchParams] = useSearchParams()
  const source = searchParams.get('utm_source')
  const { mutateAsync: loanerReconnectMutateAsync } = useLoanerReconnectMutation()
  const { mutateAsync: borrowerMutateAsync } = borrowerMutationReturn
  const { mutateAsync: loanerMutateAsync } = loanerMutationReturn
  const { setLoanPurpose, setDebtConsolidation } = useSocialCreditLoanCalculatorContext()
  const { nextStep } = useStepperContext()
  const onSubmit = async (data: Partial<TUser>) => {
    const {
      id: borrowerId,
      hasApprovedLoan,
      hasDeclinedLoan,
      hasPendingLoan,
      pendingLoanUrl,
      firstName: borrowerName
    } = await borrowerMutateAsync(prepareBorrowerMutationData(data))

    if (isReconnectingProcess) {
      await loanerReconnectMutateAsync({
        loaner: prepareLoanerReconnectMutationData(data, borrowerId)
      })

      if (!hasReconnectingBankCheck) {
        navigate('/accounts')
      }
      return
    }
    const noLoanStatus = !hasApprovedLoan
      && !hasPendingLoan
      && !hasDeclinedLoan

    if (noLoanStatus && !isConnectSocialCreditCommunity) {
      await loanerMutateAsync({ loaner: prepareLoanerMutationData(
        {
          ...data, source: source || data.source },
        borrowerId
      ) })
      return
    }
    if (noLoanStatus && isConnectSocialCreditCommunity) {
      setLoanPurpose((data as any)?.otherText
        || (data as any)?.reasonLevelTwo
        || (data as any)?.reasonLevelOne)
      setDebtConsolidation((data as any).debtConsolidation)
      nextStep()
      return
    }

    const statuses = {
      hasPendingLoan,
      hasDeclinedLoan,
      hasApprovedLoan
    }

    navigateLoanStatus(statuses, pendingLoanUrl, borrowerName)
  }

  return {
    onSubmit,
    loanerMutationReturn,
    borrowerMutationReturn
  }
}

function usePreparedDefaultValues(defaultValues: Partial<TUser>): Partial<TUser> {
  const {
    isReconnectingProcess,
    reconnectingQuery: { data }
  } = useReconnectingContext()

  if (isReconnectingProcess && data) {
    const test: TReconnectingResponse = data

    return {
      ...defaultValues,
      firstName: test.firstName,
      lastName: test.lastName,
      dateOfBirth: test.dateOfBirth && new Date(test.dateOfBirth),
      email: test.email,
      postCode: test.address.postCode,
      employmentLength: test.employmentLength,
      buildingNo: test.address.buildingNo,
      buildingName: test.address.buildingName,
      street: test.address.street,
      locality: test.address.locality,
      postTown: test.address.postTown,
      livingStatusId: test.livingStatus.id,
      employmentStatusId: test.employmentStatus.id,
      incomeAnnual: test.incomeAnnual,
      employer: test.employer,
      prevBuildingName: test.prevAddress?.buildingName,
      prevBuildingNo: test.prevAddress?.buildingNo,
      prevLocality: test.prevAddress?.locality,
      prevPostCode: test.prevAddress?.postCode,
      prevPostTown: test.prevAddress?.postTown,
      prevStreet: test.prevAddress?.street,
      prevSubLocality: test.prevAddress?.subLocality,
      primaryPhoneNumber: test.primaryPhoneNumber
        ? test.primaryPhoneNumber.slice(countryCode.length)
        : undefined,
      secondaryPhoneNumber: test.secondaryPhoneNumber
        ? test.secondaryPhoneNumber.slice(countryCode.length)
        : undefined
    }
  }

  return defaultValues
}

const UserInfo = () => {
  const organizationId = useOrganizationId()
  const { currentOrganization, buttonStepperText } = useOrganizationContext()
  const loanValues = useGetLoanLocaleStorage()
  const {
    onSubmit,
    loanerMutationReturn,
    borrowerMutationReturn
  } = useOnSubmit()
  const isOrganizationMakemyhousegreen = currentOrganization === 'makemyhousegreen'

  const defaultValues: Partial<TUser> = {
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    postCode: '',
    employmentLength: undefined,
    buildingNo: undefined,
    buildingName: undefined,
    street: undefined,
    locality: undefined,
    postTown: undefined,
    livingStatusId: '',
    employmentStatusId: '',
    incomeAnnual: '',
    employer: '',
    loanPurpose: isOrganizationMakemyhousegreen ? 'Make My House Green' : '',
    loanLength: loanValues?.period,
    loanAmount: loanValues?.loanAmount?.toString(),
    loanPurposeDescription: '',
    receiveNewsletter: false,
    source: isOrganizationMakemyhousegreen ? 'Switchd' : '',
    prevAbodeNo: undefined,
    prevBuildingName: undefined,
    prevBuildingNo: undefined,
    prevLocality: undefined,
    prevPostCode: undefined,
    prevPostTown: undefined,
    prevStreet: undefined,
    prevSubLocality: undefined,
    primaryPhoneNumber: undefined,
    secondaryPhoneNumber: undefined
  }

  const formMethods = useForm<Partial<TUser>>({
    shouldUnregister: true,
    defaultValues: usePreparedDefaultValues(defaultValues)
  })
  const { formState: { errors }, handleSubmit } = formMethods

  const uniqueFormErrors = uniqueErrors(errors)
  if (!organizationId) {
    return <></>
  }

  const {
    isLoading: loanerIsLoading,
    isIdle: loanerIsIdle,
    isError: loanerIsError,
    error: borrowerError
  } = borrowerMutationReturn

  const {
    isLoading: borrowerIsLoading,
    isIdle: borrowerIsIdle,
    isError: borrowerIsError,
    error: loanerError
  } = loanerMutationReturn

  const isButtonDisabled = loanerIsLoading || borrowerIsLoading || !loanerIsIdle || !borrowerIsIdle

  return (
    <ContainerStyled>
      {isOrganizationMakemyhousegreen && <ImageBackground butterFlyTop={-130}/>}
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formMap[currentOrganization](formMethods)}
          {
            (loanerIsError || borrowerIsError)
            && (
              <AlertStyled
                data-testid="serverErrorBlock"
                sx={{ marginTop: 3 }}
                severity="error">
                {borrowerError?.message || loanerError?.message}
              </AlertStyled>
            )
          }
          {errors && uniqueFormErrors.map((error: any) => (
            <AlertStyled
              sx={{ marginTop: 3 }}
              data-testid="formErrorBlock"
              severity="error">
              {error?.message && error?.message}
            </AlertStyled>
          ))}
          <ButtonWrapper>
            <ButtonStyled
              data-testid="Submit"
              disabled={isButtonDisabled}
              variant="contained"
              size="large"
              type="submit"
              color={borrowerIsError || loanerIsError ? 'error' : 'primary'}>
              {buttonStepperText}
            </ButtonStyled>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </ContainerStyled>
  )
}

export default UserInfo
