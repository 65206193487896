import styled from 'styled-components/macro'
import React, { useEffect } from 'react'
import { StepperContextProvider, useStepperContext } from '../../../../core/components/stepper/StepperContext'
import { flex } from '../../../../core/styles/mixins'
import TermsAndConditions from '../../../termsAndConditins/components/TermsAndConditions'
import Stepper from '../../../../core/components/stepper/Stepper'
import SocialCreditLoanPurpose from '../../../socialCreditLoanPurpose/components/SocialCreditLoanPurpose'
import SocialCreditDecision from '../../../decision/components/SocialCreditDecision'
import { useSocialCreditLoanCalculatorContext } from '../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { useNavigateWithSavedSearch } from '../../../../core/hooks/useNavigateWithSavedSearch'
import CymruPersonalInformation from '../../../SocialCreditPersonalInformation/components/SocialCreditPersonalInformation'
import { useLastStepNavigationCheck } from '../../../loanCalculator/hooks/useLastStepNavigationCheck'
import { useOrganizationContext } from '../../../../core/state/useOrganization'
import SocialCreditStepperPrivacyPolicy
  from '../../../privacyPolicy/components/stepper/SocialCreditPlendStepperPrivacyPolicy'
import SocialCreditStepperCymruPrivacyPolicy
  from '../../../privacyPolicy/components/stepper/SocialCreditStepperCymruPrivacyPolicy'
import { useIsConnectSocialCreditCommunity } from '../../../../core/hooks/useIsConnectSocialCreditCommunity'
import BankCheck from '../../../bankCheck/components/BankCheck'
import UserInfo from '../../../userInfoForm/components/UserInfo'

const ContainerStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  flex-direction: column;
`

const ContentStyled = styled.div`
  ${flex({ justify: 'center', align: 'center', direction: 'column' })}
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: transparent;
`

function useStepperComponents() {
  const { currentOrganization } = useOrganizationContext()
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  if (isConnectSocialCreditCommunity) {
    return [
      <TermsAndConditions/>,
      <SocialCreditStepperPrivacyPolicy/>,
      <UserInfo/>,
      <CymruPersonalInformation/>,
      <BankCheck/>
    ]
  }
  return [
    <SocialCreditLoanPurpose/>,
    <CymruPersonalInformation/>,
    <TermsAndConditions/>,
    currentOrganization === 'socialcreditplend'
      ? <SocialCreditStepperPrivacyPolicy/>
      : <SocialCreditStepperCymruPrivacyPolicy/>,
    <SocialCreditDecision/>
  ]
}

const stepNames = [
  'Loan Purpose',
  'Personal information',
  'T&Cs',
  'Privacy Policy',
  'Decision'
]

function swapElements(array: any[], firstIndex: number, secondIndex: number) {
  return [array[secondIndex], array[firstIndex], ...array.slice(2)]
}

function navigateToHome() {
  const { isCalculatorInitialized } = useSocialCreditLoanCalculatorContext()

  const navigate = useNavigateWithSavedSearch()

  useEffect(() => {
    if (!isCalculatorInitialized) {
      navigate('/')
    }
  }, [])
}

function navigateToDecisionStep() {
  const { setStep } = useStepperContext()

  const checkRedirect = useLastStepNavigationCheck()

  useEffect(() => {
    if (checkRedirect()) {
      setStep(5)
    }
  }, [])
}

function usePreparedStepNames() {
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  if (isConnectSocialCreditCommunity) {
    return [
      'T&Cs',
      'Privacy Policy',
      'Application',
      'Personal information',
      'Bank Check'
    ]
  }
  return useOrganizationContext().currentOrganization === 'socialcreditplend'
    ? swapElements(stepNames, 0, 1)
    : stepNames
}

function usePreparedStepperComponents() {
  const stepperComponents = useStepperComponents()
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  return useOrganizationContext().currentOrganization === 'socialcreditplend' && !isConnectSocialCreditCommunity
    ? swapElements(stepperComponents, 0, 1)
    : stepperComponents
}

const RegistrationStepper = () => {
  const { stepFrom0 } = useStepperContext()

  navigateToHome()
  navigateToDecisionStep()

  useEffect(() => window.scrollTo(0, 0), [stepFrom0])

  return (
    <ContainerStyled>
      <Stepper stepNames={usePreparedStepNames()} />
      <ContentStyled>
        {usePreparedStepperComponents()[stepFrom0]}
      </ContentStyled>
    </ContainerStyled>
  )
}

export default () => (
  <StepperContextProvider>
    <RegistrationStepper/>
  </StepperContextProvider>
)
