import styled from 'styled-components/macro'
import { memo } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'

const CaptionStyled = styled.div`
  ${fontFamily('Open Sans')}
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  max-width: 996px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  margin: 0 20px 30px 20px;
  &>div{
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin: 0 10px 30px 10px;
  }
`

const RobertOwenBankCheckHeaderBottom = () => (
  <CaptionStyled>
    <div>
      By submitting your application you’re confirming your agreement
      to our terms, conditions and privacy policies. These are available
      at
      {' '}
      <a target="_blank" href="https://www.rocbf.co.uk" rel="noreferrer">www.rocbf.co.uk</a>
      {' '}
      and you acknowledge how we will use your information
      when assessing your application.
    </div>
    <div>
      We’ll submit your information to our credit reference agency to check
      your credit status and identity. They will provide us with information
      about your financial history. A record of this search may be recorded
      and can be seen by other companies if they carry out a credit enquiry.
    </div>
    <div>
      We’ll complete our assessment, that could take into account credit reference
      agency records, and include anyone who you have a joint account or similar
      financial link with. If this is a joint application and this type of link
      doesn’t already exist, one may be created.
    </div>
    <div>
      We may use an automated assessment that takes into account different factors
      such as your existing debts, how you have managed them in the past and other
      relevant factors.
    </div>
  </CaptionStyled>
)
export default memo(RobertOwenBankCheckHeaderBottom)
