import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const PrevStreet: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Previous Street *"/>
    <CustomInput
      name="prevStreet"
      control={control}
      rules={{
        required: requiredRule(),
        maxLength: maxLengthRule(50)
      }}
    />
  </div>
)

export default PrevStreet
