import { memo } from 'react'
import SocialCreditLoanCalculator from '../../../loanCalculator/components/SocialCreditLoanCalculator'
import TellMeMore from '../../../tellMeMore/components/TellMeMore'
import { useSocialCreditLoanCalculatorContext } from '../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { useOrganizationContext } from '../../../../core/state/useOrganization'

const SocialCreditContent = () => {
  const { showCalculator } = useSocialCreditLoanCalculatorContext()

  const { currentOrganization } = useOrganizationContext()

  console.log('showCalculator')
  console.log(showCalculator)

  return (
    <>
      {showCalculator
        ? currentOrganization === 'socialcreditplend'
          ? (
            <SocialCreditLoanCalculator
              title="Interest Free Loan, By Social Credit"
              subTitle="A social initiative, powered by Plend technology - offering 0% loans to those who are eligible."
            />
          )
          : (
            <SocialCreditLoanCalculator
              title="Interest Free Loan by Social Credit Cymru"
              subTitle="A social initiative provided by Purple Shoots, Robert Owen Community Banking and Plend."
            />
          )
        : null}
      <TellMeMore/>
    </>
  )
}
export default memo(SocialCreditContent)
