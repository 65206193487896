import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { integerFormat } from '../../utils/formFormat'
import { maxLengthRule, minLengthRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'
import { PhoneTextStyled } from '../../../features/userInfoForm/styled'

const SecondaryPhoneNumber: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Secondary phone number"/>
    <CustomInput
      name="secondaryPhoneNumber"
      control={control}
      handleChange={integerFormat}
      rules={{
        minLength: minLengthRule(10, 'Please provide valid phone number value'),
        maxLength: maxLengthRule(11, 'Please provide valid phone number value')
      }}
      icon={<PhoneTextStyled>+44</PhoneTextStyled>}
    />
  </div>
)
export default SecondaryPhoneNumber
