import { memo } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../../core/styles/mixins'

const TextContainerStyled = styled.div`

  ${fontFamily('Open Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  max-width: 996px;
  margin: 0 20px;

  &>div{
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin: 0 10px;
  }
  
`

const BankCheckHeader = () => (
  <TextContainerStyled>
    <div>
      Not long now, you’re almost there, we just need your bank account information.
    </div>
    <div>
      The next screen will take you to Plaid, our Open Banking partner, enter your
      details and they’ll redirect you to your own bank. Once you’ve completed your
      account information, you’ll come back to us, and you’ll be able to submit your
      application.
    </div>
    <div>
      REMEMBER, if you have more than one bank account, please click the ‘add account’
      button and submit these details as well.
    </div>
    <div>
      Once you’ve finished adding your bank account information, please click the ‘SUBMIT’
      button to securely send your information through to us.
    </div>
  </TextContainerStyled>
)

export default memo(BankCheckHeader)
