import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomDatePicker from '../CustomDatePicker'
import { TControls } from '../../../features/userInfoForm/types'

type TProps = {
  disabled?: boolean
} & TControls

const DatePicker: FC<TProps> = ({ control, disabled }) => (
  <div>
    <CustomLabel label="Date of Birth *"/>
    <CustomDatePicker disabled={disabled} control={control}/>
  </div>

)

export default DatePicker
