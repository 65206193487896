import { memo } from 'react'
import { SliderThumb } from '@mui/material/Slider'
import { SliderNavigationIcon } from './icons/SliderNavigationIcon'

const CustomSliderThumb = (props: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other}>
      {children}
      <SliderNavigationIcon />
    </SliderThumb>
  )
}

export default memo(CustomSliderThumb)
