import { useEffect, useState } from 'react'
import constate from 'constate'
import { useLinkCorrelationId } from '../../../core/hooks/usePlaidHooks'

function useIsPlaidMenu(setIsPlaidMenu: (isPlaidMenu: boolean) => void) {
  const linkCorrelationId = useLinkCorrelationId()

  useEffect(() => {
    if (!linkCorrelationId) {
      return
    }

    setIsPlaidMenu(true)
  }, [])
}

const usePlaidMenu = () => {
  const [isPlaidMenu, setIsPlaidMenu] = useState(false)
  useIsPlaidMenu(setIsPlaidMenu)

  return {
    isPlaidMenu,
    setIsPlaidMenu
  }
}

export const [PlaidMenuProvider, usePlaidMenuContext] = constate(usePlaidMenu)
