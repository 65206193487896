import styled from 'styled-components/macro'
import React from 'react'
import Template from './Template'
import { flex } from '../../../core/styles/mixins'
import ImageBackground from '../../../core/components/MakeMyHouseGreenImageBackground'
import { useOrganizationContext } from '../../../core/state/useOrganization'

const WrapperStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })}
  position: relative;
  width: 100%;
  z-index: 1;
`

const ContentStyled = styled.div`
  max-width: 1100px;
  margin: 0 20px;
  ${({ theme }) => theme?.mixins?.termsAndConditions?.contentMixin};
`

const TermsAndConditions = () => {
  const { currentOrganization } = useOrganizationContext()
  const isMakemyhousegreen = currentOrganization === 'makemyhousegreen'
  return (
    <WrapperStyled>
      {isMakemyhousegreen && <ImageBackground butterFlyTop={-130}/>}
      <ContentStyled>
        <Template/>
      </ContentStyled>
    </WrapperStyled>
  )
}

export default TermsAndConditions
