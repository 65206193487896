import { useMemo } from 'react'
import { useOrganizationContext } from '../state/useOrganization'
import { useAuthOrganizationQuery } from '../../features/userInfoForm/queries'

export function useOrganizationId() {
  const { currentOrganization } = useOrganizationContext()
  const { data: authOrganizations } = useAuthOrganizationQuery()
  return useMemo(
    () =>
      authOrganizations?.find(
        (organization) => organization.subdomain === currentOrganization
      )?.id || 1,
    [authOrganizations]
  )
}
