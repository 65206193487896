/* eslint-disable react/no-unescaped-entities */

import { memo } from 'react'
import { TextStyled } from '../../ThankYou'

const MakemyhousegreenOrganization = () => (
  <>
    <TextStyled>
      Thanks! You're all done for this part of the process.
      We'll be in touch in 1 business day to let you know how much you can borrow.
    </TextStyled>
    <TextStyled>
      From there, you and your Switchd Guru will determine the exact amount you need to borrow,
      and given nothing changes, we'll lend you that cost and provide
      you with a formal loan contract prior to installation.
    </TextStyled>
    <TextStyled>
      If you have any questions, please email us at
      {' '}
      <a href="mailto:support@plend.co.uk">support@plend.co.uk</a>
      {' '}
      and make the email subject ‘Make My House Green - Enquiry’.
      We look forward to getting back to you and hopefully
      supporting you in making your house green!
    </TextStyled>
    <TextStyled>Team Plend</TextStyled>
  </>
)
export default memo(MakemyhousegreenOrganization)
