import React, { FC } from 'react'
import CustomSelect, { TOption } from '../CustomSelect'
import CustomLabel from '../CustomLabel'
import { TControls } from '../../../features/userInfoForm/types'

export const otherLoanPurpose = 'Other'
export const debtConsolidationLoanPurpose = 'Debt Consolidation'

const loanPurposes: string[] = [
  'A Car Loan',
  debtConsolidationLoanPurpose,
  'A Holiday',
  'Home Furnishing',
  'Home Improvement',
  'Legal Cost',
  'A Medical Bill',
  'A Wedding',
  otherLoanPurpose
]

const LoanPurposeSelect: FC<TControls> = ({ control }) => {
  const loanPurposeOptions: TOption[] = loanPurposes.map((loanPurpose) => ({
    label: loanPurpose,
    value: loanPurpose
  }), [])
  return (
    <CustomSelect
      name="loanPurpose"
      label={<CustomLabel label="Loan Purpose *"/>}
      control={control}
      options={loanPurposeOptions}
    />
  )
}

export default LoanPurposeSelect
