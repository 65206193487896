import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const Town: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Town"/>
    <CustomInput
      name="postTown"
      control={control}
      rules={{
        maxLength: maxLengthRule(25)
      }}
    />
  </div>
)
export default Town
