import constate from 'constate'
import { useSearchParams } from 'react-router-dom'
import { useLayoutEffect, useMemo, useState } from 'react'
import { useReconnectingQuery } from '../querries'
import { useLinkValidationQuery } from '../../bankCheck/queries'

function useParams() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') as string
  const loanApplicationId = searchParams.get('loanApplicationId') as string

  return {
    token: useMemo(() => token, []),
    loanApplicationId: useMemo(() => loanApplicationId, [])
  }
}

function useIsReconnectingProcess({ token }: ReturnType<typeof useParams>) {
  return useMemo(() => !!token, [])
}

function useIsInvalidLink(
  params: ReturnType<typeof useParams>,
  enabled: boolean
) {
  const { data: isValid } = useLinkValidationQuery(
    enabled,
    'RECONNECT_LINK',
    undefined,
    params.loanApplicationId
  )

  return enabled && isValid !== undefined && !isValid
}

function useReconnectionProcessCheck() {
  const [isReconnecting, setIsReconnecting] = useState(false)
  const [searchParams] = useSearchParams()
  useLayoutEffect(() => {
    if (!searchParams.get('type')) {
      setIsReconnecting(true)
    }
  }, [])

  return isReconnecting
}

const useContext = () => {
  const params = useParams()
  const isReconnectingProcess = useIsReconnectingProcess(params)
  const check = useReconnectionProcessCheck()
  const isEnabled = check && isReconnectingProcess
  const reconnectingQuery = useReconnectingQuery(
    isEnabled,
    params.token
  )

  return ({
    reconnectingQuery,
    hasReconnectingBankCheck: !!reconnectingQuery.data?.enableConnectAccount,
    isReconnectingProcess,
    token: params.token,
    isInvalidLink: useIsInvalidLink(params, isEnabled)
  })
}

export const [
  ReconnectingStateProvider,
  useReconnectingContext
] = constate(useContext)
