import React, { ReactNode } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import SocialCreditFull from './terms/socialCredit/SocialCreditFull'
import SocialCreditPlendFull from './terms/socialCredit/SocialCreditPlendFull'

const map: {[key in TOrganization]?: ReactNode} = {
  socialcreditcymru: <SocialCreditFull />,
  socialcreditplend: <SocialCreditFull />
}

const TermsAndConditionsFull = () => {
  const { currentOrganization } = useOrganizationContext()

  return (
    <>
      {map[currentOrganization]}
    </>
  )
}

export default TermsAndConditionsFull
