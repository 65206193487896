import { useSocialCreditLoanCalculatorContext } from '../state/useSocialCreditLoanCalculatorState'
import { TStatus } from '../types'

const availableStatuses: TStatus[] = ['APPROVED', 'DECLINED', 'SCORING_IN_PROGRESS']

export function useLastStepNavigationCheck() {
  const { loanDataQuery: { data } } = useSocialCreditLoanCalculatorContext()

  return () => data?.status && (availableStatuses).includes(data.status)
}
