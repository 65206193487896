import { FC } from 'react'
import { TControls } from '../../../features/userInfoForm/types'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { emailValidation, maxLengthRule, minLengthRule, requiredRule } from '../../utils/formRules'

type TProps = {
  disabled?: boolean
} & TControls

const Email: FC<TProps> = ({ control, disabled }) => (
  <div>
    <CustomLabel label="Email *"/>
    <CustomInput
      name="email"
      control={control}
      disabled={disabled}
      rules={{
        required: requiredRule(),
        minLength: minLengthRule(2),
        maxLength: maxLengthRule(100),
        validate: emailValidation
      }}
    />
  </div>

)
export default Email
