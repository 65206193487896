import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ContainerStyled, TitleStyled } from './styled'
import { TState, TStatus } from '../../types'
import PendingLoan from './loanStatusTemplate/PendingLoan'

const statusMap: {[keyof in TStatus ]: ReactNode} = {
  hasPendingLoan: (
    <PendingLoan
      url="https://connect.socialcredit.community/"
      orgName="Social Credit"
      teamName="Social Credit team" />
  ),
  hasApprovedLoan: <></>,
  hasDeclinedLoan: <></>
}

const CardStyled = styled.div`
    font-family: Neue Haas Grotesk Display Pro, sans-serif;
    max-width: 996px;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.mystic};
    width: 100%;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    color: ${({ theme }) => theme.colors.white};
    & a{
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
    }
`

const PendingLoanWrap = styled.div`
    display: flex;
    flex-direction: column;
`
const SocialCreditLoanStatus = () => {
  const { state } = useLocation() as TState
  return (
    <ContainerStyled>
      <CardStyled>
        <div>
          Hey
          {' '}
          {state?.borrowerName}
          ,
        </div>
        <PendingLoanWrap>
          {state?.currentStatus && statusMap[state?.currentStatus]}
        </PendingLoanWrap>
      </CardStyled>
    </ContainerStyled>
  )
}

export default SocialCreditLoanStatus
