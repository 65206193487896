import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { memo } from 'react'
import { flex, fontFamily } from '../../../../core/styles/mixins'

const ContentStyled = styled.div`
  ${flex({ justify: 'center', align: 'center', direction: 'column' })}
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white100};
  padding: 0 20px;
`

const TextContainerStyled = styled.div`
  ${fontFamily('Open Sans')}
  margin-top: 50px;
  max-width: 996px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  
  & >div {
    margin-bottom: 30px;
  }
`

const ButtonAcceptStyled = styled(NavLink)`
&&{
  ${fontFamily('Open Sans')}
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
  padding: 12px 20px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.brown100};
  margin:20px 0 50px 0;
  width: 190px;
  text-align: center;
  
  :hover{
    background: ${({ theme }) => theme.colors.brown200};
  }
}
  
`
const RobertOwenContent = () => (
  <ContentStyled>
    <TextContainerStyled>
      <div>
        Welcome to the Robert Owen Community Banking loan application,
        powered by our friends at Plend. Use this form to apply for a
        loan to help you install a renewable energy system and/ or make
        home improvements.
      </div>
      <div>
        This is the quickest and easiest way to get your project moving and,
        if you apply on a weekday, we’ll aim to provide you with an agreement
        in principle within 48hrs. (We tend to have a little rest at the weekend,
        so we’ll start things moving when we’re back in the office.)
      </div>
      <div>
        Plend will be using Open Banking to gather the majority of the information
        we need, so have your current account details to hand. During the process
        you’ll be taken to a site powered by Plaid, this is so we can securely request
        the information we need from your bank(s), and this means you can sit back and
        relax.
      </div>
      <div>
        Have you read these
        {' '}
        <a href="https://www.rocbf.co.uk/terms_and_conditions/" target="_blank" rel="noreferrer">Terms of Use?</a>
        {' '}
        If so, click Accept.
      </div>
    </TextContainerStyled>
    <ButtonAcceptStyled to="/registration">Accept</ButtonAcceptStyled>
  </ContentStyled>
)
export default memo(RobertOwenContent)
