import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import constate from 'constate'
import { useSearchParams } from 'react-router-dom'
import { UseQueryResult } from 'react-query'
import {
  useLoanApplicationForNoInterestLoanQuery,
  useScoreNoInterestLoanApplicationQuery,
  useValidateUTM
} from '../querries'
import { TCymruLoanAmount, TCymruPeriod, TLoanApplicationForNoInterest } from '../types'
import { calculateCymruMonthlyRepayment } from '../utils/cymruPartnerMonthlyRepayment'
import { cymruConnectDomains } from '../../../core/state/useOrganization'
import { useOrganizationId } from '../../../core/hooks/useOrganizationId'
import { TDebtConsolidation } from '../../socialCreditLoanPurpose/types'
import { TFormData } from '../../SocialCreditPersonalInformation/types'
import { useIsConnectSocialCreditCommunity } from '../../../core/hooks/useIsConnectSocialCreditCommunity'

function useIsSocialOpenConnect() {
  const [searchParams] = useSearchParams()
  const { hostname } = window.location
  return !searchParams.get('loanApplicationId')
    && !searchParams.get('borrowerId')
    && cymruConnectDomains.some((subdomain) => hostname.startsWith(subdomain))
}

export function useUTMSource() {
  return useSearchParams()[0].get('utm_source')
}

export function useMonthlyPayment(
  period?: number,
  loanAmount?: number
): number | undefined {
  if (loanAmount === undefined || period === undefined) {
    return undefined
  }

  return calculateCymruMonthlyRepayment(period, loanAmount)
}

function useUrlParams() {
  const [searchParams] = useSearchParams()

  const loanApplicationId = searchParams.get('loanApplicationId')
  const borrowerId = searchParams.get('borrowerId')

  return {
    loanApplicationId: Number(loanApplicationId),
    borrowerId: Number(borrowerId)
  }
}

export function useLoanData(enabled: boolean) {
  const { loanApplicationId, borrowerId } = useUrlParams()

  return useLoanApplicationForNoInterestLoanQuery(
    loanApplicationId,
    borrowerId,
    enabled
  )
}

function useInitialLoanCalcValues(
  loanDataQuery: UseQueryResult<TLoanApplicationForNoInterest, Error>,
  setPeriod: Dispatch<SetStateAction<TCymruPeriod | undefined>>,
  setLoanAmount: Dispatch<SetStateAction<TCymruLoanAmount | undefined>>
) {
  const utmSource = useUTMSource()
  const isSocialOpenConnect = useIsSocialOpenConnect()
  useEffect(() => {
    if (isSocialOpenConnect) {
      setPeriod(utmSource ? 12 : 24)
      setLoanAmount(1000)
    } else if (loanDataQuery.data) {
      const isPartnerSocialCredit = loanDataQuery.data.partnerSocialCredit
      setPeriod(isPartnerSocialCredit ? 24 : 6)
      setLoanAmount(isPartnerSocialCredit ? 1000 : 500)
    }

    return undefined
  }, [loanDataQuery?.data, isSocialOpenConnect])
}

const useCymruLoanCalculator = () => {
  const [initialized, setInitialized] = useState(false)
  const [period, setPeriod] = useState<TCymruPeriod | undefined>()
  const [loanAmount, setLoanAmount] = useState<TCymruLoanAmount | undefined>()
  const { loanApplicationId, borrowerId } = useUrlParams()
  const loanDataQuery = useLoanData(initialized)
  useInitialLoanCalcValues(loanDataQuery, setPeriod, setLoanAmount)
  const isCymruPartner = useMemo(
    () => (loanDataQuery
      ? loanDataQuery?.data?.partnerSocialCredit
      : undefined),
    [loanDataQuery]
  )
  const monthlyPayment = useMonthlyPayment(
    period,
    loanAmount
  )
  const isSocialOpenConnect = useIsSocialOpenConnect()
  const [loanPurpose, setLoanPurpose] = useState<string>()
  const [debtConsolidation, setDebtConsolidation] = useState<TDebtConsolidation[]>()
  const [personalInformation, setPersonalInformation] = useState<TFormData>()

  const organizationId = useOrganizationId()
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  const utmSource = useUTMSource()
  const { data } = useValidateUTM(utmSource ?? undefined)
  return {
    monthlyPayment,
    loanApplicationQuery: useScoreNoInterestLoanApplicationQuery(
      loanApplicationId,
      borrowerId,
      period!,
      loanAmount,
      monthlyPayment,
      loanPurpose,
      debtConsolidation,
      personalInformation,
      organizationId
    ),
    isCymruPartner,
    isSocialOpenConnect,
    initCalculator: () => setInitialized(true),
    isCalculatorInitialized: initialized,
    // todo
    showCalculator: isConnectSocialCreditCommunity
      ? !!data : !!loanApplicationId && !!borrowerId,
    // && cymrySocialCreditDomains.some((subdomain) => hostname.startsWith(subdomain)))
    loanDataQuery,
    setLoanPurpose,
    setDebtConsolidation,
    setPersonalInformation,
    period,
    setPeriod,
    debtConsolidation,
    loanAmount,
    loanPurpose,
    setLoanAmount
  }
}

export const [
  SocialCreditLoanCalculatorStateProvider,
  useSocialCreditLoanCalculatorContext
] = constate(useCymruLoanCalculator)
