import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../styles/mixins'

export const LabelStyled = styled.div`
  ${fontFamily('Neue')};
  font-size: 24px;
  height: 27px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme?.mixins?.personalDetails?.inputLabelTextMixin};
  cursor: default;
`

type TLabelProps = {
  label: string
}

const CustomLabel: FC<TLabelProps> = ({ label }) => (
  <LabelStyled>{label}</LabelStyled>
)

export default CustomLabel
