import { FC } from 'react'
import { Control, useController } from 'react-hook-form'
import { MenuItem, Select } from '@mui/material'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { SelectProps } from '@mui/material/Select/Select'
import { fontFamily } from '../styles/mixins'
import { requiredRule } from '../utils/formRules'

export const GlobalStyles = createGlobalStyle`
  .selectContainer {
    && {
      & .MuiButtonBase-root {
        ${fontFamily('Neue')};
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 30px;
        color: ${({ theme }) => theme.colors.blackDarkBlue};
        ${({ theme }) => theme?.mixins?.fontFamilyMixin};
      }
    }
  }
`

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  && {
    & .MuiInputBase-root {
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.blackDarkBlue};
      ${({ theme }) => theme?.mixins?.inputMixin};
    }

    & .MuiSelect-select {
      background: ${({ theme }) => theme.colors.white};
      padding: 6.5px 14px;
    }
    
    & .MuiButtonBase-root{
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 450;
      ${({ theme }) => theme?.mixins?.fontFamilyMixin};
    }
  }

  @media (max-width: 375px) {
    &:nth-child(1) {
      margin-top: 15px;
    }

    &:nth-child(2) {
      margin-top: 30px;
    }
  }
`

const StyledPlaceholder = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

const InputLabelStyled = styled.label`
  ${fontFamily('Neue')}
  font-size: 16px;
  ${({ theme }) => theme.mixins?.fontFamilyMixin};
`

export type TOption = {
  label: string
  value: string | number | any
}

type TProps = SelectProps & {
  name: string
  placeholder?: string
  label?: string | any
  control: Control<any>
  options: TOption[],
}

const CustomSelect: FC<TProps> = ({
  name,
  label,
  placeholder,
  control,
  options,
  ...rest
}) => {
  const { field: { value, onChange }, fieldState: { invalid } } = useController({
    name,
    control,
    rules: {
      required: requiredRule()
    }
  })

  return (
    <ContainerStyled>
      <GlobalStyles />
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <Select
        data-testid={`${name}-select-wrapper`}
        onChange={onChange}
        value={value || ''}
        fullWidth
        displayEmpty
        inputProps={{
          'aria-label': `${name}-select`
        }}
        MenuProps={{
          classes: {
            paper: 'selectContainer'
          }
        }}
        renderValue={
          (value) => options.find(
            (item) => item.value === value
          )?.label || (
            <StyledPlaceholder>
              {placeholder !== undefined ? placeholder : 'Please select'}
            </StyledPlaceholder>
          )
        }
        error={invalid}
        {...rest}
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </ContainerStyled>
  )
}

export default CustomSelect
