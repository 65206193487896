import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { useSearchParams } from 'react-router-dom'
import { TextStyled } from './styles'

const WrapStyled = styled.div`
  text-align: center;
`

const LinkStyled = styled.span`
  a {
    color: ${({ theme }) => theme.colors.white};

    :hover {
      text-decoration: underline;
    }
  }
`

const Resubmit: FC = () => {
  const [searchParams] = useSearchParams()

  return (
    <WrapStyled>
      <TextStyled>
        <div>
          Following your loan application, we couldn't assess your loan application because we
          couldn’t identify you with the credit reference agency.
          It looks like this is due to a mismatch of data regarding your address.
          It may be that you need to re-apply with a previous address if you have recently moved.
        </div>
        <div>
          If you’re happy to re-apply, please
          {' '}
          <LinkStyled>
            <a href={`/resubmit-address?borrowerId=${searchParams.get('borrowerId')}&loanApplicationId=${searchParams.get('loanApplicationId')}`}>
              click here
            </a>
          </LinkStyled>
          .
        </div>
        <div>
          Once this is received with an address that matches the credit bureaux,
          we should be able to assess your application further.
        </div>
        <div>
          Best Wishes,
        </div>
        <div>
          Team SCC
        </div>
      </TextStyled>
    </WrapStyled>
  )
}

export default Resubmit
