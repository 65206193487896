import { TFormData } from '../../SocialCreditPersonalInformation/types'
import { TDebtConsolidation } from '../../socialCreditLoanPurpose/types'

function parseFloatString(str: string) {
  return parseFloat(str.replace(',', '.'))
}

export function preparePersonalInformation(
  personalInformation: TFormData,
  loanProviders?: TDebtConsolidation[]
) {
  return {
    benefits: personalInformation.benefits,
    benefitDescription: personalInformation.benefitDescription,
    caringResponsibility: personalInformation.caringResponsibility,
    disability: personalInformation.disability,
    education: personalInformation.education,
    educationCompleteAge: personalInformation.educationCompleteAge
      ? Number(personalInformation.educationCompleteAge)
      : undefined,
    ethnicity: personalInformation.ethnicity,
    gender: personalInformation.gender,
    loanProviders: loanProviders?.map((item) => ({
      ...item,
      outstandingDebt: parseFloatString(item.outstandingDebt),
      apr: parseFloatString(item.apr)
    })),
    maritalStatus: personalInformation.maritalStatus,
    totalBenefitsReceived: personalInformation.totalBenefitsReceived
      ? parseFloatString(personalInformation.totalBenefitsReceived)
      : undefined,
    numberOfDependant: personalInformation.numberOfDependant
      ? Number(personalInformation.numberOfDependant)
      : undefined,
    orientation: personalInformation.orientation,
    orientationDescription: personalInformation.orientationDescription,
    qualification: personalInformation.qualification,
    religion: personalInformation.religion,
    religionDescription: personalInformation.religionDescription,
    workExperience: personalInformation.workExperience
      ? Number(personalInformation.workExperience)
      : undefined
  }
}
