import styled from 'styled-components/macro'
import { Alert } from '@mui/material'
import Ellipse from '../../core/components/icons/svg/Ellipse.svg'
import { flex, fontFamily } from '../../core/styles/mixins'
import SemicircleRight from '../../core/components/icons/svg/SemicircleRight.svg'
import { BaseButton } from '../../core/components/buttons/CustomButton'

export const commonGap = '30px'

export const WrapperStyled = styled.div<{maxWidth?: number}>`
  max-width: ${({ maxWidth }) => maxWidth || 710}px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, minmax(340px, auto));
  grid-gap: ${commonGap};
  position: relative;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1000000;
    right: -400px;
    bottom: -150px;
    background: url(${Ellipse})
    no-repeat center center/cover;
    width: 281px;
    height: 281px;
    ${({ theme }) => theme?.mixins?.hideFiguresMixin};
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  ${({ theme }) => theme.mixins?.personalDetails?.wrapperMixin}
    
`

export const StatusWrapperStyled = styled.div`
  margin-top: ${commonGap};
  @media (max-width: 768px) {
    max-width: 355px;
  }
`

export const SelectContainerStyled = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${commonGap};
  justify-items: center;
  align-items: start;
  grid-template-columns: repeat(auto-fit,minmax(340px,auto));
  @media (max-width: 768px) {
    ${flex({ direction: 'column', justify: 'center', align: 'center' })};
    grid-template-columns: repeat(1, minmax(auto, auto));
    &>:nth-child(2) {
      margin-top:${commonGap};
    }
  }
`

export const ContentStyled = styled.div<{marginBottom?: number}>`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(340px, auto));
  grid-gap: ${commonGap};
  margin-top: ${commonGap};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }
`

export const RadioButtonContainer = styled.div`
  margin:${commonGap} 0;
`

export const RadioButtonStyled = styled.div`
  ${flex({})};
`

export const RadioWrapperStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })};
  height: 32px;
  margin-right: 30px;
  cursor: default;
`

export const PeriodStyled = styled.div`
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 27px;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.personalDetails?.periodTextMixin}
`

export const SecondAddressStyled = styled.div`
  display: grid;
  grid-gap: ${commonGap};
  align-items: start;
  grid-template-columns: repeat(auto-fit,minmax(340px,auto));
`

export const PhoneTextStyled = styled.div`
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  background: ${({ theme }) => theme.colors.ivory};
  padding: 10px;
  margin-left: -8px;
  border-radius: 4px;
  ${({ theme }) => theme.mixins?.phoneIconTextMixin}
`

export const ContainerStyled = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center' })};
  width: 100%;
  z-index: 0;

  @media (max-width: 768px) {
    padding: 0 10px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    left: 0;
    top: 20.5%;
    background: url(${SemicircleRight})
    no-repeat center center/cover;
    width: 173px;
    height: 346px;
    ${({ theme }) => theme?.mixins?.hideFiguresMixin};

    @media (max-width: 1070px) {
      display: none;
    }
  }

  && {
    .MuiAlert-standardError {
      width: 100%;
      ${flex({ justify: 'center' })};
      @media (max-width: 768px) {
        margin-right: 20px;
      }
    }

    .MuiFormHelperText-root {
      background: ${({ theme }) => theme.colors.violet};
    }
  }
  ${({ theme }) => theme.mixins?.personalDetails?.containerMixin}
`
export const FormWrapper = styled.div<{maxWidth?: number}>`
  max-width: ${({ maxWidth }) => maxWidth || 710}px;
  width: 100%;
  ${({ theme }) => theme.mixins?.personalDetails?.wrapperMixin}
`

export const ButtonStyled = styled(BaseButton)`
  && {
    ${fontFamily('Neue')};
    max-width: 100px;
    width: 100%;
    margin: 30px 0 50px;
    padding: 12px 20px;
    align-self: end;
    justify-self: flex-end;
    border-radius: 10px;
    text-transform: capitalize;
    background: ${({ theme }) => theme.colors.orange};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);

    &:hover {
      background: ${({ theme }) => theme.colors.orange100};
      box-shadow: 0 4px 8px rgba(255, 96, 76, 0.5);
    }

    &:disabled {
      opacity: 1;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.pink100};
    }

    ${({ theme }) => theme?.mixins?.nextButtonMixin}
  }
`

export const ButtonWrapper = styled.div`
  ${flex({ justify: 'flex-end' })};
`

export const AlertStyled = styled(Alert)`
  && {
    &.MuiAlert-standardError {
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.orange};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`

export const UpdatesSubscription = styled.div`
  ${flex({ align: 'center' })};
  ${fontFamily('Neue')};
  max-width: 710px;
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 27px;
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    max-width: 355px;
  }
`
export const CheckboxLabel = styled.label`
  ${flex({ justify: 'flex-start', align: 'flex-start' })};
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 28px;
  cursor: pointer;
  ${({ theme }) => theme?.mixins?.checkboxLabelMixin};
`

export const EmptyRowStyled = styled.div`
  height: 83px;
`
