import { ReactNode } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import SocialCreditStepper from './stepper/SocialCreditStepper'
import DefaultStepper from './stepper/DefaultStepper'

const map: {[key in TOrganization]?: ReactNode} = {
  socialcreditcymru: <SocialCreditStepper/>,
  socialcreditplend: <SocialCreditStepper/>
}

const RegistrationStepper = () => {
  const { currentOrganization } = useOrganizationContext()

  return (
    <>
      {map[currentOrganization] || <DefaultStepper/>}
    </>
  )
}

export default RegistrationStepper
