import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const PrevBuildingNumber: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Previous Building Number"/>
    <CustomInput
      name="prevBuildingNo"
      control={control}
      rules={{
        maxLength: maxLengthRule(12)
      }}
    />
  </div>
)

export default PrevBuildingNumber
