import styled from 'styled-components/macro'
import { memo } from 'react'
import PlendLogo from '../../../../core/assets/logos/plendLogo.png'
import { flex } from '../../../../core/styles/mixins'

const HeaderStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  background: ${({ theme }) => theme.colors.ivory};
  padding: 24px;
`
const LogoStyled = styled.img`
  width: 127px;
  height: 40px;
  object-fit: contain;
`
const PlendResubmitHeader = () => (
  <HeaderStyled data-testid="plendResubmitHeader">
    <LogoStyled src={PlendLogo} alt="logo"/>
  </HeaderStyled>
)

export default memo(PlendResubmitHeader)
