import React, { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { TControls } from '../../../features/userInfoForm/types'

const LoanSize: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Loan size"/>
    <CustomInput
      disabled
      name="loanAmount"
      control={control}
    />
  </div>
)
export default LoanSize
