import React from 'react'
import styled from 'styled-components/macro'
import { useMedia } from 'react-use'
import SocialCreditPlend from '../../../../core/assets/logoSVG/SocialCreditPlend.svg'
import SocialCreditPlendDesktop from '../../../../core/assets/logoSVG/SocialCreditPlendDesktop.svg'
import { TABLET_SIZE } from '../../../../core/const/sizes'

const WrapStyled = styled.div`
  height: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.mixins?.mainBackgroundMixin};
`

export default () => {
  const isTablet = useMedia(`(max-width: ${TABLET_SIZE}px)`)

  return (
    <WrapStyled>
      <img src={isTablet ? SocialCreditPlend : SocialCreditPlendDesktop} alt="logo"/>
    </WrapStyled>
  )
}
