import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { apiClient } from '../../core/api/apiClient'
import { TBorrowerInformationParams, TCymruLoanMutationData, TNilsDictionariesData } from './types'
import { useMultiStepStateContext } from '../userInfoForm/state/useToken'
import { useStepperContext } from '../../core/components/stepper/StepperContext'
import { TLoanerResponse } from '../userInfoForm/types'

export function usNilsDictionariesQuery() {
  return useQuery<TNilsDictionariesData, Error>(
    'dictionariesNils',
    async () => {
      try {
        const response = await apiClient.get('/dictionaries/nils')
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    }
  )
}

export function useCymruPartnerLoanerMutation() {
  const { setState } = useMultiStepStateContext()
  const { nextStep } = useStepperContext()
  const [searchParams] = useSearchParams()

  return useMutation<TLoanerResponse, Error, TCymruLoanMutationData>(async (loaner) => {
    try {
      const { data } = await apiClient.put(
        `/loans/${searchParams.get('loanApplicationId')}/lead`,
        loaner.data,
        { params: loaner.params }
      )

      return data
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error(`loaner, ${err}`)
      }
    }
  }, {
    onSuccess: (data) => {
      setState({ loanApplicationId: data.id })
      nextStep()
    }
  })
}

export function useSaveBorrowerPersonalInformationMutation() {
  const { nextStep } = useStepperContext()
  return useMutation<any, Error, TBorrowerInformationParams>(async (
    { borrowerInformation, loanApplicationId }
  ) => {
    try {
      await apiClient.post(`/loans/${loanApplicationId}/personal-info`, borrowerInformation)
    } catch (error: any) {
      throw new Error(error)
    }
  }, {
    onSuccess: () => {
      nextStep()
    }
  })
}
