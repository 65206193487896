import styled from 'styled-components/macro'
import { fontFamily } from '../../styles/mixins'
import CustomButton from './CustomButton'

export const SocialCreditStepperButton = styled(CustomButton)`
  && {
    ${fontFamily('Open Sans')};
    width: 190px;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    background: ${({ theme }) => theme.colors.seaBuckthorn} !important;
    border-radius: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px 0;
    margin-top: 30px;
    
    :hover {
      background: ${({ theme }) => theme.colors.seaBuckthorn} !important;
    }
    
    ${({ theme }) => theme.mixins?.stepper?.submitButtonMixin}
  }
`
