import styled from 'styled-components/macro'
import { fontFamily } from '../../../../core/styles/mixins'

export const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 50px;
  }

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
`

export const ContentStyled = styled.div`
  max-width: 996px;
  width: 100%;
`

export const TitleStyled = styled.div`
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.stepper?.stepperTitleMixin}
`

export const TextStyled = styled.div<{ noMargin?: boolean, italic?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  ${({ italic }) => italic && 'font-style: italic'};
  ${({ theme }) => theme.mixins?.stepper?.textMixin}
`

export const BoldTextStyled = styled.div<{ noMargin?: boolean, inline?: boolean }>`
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  ${fontFamily('Open Sans')};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

export const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

export const SubTitleStyled = styled.div<{ noMargin?: boolean }>`
  ${fontFamily('Open Sans')};
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

export const ListStyled = styled.ul<{ noMargin?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '10px')};
  list-style-type: disc;
  padding-left: 22px;
  ${({ theme }) => theme.mixins?.stepper?.listMixin}
`

export const TableWrapStyled = styled.div`
  margin-top: 20px;
  overflow-x:auto;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.mercury};
`

export const TableStyled = styled.table`
  border-collapse: collapse;
  min-width: 600px;
  
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
  
  td {
    padding: 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.mercury};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    width: 33.3%;
    vertical-align: baseline;
    text-align: left;
    
    :last-child {
      border-right: none;
    }
  }
`

export const CardStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-top: 50px;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  max-height: 594px;
  overflow: auto;
  width: 100%;
`

export const ButtonWrapStyled = styled.ul`
  display: flex;
  justify-content: flex-end;
`
