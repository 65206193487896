import { FC } from 'react'
import styled from 'styled-components/macro'
import { SpinnerIcon } from '../../../core/components/icons/SpinnerIcon'
import { TextStyled, TitleStyled } from './styles'
import { fontFamily } from '../../../core/styles/mixins'

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px 0;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 0;
  }

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
`

const ContentStyled = styled.div`
  max-width: 996px;
`

const SpinnerWrapIcon = styled.div`
  color: transparent;
  display: flex;
  justify-content: center;
  margin: 75px 0;
  @media (max-width: 768px) {
    margin: 55px 0;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  > * {
    animation: lds-dual-ring 1.2s linear infinite;
  }
`

const Connect: FC = () => (
  <WrapStyled>
    <ContentStyled>
      <TitleStyled>
        Processing your application
      </TitleStyled>
      <TextStyled center>
        We’re just reviewing your details so we can make a decision on your application.
        <br/>
        This is an automatic process so please keep this tab open and avoid refreshing
        your page. It might take a couple of minutes so thanks for bearing with us.
      </TextStyled>
      <SpinnerWrapIcon>
        <SpinnerIcon />
      </SpinnerWrapIcon>
    </ContentStyled>
  </WrapStyled>
)

export default Connect
