import styled from 'styled-components/macro'
import { Stepper as StepperMui } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { FC } from 'react'
import StepIcon from './StepIcon'
import { flex, fontFamily } from '../../styles/mixins'
import { useStepperContext } from './StepperContext'

const StepperWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  background: ${({ theme }) => theme.colors.ivory};
  ${({ theme }) => theme?.mixins?.stepper?.wrapperMixin}
`

const StepperMuiStyled = styled(StepperMui)`
  &&{
    width: 100%;
    max-width: 1060px;
    background: ${({ theme }) => theme.colors.ivory};
    margin-bottom: 30px;
    ${({ theme }) => theme?.mixins?.stepper?.stepperMuiMixin}
    & .MuiStepConnector-root{
      top: 27px;
      left: calc(-50% + 47px);
      right: calc(50% + 47px);
      height: 2px;
      background: ${({ theme }) => theme.colors.white200};

      & .MuiStepConnector-line{
        border: ${({ theme }) => theme.colors.white200};
      }
      &.Mui-active, &.Mui-completed{
        border: ${({ theme }) => theme.colors.yellow};
        background: ${({ theme }) => theme.colors.yellow};
        ${({ theme }) => theme?.mixins?.stepper?.stepperActiveLineMixin}
      }
    }
  }
`

const StepLabelStyled = styled(StepLabel)`
  && .MuiStepLabel-label{
    ${fontFamily('Neue')}
    margin-top: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    text-align: center;

    ${({ theme }) => theme?.mixins?.stepper?.stepLabelMixin};
  }
`

type TProps = {
  stepNames: string[]
  showPreviousStepsIcon?: boolean
}
const Stepper: FC<TProps> = ({ stepNames, showPreviousStepsIcon }) => {
  const { stepFrom0 } = useStepperContext()

  return (
    <StepperWrapper>
      <StepperMuiStyled activeStep={stepFrom0} alternativeLabel>
        {stepNames.map((name) => (
          <Step key={name}>
            {/* eslint-disable-next-line react/no-unstable-nested-components */}
            <StepLabelStyled StepIconComponent={({ completed, icon, active }) => (
              <StepIcon
                completed={completed}
                showPreviousStepsIcon={showPreviousStepsIcon}
                icon={icon}
                active={active}
              />
            )}>
              {name}
            </StepLabelStyled>
          </Step>
        ))}
      </StepperMuiStyled>
    </StepperWrapper>
  )
}

export default Stepper
