export const calculateCymruMonthlyRepayment = (
  yearPeriod: number,
  loanAmount: number
  // eslint-disable-next-line consistent-return
) => {
  switch (yearPeriod) {
  case 6:
    if (loanAmount === 500) {
      return 83.33
    }
    if (loanAmount === 1000) {
      return 166.67
    }
    if (loanAmount === 1500) {
      return 250
    }
    if (loanAmount === 2000) {
      return 333.33
    }
    break
  case 12:
    if (loanAmount === 500) {
      return 41.67
    }
    if (loanAmount === 1000) {
      return 83.33
    }
    if (loanAmount === 1500) {
      return 125
    }
    if (loanAmount === 2000) {
      return 166.67
    }
    break
  case 18:
    if (loanAmount === 500) {
      return 27.78
    }
    if (loanAmount === 1000) {
      return 55.56
    }
    if (loanAmount === 1500) {
      return 83.33
    }
    if (loanAmount === 2000) {
      return 111.11
    }
    break
  case 24:
    if (loanAmount === 500) {
      return 20.83
    }
    if (loanAmount === 1000) {
      return 41.67
    }
    if (loanAmount === 1500) {
      return 62.50
    }
    if (loanAmount === 2000) {
      return 83.33
    }
    break
  default:
    break
  }
}
