import React, { FC, ReactNode } from 'react'
import { TControls } from '../../../../features/userInfoForm/types'
import PostCodeBase from './PostCodeBase'

type TProps = {
  onFocus: () => void
  onInput: () => void
  value: string,
  children: ReactNode
} & TControls

export const postCodeFieldName = 'postCode'

const PostCode: FC<TProps> = ({ children, ...rest }) => (
  <PostCodeBase label="Post code *" name={postCodeFieldName} {...rest}>{children}</PostCodeBase>
)

export default PostCode
