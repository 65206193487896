import constate from 'constate'
import PlendLogo from '../assets/logos/plendLogo.png'
import PurpleShootsLogo from '../assets/logos/purple-shoots-logo-purple.png'
import LendologyLogo from '../assets/logos/LendologyLogo.png'
import PlendFavicon from '../assets/icons/Plendfavicon.ico'
import PurpleShootsFavicon from '../assets/icons/purpleshootsFavicon.ico'
import CimruFavicon from '../assets/icons/СimruFavicon.ico'
import SocialCreditPlendFavicon from '../assets/icons/socialCreditPlendFavicon.ico'
import LendologyFavicon from '../assets/icons/lendologyFavicon.ico'
import { useIsConnectSocialCreditCommunity } from '../hooks/useIsConnectSocialCreditCommunity'

export type TLoan = {
  loanAmount: number
  loanLength: number
  interestRate: number
  monthlyRepayment: number
}

type TOrganizationsConfig = {
  title: string
  label: string
  logo: {
    url: string
    width: number
    height: number
  }
  favicon: string
  loan?: TLoan
  buttonStepperText: 'Next' | 'Accept' | 'Continue'
  bankCheckText?: string
}

export type TOrganization = 'plend' | 'purpleshoots' | 'lendology' | 'makemyhousegreen' | 'robertowen' | 'socialcreditcymru' | 'socialcreditplend'

const organizationsConfigs: { [key in TOrganization]: TOrganizationsConfig } = {
  plend: {
    title: 'Plend',
    label: 'Terms and conditions',
    logo: {
      url: PlendLogo,
      width: 90,
      height: 40
    },
    favicon: PlendFavicon,
    buttonStepperText: 'Next'
  },
  purpleshoots: {
    title: 'Purple Shoot',
    label: 'Purple Shoots - Powered by Plend',
    logo: {
      url: PurpleShootsLogo,
      width: 90,
      height: 70
    },
    favicon: PurpleShootsFavicon,
    loan: {
      loanAmount: 2700,
      loanLength: 30,
      interestRate: 25.9,
      monthlyRepayment: 122.63
    },
    buttonStepperText: 'Accept'

  },
  lendology: {
    title: 'Lendology',
    label: 'Lendology - Powered by Plend',
    logo: {
      url: LendologyLogo,
      width: 120,
      height: 100
    },
    favicon: LendologyFavicon,
    loan: {
      loanAmount: 8000,
      loanLength: 120,
      interestRate: 4.1,
      monthlyRepayment: 81
    },
    buttonStepperText: 'Accept'
  },
  makemyhousegreen: {
    title: 'Plend',
    label: '',
    logo: {
      url: '',
      width: 90,
      height: 40
    },
    favicon: PlendFavicon,
    loan: {
      loanAmount: 15000,
      loanLength: 60,
      interestRate: 2.99,
      monthlyRepayment: 269.20
    },
    buttonStepperText: 'Next'
  },
  robertowen: {
    title: 'Robert Owen',
    label: '',
    logo: {
      url: '',
      width: 120,
      height: 100
    },
    favicon: PlendFavicon,
    loan: {
      loanAmount: 12000,
      loanLength: 36,
      interestRate: 0,
      monthlyRepayment: 333.33
    },
    buttonStepperText: 'Next'
  },
  socialcreditcymru: {
    title: 'Social Credit Cymru',
    label: '',
    logo: {
      url: '',
      width: 120,
      height: 100
    },
    favicon: CimruFavicon,
    buttonStepperText: 'Next',
    bankCheckText: 'Connect your bank account'
  },
  socialcreditplend: {
    title: 'Social Credit Community',
    label: '',
    logo: {
      url: '',
      width: 120,
      height: 100
    },
    favicon: SocialCreditPlendFavicon,
    buttonStepperText: 'Next'
  }
}

function useOrganizationsConfig(currentOrganization: TOrganization) {
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()
  const organization = organizationsConfigs[currentOrganization]
  return isConnectSocialCreditCommunity ? { ...organization, buttonStepperText: 'Continue' } : organization
}

const organizations: TOrganization[] = [
  'purpleshoots',
  'lendology',
  'makemyhousegreen',
  'robertowen',
  'plend',
  'socialcreditcymru',
  'socialcreditplend'
]

type TCymruConnectDomains = 'connect-stage.socialcredit' | 'connect-dev.socialcredit' | 'connect.socialcredit'
export const cymruConnectDomains: TCymruConnectDomains[] = [
  'connect-stage.socialcredit',
  'connect-dev.socialcredit',
  'connect.socialcredit'
]

export const cymrySocialCreditDomains = [
  'socialcredit',
  'dev.socialcredit',
  'stage.socialcredit'
]

export function getCurrentOrganizationByHostname(hostname: string): TOrganization {
  const subdomain = hostname.split('.')[0]
  if (
    hostname.startsWith('connect.socialcredit.community')
    || hostname.startsWith('socialcredit.community')
    || hostname.startsWith('connect-dev.socialcredit.community')
    || hostname.startsWith('dev.socialcredit.community')
    || hostname.startsWith('stage.socialcredit.community')
    || hostname.startsWith('connect-stage.socialcredit.community')
  ) {
    return 'socialcreditplend'
  }

  if ([
    ...cymruConnectDomains,
    ...cymrySocialCreditDomains
  ].some((cymruSubdomain) => hostname.startsWith(cymruSubdomain))
  ) {
    return 'socialcreditcymru'
  }

  return organizations.find((organization) => subdomain === organization)
    || (subdomain === 'makemyhousegreen-dev' ? 'makemyhousegreen' : 'plend')
}

function useOrganization() {
  const currentOrganization = getCurrentOrganizationByHostname(window.location.hostname)
  return {
    currentOrganization: currentOrganization as TOrganization,
    ...useOrganizationsConfig(currentOrganization as TOrganization)
  }
}

export const [OrganizationProvider, useOrganizationContext] = constate(useOrganization)
