import React, { FC } from 'react'
import styled from 'styled-components/macro'
import {
  useSocialCreditLoanCalculatorContext
} from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { TextStyled } from './styles'

const LinkStyled = styled.span`
  a {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }
`

const UnclaimedBenefits: FC = () => {
  const { loanDataQuery: { data } } = useSocialCreditLoanCalculatorContext()

  if (!data?.extraMonthlyBenefitsAmount) {
    return null
  }

  return (
    <>
      <TextStyled>
        <strong>
          Unclaimed benefits:
        </strong>
        <br/>
        Our analysis of your transaction data suggests you may have up to
        {' '}
        {formatToPound(data?.extraMonthlyBenefitsAmount || 0)}
        {' '}
        of unclaimed UK benefits you may be entitled to.
      </TextStyled>
      <TextStyled>
        <LinkStyled>
          <a href={data?.benefitsCalculatorLink} rel="noreferrer" target="_blank">
            Click here
          </a>
        </LinkStyled>
        {' '}
        to explore this further with our partners, InBest,
        who can not only give you a more detailed breakdown of which
        benefits you could claim, but can assist you with the application
        process for claiming them as well.
      </TextStyled>
    </>
  )
}

export default UnclaimedBenefits
