import { useLocation, useNavigate } from 'react-router-dom'
import { ReactNode, useEffect } from 'react'
import { TState } from '../types'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import MakeMyHouseGreenLoanStatus from './loanStatus/MakeMyHouseGreenLoanStatus'
import DefaultLoanStatus from './loanStatus/DefaultLoanStatus'
import RobertOwenLoanStatus from './loanStatus/RobertOwenLoanStatus'
import CymruLoanStatus from './loanStatus/CymruLoanStatus'
import SocialCreditLoanStatus from './loanStatus/SocialCreditLoanStatus'

const loanStatusMap: { [key in TOrganization]?: ReactNode } = {
  makemyhousegreen: <MakeMyHouseGreenLoanStatus/>,
  robertowen: <RobertOwenLoanStatus/>,
  socialcreditcymru: <CymruLoanStatus/>,
  socialcreditplend: <SocialCreditLoanStatus/>
}

const LoanStatus = () => {
  const { currentOrganization } = useOrganizationContext()
  const navigate = useNavigate()
  const { state } = useLocation() as TState

  useEffect(() => {
    if (!state) {
      navigate('/registration')
    }
  }, [state])

  return (
    <>
      {loanStatusMap[currentOrganization] || <DefaultLoanStatus/>}
    </>
  )
}
export default LoanStatus
