import axios from 'axios'

export function borrowerError(err: any) {
  const errorDateOfBirth = 'dateOfBirth must be more than 18; '
  if (axios.isAxiosError(err) && err.response) {
    throw new Error(err.response.data.message === errorDateOfBirth
      ? 'Date of birth must be more than 18'
      : err.response.data.message)
  } else {
    throw new Error(`borrower, ${err}`)
  }
}
