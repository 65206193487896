import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type TStatuses = {
  hasPendingLoan: boolean,
  hasDeclinedLoan: boolean,
  hasApprovedLoan: boolean
}

export function useLoanStatusNavigation() {
  const navigate = useNavigate()
  return useCallback((statuses: TStatuses, pendingLoanUrl?: string, borrowerName?: string) => {
    const currentStatus = Object.entries(statuses).find(([_, value]) => value)?.[0]

    navigate('/loan-status', {
      state: {
        currentStatus,
        pendingLoanUrl,
        borrowerName
      }
    })
  }, [])
}
