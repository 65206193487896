import styled from 'styled-components/macro'
import { ReactNode, useEffect } from 'react'
import UserInfo from '../../../userInfoForm/components/UserInfo'
import { StepperContextProvider, useStepperContext } from '../../../../core/components/stepper/StepperContext'
import { TOrganization, useOrganizationContext } from '../../../../core/state/useOrganization'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import TermsAndConditions from '../../../termsAndConditins/components/TermsAndConditions'
import BankCheck from '../../../bankCheck/components/BankCheck'
import StepperLogo from '../StepperLogo'
import Stepper from '../../../../core/components/stepper/Stepper'
import { useReconnectingContext } from '../../../reconnecting/state/useReconnectingState'

const ContainerStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  flex-direction: column;
`

const ContentStyled = styled.div`
  ${flex({ justify: 'center', align: 'center', direction: 'column' })}
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: transparent;
`

const LabelStyled = styled.div`
  ${fontFamily('Mongoose')}
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin: 30px 0;
  z-index: 3;
  ${({ theme }) => theme?.mixins?.stepper?.stepperTitleMixin}
`

const stepperTextMap: {[key in TOrganization]?: 'T&Cs' | 'Disclaimer'} = {
  plend: 'T&Cs',
  purpleshoots: 'Disclaimer',
  lendology: 'Disclaimer',
  makemyhousegreen: 'T&Cs',
  robertowen: 'T&Cs'
}

const stepperTitleTextMap: {[key in TOrganization]?: string} = {
  plend: 'TERMS AND CONDITIONS',
  purpleshoots: 'Disclaimer',
  lendology: 'Disclaimer',
  makemyhousegreen: 'TERMS AND CONDITIONS',
  robertowen: 'Terms and conditions'
}

const stepperLogo: {[key in TOrganization]?: ReactNode} = {
  plend: <StepperLogo/>,
  purpleshoots: <StepperLogo/>,
  lendology: <StepperLogo/>
}

function useShowBankCheck() {
  const { isReconnectingProcess, hasReconnectingBankCheck } = useReconnectingContext()
  return !isReconnectingProcess || hasReconnectingBankCheck
}

const RegistrationStepper = () => {
  const { stepFrom0 } = useStepperContext()
  const { currentOrganization } = useOrganizationContext()

  const stepperComponents = [<TermsAndConditions/>, <UserInfo/>, <BankCheck/>]
  const stepperTitle = [stepperTitleTextMap[currentOrganization], 'Personal Details']
  useEffect(() => window.scrollTo(0, 0), [stepFrom0])

  const stepNames = [
    stepperTextMap[currentOrganization] || '',
    'Application',
    ...(useShowBankCheck() ? ['Bank Check'] : [])
  ]

  return (
    <ContainerStyled>
      {stepperLogo[currentOrganization]}
      <Stepper stepNames={stepNames} showPreviousStepsIcon />
      <ContentStyled>
        {stepperTitle[stepFrom0] && (
          <LabelStyled>{stepperTitle[stepFrom0]}</LabelStyled>
        )}
        {stepperComponents[stepFrom0]}
      </ContentStyled>
    </ContainerStyled>
  )
}

export default () => (
  <StepperContextProvider>
    <RegistrationStepper/>
  </StepperContextProvider>
)
