import { FC } from 'react'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { FieldAddressMap, TControls, TPermittedFields } from '../../../../features/userInfoForm/types'
import PrevPostCode, { prevPostCodeFieldName } from './PrevPostCode'
import PostCodeLoqateBase from './PostCodeLoqateBase'

const fieldAddressMap: FieldAddressMap = {
  CountryName: 'prevLocality',
  BuildingName: 'prevBuildingName',
  SubBuilding: 'prevBuildingNo',
  City: 'prevPostTown',
  Street: 'prevStreet',
  PostalCode: 'prevPostCode',
  BuildingNumber: 'prevBuildingNo'
}

const organizationPermittedFields: TPermittedFields = {
  plend: ['prevLocality', 'prevBuildingName', 'prevBuildingNo', 'prevPostTown', 'prevStreet', 'prevPostCode'],
  makemyhousegreen: ['prevLocality', 'prevBuildingName', 'prevBuildingNo', 'prevPostTown', 'prevStreet', 'prevPostCode'],
  purpleshoots: ['prevLocality', 'prevBuildingName', 'prevBuildingNo', 'prevPostTown', 'prevStreet', 'prevPostCode'],
  socialcreditplend: ['prevLocality', 'prevBuildingName', 'prevBuildingNo', 'prevPostTown', 'prevStreet', 'prevPostCode'],
  socialcreditcymru: ['prevLocality', 'prevBuildingName', 'prevBuildingNo', 'prevPostTown', 'prevStreet', 'prevPostCode']
}

type TProps = {
  setValue: UseFormSetValue<any>
} & TControls

const PrevPostCodeLoqate: FC<TProps> = ({ control, setValue }) => (
  <PostCodeLoqateBase
    control={control}
    fieldName={prevPostCodeFieldName}
    setValue={setValue}
    fieldAddressMap={fieldAddressMap}
    organizationPermittedFields={organizationPermittedFields}
    PostCodeComponent={PrevPostCode}
  />
)

export default PrevPostCodeLoqate
