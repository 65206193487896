export function sendMetric(action: string) {
  if (typeof window !== 'undefined' && typeof (<any>window).gtag !== 'undefined') {
    // @ts-ignore
    window.gtag('event', action)
  }
}

export function initGoogleAnalyticsConfig() {
  const appId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

  if (!appId) {
    return
  }

  const script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${appId}`
  script.async = true
  document.head.appendChild(script)

  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params,func-names
  window.gtag = function () { window.dataLayer.push(arguments) }
  // @ts-ignore
  window.gtag('js', new Date())
  // @ts-ignore
  window.gtag('config', appId, { send_page_view: false })
}
