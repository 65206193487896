import React, { FC } from 'react'
import Radio, { RadioProps } from '@mui/material/Radio'
import styled from 'styled-components/macro'

const WrapperStyled = styled.div<TProps>`
  && {
    .MuiRadio-root {
      width: ${({ width }) => width}px;
      height: ${({ height }) => height}px;
      padding: 0;
      color: ${({ theme }) => theme.colors.transparent};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`

type TProps = {
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: number
  height?: number
} & RadioProps

const CustomRadioButton: FC<TProps> = ({
  checked,
  onChange,
  width,
  height,
  ...props
}) => (
  <WrapperStyled width={width} height={height}>
    <Radio
      checked={checked}
      onChange={onChange}
      name="radio-buttons"
      {...props}
    />
  </WrapperStyled>
)

export default CustomRadioButton
