/* eslint-disable react/no-unescaped-entities */
import { memo } from 'react'
import styled from 'styled-components/macro'

const TextStyled = styled.div<{bold?: boolean}>`
  margin-top: 30px;
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
`

const DeclinedLoan = () => (
  <>
    <TextStyled>
      <div>
        After assessing your application for a Plend loan, we’re unfortunately
        unable to progress you any further.
      </div>
    </TextStyled>
    <TextStyled>
      <div>
        We use your open banking data in order to assess your application
        holistically and to give us a better understanding of your financial situation,
        without relying solely on your credit profile.
      </div>
    </TextStyled>
    <TextStyled>
      Unfortunately on this occasion, your application was outside of our
      lending criteria and therefore we are unable to proceed.
    </TextStyled>
    <TextStyled bold>
      <div>
        Next Step
      </div>
    </TextStyled>
    <TextStyled>
      <div>
        Whilst we understand that this is disappointing to hear, providing you with credit
        at this time is not possible. However, if your situation improves, we will be happy
        to look into a new application after 60 days.
      </div>
    </TextStyled>
    <TextStyled>
      <div>
        If you would like to know more about why a lender like Plend has declined your application
        for credit, please review the ‘refused credit or refused a loan - what you can do’ page on
        Money Helper’s website. Click
        {' '}
        <a href="https://www.moneyhelper.org.uk/en/everyday-money/credit-and-purchases/what-to-do-if-you-have-been-refused-a-loan-or-credit-card">here</a>
        {' '}
        for a direct link
      </div>
    </TextStyled>
    <TextStyled>
      <div>
        If for any reason you are looking to consolidate any arrears or find yourself overcommitted,
        you can contact StepChange, the UK's leading debt charity, to get expert debt advice
        and fee-free debt management.
      </div>
    </TextStyled>
    <TextStyled>
      <div>
        You can find their website and contact details here if this may be helpful for
        your situation:
        {' '}
        <a href="https://www.stepchange.org/">https://www.stepchange.org/</a>
      </div>
    </TextStyled>
    <TextStyled>
      Thank you for your interest in being part of the Plend journey.
    </TextStyled>
    <TextStyled>
      <p>All the best,</p>
      <p>Team Plend</p>
    </TextStyled>
  </>
)

export default memo(DeclinedLoan)
