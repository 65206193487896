/* eslint-disable */

import { TInfoData } from '../../../core/assets/faq/types'
import styled from 'styled-components/macro'

const ListStyled = styled.ul`
  margin: 10px 0;
  list-style-type: disc;
  padding-left: 20px;
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

const data: TInfoData[] = [
  {
    id: 1,
    title: 'How do I apply for a loan?',
    description: (
      <>
        Simply select ‘Apply’ above to start the process.
        We’ll then reuse your application details for your Plend loan to check your eligibility for an Interest Free Loan.
      </>
    )
  },
  {
    id: 2,
    title: 'Am I eligible for an Interest Free Loan?',
    description: (
      <>
        To apply for an Interest Free Loan, you must:
        <ListStyled>
          <li>Be over 18</li>
          <li>
            Have a regular source of income (this means being employed with no probationary period,
            self-employed, receiving a pension or regular benefits like Universal Credit)
          </li>
          <li>Be a Welsh resident and only have tax residency in Wales</li>
          <li>Be using the loan for personal purposes (not business)</li>
        </ListStyled>
        Please be aware that being eligible does not guarantee that you’ll be accepted for a loan.
        We look at a number of things to determine whether or not we can provide you with a loan.
      </>
    )
  },
  {
    id: 3,
    title: 'What are the loan terms?',
    description: (
      <>
        The final repayment terms will be dependent on the agreed loan amount and the loan term.
        The loan amounts range from £500-£2000 with loan terms ranging from 12-24 months.
        Loan repayments are collected monthly by direct debit.
        Please see our terms and conditions here.

      </>
    )
  },
  {
    id: 4,
    title: 'Can I cancel my loan agreement? Is there a Cooling off period?',
    description: (
      <>
        You will have a right to withdraw from the Loan Contract without giving any
        reason and by giving verbal or written notice of the withdrawal to us
        (on behalf of the Lender) within the fourteen (14) calendar days
        starting on the day after you sign it. If you wish to exercise that right
        to withdraw: You can notify us in writing by email at support@socialcredit.cymru.
        You must repay the whole loan amount to Robert Owen Community Ltd  within thirty (30)
        days of providing your notice of cancellation, failing which you will be deemed
        to have changed your mind and will continue your loan term.
      </>
    )
  },
  {
    id: 5,
    title: 'Will applying for an Interest Free Loan affect my credit score?',
    description: <>It won’t be affected if you apply for an Interest Free Loan, however your credit score could be affected when you are approved for a loan.</>
  },
  {
    id: 6,
    title: 'When borrowing money, what are my responsibilities?',
    description: (
      <>
        You must make your payments on time. We collect repayments using Direct Debit, so you won’t have to do anything except make sure there’s
        enough money in your bank account. You will also need to ensure you have a plan in place should your financial circumstances change.
      </>
    )
  },
  {
    id: 7,
    title: 'What are the risks from borrowing from Social Credit Cymru?',
    description: (
      <>
        There is no risk of accruing interest on the loan given that it is a 0% product,
        however the general risk of borrowing with us is the same as the risk of borrowing from any other provider.
        You will need to consider if the repayments are affordable for you on a monthly basis.
        You will also need to ensure you have a plan in place should your financial circumstances change.
      </>
    )
  },
  {
    id: 8,
    title: 'Once I have submitted my application is there anything else I need to do?',
    description: (
      <>
        Once you have completed the application form, there is nothing more we need from you.
      </>
    )
  },
  {
    id: 9,
    title: 'Is Social Credit Cymru regulated?',
    description: (
      <>
        Social Credit (Cymru) is unincorporated association representing Robert Owen Community Banking
        Fund Limited which is authorised by the Financial Conduct Authority (FRN 726281), Purple
        Shoots Business Lending Limited which is authorised by the Financial Conduct Authority
        (FRN 720894) and Plend Limited which is authorised by the Financial Conduct Authority (FRN 963328)
      </>
    )
  },
  {
    id: 10,
    title: 'Who is Plaid?',
    description: (
      <>
        Plaid is the open banking partner that we use to access your financial information so we
        can better understand your financial situation and offer you a more affordable loan. You can read more about Plaid <LinkStyled target="_blank"
                                                                                                                                       href="https://plaid.com/company/">here</LinkStyled>.
      </>
    )
  },
  {
    id: 11,
    title: 'Are there any fees or costs?',
    description: (
      <>
        No - there are no fees or costs associated with the Interest Free Loan
      </>
    )
  },
  {
    id: 12,
    title: 'If it’s Interest Free, what’s in it for you?',
    description: (
      <>
        The Interest Free Loan by Social Credit Cymru is a social initiative which is seeking to provide
        affordable loans to people who would otherwise be excluded from affordable credit.
        The initiative does not seek to make a profit.
      </>
    )
  },
  {
    id: 13,
    title: 'I have a question, how can I get in touch with Social Credit Cymru?',
    description: (
      <>
        To speak to us, please use out chatbot here or <LinkStyled href="mailto:support@socialcredit.cymru">support@socialcredit.cymru</LinkStyled>
      </>
    )
  }
]

export default data
