/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Dialog as MuiDialog
} from '@mui/material'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { FC } from 'react'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { CloseLineIcon } from '../../../core/components/icons/CloseLineIcon'

export const GlobalStyles = createGlobalStyle`
  .modal {

    && {
      & .MuiBackdrop-root{
        background:${({ theme }) => theme.colors.blackDarkBlue};
        opacity: 0.5 !important;
      }
      & .MuiDialog-container {
        height: auto;
      
        & .MuiDialog-paper{
          margin:100px 32px 32px 32px;
        }
      }
    }
  }
`

const Dialog = styled.div`
  ${flex({ align: 'center' })};
  position: relative;
  width: 400px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 20px rgba(14, 20, 36, 0.1);
  border-radius: 20px;
  padding: 40px 24px 24px;
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black500};
`

const TextStyled = styled.p`
  ${fontFamily('Neue')}
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  margin-bottom: 22px;
  ${({ theme }) => theme.mixins?.resubmitAddress?.modalTextMixin}
`

const CloseButton = styled(Button)`
&&{
  ${fontFamily('Neue')}
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  background: ${({ theme }) => theme.colors.orange};
  box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);
  border-radius: 10px;
  text-transform: none;
  padding: 12px 20px;
  margin-top: 2px;
  width: 100px;
  &:hover{
    background: ${({ theme }) => theme.colors.orange100};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.5);
  }
  ${({ theme }) => theme.mixins?.resubmitAddress?.modalCloseButtonMixin}
}
`
type TProps = {
  isOpen: boolean
  handleClose: () => void
}

const AlreadyResubmittedModal: FC<TProps> = ({
  isOpen,
  handleClose
}) => (
  <>
    <GlobalStyles/>
    <MuiDialog
      sx={{ overflow: 'hidden' }}
      open={isOpen}
      className="modal"
      onClose={handleClose}
      data-testid="alreadyResubmittedModal"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperComponent={Dialog}>
      <IconContainerStyled onClick={handleClose}>
        <CloseLineIcon width={24} height={24}/>
      </IconContainerStyled>
      <TextStyled>
        We've already tried to find you at this address
        and were unable to find a match with the Bureaux data.
      </TextStyled>
      <TextStyled>
        If you've recently moved, please try inputting your
        previous address. Otherwise, please check that the input
        of your current address is correct.
      </TextStyled>
      <TextStyled>
        Please contact
        {' '}
        <a target="_blank" href="mailto:support@plend.co.uk" rel="noreferrer">support@plend.co.uk</a>
        {' '}
        for further assistance, or use the chatbox on this page to
        talk to a member of our support team.
      </TextStyled>
      <CloseButton onClick={handleClose}>
        Close
      </CloseButton>
    </MuiDialog>
  </>
)

export default AlreadyResubmittedModal
