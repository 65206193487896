import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import CustomButton, { BaseButton } from '../../../../core/components/buttons/CustomButton'
import { flex, fontFamily } from '../../../../core/styles/mixins'

const ContainerStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  width: 100%;
  padding: 50px 20px;
  background: ${({ theme }) => theme.colors.white};

  & div:nth-child(4) {
    margin-top: 20px;
  }

  ${BaseButton} {
    ${fontFamily('Work Sans')} 
    ${flex({ justify: 'center', align: 'center' })};
    font-weight: 700;
    width: 384px;
    padding: 12px 20px;
    color: ${({ theme }) => theme.colors.darkBlue100};
    background: ${({ theme }) => theme.colors.greenMint};
    border-radius: 50px !important;

    &:hover {
      color: ${({ theme }) => theme.colors.darkBlue100};
      background: ${({ theme }) => theme.colors.green200};
    }
  }


`

const ContainerWrapper = styled.div`
  max-width: 933px;
  width: 100%;
`

const TitleStyled = styled.div`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
  
  @media (max-width: 375px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const LoanInfo = styled.div`
  ${fontFamily('Work Sans')};
  font-style: normal;
  margin: 50px 0 30px;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.03em;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  grid-column-gap: 30px;
  
  @media (max-width: 970px) {
    font-size: 22px;
    line-height: 26px;
    grid-column-gap: 44px;
    letter-spacing: -0.05em;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  
`

const LoanInfoStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'baseline' })}
  ${fontFamily('Work Sans')};
  font-weight: 400;
  
`
const LoanInfoValueStyled = styled.p`
  ${flex({ justify: 'flex-start', align: 'center' })}
  margin-left: 8px;
  font-weight: 700;

`

const TextStyled = styled.div`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.03em;
  
  &>span{
    font-weight: 700;
  }
`

const ButtonWrapper = styled.div`
  ${flex({ justify: 'center' })};
  margin-top: 30px;
`

const MakeMyHouseGreenHeader = () => {
  const navigate = useNavigate()
  const handleNavigate = () => navigate('registration')
  return (
    <ContainerStyled>
      <ContainerWrapper>
        <TitleStyled>
          Go green with an affordable loan from Plend,
          trusted partner for MakeMyHouseGreen
        </TitleStyled>
        <LoanInfo>
          <LoanInfoStyled>
            Loan size:
            <LoanInfoValueStyled>Up to £15,000</LoanInfoValueStyled>
          </LoanInfoStyled>
          <LoanInfoStyled>
            Loan length:
            <LoanInfoValueStyled>2-5 years</LoanInfoValueStyled>
          </LoanInfoStyled>
          <LoanInfoStyled>
            Rep APR:
            <LoanInfoValueStyled>8.99%</LoanInfoValueStyled>
          </LoanInfoStyled>
        </LoanInfo>
        <TextStyled>
          <span> Representative Example:</span>
          {' '}
          Borrowing £8,000 over 48 months at an interest rate of 8.99% p.a. (fixed),
          you will repay 48 monthly payments of £199.04.
          Interest Payable £1,554.03. Total Repayable £9,554.03.
        </TextStyled>
        <TextStyled>
          Your final loan terms may differ from your pre-approved terms
          following your consultation with your Guru.
        </TextStyled>
        <ButtonWrapper>
          <CustomButton onClick={handleNavigate} text="GET QUOTE"/>
        </ButtonWrapper>
      </ContainerWrapper>
    </ContainerStyled>
  )
}

export default MakeMyHouseGreenHeader
