import { useEffect } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import { initIntercom } from '../utils/initIntercom'

const defaultAppId = 'joziz0cr'
const cymruAppId = 'jb0q6g5k'

export function useInitIntercomChat(permittedOrganizations: TOrganization[]) {
  const { currentOrganization } = useOrganizationContext()

  const appId = (['socialcreditcymru', 'socialcreditplend'] as TOrganization[]).includes(currentOrganization)
    ? cymruAppId
    : defaultAppId

  useEffect(() => {
    if (permittedOrganizations.includes(currentOrganization)) {
      initIntercom(appId)
    }
  }, [])
}
