import { FC } from 'react'
import styled from 'styled-components/macro'
import {
  useSocialCreditLoanCalculatorContext
} from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { TextStyled, TitleStyled } from './styles'
import UnclaimedBenefits from './UnclaimedBenefits'
import { useOrganizationContext } from '../../../core/state/useOrganization'

const TextLineStyled = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  
  ${TextStyled} {
    margin-right: 40px;
    white-space: nowrap;
    margin-top: 0;

    @media (max-width: 768px) {
      margin-top: 10px;
    }
    
    :last-child {
      margin-right: 0;
    }
  }
`

const Success: FC = () => {
  const { loanDataQuery: { data } } = useSocialCreditLoanCalculatorContext()

  const isThisScamText = useOrganizationContext().currentOrganization === 'socialcreditplend'
    ? (
      <>
        No - The Interest Free Loan by Social Credit is a social initiative,
        powered by Plend. We’re operating this scheme on a not for profit
        basis to offer affordable credit to those that may traditionally be
        locked out from accessing it.
      </>
    )
    : (
      <>
        No — The Interest Free Loan by Social Credit Cymru is a social
        initiative run by Purple Shoots, Robert Owen Community Banking and
        Plend. We’re operating on a not for profit basis because we recognise
        that access to affordable credit is something everyone deserves.
      </>
    )

  return (
    <>
      <TitleStyled>
        We have good news!
      </TitleStyled>
      <TextStyled>
        Your application for the Interest Free Loan has been
        accepted. Here are the details of your loan:
      </TextStyled>
      <TextLineStyled>
        <TextStyled>
          Loan size:
          {' '}
          <b>{formatToPound(data?.loanAmount || 0)}</b>
        </TextStyled>
        <TextStyled>
          Loan length:
          {' '}
          <b>{data?.loanLength}</b>
          {' '}
          months
        </TextStyled>
        <TextStyled>
          Rep APR:
          {' '}
          <b>0%</b>
        </TextStyled>
      </TextLineStyled>
      <TextStyled>
        <strong>
          Repayment details:
        </strong>
        <br/>
        You are borrowing
        {' '}
        <b>{formatToPound(data?.loanAmount || 0)}</b>
        {' '}
        over
        {' '}
        <b>{data?.loanLength}</b>
        {' '}
        months at an interest rate
        of
        {' '}
        <b>0%</b>
        {' '}
        p.a.(fixed), you will repay
        {' '}
        <b>{data?.loanLength}</b>
        {' '}
        monthly payments of
        {' '}
        <b>{formatToPound(data?.monthlyRepayment || 0)}</b>
        {' '}
        . Interest Payable
        {' '}
        <b>£0</b>
        {' '}
        . Total Repayable
        {' '}
        <b>{formatToPound(data?.loanAmount || 0)}</b>
        .
      </TextStyled>
      <TextStyled>
        <strong>
          Is this a scam?
        </strong>
        <br/>
        {isThisScamText}
      </TextStyled>
      <TextStyled>
        <strong>
          Your next steps:
        </strong>
        <br/>
        You should receive an email from us shortly which will include
        a link to your loan contract. Please review the terms carefully
        and sign within 7 days to avoid the offer expiring. You can now close this window.
      </TextStyled>
      <UnclaimedBenefits />
    </>
  )
}

export default Success
