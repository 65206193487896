import { FC } from 'react'
import { Control, useController } from 'react-hook-form'
import { Box, Chip, MenuItem, Select } from '@mui/material'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { SelectProps } from '@mui/material/Select/Select'
import { fontFamily } from '../styles/mixins'
import { requiredRule } from '../utils/formRules'
import { TOption } from './CustomSelect'
import { CloseLineIcon } from './icons/CloseLineIcon'

export const GlobalStyles = createGlobalStyle`
  .selectContainer {
    && {
      & .MuiButtonBase-root {
        ${fontFamily('Neue')};
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 30px;
        color: ${({ theme }) => theme.colors.blackDarkBlue};
        ${({ theme }) => theme?.mixins?.fontFamilyMixin};
      }
    }
  }
`

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  && {
    & .MuiInputBase-root {
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.blackDarkBlue};
      ${({ theme }) => theme?.mixins?.inputMixin};
    }

    & .MuiSelect-select {
      background: ${({ theme }) => theme.colors.white};
      padding: 8px 12px;
    }
    
    & .MuiButtonBase-root{
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 450;
      ${({ theme }) => theme?.mixins?.fontFamilyMixin};
    }
    
    & .MuiChip-root {
      border-radius: 8px;
      ${fontFamily('Open Sans')};
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    
    & .MuiChip-label {
      padding-right: 10px;
    }
  }

  @media (max-width: 375px) {
    &:nth-child(1) {
      margin-top: 15px;
    }

    &:nth-child(2) {
      margin-top: 30px;
    }
  }
`

const StyledPlaceholder = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

const CloseLineIconWrap = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blackDarkBlue} !important;
`

const BoxStyled = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`

const InputLabelStyled = styled.label`
  ${fontFamily('Neue')}
  font-size: 16px;
  ${({ theme }) => theme.mixins?.fontFamilyMixin};
`

type TProps = SelectProps & {
  name: string
  label?: string | any
  control: Control<any>
  options: TOption[],
  itemsLimit?: number,
}

function isMenuItemDisabled(value: string, values: string[], itemsLimit?: number): boolean {
  return !!itemsLimit && values.length >= itemsLimit && !values.includes(value)
}

const CustomMultipleSelect: FC<TProps> = ({
  name,
  label,
  control,
  itemsLimit,
  options,
  ...rest
}) => {
  const { field: { value: selectedValues, onChange }, fieldState: { invalid } } = useController({
    name,
    control,
    rules: {
      required: requiredRule()
    }
  })

  return (
    <ContainerStyled>
      <GlobalStyles />
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <Select
        onChange={(event) => onChange(event.target.value)}
        value={selectedValues || []}
        fullWidth
        multiple
        displayEmpty
        MenuProps={{
          classes: {
            paper: 'selectContainer'
          }
        }}
        renderValue={(selected) => (
          <BoxStyled>
            {selected?.map((value: any) => (
              <Chip
                key={value}
                label={options.find(({ value: val }) => value === val)?.label}
                deleteIcon={(
                  <CloseLineIconWrap>
                    <CloseLineIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  </CloseLineIconWrap>
                )}
                onDelete={() => {
                  const updatedSelectedOptions = selectedValues?.filter(
                    (option: string) => option !== value
                  )
                  onChange(updatedSelectedOptions)
                }}
              />
            ))}
          </BoxStyled>
        )}
        error={invalid}
        {...rest}
      >
        {options?.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={isMenuItemDisabled(value, selectedValues, itemsLimit)}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </ContainerStyled>
  )
}

export default CustomMultipleSelect
