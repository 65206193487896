import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../core/styles/mixins'
import { useSocialCreditLoanCalculatorContext } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import Success from './Success'
import Failed from './Failed'
import Connect from './Connect'
import { TStatus } from '../../loanCalculator/types'
import Resubmit from './Resubmit'

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px 100px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 50px;
  }

  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
`

const ContentStyled = styled.div`
  max-width: 996px;
`

function useIsResubmitAddressRequired() {
  const { loanDataQuery: { data } } = useSocialCreditLoanCalculatorContext()

  return data?.resubmitAddressRequired
}

function useFetchingTimer() {
  const { loanDataQuery: { data, refetch } } = useSocialCreditLoanCalculatorContext()
  const isResubmitAddressRequired = useIsResubmitAddressRequired()

  const ref = useRef<any>()

  useEffect(() => {
    ref.current = setInterval(() => refetch(), 1000)
    return () => clearInterval(ref.current)
  }, [])

  useEffect(() => {
    const completedStatuses: TStatus[] = ['APPROVED', 'DECLINED']

    if (
      isResubmitAddressRequired
       || (data?.status && completedStatuses.includes(data.status))
    ) {
      clearInterval(ref.current)
    }
  }, [data, isResubmitAddressRequired])
}

const SocialCreditDecision: FC = () => {
  const { loanDataQuery: { data } } = useSocialCreditLoanCalculatorContext()
  useFetchingTimer()

  const isConnectProcess = !data?.status || data.status === 'SCORING_IN_PROGRESS'
  const success = data?.status === 'APPROVED'
  const isResubmit = useIsResubmitAddressRequired()

  return (
    <>
      {isConnectProcess && <Connect />}
      {!isConnectProcess
        ? (
          <WrapStyled>
            <ContentStyled>
              {isResubmit
                ? <Resubmit />
                : success
                  ? <Success />
                  : <Failed />}
            </ContentStyled>
          </WrapStyled>
        )
        : null}
    </>
  )
}

export default SocialCreditDecision
