import { useCallback, useEffect, useState } from 'react'
import constate from 'constate'
import { useSearchParams } from 'react-router-dom'
import { useLinkCorrelationId } from '../../hooks/usePlaidHooks'
import { useOrganizationContext } from '../../state/useOrganization'

const stepperContext = () => {
  const [step, setStep] = useState<number>(1)
  const [searchParams] = useSearchParams()

  const prevStep = useCallback(() => {
    setStep((prev) => prev - 1)
  }, [])

  const nextStep = useCallback(() => {
    setStep((prev) => prev + 1)
  }, [])

  const { currentOrganization } = useOrganizationContext()
  const isLinkCorrelationId = !!useLinkCorrelationId()

  useEffect(() => {
    const isLoanApplicationId = searchParams.get('loanApplicationId')
    if ((isLoanApplicationId || isLinkCorrelationId) && !['socialcreditcymru', 'socialcreditplend'].includes(currentOrganization)) {
      setStep(3)
    }
  }, [])

  return {
    step,
    setStep,
    stepFrom0: step - 1,
    prevStep,
    nextStep
  }
}

export const [StepperContextProvider, useStepperContext] = constate(stepperContext)
