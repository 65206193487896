import axios, { AxiosError } from 'axios'
import { useEffect } from 'react'
import { apiClient } from '../../../core/api/apiClient'
import ErrorNotification from '../../notifications/components/ErrorNotification'
import { useNotificationContext } from '../../notifications/state/useNotification'

function useErrorResponseInterceptor() {
  const { showErrorNotification } = useNotificationContext()

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (res) => res,
      async (err: AxiosError) => {
        if (axios.isAxiosError(err)) {
          const { response } = err

          if (!response) {
            return
          }
          showErrorNotification(<ErrorNotification message={response.data.message} />)
          throw new Error(response.data.message)
        }
      }
    )
    return () => apiClient.interceptors.request.eject(interceptor)
  }, [])
}

const ApiClient = () => {
  useErrorResponseInterceptor()

  return null
}
export default ApiClient
