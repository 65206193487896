/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components/macro'
import { Button } from '@mui/material'
import { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { usePlaidMenuContext } from '../state/usePlaidMenuContext'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import RobertOwensBankCheckHeaderTop from './bankCheckHeaderTop/RobertOwensBankCheckHeaderTop'
import RobertOwenBankCheckHeaderBottom from './bankCheckHeaderBottom/RobertOwenBankCheckHeaderBottom'
import DefaultBankCheckHeaderTop from './bankCheckHeaderTop/DefaultBankCheckHeaderTop'
import { useLinkValidationQuery } from '../queries'
import NotValidHeader from './NotValidHeader'
import CymruPartnerBankCheckHeaderTop from './bankCheckHeaderTop/CymruPartnerBankCheckHeaderTop'

const ContentStyled = styled.div`
  ${flex({ direction: 'column', align: 'center' })};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.violet};
  ${({ theme }) => theme?.mixins?.bankCheck?.header?.contentMixin}
`

const ButtonStyled = styled(Button)`
  && {
    ${fontFamily('Neue')};
    padding: 12px 20px;
    margin: 40px 0 100px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.orange};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);
    text-transform: none;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.orange};
    }

    &:disabled {
      opacity: 1;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.pink100};
    }
    @media (max-width: 768px) {
      && {
        margin: 30px 0 50px;
      }
    }
    @media (max-width: 375px) {
      && {
        margin: 30px 0;
      }
    }

    ${({ theme }) => theme?.mixins?.bankCheck?.header?.connectButtonMixin}
  }
`

const topMap: { [key in TOrganization]?: ReactNode } = {
  robertowen: <RobertOwensBankCheckHeaderTop />,
  socialcreditcymru: <CymruPartnerBankCheckHeaderTop/>
}

const bottomMap: { [key in TOrganization]?: ReactNode } = {
  robertowen: <RobertOwenBankCheckHeaderBottom />
}

function useIsLinkValid() {
  const [searchParams] = useSearchParams()
  const failedAttempt = searchParams.get('failedAttempt') ?? undefined
  const borrowerId = searchParams.get('borrowerId') ?? undefined
  const loanApplicationId = searchParams.get('loanApplicationId') ?? undefined
  const expiration = searchParams.get('expiration') ?? undefined
  const token = searchParams.get('token') ?? undefined
  const linkType = searchParams.get('type') ?? undefined
  const needToCheck = linkType && token ? true : (!!failedAttempt && !!expiration)

  const {
    data: isValid,
    isLoading
  } = useLinkValidationQuery(
    needToCheck,
    linkType ? linkType.toUpperCase() : 'MAIN_ACCOUNT',
    borrowerId,
    loanApplicationId,
    expiration,
    token
  )

  return {
    isValid: needToCheck ? isValid !== undefined && isValid : true,
    isLoading
  }
}

const BankCheckHeader = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { currentOrganization, bankCheckText } = useOrganizationContext()
  const { setIsPlaidMenu, isPlaidMenu } = usePlaidMenuContext()
  const handleConnect = () => setIsPlaidMenu(true)
  const token = searchParams.get('token') ?? undefined
  const linkType = searchParams.get('type') ?? undefined
  const { isValid, isLoading } = useIsLinkValid()

  if (token && linkType && !isValid && !isLoading) {
    navigate('/accounts', { state: { isLinkExpired: true } })
    return null
  }

  if (!isValid && isLoading && !token && !linkType) {
    return null
  }
  if (!isValid && isLoading) {
    return null
  }

  if (!isValid && !isLoading) {
    return <NotValidHeader />
  }

  return (
    <ContentStyled>
      {topMap[currentOrganization] || <DefaultBankCheckHeaderTop />}
      <ButtonStyled disabled={isPlaidMenu} onClick={handleConnect}>
        {bankCheckText || 'Connect your bank to Plend'}
      </ButtonStyled>
      {bottomMap[currentOrganization]}
    </ContentStyled>
  )
}

export default BankCheckHeader
