import { css, DefaultTheme } from 'styled-components/macro'
import { styledThemeDefault } from './styledTheme'
import { flex, fontFamily } from './mixins'

// mixins
const nextButtonMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border-radius: 999px;
  color: ${({ theme }) => theme.colors.darkBlue100};
  background: ${({ theme }) => theme.colors.greenMint};
  box-shadow: none;
  padding: 12px 20px;

  &:disabled {
    background: ${({ theme }) => theme.colors.grey100};
    color: ${({ theme }) => theme.colors.darkBlue100};
    opacity: 1;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.green200};
    box-shadow: none;
  }
`

const TemplateTextMixin = css`
  ${fontFamily('Work Sans')};
  border-top: 1px solod  ${({ theme }) => theme.colors.greenMint100};
`
const mainBackgroundMixin = css`
  background: ${({ theme }) => theme.colors.darkGreen};
`

const checkboxLabelMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`

const hideFiguresMixin = css`
  display: none;
  
  :before{
    content: 'none'
  }
`

// home
const mainMixin = css`
  position: relative;
  z-index: 1;
`

const cardMixin = css`
  z-index: 20;

  &:before {
    content: none;
  }
`

const textMixin = css`
  z-index: 20;
`

const homeTitleMixin = css`
  ${fontFamily('Work Sans')};
  text-transform: none!important;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #FFFFFF;
`

// accordion
const infoTitleMixin = css`
  ${fontFamily('Work Sans')};
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
`

const descriptionMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
`

// stepper
const stepperMuiMixin = css`
  padding: 30px 0;
  background: ${({ theme }) => theme.colors.white100};
  margin-bottom: 0;
`

const circleMixin = css<{ isActive: boolean }>`
  background: ${({ theme, isActive }) => (isActive ? theme.colors.green100 : theme.colors.ivory)};
`

const wrapperMixin = css`
  background: ${({ theme }) => theme.colors.white100};
`

const stepLabelMixin = css`
  ${fontFamily('Work Sans')}
  letter-spacing: -0.5px;
`
const circleTextMixin = css`
  ${fontFamily('Work Sans')};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const stepperTitleMixin = css`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  text-transform: none;
`

const stepperActiveLineMixin = css`
  border: ${({ theme }) => theme.colors.green100};
  background: ${({ theme }) => theme.colors.green100};
`

// personalDetails
const inputLabelTextMixin = css`
  ${fontFamily('Work Sans')}
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`

const containerMixin = css`
  position: relative;

`

// accounts
const containerAccountsMixin = css`
  ${flex({ justify: 'center', align: 'center' })};
  background: ${({ theme }) => theme.colors.white};
`

const messageAccountsMixin = css`
  margin: 50px 0 26px;
  height: max-content;
`

const textAccountsMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

// bankCheck
const bankCheckHeaderContentMixin = css`
  background: ${({ theme }) => theme.colors.white100};
`

const backCheckHeaderText = css`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  margin-top: 50px;
  
  &:before{
    content: none;
  }
`

const bankCheckConnectButtonMixin = css`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  padding: 18px 30px;
  color: ${({ theme }) => theme.colors.darkBlue100};
  background: ${({ theme }) => theme.colors.greenMint};
  box-shadow: none;
  border-radius: 999px;
  text-transform: none;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlue100};
    background: ${({ theme }) => theme.colors.green200};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.grey100};
    color: ${({ theme }) => theme.colors.darkBlue100};
    opacity: 1;
  }

`

const backCheckCardMixin = css`
  &:before{
    content: none;
  }
`
const bankCheckContentMixin = css`
  position: relative;
  z-index: 1;
  background: ${({ theme }) => theme.colors.darkGreen};
`

const bankCheckTellMoreTextMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  margin-top: 50px;
  text-align: center;
  z-index: 2;
`

// notifications

const errorNotificationMixin = css`
  ${fontFamily('Work Sans')};
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const periodTextMixin = css`
  ${fontFamily('Work Sans')}
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`
const fontFamilyMixin = css`
  ${fontFamily('Work Sans')}
`

const inputMixin = css`
  ${fontFamily('Work Sans')}
  font-weight: 400;
`
const phoneIconTextMixin = css`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`
// resubmitAddress
const resubmitTitleMixin = css`
  ${fontFamily('Work Sans')};
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-transform: none;
`

const resubmitButtonMixin = css`
  ${fontFamily('Work Sans')};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 999px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  background: ${({ theme }) => theme.colors.greenMint};
  padding: 12px 15px 12px 20px;
  box-shadow: none;

  :hover {
    color: ${({ theme }) => theme.colors.darkBlue100};
    box-shadow: none;
    background: ${({ theme }) => theme.colors.green200};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.grey100};
    color: ${({ theme }) => theme.colors.darkBlue100};
    opacity: 1;
  }
`

const resubmitModalCloseButtonMixin = css`
  ${fontFamily('Work Sans')};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 999px;
  color: ${({ theme }) => theme.colors.darkBlue100};
  background: ${({ theme }) => theme.colors.greenMint};
  padding: 12px 20px;
  box-shadow: none;

  :hover {
    color: ${({ theme }) => theme.colors.darkBlue100};
    box-shadow: none;
    background: ${({ theme }) => theme.colors.green200};
  }
`

const resubmitModalTextMixin = css`
  ${fontFamily('Work Sans')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

export const styledThemeMakerHouse: DefaultTheme = {
  ...styledThemeDefault,
  mixins: {
    nextButtonMixin,
    mainBackgroundMixin,
    checkboxLabelMixin,
    hideFiguresMixin,
    fontFamilyMixin,
    inputMixin,
    phoneIconTextMixin,
    TCMixin: TemplateTextMixin,
    home: {
      mainMixin,
      cardMixin,
      textMixin,
      homeTitleMixin
    },
    accordion: {
      infoTitleMixin,
      descriptionMixin
    },
    stepper: {
      stepperMuiMixin,
      circleMixin,
      wrapperMixin,
      stepLabelMixin,
      circleTextMixin,
      stepperTitleMixin,
      stepperActiveLineMixin
    },
    personalDetails: {
      inputLabelTextMixin,
      containerMixin,
      periodTextMixin
    },
    bankCheck: {
      header: {
        contentMixin: bankCheckHeaderContentMixin,
        headerTextMixin: backCheckHeaderText,
        connectButtonMixin: bankCheckConnectButtonMixin
      },
      contentMixin: bankCheckContentMixin,
      tellMeMoreMixin: backCheckCardMixin,
      tellMeMoreTextMixin: bankCheckTellMoreTextMixin
    },
    accounts: {
      containerAccountsMixin,
      messageAccountsMixin,
      textAccountsMixin
    },
    notification: {
      error: {
        textMixin: errorNotificationMixin
      }
    },
    resubmitAddress: {
      title: resubmitTitleMixin,
      submitButton: resubmitButtonMixin,
      modalCloseButtonMixin: resubmitModalCloseButtonMixin,
      modalTextMixin: resubmitModalTextMixin
    }
  }
}
