import styled from 'styled-components/macro'
import { memo } from 'react'
import { useOrganizationContext } from '../../../core/state/useOrganization'

const LogoWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.ivory};
`

const LogoStyled = styled.img<{width: number, height: number}>`
  display: block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  object-fit: contain;
  margin: 16px auto;
`
const StepperLogo = () => {
  const { logo: { url, width, height } } = useOrganizationContext()
  return (
    <LogoWrapper>
      <LogoStyled
        width={width}
        height={height}
        src={url}
        alt="logo"
      />
    </LogoWrapper>
  )
}

export default memo(StepperLogo)
