import { useQuery } from 'react-query'
import { apiClient } from '../../core/api/apiClient'
import { TReconnectingResponse } from './type'

export function useReconnectingQuery(
  enabled: boolean,
  token?: string
) {
  return useQuery<TReconnectingResponse, Error>(
    ['Reconnecting', token],
    async () => {
      try {
        const response = await apiClient.post<TReconnectingResponse>('/borrowers/detail', {
          token
        })

        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      enabled
    }
  )
}
