import React, { FC, ReactNode } from 'react'
import { TControls } from '../../../../features/userInfoForm/types'
import PostCodeBase from './PostCodeBase'

type TProps = {
  onFocus: () => void
  onInput: () => void
  value: string,
  children: ReactNode
} & TControls

export const prevPostCodeFieldName = 'prevPostCode'

const PrevPostCode: FC<TProps> = ({
  children,
  ...rest
}) => (
  <PostCodeBase label="Previous Post code *" name={prevPostCodeFieldName} {...rest}>{children}</PostCodeBase>
)

export default PrevPostCode
