import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { fontFamily } from '../../../../../core/styles/mixins'

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 0 20px 50px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 0 10px 50px;
  }

  ${({ theme }) => theme.mixins?.mainBackgroundMixin};
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin};
`

const ContentStyled = styled.div`
  max-width: 996px;
`

const TitleStyled = styled.div`
  width: 100%;
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin};
`

const CardStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin-top: 30px;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 10px;
  word-break: break-word;
`

const BoldTextStyled = styled.div<{ noMargin?: boolean }>`
  font-size: 20px;
  font-weight: 700;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '10px')};
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

const UnderlinedTextStyled = styled.span`
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  text-decoration: underline;
`

const SocialCreditFull: FC = () => (
  <WrapStyled>
    <Helmet>
      <title>Terms and Conditions</title>
    </Helmet>
    <ContentStyled>
      <TitleStyled>Terms and Conditions</TitleStyled>
      <CardStyled>
        <BoldTextStyled noMargin>
          Introduction
        </BoldTextStyled>
        <TextStyled>
          These terms and conditions apply between you, the User of
          this Website (including any sub-domains, unless expressly
          excluded by their own terms and conditions), and PLEND,
          the owner and operator of this Website.
        </TextStyled>
        <TextStyled>
          Please read these terms and conditions carefully, as they
          affect your legal rights. Your agreement to comply with
          and be bound by these terms and conditions is deemed
          to occur upon your first use of the Website. If you do not
          agree to be bound by these terms and conditions, you should
          stop using the Website immediately.
        </TextStyled>
        <TextStyled>
          In these terms and conditions, User or Users means any third
          party that accesses the Website and is not either (i)
          employed by PLEND and acting in the course of their employment
          or (ii) engaged as a consultant or otherwise providing services
          to PLEND and accessing the Website in connection with the provision
          of such services.
        </TextStyled>
        <TextStyled>
          You must be at least 18 years of age to use this Website. By using the
          Website and agreeing to these terms and conditions, you represent and
          warrant that you are at least 18 years of age.
        </TextStyled>
        <BoldTextStyled>
          Intellectual property and acceptable use
        </BoldTextStyled>
        <TextStyled>
          All Content included on the Website, unless uploaded by Users, is
          the property of PLEND, our affiliates or other relevant third
          parties. In these terms and conditions, Content means any text,
          graphics, images, audio, video, software, data compilations,
          page layout, underlying code and software and any other form
          of information capable of being stored in a computer that appears
          on or forms part of this Website, including any such content uploaded
          by Users. By continuing to use the Website you acknowledge that such
          Content is protected by copyright, trademarks, database rights and
          other intellectual property rights. Nothing on this site shall be
          construed as granting, by implication, estoppel, or otherwise,
          any license or right to use any trademark, logo or service mark
          displayed on the site without the owner’s prior written permission
        </TextStyled>
        <TextStyled>
          You may, for your own personal, non-commercial use only, do the
          following:retrieve, display and view the Content on a computer screenYou
          must not otherwise reproduce, modify, copy, distribute or use for
          commercial purposes any Content without the written permission of PLEND.
        </TextStyled>
        <BoldTextStyled>
          Prohibited use
        </BoldTextStyled>
        <TextStyled>
          You may not use the Website for any of the following purposes:in any
          way which causes, or may cause, damage to the Website or interferes
          with any other person’s use or enjoyment of the Website; in any way
          which is harmful, unlawful, illegal, abusive, harassing,
          threatening or otherwise objectionable or in breach of any
          applicable law, regulation, governmental order; making,
          transmitting or storing electronic copies of Content
          protected by copyright without the permission of the owner.
        </TextStyled>
        <TextStyled>
          Links to other websites:This Website may contain links to other
          sites. Unless expressly stated, these sites are not under the control
          of PLEND or that of our affiliates.We assume no responsibility for
          the content of such Websites and disclaim liability for any and
          all forms of loss or damage arising out of the use of them. The
          inclusion of a link to another site on this Website does not
          imply any endorsement of the sites themselves or of those in control of them.
        </TextStyled>
        <BoldTextStyled>
          Privacy Policy
        </BoldTextStyled>
        <TextStyled>
          Use of the Website is also governed by our Privacy Policy, which is
          incorporated into these terms and conditions by this reference.
          To view the Privacy Policy, please click on the following:
          {' '}
          <UnderlinedTextStyled>
            https://www.plend.co.uk/privacy-policy
          </UnderlinedTextStyled>
          .
        </TextStyled>
        <BoldTextStyled>
          Availability of the Website and disclaimers
        </BoldTextStyled>
        <TextStyled>
          Any online facilities, tools, services or information that PLEND
          makes available through the Website (the Service) is provided
          “as is” and on an “as available” basis. We give no warranty
          that the Service will be free of defects and/or faults. To the
          maximum extent permitted by the law, we provide no warranties
          (express or implied) of fitness for a particular purpose, accuracy
          of information, compatibility and satisfactory quality. PLEND is
          under no obligation to update information on the Website.
        </TextStyled>
        <TextStyled>
          Whilst PLEND uses reasonable endeavours to ensure that the Website
          is secure and free of errors, viruses and other malware, we
          give no warranty or guaranty in that regard and all Users take
          responsibility for their own security, that of their personal
          details and their computers.
        </TextStyled>
        <TextStyled>
          PLEND accepts no liability for any disruption or non-availability of the Website.
        </TextStyled>
        <TextStyled>
          PLEND reserves the right to alter, suspend or discontinue any part (or the whole of)
          the Website including, but not limited to, any products and/or services available.
          These terms and conditions shall continue to apply to any modified version of the
          Website unless it is expressly stated otherwise.
        </TextStyled>
        <BoldTextStyled>
          Limitation of liability
        </BoldTextStyled>
        <TextStyled>
          Nothing in these terms and conditions will: (a) limit or exclude our or your liability
          for death or personal injury resulting from our or your negligence, as applicable;
          (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation;
          or (c) limit or exclude any of our or your liabilities in any way that is not permitted
          under applicable law.
        </TextStyled>
        <TextStyled>
          We will not be liable to you in respect of any losses arising out of events beyond our
          reasonable control.
        </TextStyled>
        <TextStyled>
          To the maximum extent permitted by law, PLEND accepts no liability for
          any of the following:
          any business losses, such as loss of profits, income, revenue, anticipated
          savings, business,
          contracts, goodwill or commercial opportunities; loss or corruption of
          any data, database or
          software; any special, indirect or consequential loss or damage.
        </TextStyled>
        <BoldTextStyled>
          General
        </BoldTextStyled>
        <TextStyled>
          You may not transfer any of your rights under these terms and conditions
          to any other person.
          We may transfer our rights under these terms and conditions where we reasonably believe
          your rights will not be affected.
        </TextStyled>
        <TextStyled>
          These terms and conditions may be varied by us from time to time. Such revised terms will
          apply to the Website from the date of publication. Users should check the terms and
          conditions
          regularly to ensure familiarity with the then current version.These terms and conditions
          together with the Privacy Policy contain the whole agreement between the parties relating
          to its subject matter and supersede all prior discussions, arrangements or agreements that
          might have taken place in relation to the terms and conditions.
        </TextStyled>
        <TextStyled>
          The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and
          conditions
          and no third party will have any right to enforce or rely on any provision of these
          terms and conditions.
        </TextStyled>
        <TextStyled>
          If any court or competent authority finds that any provision of these terms and
          conditions (or part of any provision) is invalid, illegal or unenforceable,
          that provision or part-provision will, to the extent required, be deemed
          to be deleted, and the validity and enforceability of the other provisions
          of these terms and conditions will not be affected.
        </TextStyled>
        <TextStyled>
          Unless otherwise agreed, no delay, act or omission by a party in exercising
          any right or remedy will be deemed a waiver of that, or any other, right
          or remedy.This Agreement shall be governed by and interpreted according
          to the law of England and Wales and all disputes arising under the
          Agreement (including non-contractual disputes or claims) shall be subject
          to the exclusive jurisdiction of the English and Welsh courts.
        </TextStyled>
        <BoldTextStyled>
          PLEND details
        </BoldTextStyled>
        <TextStyled>
          PLEND is a company incorporated in England and Wales with registered number
          12581855 whose registered address is Aldgate Tower – 6th Floor, 2 Leman Street,
          London, E1 8FA and it operates the Website https://www.plend.co.uk/
          .
        </TextStyled>
        <TextStyled>
          You can contact PLEND at:
          {' '}
          <LinkStyled target="_blank" href="https://plend.co.uk/contact-us/">
            https://plend.co.uk/contact-us/
          </LinkStyled>
        </TextStyled>
      </CardStyled>
    </ContentStyled>
  </WrapStyled>
)

export default SocialCreditFull
