import React, { FC, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useStepperContext } from '../../../../core/components/stepper/StepperContext'
import { fontFamily } from '../../../../core/styles/mixins'
import { SocialCreditStepperButton } from '../../../../core/components/buttons/SocialCreditStepperButton'
import { useSocialCreditLoanCalculatorContext } from '../../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import {
  ButtonWrapStyled,
  CardStyled,
  ContentStyled, LinkStyled,
  WrapStyled
} from './styles'

const TextLineStyled = styled.div<{width?: number}>`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.56px;
  text-align: left;
  position: relative;
  margin-top: 10px;
  
  &:before{
    content: '';
    height: 1px;
    background: ${({ theme }) => theme.colors.blackDarkBlue};
    position: absolute;
    bottom: 3px;
    width: ${({ width }) => width || 0}px;
    left: 0;
  }

`

const ListStyled = styled.ul`
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 25px;
`

const TextStyled = styled.p<{noMargin?: boolean}>`
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
`

export const BoldTextStyled = styled.div<{ noMargin?: boolean, inline?: boolean }>`
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  ${fontFamily('Neue Haas Grotesk Display Pro')};
  font-size: 28px;
  font-weight: 700;
  line-height: 44px;
`

const SocialCreditStepperPrivacyPolicy: FC = () => {
  const { loanApplicationQuery: { refetch, isLoading } } = useSocialCreditLoanCalculatorContext()

  const { nextStep } = useStepperContext()

  const submit = useCallback(() => {
    refetch()
    nextStep()
  }, [])

  return (
    <WrapStyled>
      <ContentStyled>

        <CardStyled>

          <BoldTextStyled noMargin>
            Privacy Policy
          </BoldTextStyled>

          <TextStyled>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Social Credit CIC trading as ‘Social Credit' (referred to herein as Social Credit and by
            the words ‘us’ ‘we’ and ‘our’) is a community interest company serviced by Plend
            Limited which is authorised by the Financial Conduct Authority (FRN 963328)
          </TextStyled>

          <TextStyled>
            This Privacy Policy explains when and why we collect personal information, how we use
            the data, conditions under which we may disclose it to others and how we keep it
            secure. The terms ‘you’ and ‘your’ means you as an individual accessing this website,
            and applying for or utilising our products and services whether as credit applicant or
            borrower.
          </TextStyled>

          <BoldTextStyled>
            Who are we
          </BoldTextStyled>

          <TextStyled>
            This Privacy Policy (the Policy) has been prepared by Social Credit to meet the
            requirements of data protection laws and regulations, including the General Data
            Protection Regulations (EU 2016/679) (the Data Protection Laws). Where we determine
            how and why your personal data is processed, we are the data controller of such
            personal data. We are registered as a data controller with the Information
            Commissioner’s Office under the Data Protection Laws with registration number
            ZB037977
          </TextStyled>

          <TextStyled>
            Here at Social Credit we’re committed to safeguarding and preserving your privacy
            when you visit our site or communicate with us electronically. This Privacy Policy,
            together with our Terms and Conditions, explains what happens to any personal data
            that you give to us, or that we collect from you, via our website at
            {' '}
            <LinkStyled target="_blank" href="https://socialcredit.community/">www.socialcredit.community</LinkStyled>
            {' '}
            (the Website). We may update and modify this Policy from
            time to time, so please do return to the Website and review this Policy regularly. You
            can see when this Policy was last updated by referring to the date at the bottom.
          </TextStyled>

          <TextStyled>
            Please read the following carefully to understand
            our views and practices regarding your personal data.
          </TextStyled>

          <BoldTextStyled>
            Collection of Personal Data
          </BoldTextStyled>

          <TextStyled>
            We may collect the following information from you as part of the process
            of validating your application
            and managing your loan agreement. Personal Data we collect from you includes:
          </TextStyled>
          <TextStyled>
            Information that you provide by filling in forms on the Site:
          </TextStyled>

          <ListStyled>
            <li>Basic personal information such as your name, date of birth, gender</li>
            <li>Your contact details such as postal address, email address and phone number.</li>
            <li>
              Verification information such as past addresses and proof of income and
              expenditure.
            </li>
            <li>
              Details of any loan applied for such as the amount, preferred repayment period,
              and bank details for making repayments and consenting to Open Banking.
            </li>
            <li>
              Information we need to assess the affordability of the loan such as your
              employment details and your monthly income.
            </li>
            <li>
              Identifiers assigned to your computer or other devices, including your Internet
              Protocol (IP) address and other information about your visits to the Website.
            </li>
            <li>
              Any other personal data that you choose to provide on the Website or in your
              communications with us. Please note, call recordings of conversations may be
              retained for monitoring and training purposes, except where bank card details are
              provided when the call recording will be paused.
            </li>
          </ListStyled>

          <TextStyled>
            Information that we may obtain from third parties such as:
          </TextStyled>

          <TextStyled>
            If you own any property, we may carry out a search with the land registry – We do not
            place any charge on the property as security for providing the loan. Performing the
            search helps us verify you and, in some cases, means we can lend higher amounts.
          </TextStyled>

          <ListStyled>
            <li>
              A credit check will be carried out by TransUnion to view your financial commitments.
              For further details, please refer to the Credit Reference Agencies section under “4.
              Sharing your personal data” below. If you advise of any other financial commitments
              not shown on the credit file, details will be retained.
            </li>
            <li>
              Social Credit may also carry out a soft credit search if payments are missed or if
              we are unable to contact you.
            </li>
            <li>
              Details of your health or medical history if you have provided explicit consent for
              Social Credit to note these on your records.
            </li>
            <li>
              We may obtain information about complaints from the Financial Ombudsman
              Service if you escalate a complaint.
            </li>
          </ListStyled>

          <TextStyled>
            Information that may be generated during the course
            of your relationship with us, for example:
          </TextStyled>

          <ListStyled>
            <li>Details of repayments that you have made or missed;</li>
            <li>Account reference details on our system; and</li>
            <li>
              We may collect information about your interactions with the Website using
              cookies and similar technologies. For more information about our use of cookies
              and similar technologies, please see our Cookie Notice.
            </li>
          </ListStyled>

          <TextStyled>
            You are not obliged to provide us with any personal data, however if you do not provide
            certain necessary personal data, we may not be able to enter into an agreement with
            you
          </TextStyled>

          <BoldTextStyled>
            Use of Your information and Legal Bases for Processing Data
          </BoldTextStyled>

          <TextStyled>
            We may store and use your personal information for the purposes of:
          </TextStyled>

          <TextStyled>
            (a) administering your application and loan agreement (as is necessary for performance
            of a contract between you and us and/or as is necessary for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (b) carrying out anti-fraud and anti-money laundering checks and verifying your identity
            (as is necessary for compliance with our legal obligations and/or as is
            necessary for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (c) assessing financial risks, including by carrying out credit reference checks (as is
            necessary for the performance of a contract between you and us and/or as is necessary
            for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (d) using your payment details to process payments relating to your loan repayments,
            and refunds (as is necessary for the performance of a contract between you and us
            and/or as is necessary for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (e) communicating with you about your loan application/agreement, including
            responding to your enquiries (as is necessary for the performance of a contract between
            you and us and/or as is necessary for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (f) administering debt recoveries, where you owe us money under a contract or
            otherwise (as is necessary for the performance of a contract between you and us and/or
            as is necessary for our legitimate interests);
          </TextStyled>

          <TextStyled>
            (g) undertaking statistical analysis, including analysing your use of our website. This
            allows us to develop new, or improve existing, products and services (as is necessary
            for our legitimate interests); and
          </TextStyled>

          <TextStyled>
            (h) fulfilling our obligations owed to a relevant
            regulator, tax authority or revenue service (as is necessary for compliance with our
            legal obligations and/or as is necessary for our
            legitimate interests).
          </TextStyled>

          <TextStyled>
            Our “legitimate interests” as referred to above include our legitimate business purposes
            and commercial interests in operating our business in a customer-focused, efficient, and
            sustainable manner, in accordance with all applicable legal and regulatory
            requirements. Your personal data is not used for the purposes of solely automated
            decision making or profiling
          </TextStyled>

          <TextLineStyled width={280}>
            Special category personal data
          </TextLineStyled>

          <TextStyled>
            We may occasionally process personal data revealing racial or ethnic origin, political
            opinions, religious or philosophical beliefs, trade union memberships, genetic or
            biometric data, data concerning health, or data concerning your sex life or sexual
            orientation. For example, you may inform us about health conditions that impact your
            ability to repay.
          </TextStyled>

          <TextStyled>
            This data is considered ‘special category personal data’ under the Data Protection
            Laws. We will generally only process this personal data where you have given your
            express consent to this, or where it is necessary for the establishment, exercise or
            defence of legal claims
          </TextStyled>

          <TextStyled>
            Where we process special category personal data on the basis of your express consent,
            this can be withdrawn at any time. Withdrawing consent will not affect the lawfulness of
            any processing based on the consent before it was withdrawn. To withdraw consent to
            the processing of special category personal data, please contact our Data Protection
            Officer
            {' '}
            <LinkStyled
              target="_blank"
              href="mailto:dataprotectionofficer@socialcredit.community">
              dataprotectionofficer@socialcredit.community
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            Sharing your information
          </BoldTextStyled>
          <TextStyled>
            We may share your personal information with our
            associated companies and with certain third parties including:
          </TextStyled>

          <TextLineStyled width={105}>
            GoCardless
          </TextLineStyled>

          <TextStyled>
            We use GoCardless to process direct debit payments. More information
            on how GoCardless processes personal data and your data protection rights,
            including your right to object, is available at
            {' '}
            <LinkStyled
              target="_blank"
              href="https://gocardless.com/legal/privacy/">
              https://gocardless.com/legal/privacy/
            </LinkStyled>
            .
          </TextStyled>

          <TextLineStyled width={125}>
            Open Banking
          </TextLineStyled>

          <TextStyled>
            Open Banking is a secure way of providing read-only access to your bank or
            building society account to providers who are registered for this purpose.
            For more information,visit
            {' '}
            <LinkStyled
              target="_blank"
              href="https://www.openbanking.org.uk/">
              www.openbanking.org.uk
            </LinkStyled>
            .
          </TextStyled>

          <TextStyled>
            In order to enter into and administer your loan agreement you must agree for us to
            share your personal, contact and loan application details with our registered Open
            Banking partner, Plaid. During your loan application we shall safely and securely direct
            you to Plaid’s secure portal for the purposes of granting Plaid access to your bank or
            building society account information. As soon as your account information is received it
            shall be reported back to us in the form of a completed search in order that we may
            continue to process your loan application.
          </TextStyled>

          <TextStyled>
            Plaid are registered under the Open Banking Directory as an account information
            service provider and are also regulated by the Financial Conduct Authority as a
            payment services firm under number 804718. Any data you submit via the Plaid portal
            will be encrypted and its usage tracked as part of set Open Banking data security
            standards.
          </TextStyled>

          <TextStyled>
            You will not be required to share your banking password or log in details with either us
            or Plaid. Once you have given your explicit consent to share your bank account
            information on the Plaid portal you will be directed to your own
            bank or building society’s
            login page where you will enter in your own login details directly.
          </TextStyled>

          <TextStyled>
            Modulr
          </TextStyled>

          <TextStyled>
            We use Modulr to manage our investor e-wallets. More information on how Modulr
            process personal data is available at
            {' '}
            <LinkStyled
              target="_blank"
              href="https://www.modulrfinance.com/privacy-policy">
              https://www.modulrfinance.com/privacy-policy
            </LinkStyled>
          </TextStyled>

          <TextLineStyled width={122}>
            Plend Limited
          </TextLineStyled>

          <TextStyled>
            Plend Limited which is authorised by the Financial
            Conduct Authority (FRN 963328) is a member of the unincorporated association.
          </TextStyled>

          <TextLineStyled width={144}>
            Fair4All Finance
          </TextLineStyled>

          <TextStyled>
            Social Credit and Fair4All Finance Limited are working together to deliver the Interest
            Free Loan Scheme. For more information on how your data will be used in this capacity,
            please see the privacy policy upon application.
          </TextStyled>

          <TextLineStyled width={200}>
            With other third parties
          </TextLineStyled>

          <TextStyled>
            We may share your personal information with our
            associated companies and with certain third parties including:
          </TextStyled>

          <ListStyled>
            <li>
              Third parties that assist us in preparing or sending any communications
              to you, or to assist us in connection with any of our administrative or
              business functions, or in the provision of any of our services to you,
              or to third parties acting as our agents.
            </li>
            <li>
              We reserve the right to sell non-performing accounts. We will inform you if we do
              this.
            </li>
            <li>
              If we are unable to make contact with you, we may employ third party companies
              to trace your whereabouts and re-engage with us. We may also employ third
              parties to enforce our rights under any agreement with you.
            </li>
            <li>
              If Social Credit or its assets are potentially to be acquired by a third party,
              or if we consider restructuring, personal data held by us about our customers
              (including borrowers and investors) will be one of the transferred assets
              and may be shared during the potential transaction or restructuring process.
            </li>
            <li>
              Anyone to whom we transfer or may transfer our rights and duties under any
              agreement with you.
            </li>
            <li>
              Legal and regulatory bodies, such as the Financial Conduct Authority, the
              Information Commissioner’s Office, the Financial Ombudsman Service, fraud
              prevention agencies or when we have a legal obligation to do so.
            </li>
            <li>
              Our auditors, solicitors, professional advisors, sub-contractors who have agreed
              to treat your personal details as confidential.
            </li>
            <li>
              If you were introduced to us by a third party, we will provide them details of
              whether the loan was successful or not. A commission may be paid for the
              introduction. If you require details of any commission paid, please contact Social
              Credit by using our contact details.
            </li>
          </ListStyled>

          <BoldTextStyled>
            Credit Reference Agencies (CRA)
          </BoldTextStyled>
          <TextStyled>
            In order to process your application, we will perform credit and identity checks on you
            with TransUnion, Experian & Equifax credit reference agencies (“CRAs”).
          </TextStyled>

          <TextStyled>
            To do this, we will supply your personal information to TransUnion, Experian & Equifax
            and they will give us information about you. This will include information from
            your credit application and about your financial situation and financial history.
            TransUnion, Experian & Equifax will supply to us both public (including the electoral
            register) and shared credit, financial situation and financial history
            information and fraud prevention information.
          </TextStyled>

          <TextStyled>
            We will use this information to:
          </TextStyled>

          <ListStyled>
            <li>
              Assess your creditworthiness and whether you can afford to take the product;
            </li>
            <li>
              Verify the accuracy of the data you have provided to us;
            </li>

            <li>
              Prevent criminal activity, fraud, and money laundering;
            </li>
            <li>
              Manage your account(s);
            </li>

            <li>
              Trace and recover debts; and
            </li>
            <li>
              Ensure any offers provided to you are appropriate to your circumstances.
            </li>

          </ListStyled>

          <TextStyled>
            We will continue to exchange information about you with TransUnion while you have a
            relationship with us. We will also inform TransUnion about your settled accounts. If you
            borrow and do not repay in full and on time, TransUnion, Experian & Equifax will record
            the outstanding debt. This information may be supplied to other organisations by CRAs.
          </TextStyled>

          <TextStyled>
            When TransUnion, Experian & Equifax receive a search from us they will place a search
            footprint on your credit file that may be seen by other lenders.
          </TextStyled>

          <TextStyled>
            If you are making a joint application or tell us that you have a spouse or financial
            associate, we will link your records together, so you should make sure you discuss this
            with them, and share with them this information, before lodging the application. CRAs
            will also link your records together and these links will remain
            on your and their files until such time as you or your partner successfully
            files for a disassociation with the CRAs to break that link.
          </TextStyled>

          <TextStyled>
            The identities of the CRAs, their role also as fraud prevention agencies, the data they
            hold, the ways in which they use and share personal information, data retention periods
            and your data protection rights with the CRAs are explained in more detail at
            {' '}
            <LinkStyled target="_blank" href="https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference">
              https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference
            </LinkStyled>
            .
          </TextStyled>

          <BoldTextStyled>
            Transfers outside the UK
          </BoldTextStyled>

          <TextStyled>
            The data that we collect from you may be transferred to,
            and stored at, a destination outside the United Kingdom (UK).
            We will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this
            privacy policy.
          </TextStyled>

          <BoldTextStyled>
            Data Retention
          </BoldTextStyled>

          <TextStyled>
            We retain the personal data processed by us for as long as
            is considered necessary for the purpose for which it was
            collected (including as required by applicable law or regulation).
          </TextStyled>

          <TextStyled>
            In accordance with our retention policy, we will retain your personal information for a
            minimum of six years from the end of our business relationship with you. Our business
            relationship will be deemed to be at an end on the date upon which your account is
            closed (which will either be when all outstanding sums under the agreement have been
            repaid or when we stop pursuing arrears on the account) or when your application has
            been declined.
          </TextStyled>

          <TextStyled>
            We take steps with a view to deleting, destroying, or permanently anonymising your
            personal data (which means that we are no longer able to identify you from it)
            when it is no longer necessary for its purpose and we are not required by law
            to keep it. For further information on Social Credit Retention & Erasure policies,
            please contact the Data Protection Officer:
            {' '}
            <LinkStyled target="_blank" href="mailto:dataprotectionofficer@socialcredit.community">
              dataprotectionofficer@socialcredit.community
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            Security of Information
          </BoldTextStyled>

          <TextStyled>
            We are aware of the importance of protecting your personal
            data we collect from you and therefore we store data in
            encrypted form on computers and control access via secure
            web pages. We employ firewalls and other security technologies
            to protect our servers from external attack. We train our
            employees in the proper handling of personal information
            and when we use other companies to provide services for us,
            we require them to protect the confidentiality of personal
            information they receive
          </TextStyled>

          <TextStyled>
            When you enter personal information on the application form, we encrypt that
            information using secure socket layer technology (SSL). We ask you not to permit
            anyone to use your name and you must not share your account details or your
            password for the Social Credit portal with anyone. For more information, please see our
            Website Terms of Use.
          </TextStyled>

          <TextStyled>
            Unfortunately, the transmission of information via the internet is not
            completely secure. Although we will do our best to protect your personal
            data, we cannot guarantee the security of your data transmitted to our Site;
            any transmission is at your own risk. Once
            we have received your personal data, we will use strict procedures and security features
            to try to prevent unauthorised access.
          </TextStyled>

          <BoldTextStyled>
            Your rights under applicable data protection law
          </BoldTextStyled>

          <TextStyled>
            Your personal information is protected under data protection law and you have several
            rights (explained below) that you can seek to exercise. Please contact our Data
            Protection Officer
            {' '}
            <LinkStyled
              target="_blank"
              href="mailto:dataprotectionofficer@socialcredit.community">
              dataprotectionofficer@socialcredit.community
            </LinkStyled>
            {' '}
            if you wish to do so, or
            if you have any queries in relation to your rights.
          </TextStyled>

          <TextStyled>
            If you seek to exercise your rights, we will explain
            to you whether or not the right applies to you;
            these rights do not apply in all circumstances. Please be aware that if your
            request is manifestly unfounded or excessive we may refuse to deal with your request
            or may charge a reasonable fee for dealing with it.
          </TextStyled>

          <TextStyled>
            Right to access
          </TextStyled>

          <TextStyled>
            You have the right to access the personal information held about you and to obtain
            certain prescribed information about how we process it. This is more commonly known
            as submitting a ‘data subject access request’. We may request proof of your identity
            before sharing such information.
          </TextStyled>

          <TextStyled>
            Right to rectify your personal information
          </TextStyled>

          <TextStyled>
            If you discover that the information we hold about you is inaccurate or incomplete, you
            have the right to have this information rectified (i.e. corrected).
          </TextStyled>

          <TextStyled>
            Right to be forgotten
          </TextStyled>

          <TextStyled>
            You may ask us to delete information we hold about you in certain circumstances, this is
            often referred to as the ‘right to be forgotten’. This right
            is not absolute and only applies in particular circumstances.
            It may not therefore be possible for us to delete the
            information we hold about you, for example, if we have an ongoing relationship or are
            required to retain information to comply with our legal obligations or to exercise or
            defend legal claims.
          </TextStyled>

          <TextStyled>
            Right to object to processing
          </TextStyled>

          <TextStyled>
            You may object to the processing of your personal information when it is based upon
            our legitimate interests. You may also object to the processing of your personal
            information for the purposes of direct marketing and for the purposes of statistical
            analysis
          </TextStyled>

          <TextStyled>
            Right to restriction of processing
          </TextStyled>

          <TextStyled>
            In some cases, you may have the right to have the processing of your personal
            information restricted. For example, where you contest the accuracy of your personal
            information, it may be restricted until the accuracy is verified,
            or where the processing is unlawful but you object to it being deleted
            and request that it is restricted instead.
          </TextStyled>

          <TextStyled>
            Right to data portability
          </TextStyled>

          <TextStyled>
            You have the right to receive, move, copy, or transfer your personal information to a
            controller which is also known as ‘data portability’. You have the right to this when we
            are processing your personal information based on consent or on a contract and the
            processing is carried out by automated means. You should note that this right is
            different from the right of access (see above) and the types of information you can
            obtain under the two separate rights may be different.
          </TextStyled>

          <BoldTextStyled>
            Marketing
          </BoldTextStyled>

          <TextStyled>
            Your Consent- When you submit your application for a loan, we will ask you if you want
            to receive marketing information from Social Credit, about products from Social Credit
            (opportunities to take out new loans, top up loans etc.).
          </TextStyled>

          <TextStyled>
            You can decide if you want to receive information and how you want to receive it (email,
            SMS, telephone, post).
          </TextStyled>

          <TextStyled>
            We will not pass your details to other third-party organisations, for the purposes of
            marketing, without your consent.
          </TextStyled>

          <TextStyled>
            If you want to change your marketing preferences, you can update them through your
            Social Credit online portal or make contact with our offices directly
          </TextStyled>

          <BoldTextStyled>
            Data Controller and Contact Information
          </BoldTextStyled>

          <TextStyled>
            The data controller is Social Credit which is
            authorised by the Financial Conduct Authority (FRN 963328).
          </TextStyled>

          <TextStyled>
            If you have any questions about this privacy statement
            or how and why we process personal data, please contact us at:
          </TextStyled>

          <TextStyled>
            James Pursaill
          </TextStyled>

          <TextStyled>
            Data Protection Officer
          </TextStyled>

          <TextStyled>
            Post: Aldgate Tower, 2 Leman St, London E1 8FA.
          </TextStyled>

          <TextStyled>
            Email:
            {' '}
            <LinkStyled target="_blank" href="mailto:dataprotectionofficer@socialcredit.community">
              dataprotectionofficer@socialcredit.community
            </LinkStyled>
          </TextStyled>

          <BoldTextStyled>
            Complaints
          </BoldTextStyled>

          <TextStyled>
            We hope that you won’t ever need to, but if you do want to complain about our use of
            personal data, please send an email with the details of your complaint to
            {' '}
            <LinkStyled target="_blank" href="mailto:dataprotectionofficer@socialcredit.community">
              dataprotectionofficer@socialcredit.community
            </LinkStyled>
            {' '}
            or use the contact details above. We will
            investigate and respond to any complaints we receive.
          </TextStyled>

          <TextStyled>
            You also have the right to lodge a complaint with the Information Commissioner’s Office
            (“ICO”) (the UK data protection regulator). For further information on your rights and
            how to complain to the ICO, please refer to the
            {' '}
            <LinkStyled target="_blank" href="https://ico.org.uk/">ICO website</LinkStyled>
            {' '}
            or contact the ICO on 0303
            123 1113.
          </TextStyled>

          <BoldTextStyled>
            Changes to this Privacy Policy
          </BoldTextStyled>

          <TextStyled>
            We reserve the right to make changes to this Policy at any time and any significant
            changes to this Policy will be emailed direct to the primary email address supplied by
            you; minor changes will be updated directly to the Policy and may not require
            notification. We advise you regularly review this document to ensure that you are aware
            of any amendments and to be clear on how this policy may affect you.
          </TextStyled>

          <TextStyled>
            This privacy statement was last updated on 25th March 2024
          </TextStyled>

        </CardStyled>

        <ButtonWrapStyled>
          <SocialCreditStepperButton text="Accept" onClick={submit} disabled={isLoading}/>
        </ButtonWrapStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default SocialCreditStepperPrivacyPolicy
