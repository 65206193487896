import React, { FC, useState } from 'react'
import styled from 'styled-components/macro'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { flex, fontFamily } from '../styles/mixins'
import { MinusIcon } from './icons/MinusIcon'
import { PlusIcon } from './icons/PlusIcon'

const AccordionStyled = styled(Accordion)`
  && {
    &.MuiPaper-root {
      box-shadow: none;
      padding: 0;
    }
  }

  && {
    .MuiAccordionSummary-root {
      padding: 0;
    }
  }
`

export const InfoTitleStyled = styled.div`
  ${flex({ align: 'center' })};
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  ${({ theme }) => theme?.mixins?.accordion?.infoTitleMixin};
  
  @media (max-width: 375px) {
    ${flex({ align: 'start' })};
  }
`

const TitleWrapperStyled = styled.span`
  margin-left: 6px;
`

export const DescriptionStyled = styled.div`
  ${fontFamily('Neue')};
  margin-left: 16px;
  letter-spacing: .2px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  
  ${({ theme }) => theme?.mixins?.accordion?.descriptionMixin}
`

type TProps = {
  info: {
    title: string
    description: string
  }
}

const CustomAccordion: FC<TProps> = ({ info: { title, description } }) => {
  const [isShowDescription, setIsShowDescription] = useState(false)
  return (
    <div>
      <AccordionStyled square>
        <AccordionSummary
          onClick={() => setIsShowDescription(!isShowDescription)}
        >
          <InfoTitleStyled>
            {
              isShowDescription
                ? <MinusIcon/>
                : <PlusIcon/>
            }
            <TitleWrapperStyled>
              {title}
            </TitleWrapperStyled>
          </InfoTitleStyled>
        </AccordionSummary>
        <AccordionDetails>
          <DescriptionStyled>
            {description}
          </DescriptionStyled>
        </AccordionDetails>
      </AccordionStyled>
    </div>
  )
}
export default CustomAccordion
