/* eslint-disable react/no-unescaped-entities */

import styled from 'styled-components/macro'
import { memo } from 'react'

const TextStyled = styled.div`
  margin-top: 30px;
`
const ApprovedLoan = () => (
  <>
    <TextStyled>
      <div>
        Thank you for your recent application for a Plend loan.
      </div>
      <div>
        It looks like you already have an outstanding loan with Plend, according to our records.
      </div>
    </TextStyled>
    <TextStyled>
      Unfortunately we cannot consider you for a second Plend loan until you've repaid your
      current loan in full. If you have settled your loan with us, you can re-apply
      with us after 60 days.
    </TextStyled>
    <TextStyled>
      Please reach out to us at
      {' '}
      <a href="mailto:support@plend.co.uk">support@plend.co.uk</a>
      {' '}
      if you need assistance with this.
    </TextStyled>
    <TextStyled>
      <p>Regards,</p>
      <p>Team Plend</p>
    </TextStyled>
  </>
)

export default memo(ApprovedLoan)
