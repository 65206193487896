import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useSetState } from 'react-use'
import { flex, fontFamily } from '../../../core/styles/mixins'
import Semicircle from '../../../core/components/icons/svg/Semicircle.svg'
import CustomSlider from '../../../core/components/CustomSlider'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { PlendLogoIcon } from '../../../core/components/icons/PlendLogoIcon'
import { useReconnectingContext } from '../../reconnecting/state/useReconnectingState'
import NotValidHeader from '../../bankCheck/components/NotValidHeader'

const CalculatorStyled = styled.div`
  max-width: 1092px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 70px;
  padding: 32px 48px 32px 46px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    z-index: 1000000;
    bottom: -32%;
    right: -32%;
    background: url(${Semicircle}) no-repeat center center/cover;
    width: 317px;
    height: 160px;

    @media (max-width: 1040px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 375px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    ${flex({ direction: 'column' })};
    max-width: 604px;
    padding: 32px 34px 32px 32px;
    margin-bottom: 50px;
    && {
      & .MuiSlider-markLabel {
        font-size: 10px!important;
      }
    }
  }

  @media (max-width: 375px) {
    max-width: 355px;
    padding: 24px 16px;
  }
  @media (max-width: 375px) {
    margin-bottom: 30px
  }
`

const HeaderStyled = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center' })};
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.orange};
  @media (max-width: 375px) {
    padding: 0 10px;
  }
`
const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 24px;
`

const TitleStyled = styled.div`
  ${fontFamily('Mongoose')};
  margin-top: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 56px;
  line-height: 65px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 50px;
  }
  @media (max-width: 375px) {
    line-height: 56px;
  }
`

const SliderStyled = styled.div`
  flex: 0.6;
  margin-right: 98px;

  && {
    & .MuiSlider-root {
      margin-top: 32px;
    }

    & .MuiSlider-markLabel {
      margin-top: 10px;
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.blackDarkBlue};
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const SubtitleStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const BorrowAmount = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 48px;
`

const MonthlyPaymentAmount = styled.div`
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-align: right;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const LoanPeriod = styled.div`
  ${flex({ justify: 'space-between', align: 'baseline' })};
  ${fontFamily('Neue')};
  margin-top: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const AmountCalculation = styled.div`
  flex: 0.4;
  @media (max-width: 768px) {
    margin-top: 32px;
  }
`

const MonthlyPayment = styled.div`
  ${flex({ justify: 'space-between', align: 'baseline' })};
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const MonthlyPaymentTitle = styled.div`
  margin-bottom: 42px;
`

const MonthlyPaymentSubtitle = styled.div`
  margin-bottom: 16px;
`

const TotalResult = styled.div``
const ButtonStyled = styled(Button)`
  && {
    ${fontFamily('Neue')};
    width: 100%;
    padding: 12px 20px;
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.orange};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.orange};
    }
  }
`

export type TLoanValues = {
  loanAmount: number,
  period: number,
  apr: string,
  fixedRate: string,
  monthlyPaymentCalc: any
}

function useIsLoading() {
  const {
    reconnectingQuery: { data: reconnectingData },
    isReconnectingProcess
  } = useReconnectingContext()

  return isReconnectingProcess && !reconnectingData
}

function useIsNotValidContentShowed() {
  const {
    isReconnectingProcess,
    isInvalidLink
  } = useReconnectingContext()

  return isReconnectingProcess && isInvalidLink
}

function updateLoanValuesWithReconnectingData(
  setLoanValues: (patch: Partial<TLoanValues>) => void
) {
  const {
    reconnectingQuery: { data: reconnectingData }
  } = useReconnectingContext()

  useEffect(() => {
    if (reconnectingData) {
      setLoanValues({ loanAmount: reconnectingData.loanAmount })
      setLoanValues({ period: reconnectingData.loanLength })
    }
  }, [reconnectingData])
}

const DefaultLoanCalculator = () => {
  const [, setLocaleStorageLoan] = useLocalStorage('loan')
  const navigate = useNavigate()
  const [loanValues, setLoanValues] = useSetState<TLoanValues>({
    loanAmount: 3000,
    period: 12,
    apr: '28.99',
    fixedRate: '25.73',
    monthlyPaymentCalc: 0
  })

  updateLoanValuesWithReconnectingData(setLoanValues)

  const { loanAmount, period, apr, fixedRate } = loanValues

  useEffect(() => setLocaleStorageLoan(loanValues), [loanValues])
  useEffect(() => {
    calculateMonthlyPaymentApr()
    calculateMonthlyPaymentFixedRate()
    setLoanValues({ monthlyPaymentCalc: monthlyPayment })
  }, [loanAmount, period, apr, fixedRate])

  const month = period % 12
  const year = Math.floor(period / 12)
  const loanPeriod = `${year} ${year === 1 ? 'year' : 'years'} ${month} months`

  const loanAmountMarks = [
    {
      value: 3000,
      label: formatToPound(3000)
    },
    {
      value: 5000,
      label: formatToPound(5000)
    },
    {
      value: 6500,
      label: formatToPound(6500)
    },
    {
      value: 8000,
      label: formatToPound(8000)
    },
    {
      value: 10000,
      label: formatToPound(10000)
    }
  ]

  const periodMarks = [
    {
      value: 12,
      label: '1 year'
    },
    {
      value: 24,
      label: '2 years'
    },
    {
      value: 36,
      label: '3 years'
    },
    {
      value: 48,
      label: '4 years'
    },
    {
      value: 60,
      label: '5 years'
    }
  ]

  const calculateMonthlyPaymentApr = () => {
    const yearPeriod = period.toString()

    switch (yearPeriod) {
    case '12':
    case '18':
    case '24':
    case '30':
    case '36':
      if (loanAmount >= 1000 && loanAmount < 3000) {
        setLoanValues({ apr: '28.99' })
      }
      if (loanAmount >= 3000 && loanAmount < 5000) {
        setLoanValues({ apr: '25.99' })
      }
      if (loanAmount >= 5000 && loanAmount < 8000) {
        setLoanValues({ apr: '24.99' })
      }
      if (loanAmount >= 8000 && loanAmount <= 10000) {
        setLoanValues({ apr: '20.99' })
      }
      if (loanAmount > 10000) {
        setLoanValues({ apr: '8.99' })
      }
      break
    case '42':
    case '48':
    case '54':
    case '60':
      if (loanAmount >= 1000 && loanAmount < 3000) {
        setLoanValues({ apr: '27.99' })
      }
      if (loanAmount >= 3000 && loanAmount < 5000) {
        setLoanValues({ apr: '24.99' })
      }
      if (loanAmount >= 5000 && loanAmount < 8000) {
        setLoanValues({ apr: '22.99' })
      }
      if (loanAmount >= 8000 && loanAmount <= 10000) {
        setLoanValues({ apr: '17.99' })
      }
      if (loanAmount > 10000) {
        setLoanValues({ apr: '8.99' })
      }
      break
    default:
      break
    }
  }

  const calculateMonthlyPaymentFixedRate = () => {
    const yearPeriod = period.toString()

    switch (yearPeriod) {
    case '12':
    case '18':
    case '24':
    case '30':
    case '36':
      if (loanAmount >= 1000 && loanAmount < 3000) {
        setLoanValues({ fixedRate: '25.73' })
      }
      if (loanAmount >= 3000 && loanAmount < 5000) {
        setLoanValues({ fixedRate: '23.33' })
      }
      if (loanAmount >= 5000 && loanAmount < 8000) {
        setLoanValues({ fixedRate: '22.51' })
      }
      if (loanAmount >= 8000 && loanAmount <= 10000) {
        setLoanValues({ fixedRate: '19.21' })
      }
      if (loanAmount > 10000) {
        setLoanValues({ fixedRate: '8.64' })
      }
      break
    case '42':
    case '48':
    case '54':
    case '60':
      if (loanAmount >= 1000 && loanAmount < 3000) {
        setLoanValues({ fixedRate: '24.93' })
      }
      if (loanAmount >= 3000 && loanAmount < 5000) {
        setLoanValues({ fixedRate: '22.51' })
      }
      if (loanAmount >= 5000 && loanAmount < 8000) {
        setLoanValues({ fixedRate: '20.87' })
      }
      if (loanAmount >= 8000 && loanAmount <= 10000) {
        setLoanValues({ fixedRate: '16.66' })
      }
      if (loanAmount > 10000) {
        setLoanValues({ fixedRate: '8.64' })
      }
      break
    default:
      break
    }
  }

  const handleLoanAmountChange = (event: any) => {
    setLoanValues({ loanAmount: event.target.value })
  }

  const handlePeriodChange = (event: any) => {
    setLoanValues({ period: event.target.value })
  }

  const numerator = Number(loanAmount) * ((Number(fixedRate) / 100) / 12)
    * (1 + ((Number(fixedRate) / 100) / 12)) ** period
  const denominator = ((1 + ((Number(fixedRate) / 100) / 12)) ** period) - 1
  const monthlyPayment = (numerator / denominator).toFixed(2)
  const totalRepayableCalc = (Number(monthlyPayment) * Number(period)).toFixed(2)
  const totalInterestCalc = (Number(totalRepayableCalc) - Number(loanAmount)).toFixed(2)
  const isNotValidContentShowed = useIsNotValidContentShowed()

  if (useIsLoading()) {
    return null
  }

  if (isNotValidContentShowed) {
    return <NotValidHeader />
  }

  return (
    <HeaderStyled>
      <LogoWrapper>
        <PlendLogoIcon/>
      </LogoWrapper>
      <TitleStyled>Get an affordable loan that matches your ambition</TitleStyled>
      <CalculatorStyled>
        <SliderStyled>
          <SubtitleStyled>
            <div>I want to borrow</div>
            <BorrowAmount>
              {formatToPound(loanAmount)}
            </BorrowAmount>
          </SubtitleStyled>
          <CustomSlider
            value={loanAmount}
            onChange={handleLoanAmountChange}
            defaultValue={3000}
            min={3000}
            max={10000}
            step={500}
            marks={loanAmountMarks}
          />
          <LoanPeriod>
            <SubtitleStyled>For how long</SubtitleStyled>
            <div>{loanPeriod}</div>
          </LoanPeriod>
          <CustomSlider
            value={period}
            onChange={handlePeriodChange}
            defaultValue={12}
            step={6}
            marks={periodMarks}
            min={12}
            max={60}
          />
        </SliderStyled>
        <AmountCalculation>
          <MonthlyPayment>
            <MonthlyPaymentTitle>Monthly payment:</MonthlyPaymentTitle>
            <MonthlyPaymentAmount>
              {formatToPound(Number(monthlyPayment))}
            </MonthlyPaymentAmount>
          </MonthlyPayment>
          <TotalResult>
            <MonthlyPayment>
              <MonthlyPaymentSubtitle>APR:</MonthlyPaymentSubtitle>
              <div>
                {apr}
                %
              </div>
            </MonthlyPayment>
            <MonthlyPayment>
              <MonthlyPaymentSubtitle>Fixed Rate:</MonthlyPaymentSubtitle>
              <div>
                {fixedRate}
                %
              </div>
            </MonthlyPayment>
            <MonthlyPayment>
              <MonthlyPaymentSubtitle>Total repayable:</MonthlyPaymentSubtitle>
              <div>{formatToPound(Number(totalRepayableCalc))}</div>
            </MonthlyPayment>
            <MonthlyPayment>
              <div>Total interest:</div>
              <div>{formatToPound(Number(totalInterestCalc))}</div>
            </MonthlyPayment>
          </TotalResult>
          <ButtonStyled onClick={() => navigate(`registration${window.location.search}`)}>GET QUOTE</ButtonStyled>
        </AmountCalculation>
      </CalculatorStyled>
    </HeaderStyled>
  )
}
export default DefaultLoanCalculator
