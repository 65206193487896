import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { Alert } from '@mui/material'
import { FieldError } from 'react-hook-form/dist/types/errors'
import { useCymruPersonalInformationContext } from '../state/useCymruPersonalInformationState'
import { fontFamily } from '../../../core/styles/mixins'

const AlertStyled = styled(Alert)`
  && {
    &.MuiAlert-standardError {
      ${fontFamily('Neue')};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.orange};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`

const AlertWrapStyled = styled.div`
  width: 100%;
  && {
    .MuiPaper-root {
      display: flex;
      justify-content: center;
      border-radius: 0
    }
  }
`

function useErrors() {
  const { useFormReturn: { formState: { errors } } } = useCymruPersonalInformationContext()

  return Object.entries(errors).reduce<FieldError[]>((result, [, item]) => {
    const error = Array.isArray(item) ? item[0] : item

    return [
      ...result,
      ...(
        error.type === 'required'
          ? result.find((item) => item.type === 'required')
            ? []
            : [error]
          : [error]
      )
    ]
  }, [])
}

const Errors: FC = () => {
  return (
    <>
      {useErrors()
        .map((error) => (
          <AlertWrapStyled>
            <AlertStyled
              sx={{ marginTop: 3 }}
              data-testid="formErrorBlock"
              severity="error">
              {error?.message}
            </AlertStyled>
          </AlertWrapStyled>
        ))}
    </>
  )
}

export default Errors
