import { useCallback, useEffect } from 'react'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import { initGoogleAnalyticsConfig, sendMetric } from '../utils/googleAnalyticsUtils'

export function useInitGoogleAnalytics() {
  const { currentOrganization } = useOrganizationContext()

  useEffect(() => {
    if ((['makemyhousegreen', 'socialcreditcymru'] as TOrganization[]).includes(currentOrganization)) {
      initGoogleAnalyticsConfig()
    }
  }, [])
}

export function useGoogleAnalyticsMetrics() {
  const { currentOrganization } = useOrganizationContext()

  const isMakeMyHouseGreenOrganization = currentOrganization === 'makemyhousegreen'
  const isCymruOrganization = currentOrganization === 'socialcreditcymru'

  return {
    sendLandingOnFirstPageMetric: useCallback(() => {
      if (isMakeMyHouseGreenOrganization) {
        sendMetric('MMHG - https://makemyhousegreen.plend.co.uk/')
      }
      if (isCymruOrganization) {
        sendMetric('Social Credit Cymru - Landing on the first page')
      }
    }, []),
    sendSubmittingApplicationDetailsMetric: useCallback(() => {
      if (isMakeMyHouseGreenOrganization) {
        sendMetric('MMHG - Submitting application details')
      }
    }, []),
    sendSuccessfullyConnectingBankAccountMetric: useCallback(() => {
      if (isMakeMyHouseGreenOrganization) {
        sendMetric('MMHG - Successfully connecting bank account')
      }
    }, []),
    sendCymruApprovedDecisionMetric: useCallback(() => {
      if (isCymruOrganization) {
        sendMetric('Social Credit Cymru - Approved decision')
      }
    }, []),
    sendCymruDeclinedDecisionMetric: useCallback(() => {
      if (isCymruOrganization) {
        sendMetric('Social Credit Cymru - Declined decision')
      }
    }, [])
  }
}
