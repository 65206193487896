import { useMutation } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { TResubmitAddressParams } from './types'
import { useOrganizationContext } from '../../core/state/useOrganization'

enum QueryKey {
  ResubmitAddress = 'ResubmitAddress'
}

async function resubmitAddress(params: TResubmitAddressParams) {
  const { borrowerId, formData } = params
  const baseUrl = process.env.REACT_APP_HOST
  try {
    await axios.post(`${baseUrl}/borrowers/${borrowerId}/resubmit-address`, formData)
  } catch (err: any) {
    throw {
      message: err.response.data.message || 'Something went wrong',
      stack: { status: err.response.status }
    } as TResubmitAddressMutationError
  }
}

export type TResubmitAddressMutationError = {
  message: string
  stack?: { status: string }
}

export const useResubmitAddressMutation = () => {
  const key = QueryKey.ResubmitAddress
  const navigate = useNavigate()

  const { currentOrganization } = useOrganizationContext()
  const [searchParams] = useSearchParams()

  return useMutation<any, TResubmitAddressMutationError, TResubmitAddressParams>(
    [key],
    (params) => resubmitAddress(params),
    {
      onSuccess: () => {
        // todo check if it works
        if (currentOrganization === 'socialcreditcymru') {
          navigate(`/registration?loanApplicationId=${searchParams.get('loanApplicationId')}&borrowerId=${searchParams.get('borrowerId')}`)
          return
        }

        navigate('/accounts', { state: { contentType: 'resubmit' } })
      }
    }
  )
}
