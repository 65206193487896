import { FC } from 'react'
import styled from 'styled-components/macro'
import { CompletedIcon } from '../icons/CompletedIcon'
import { flex, fontFamily } from '../../styles/mixins'

const StepContentStyled = styled.div<{isActive: boolean}>`
  ${flex({ justify: 'center', align: 'center' })};
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.yellow : theme.colors.white)};
  ${({ theme }) => theme?.mixins?.stepper?.circleMixin};
`

const TextStyled = styled.p`
  ${fontFamily('Neue')}
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  ${({ theme }) => theme?.mixins?.stepper?.circleTextMixin};
`

type TProps = {
  completed: boolean
  icon: number
  active: boolean
  showPreviousStepsIcon?: boolean
}

const StepIcon: FC<TProps> = ({ completed, icon, active, showPreviousStepsIcon }) => (
  <StepContentStyled isActive={active || completed}>
    {completed && showPreviousStepsIcon
      ? <CompletedIcon/>
      : <TextStyled>{icon}</TextStyled>}
  </StepContentStyled>
)

export default StepIcon
