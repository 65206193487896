import { UseFormReturn, useWatch } from 'react-hook-form'
import React, { FC, useState } from 'react'
import FirstName from '../../../../core/components/controls/FirstName'
import LastName from '../../../../core/components/controls/LastName'
import Email from '../../../../core/components/controls/Email'
import DatePicker from '../../../../core/components/controls/DatePicker'
import EmploymentLength from '../../../../core/components/controls/EmploymentLength'
import IncomeAnnual from '../../../../core/components/controls/IncomeAnnual'
import LivingStatusSelect from '../../../../core/components/controls/LivingStatusSelect'
import EmploymentStatusSelect from '../../../../core/components/controls/EmploymentStatusSelect'
import {
  EmptyRowStyled,
  PeriodStyled, RadioButtonContainer,
  RadioButtonStyled,
  RadioWrapperStyled, SecondAddressStyled,
  SelectContainerStyled,
  StatusWrapperStyled,
  WrapperStyled
} from '../../styled'
import CheckBox from '../../../../core/components/controls/CheckBox'
import { TUser } from '../../types'
import PostCodeLoqate from '../../../../core/components/controls/PostCode/PostCodeLoqate'
import PrimaryPhoneNumber from '../../../../core/components/controls/PrimaryPhoneNumber'
import SecondaryPhoneNumber from '../../../../core/components/controls/SecondaryPhoneNumber'
import Employer from '../../../../core/components/controls/Employer'
import BuildingNumber from '../../../../core/components/controls/BuildingNumber'
import BuildingName from '../../../../core/components/controls/BuildingName'
import Street from '../../../../core/components/controls/Street'
import Town from '../../../../core/components/controls/Town'
import Country from '../../../../core/components/controls/Country'
import CustomLabel from '../../../../core/components/CustomLabel'
import CustomRadioButton from '../../../../core/components/CustomRadioButton'
import { RadioIcon } from '../../../../core/components/icons/RadioIcon'
import PrevPostCodeLoqate from '../../../../core/components/controls/PostCode/PrevPostCodeLoqate'
import PrevBuildingNumber from '../../../../core/components/controls/PrevBuildingNumber'
import PrevBuildingName from '../../../../core/components/controls/PrevBuildingName'
import PrevStreet from '../../../../core/components/controls/PrevStreet'
import PrevTown from '../../../../core/components/controls/PrevTown'
import PrevCountry from '../../../../core/components/controls/PrevCountry'
import { useStatusEmployer } from '../../hooks/useStatusEmployer'

type TProps = {
  formMethods: UseFormReturn<Partial<TUser>>
}

const PurpleShootForm: FC<TProps> = ({ formMethods: { control, setValue, watch } }) => {
  const [isSelectedValue, setIsSelectedValue] = useState(true)

  const employerShowFlag = useStatusEmployer()
  const handleChange = () => {
    setIsSelectedValue(!isSelectedValue)
  }

  return (
    <>
      <WrapperStyled>
        <FirstName control={control}/>
        <LastName control={control}/>
        <Email control={control}/>
        <DatePicker control={control}/>
        <EmploymentLength control={control}/>
        <PostCodeLoqate control={control} setValue={setValue}/>
        <BuildingNumber control={control}/>
        <BuildingName control={control}/>
        <Street control={control}/>
        <Country control={control}/>
        <Town control={control}/>
        <PrimaryPhoneNumber control={control}/>
        <SecondaryPhoneNumber control={control}/>
        <IncomeAnnual control={control}/>
        {employerShowFlag.includes(watch('employmentStatusId') as number)
          ? (
            <Employer control={control}/>
          )
          : <EmptyRowStyled />}
      </WrapperStyled>
      <RadioButtonContainer>
        <CustomLabel label="Length of time at Address *"/>
        <RadioButtonStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-short"
              checked={!isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>0-1 years</PeriodStyled>
          </RadioWrapperStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-long"
              checked={isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>1+ years</PeriodStyled>
          </RadioWrapperStyled>
        </RadioButtonStyled>
      </RadioButtonContainer>
      {!isSelectedValue
        && (
          <SecondAddressStyled>
            <PrevPostCodeLoqate control={control} setValue={setValue}/>
            <PrevBuildingNumber control={control}/>
            <PrevBuildingName control={control}/>
            <PrevStreet control={control}/>
            <PrevTown control={control}/>
            <PrevCountry control={control}/>
          </SecondAddressStyled>
        )}
      <StatusWrapperStyled>
        <SelectContainerStyled>
          <LivingStatusSelect control={control}/>
          <EmploymentStatusSelect control={control}/>
        </SelectContainerStyled>
      </StatusWrapperStyled>
      <CheckBox control={control}/>
    </>
  )
}
export default PurpleShootForm
