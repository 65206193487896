import React from 'react'
import styled from 'styled-components/macro'
import TellMeMore from '../../tellMeMore/components/TellMeMore'
import BankCheckHeader from './BankCheckHeader'
import { PlaidMenuProvider, usePlaidMenuContext } from '../state/usePlaidMenuContext'
import PlaidMenu from './PlaidMenu'
import { useInitIntercomChat } from '../../intercomChat/hooks/useInitIntercomChat'

const MainStyled = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`

const BankCheck = () => {
  const { isPlaidMenu } = usePlaidMenuContext()
  useInitIntercomChat(['plend'])
  return (
    <MainStyled>
      <BankCheckHeader/>
      <TellMeMore bankCheckContent/>
      {isPlaidMenu && <PlaidMenu/>}
    </MainStyled>
  )
}

export default () => (
  <PlaidMenuProvider>
    <BankCheck/>
  </PlaidMenuProvider>
)
