import { FlOAT_VALIDATION, INTEGER_VALIDATION } from '../const/regexes'
import { TStatus } from '../../features/userInfoForm/types'

export const formatToOption = (data: TStatus[]) => data.map(({ id, value }) => ({
  label: value,
  value: id
}))

export const integerFormat = (value: string) => value && value.replace(INTEGER_VALIDATION, '')
export const floatFormat = (value: string) => value && value.replace(FlOAT_VALIDATION, '')
