import Slider, { SliderProps } from '@mui/material/Slider'
import { FC } from 'react'
import styled from 'styled-components/macro'
import CustomSliderThumb from './CustomSliderThumb'

const CustomSliderStyled = styled(Slider)<SliderProps>`
  && {
    color: ${({ theme }) => theme.colors.green400};
    height: 10px;

    & .MuiSlider-thumb {
      background: ${({ theme }) => theme.colors.green400};
      box-shadow: none;
      
      :before {
        box-shadow: none;
      }
    }

    & .MuiSlider-rail {
      color: ${({ theme }) => theme.colors.yellow};
      opacity: 1;
    }

    & .MuiSlider-mark {
      width: 2px;
      height: 7px;
      background: ${({ theme }) => theme.colors.white};
    }

    ${({ theme }) => theme.mixins?.customSliderMixin}
  }
`

const CustomSlider: FC<SliderProps> = (props) => (
  <CustomSliderStyled
    components={{ Thumb: CustomSliderThumb }}
    {...props}
  />
)

export default CustomSlider
