import { useQuery } from 'react-query'
import { TLoanApplicationForNoInterest } from './types'
import { apiClient } from '../../core/api/apiClient'
import { useGoogleAnalyticsMetrics } from '../googleAnalytics/hooks/googleAnalyticsHoooks'
import { TDebtConsolidation } from '../socialCreditLoanPurpose/types'
import { TFormData } from '../SocialCreditPersonalInformation/types'
import { preparePersonalInformation } from './utils/preparePersonalInformation'

export function useLoanApplicationForNoInterestLoanQuery(
  loanApplicationId: number,
  borrowerId: number,
  enabled: boolean
) {
  return useQuery<TLoanApplicationForNoInterest, Error>(
    ['LoanApplicationForNoInterest', loanApplicationId, borrowerId],
    async () => {
      try {
        const response = await apiClient.get<TLoanApplicationForNoInterest>(`/loans/${loanApplicationId}/borrower/${borrowerId}`)

        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      enabled: enabled && !!loanApplicationId && !!borrowerId
    }
  )
}

export function useScoreNoInterestLoanApplicationQuery(
  loanApplicationId: number,
  borrowerId: number,
  loanLength: number,
  loanAmount?: number,
  monthlyRepayment?: number,
  loanPurpose?: string,
  loanProviders?: TDebtConsolidation[],
  personalInformation?: TFormData,
  organizationId?: number
) {
  const {
    sendCymruDeclinedDecisionMetric,
    sendCymruApprovedDecisionMetric
  } = useGoogleAnalyticsMetrics()

  return useQuery<TLoanApplicationForNoInterest, Error>(
    ['scoreNoInterestLoanApplication', loanApplicationId],
    async () => {
      try {
        if (!personalInformation) {
          throw new Error('No personal information')
        }

        const response = await apiClient.post<TLoanApplicationForNoInterest>(
          `/loans/${loanApplicationId}/nils/score`,
          preparePersonalInformation(personalInformation, loanProviders),
          {
            params: {
              borrowerId,
              loanAmount,
              loanLength,
              loanPurpose,
              monthlyRepayment,
              organizationId
            }
          }
        )

        if (response.data.status === 'APPROVED') {
          sendCymruApprovedDecisionMetric()
        } else if (response.data.status === 'DECLINED') {
          sendCymruDeclinedDecisionMetric()
        }

        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      enabled: false
    }
  )
}

export function useValidateUTM(utmSource?: string) {
  return useQuery<boolean, Error>(
    ['ValidateUTM', utmSource],
    async () => {
      try {
        const response = await apiClient(
          '/loans/intermediary/utm-source/validate',
          { params: { utmSource } }
        )
        return response.data
      } catch (e: any) {
        throw new Error(e)
      }
    },
    {
      enabled: !!utmSource
    }
  )
}
