import styled from 'styled-components/macro'
import { memo } from 'react'
import { flex } from '../../../../core/styles/mixins'
import Logo from '../../../../core/assets/logos/robertOwen.png'

const HeaderStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })}
  background: ${({ theme }) => theme.colors.darkBlue50};
  height: 170px;
`

const RobertOwenHeader = () => (
  <HeaderStyled>
    <img src={Logo} alt="robertOwn"/>
  </HeaderStyled>
)

export default memo(RobertOwenHeader)
