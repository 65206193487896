import styled from 'styled-components/macro'
import { Outlet, useSearchParams } from 'react-router-dom'
import React, { ReactNode, useEffect } from 'react'
import DefaultFooter from './footer/DefaultFooter'
import { flex } from '../../../core/styles/mixins'
import { cymruConnectDomains, TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import HouseGreenHeader from './header/HouseGreenHeader'
import RobertOwenHeader from './header/RobertOwenHeader'
import { useLayoutContext, LayoutProvider } from '../state/useLayoutMain'
import CymruFooter from './footer/CymruFooter'
import CymruHeader from './header/CymruHeader'
import { useLinkCorrelationId } from '../../../core/hooks/usePlaidHooks'
import SocialCreditPlendFooter from './footer/SocialCreditPlendFooter'
import SocialCreditPlendHeader from '../../home/components/header/SocialCreditPlendHeader'
import { useIsConnectSocialCreditCommunity } from '../../../core/hooks/useIsConnectSocialCreditCommunity'

const WrapperStyled = styled.div<{isFullSize: boolean}>`
  ${flex({ direction: 'column' })};
  background: ${({ theme }) => theme.colors.violet};
  ${({ theme }) => theme?.mixins && theme.mixins.mainBackgroundMixin};
  height: ${({ isFullSize }) => (isFullSize ? '100%' : 'auto')};
  width: ${({ isFullSize }) => (isFullSize ? '100%' : 'auto')};
`

export const OuterWrapperStyled = styled.div`
  flex: 1 1 auto;
`

const headerMap: { [key in TOrganization]?: ReactNode } = {
  robertowen: <RobertOwenHeader/>,
  makemyhousegreen: <HouseGreenHeader/>,
  socialcreditcymru: <CymruHeader/>,
  socialcreditplend: <SocialCreditPlendHeader />
}

const footerMap: { [key in TOrganization]?: ReactNode } = {
  makemyhousegreen: <DefaultFooter/>,
  plend: <DefaultFooter/>,
  robertowen: <DefaultFooter/>,
  socialcreditcymru: <CymruFooter/>,
  socialcreditplend: <SocialCreditPlendFooter />
}

const connectSocialCreditToSocialCredit = {
  'connect-dev.socialcredit': 'dev.socialcredit',
  'connect-stage.socialcredit': 'stage.socialcredit',
  'connect.socialcredit': 'socialcredit'
}

function useSocialCreditRedirect() {
  const [searchParams] = useSearchParams()
  const source = searchParams.get('utm_source')
  const { hostname } = window.location
  const linkCorrelationId = useLinkCorrelationId()
  const isConnectSocialCredit = useIsConnectSocialCreditCommunity()
  useEffect(() => {
    if (isConnectSocialCredit) {
      return
    }
    const cymruConnectSubdomain = cymruConnectDomains
      .find((subdomain) => hostname.startsWith(subdomain))

    if (cymruConnectSubdomain && !source && !linkCorrelationId) {
      window.location.href = `https://${connectSocialCreditToSocialCredit[cymruConnectSubdomain]}.cymru/`
    }
  }, [])
}

const MainLayout = () => {
  const { currentOrganization } = useOrganizationContext()
  const { isFullSize } = useLayoutContext()
  useSocialCreditRedirect()
  return (
    <WrapperStyled isFullSize={isFullSize}>
      {headerMap[currentOrganization]}
      <OuterWrapperStyled>
        <Outlet/>
      </OuterWrapperStyled>
      {footerMap[currentOrganization]}
    </WrapperStyled>
  )
}

export default () => (
  <LayoutProvider>
    <MainLayout/>
  </LayoutProvider>
)
