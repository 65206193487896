import { memo } from 'react'
import { TextStyled } from '../../ThankYou'

const LendologyThankYouContent = () => (
  <TextStyled>
    Thank you for sharing your information. Your Case Manager will review
    this and contact you within 2 working days to discuss the next steps.
    Any questions in the meantime, give us a shout on
    {' '}
    <a href="mailto:loans@lendology.org.uk ">loans@lendology.org.uk </a>
    or 01823 461099.
  </TextStyled>
)

export default memo(LendologyThankYouContent)
