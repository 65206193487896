/* eslint-disable react/no-unescaped-entities */
import { memo } from 'react'
import { TextStyled } from '../../ThankYou'

const PlendThankTouResubmitContent = () => (
  <>
    <TextStyled>
      Thank you for submitting your data to the Plend Score trial programme.
    </TextStyled>
    <TextStyled>
      With your help, we're one step closer to bringing fairer credit to those
      who need it across the UK. If you have any questions, please just get in
      touch with us at
      {' '}
      <a target="_blank" rel="noreferrer" href="mailto:support@plend.co.uk"> support@plend.co.uk </a>
      .
    </TextStyled>
  </>
)

export default memo(PlendThankTouResubmitContent)
