import React from 'react'
import styled from 'styled-components/macro'
import { CymruLogoIcon } from '../../../../core/components/icons/CymruLogoIcon'

const WrapStyled = styled.div`
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.deepSeaGreen};
`

export default () => (
  <WrapStyled>
    <CymruLogoIcon/>
  </WrapStyled>
)
