import constate from 'constate'
import { useSetState } from 'react-use'

type TState = {
  borrowerId?: number
  publicToken?: string
  loanApplicationId?: number
}

const useMultiStepState = () => {
  const [state, setState] = useSetState<TState>()
  return { state, setState }
}

export const [MultiStepStateProvider, useMultiStepStateContext] = constate(useMultiStepState)
