import styled from 'styled-components/macro'
import { memo } from 'react'
import { useMedia } from 'react-use'
import { flex } from '../../../../core/styles/mixins'
import headerBackgroundImage from '../../../../core/assets/images/makeMyhouseGreenBackground.png'
import { MOBILE_SIZE } from '../../../../core/const/sizes'

const ContainerStyled = styled.div<{height: number}>`
  ${flex({ justify: 'space-between', align: 'flex-end' })} 
  background: ${({ theme }) => theme.colors.darkGreen};
  background:url(${headerBackgroundImage})    no-repeat center center/cover ;
  height: ${({ height }) => height}px;
`

const HouseGreenHeader = () => {
  const isMobile = useMedia(`(max-width: ${MOBILE_SIZE}px)`)

  return <ContainerStyled height={isMobile ? 182 : 300}/>
}

export default memo(HouseGreenHeader)
