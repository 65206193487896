import React, { FC } from 'react'
import CustomLabel from '../../../core/components/CustomLabel'
import CustomInput from '../../../core/components/CustomInput'
import { requiredRule, validateFloat } from '../../../core/utils/formRules'
import CustomSelect, { TOption } from '../../../core/components/CustomSelect'
import { useCymruPersonalInformationContext } from '../state/useCymruPersonalInformationState'
import { prepareOptions } from '../utils/options'
import { floatFormat } from '../../../core/utils/formFormat'
import CustomMultipleSelect from '../../../core/components/CustomMultipleSelect'
import { EmptyFieldStyled } from './EmptyFieldStyled'

function useBenefits() {
  const {
    nilsDictionariesQuery: { data },
    useFormReturn: { watch }
  } = useCymruPersonalInformationContext()
  const yesValue = 'YES'

  return {
    showBenefitsExtraFields: watch('inReceiptOfBenefits') === yesValue,
    showBenefitsOtherField: watch('benefits')?.includes('OTHER'),
    benefitsConditionOptions: [
      {
        label: 'Yes',
        value: yesValue
      },
      {
        label: 'No',
        value: 'NO'
      }
    ] as TOption[],
    benefitsOptions: prepareOptions(data?.BENEFIT)
  }
}

const Benefits: FC = () => {
  const { useFormReturn: { control } } = useCymruPersonalInformationContext()

  const { showBenefitsOtherField, benefitsOptions } = useBenefits()

  return (
    <>
      <CustomSelect
        name="inReceiptOfBenefits"
        placeholder=""
        label={<CustomLabel label="In receipt of benefits *"/>}
        control={control}
        options={useBenefits().benefitsConditionOptions}
      />

      {useBenefits().showBenefitsExtraFields && (
        <CustomMultipleSelect
          name="benefits"
          label={<CustomLabel label="Benefits (up to 4) *"/>}
          control={control}
          itemsLimit={4}
          options={benefitsOptions}
        />
      )}

      {useBenefits().showBenefitsExtraFields
        ? (
          <div>
            <CustomLabel label="Total benefits received £ (monthly) *"/>
            <CustomInput
              name="totalBenefitsReceived"
              control={control}
              handleChange={floatFormat}
              rules={{
                required: requiredRule(),
                validate: validateFloat('Please provide a valid total benefits received value')
              }}
            />
          </div>
        )
        : <EmptyFieldStyled />}

      {useBenefits().showBenefitsExtraFields
        ? showBenefitsOtherField
          ? (
            <div>
              <CustomLabel label="Benefits  - please specify *"/>
              <CustomInput
                name="benefitDescription"
                control={control}
                rules={{
                  required: requiredRule()
                }}
              />
            </div>
          )
          : <EmptyFieldStyled />
        : null}
    </>
  )
}

export default Benefits
