import { FC } from 'react'
import CustomLabel from '../CustomLabel'
import CustomInput from '../CustomInput'
import { maxLengthRule, requiredRule } from '../../utils/formRules'
import { TControls } from '../../../features/userInfoForm/types'

const BuildingName: FC<TControls> = ({ control }) => (
  <div>
    <CustomLabel label="Building Name"/>
    <CustomInput
      name="buildingName"
      control={control}
      rules={{
        maxLength: maxLengthRule(50)
      }}
    />
  </div>
)

export default BuildingName
