/* eslint-disable */
import { TInfoData } from './types'
import LoanTermsTable from './loanTermsTable'
import { useOrganizationContext } from '../../state/useOrganization'

export function useHomePage(){
  const { currentOrganization } = useOrganizationContext()
  const questionDescription = currentOrganization === 'plend' ?
    ` Just use our loan calculator above to select your loan size and length. Then click the
    button to start the process.`
    :
    `Simply select 'Get Quote' above to start the process.
    You will then need to fill out our application form and
    connect via open banking for us to make a lending decision.`

  const additionalInfo: TInfoData[] = [
    {
      id: 1,
      title: 'How do I apply for a Plend loan?',
      description: questionDescription
    },
    {
      id: 2,
      title: 'Am I eligible for a Plend loan?',
      description: <>If you are 21 or over and have had an active UK bank account with open banking access for a minimum
      of 3 months, you can apply for a Plend loan. We will do our best to support you, but approval is dependent on
      several assessment factors after you submit your application.</>
    },
    {
      id: 3,
      title: 'What are the loan terms?',
      description: <LoanTermsTable/>
    },
    {
      id: 4,
      title: 'Can I cancel my loan agreement? Is there a Cooling off period?',
      description: <>You will have a right to withdraw from the Loan Contract without giving any reason and by giving
        verbal or written notice of the withdrawal to us (on behalf of the Lender) within the fourteen (14) calendar days
        starting on the day after you sign it. If you wish to exercise that right to withdraw: You can notify us in
        writing by email at <a href="mailto:customersupport@plend.co.uk">customersupport@plend.co.uk</a> You must repay
        the whole loan amount (including interest charged
        to Plend Limited) within thirty (30) days of providing your notice of cancellation, failing which you will be
        deemed to have changed your mind and will continue your loan term.</>
    },
    {
      id: 5,
      title: 'Will applying for a Plend loan affect my credit score?',
      description: <>It won’t be affected if you ask us for a free quote to see if you qualify, however your credit score
        could be affected when you are approved for a loan.</>
    },
    {
      id: 6,
      title: 'When borrowing money, what are my responsibilities?',
      description: <>You must make your payments on time. We collect repayments using Direct Debit, so you won’t have to
        do anything except make sure there’s enough money in your bank account. You will also need to ensure you have a
        plan in place should your financial circumstances change.</>
    },
    {
      id: 7,
      title: 'What are the risks from borrowing from Plend?',
      description: <>The general risk of borrowing with us is the same as the risk of borrowing from any other provider.
        You will need to consider if the repayments are affordable for you on a monthly basis. You will also need to
        ensure you have a plan in place should your financial circumstances change.</>
    },
    {
      id: 8,
      title: 'Once I have submitted my application is there anything else I need to do?',
      description: <>Our credit team will be in touch if they require any more information from you, but if you have
        already provided this or have not been asked to provide any more information, then nothing else is needed at this
        time.</>
    },
    {
      id: 9,
      title: 'What if my bank provider is not listed on open banking?',
      description: <>Unfortunately we can only provide loans to customers who have a bank with open banking access. Most
        banks allow open banking access, if you are still unsure please check the latest list here - <a
          target="_blank" href="https://www.openbanking.org.uk/faqs/"> https://www.openbanking.org.uk/faqs/</a></>
    },
    {
      id: 10,
      title: 'What is open banking? And why do people use it?',
      description: <>Open banking is a banking practice that provides third-party financial service providers open access
        to consumer banking, transaction, and other financial data from banks and non-bank financial institutions through
        the use of application programming interfaces (APIs). You are in control and provide us with the access through
        the use of Plaid (for more information, click here). Open banking is designed to be safe and secure, it’s
        regulated by the FCA and uses bank-level security. With open banking and it’s data, customers are able to receive
        new offers and products more suited to their financial position right now. Because of this, there are currently
        over 4.5 million regular open banking users globally.</>
    },
    {
      id: 11,
      title: 'Can I use open banking if I don’t use online banking?',
      description: <>No. To use open banking, you need online or mobile banking for your payment account.</>
    },
    {
      id: 12,
      title: 'Is open banking safe?',
      description: <>Open banking has been designed with security at its heart – here’s how: Bank-level security – open
        banking uses rigorously tested software and security systems. You’ll never be asked to give access to your bank
        login details or password to anyone
        other than your own bank or building society. It’s regulated – only companies regulated by the FCA or European
        equivalent can use this technology. You’re in charge – you choose when, and for how long, you give access to your
        data. Extra protection – your bank or building society will pay your money back if fraudulent payments are made.
        You’re also protected by data protection laws and the Financial Ombudsman Service.</>
    },
    {
      id: 13,
      title: 'Is  Plend  Regulated?',
      description: <>Yes, Plend is fully authorised and regulated by the Financial Conduct Authority (FCA) FRN 963328.</>
    },
    {
      id: 14,
      title: 'Who is Plaid?',
      description: <> Plaid is the open banking partner that we use to access your financial information so we can better
        understand your financial situation and offer you a more affordable loan. You can read more about Plaid <a
          target="_blank" href="https://plaid.com/company/">here</a>.</>
    }
  ]

return additionalInfo
}



