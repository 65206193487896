import { TInfoData } from '../../../core/assets/faq/types'

const data: TInfoData[] = [
  {
    id: 1,
    title: 'What if my bank provider is not listed on open banking?',
    description: (
      <>
        Unfortunately we can only provide loans to customers
        who have a bank with open banking access. Most banks
        allow open banking access, if you are still unsure
        please check online.
      </>
    )
  },
  {
    id: 2,
    title: 'What is open banking? and why do people use it?',
    description: (
      <>
        Open banking is a banking practice that provides third-party
        financial service providers open access to consumer banking,
        transaction, and other financial data from banks and non-bank
        financial institutions through the use of application programming
        interfaces (APIs). You are in control and provide us with the
        access through the use of Plaid (for more information, click here).
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Open banking is designed to be safe and secure, it's regulated by
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        the FCA and uses bank-level security. With open banking and it's
        data, customers are able to receive new offers and products more
        suited to their financial position right now. Because of this,
        there are currently over 4.5 million regular open banking users
        globally.
      </>
    )
  },
  {
    id: 3,
    title: 'Can I use open banking if I don’t use online banking?',
    description: (
      <>
        No. To use open banking, you need online or mobile banking for your payment account.
      </>
    )
  },
  {
    id: 4,
    title: 'Is open banking safe?',
    description: (
      <>
        Open banking has been designed with
        security at its heart – here’s how:
        Bank-level security – open banking uses
        rigorously tested software and security
        systems. You’ll never be asked to give
        access to your bank login details or password
        to anyone other than your own bank or building
        society. It’s regulated – only companies regulated
        by the FCA or European equivalent can use this
        technology. You’re in charge – you choose when,
        and for how long, you give access to your data.
        Extra protection – your bank or building society
        will pay your money back if fraudulent payments
        are made. You’re also protected by data protection
        laws and the Financial Ombudsman Service.
      </>
    )
  },
  {
    id: 5,
    title: 'Is the Interest Free Loan, (Powered by Plend) regulated?',
    description: (
      <>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Social Credit CIC trading as ‘Social Credit'
        (referred to herein as Social Credit and by the words
        ‘us’ ‘we’ and ‘our’) is a community interest company
        serviced by Plend Limited which is authorised by the
        Financial Conduct Authority (FRN 963328)
      </>
    )
  },
  {
    id: 6,
    title: 'Who is Plaid?',
    description: (
      <>
        Plaid is the open banking partner that we use to
        access your financial information so we can better
        understand your financial situation and offer you
        a more affordable loan. You can read more about
        Plaid here.
      </>
    )
  }
]

export default data
