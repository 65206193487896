import styled from 'styled-components/macro'
import { BaseButton } from '../../../../core/components/buttons/CustomButton'
import { flex, fontFamily } from '../../../../core/styles/mixins'

export const ContentAgreementStyled = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;

  ${BaseButton} {
    max-width: 100px;
    width: 100%;
    background: ${({ theme }) => theme.colors.orange};
    box-shadow: 0 4px 8px rgba(255, 96, 76, 0.3);
    color: ${({ theme }) => theme.colors.white};
    padding: 12px 20px;
    justify-self: flex-end;
    align-self: flex-end;
    margin-top: 30px;

    &:disabled {
      opacity: 1;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.pink100};
    }

    &:hover {
      background: ${({ theme }) => theme.colors.orange100};
      box-shadow: 0 4px 8px rgba(255, 96, 76, 0.5);
    }
    ${({ theme }) => theme?.mixins?.nextButtonMixin}
    ${({ theme }) => theme?.mixins?.acceptButtonMixin}
  }
`

export const ButtonWrapper = styled.div`
  ${flex({ justify: 'flex-end' })}
`

export const CheckBoxContainer = styled.div`
  ${flex({ justify: 'center', align: 'flex-start', direction: 'column' })}
`

export const FormStyled = styled.form`
  ${flex({ justify: 'center', align: 'flex-start', direction: 'column' })}
`

export const LabelStyled = styled.label<{ marginTop?: boolean }>`
  ${flex({ justify: 'center', align: 'center' })}
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ marginTop }) => (marginTop ? 20 : 0)}px;
  cursor: pointer;
  @media (max-width: 450px) {
    ${flex({ align: 'flex-start' })}
  }
  
  ${({ theme }) => theme?.mixins?.checkboxLabelMixin}
`
