import { FC } from 'react'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { FieldAddressMap, TControls, TPermittedFields } from '../../../../features/userInfoForm/types'
import PostCode, { postCodeFieldName } from './PostCode'
import PostCodeLoqateBase from './PostCodeLoqateBase'

const fieldAddressMap: FieldAddressMap = {
  CountryName: 'locality',
  BuildingName: 'buildingName',
  SubBuilding: 'buildingNo',
  City: 'postTown',
  Street: 'street',
  PostalCode: 'postCode',
  BuildingNumber: 'buildingNo'
}

const organizationPermittedFields: TPermittedFields = {
  plend: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode'],
  makemyhousegreen: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode'],
  purpleshoots: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode'],
  lendology: ['postCode'],
  robertowen: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode'],
  socialcreditcymru: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode'],
  socialcreditplend: ['locality', 'buildingName', 'buildingNo', 'postTown', 'street', 'postCode']
}

type TProps = {
  setValue: UseFormSetValue<any>
} & TControls

const PostCodeLoqate: FC<TProps> = ({ control, setValue }) => (
  <PostCodeLoqateBase
    control={control}
    fieldName={postCodeFieldName}
    setValue={setValue}
    fieldAddressMap={fieldAddressMap}
    organizationPermittedFields={organizationPermittedFields}
    PostCodeComponent={PostCode}
  />
)

export default PostCodeLoqate
