import { UseFormReturn } from 'react-hook-form'
import React, { FC } from 'react'
import FirstName from '../../../../core/components/controls/FirstName'
import LastName from '../../../../core/components/controls/LastName'
import Email from '../../../../core/components/controls/Email'
import DatePicker from '../../../../core/components/controls/DatePicker'
import PrimaryPhoneNumber from '../../../../core/components/controls/PrimaryPhoneNumber'
import SecondaryPhoneNumber from '../../../../core/components/controls/SecondaryPhoneNumber'
import LivingStatusSelect from '../../../../core/components/controls/LivingStatusSelect'
import EmploymentStatusSelect from '../../../../core/components/controls/EmploymentStatusSelect'
import IncomeAnnual from '../../../../core/components/controls/IncomeAnnual'
import { EmptyRowStyled, WrapperStyled } from '../../styled'
import { TUser } from '../../types'
import BuildingNumber from '../../../../core/components/controls/BuildingNumber'
import BuildingName from '../../../../core/components/controls/BuildingName'
import Country from '../../../../core/components/controls/Country'
import Town from '../../../../core/components/controls/Town'
import Street from '../../../../core/components/controls/Street'
import PostCodeLoqate from '../../../../core/components/controls/PostCode/PostCodeLoqate'
import Employer from '../../../../core/components/controls/Employer'
import { useStatusEmployer } from '../../hooks/useStatusEmployer'

type TProps = {
  formMethods: UseFormReturn<Partial<TUser>>
}

const RobertOwenForm: FC<TProps> = ({ formMethods: { control, setValue, watch } }) => {
  const employerShowFlag = useStatusEmployer()

  return (
    <WrapperStyled>
      <FirstName control={control}/>
      <LastName control={control}/>
      <Email control={control}/>
      <DatePicker control={control}/>
      <PostCodeLoqate control={control} setValue={setValue}/>
      <BuildingNumber control={control}/>
      <BuildingName control={control}/>
      <Street control={control}/>
      <Town control={control}/>
      <Country control={control}/>
      <PrimaryPhoneNumber control={control}/>
      <SecondaryPhoneNumber control={control}/>
      <LivingStatusSelect control={control}/>
      <EmploymentStatusSelect control={control}/>
      <IncomeAnnual label="Annual income *" control={control}/>
      {employerShowFlag.includes(watch('employmentStatusId') as number)
        ? (
          <Employer control={control}/>
        )
        : <EmptyRowStyled />}
    </WrapperStyled>
  )
}

export default RobertOwenForm
