import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../core/styles/mixins'

export const RadioWrapper = styled.div`
  @media (min-width: 768px) {
    grid-column:1/3 ;
  }

`

export const RadioButtonStyled = styled.div`
  ${flex({})};
`

export const RadioContentStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })};
  height: 32px;
  margin-right: 30px;
  cursor: default;
`

export const PeriodStyled = styled.div`
  ${fontFamily('Neue')};
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 27px;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.personalDetails?.periodTextMixin}
`
