import { memo } from 'react'
import { TextStyled } from '../../ThankYou'

const CymruThankYouContent = () => (
  <>
    <TextStyled>
      Thanks for applying for a Social Credit Cymru loan!
    </TextStyled>
    <TextStyled>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      You're one step closer to achieving that goal.
    </TextStyled>
    <TextStyled>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      We've received your application and will come back to you shortly with a decision.
    </TextStyled>
    <TextStyled>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      For any questions, please contact
      {' '}
      <a target="_blank" rel="noreferrer" href="mailto:support@socialcredit.cymru">support@socialcredit.cymru</a>
      {' '}
      or visit our FAQ and chat with at
      {' '}
      <a target="_blank" rel="noreferrer" href="https://socialcredit.cymru/">https://socialcredit.cymru/. </a>
    </TextStyled>
    <TextStyled>
      Please note our hours of operation are 9-5, Monday-Friday.
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      If you contact us outside these hours we'll respond when we're back!
    </TextStyled>
    <TextStyled>
      <div>Regards,</div>
      <div>Social Credit Cymru team</div>
    </TextStyled>
  </>
)

export default memo(CymruThankYouContent)
