import React, { memo } from 'react'
import styled from 'styled-components/macro'
import { CymruLogoIcon } from '../../../../core/components/icons/CymruLogoIcon'
import { fontFamily } from '../../../../core/styles/mixins'

const WrapStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.blackDarkBlue};
  ${fontFamily('Neue')};
  min-height: 208px;
  padding: 50px 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 20px;
  }
`

const WrapContainer = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TopContentWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 375px) {
    flex-direction: column;
  }
`

const BottomContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const IconWrapStyled = styled.div`
  width: 175px;
  height: 54px;
  
  svg {
    width: 175px;
    height: 54px;
  }
`

const TextStyled = styled.div`
  margin-left: 28px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;

  @media (max-width: 768px) {
    margin-left: 20px;
  }

  @media (max-width: 375px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

const CopyrightTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  margin-right: 15px;
`

const LinksStyled = styled.div`
  margin-right: 36px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-right: 0;
  }
  
  @media (max-width: 375px) {
    margin-top: 20px;
  }
  
  a {
    color: ${({ theme }) => theme.colors.white};
    
    + * {
      margin-top: 4px;
    }
    
    :hover {
      text-decoration: underline;
    }
  }
`

const LinkStyled = styled.div`
  + * {
    margin-top: 4px;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    
    :hover {
      text-decoration: underline;
    }
  }
`

const CymruFooter = () => (
  <WrapStyled>
    <WrapContainer>
      <TopContentWrap>
        <IconWrapStyled>
          <CymruLogoIcon/>
        </IconWrapStyled>
        <TextStyled>
          Social Credit (Cymru) is unincorporated association representing Robert
          Owen Community Banking Fund Limited which is authorised by the Financial
          Conduct Authority (FRN 726281), Purple Shoots Business Lending Limited
          which is authorised by the Financial Conduct Authority (FRN 720894)
          and Plend Limited which is authorised by the Financial Conduct Authority (FRN 963328)
        </TextStyled>
      </TopContentWrap>
      <BottomContentWrap>
        <CopyrightTextStyled>
          {`© Social Credit Cymru ${new Date().getFullYear()}. All rights reserved.`}
        </CopyrightTextStyled>

        <LinksStyled>
          <LinkStyled>
            <a href="/privacy-policy">Privacy Policy</a>
          </LinkStyled>
          <LinkStyled>
            <a href="/terms-conditions">Terms and Conditions</a>
          </LinkStyled>
        </LinksStyled>
      </BottomContentWrap>
    </WrapContainer>
  </WrapStyled>
)
export default memo(CymruFooter)
