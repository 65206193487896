import { css } from 'styled-components/macro'

type TFlexProps = {
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  align?: 'start' | 'end' | 'center' | 'flex-start' | 'flex-end' | 'left' | 'right' | 'baseline'
  direction?: 'column'
}

type TFontProps = 'Mongoose' | 'Neue' | 'Work Sans' | 'Open Sans' | 'Neue Haas Grotesk Display Pro' | 'League Spartan'

export const flex = ({ direction, justify, align }: TFlexProps) => css`
  display: flex;
  justify-content: ${justify || 'normal'};
  align-items: ${align || 'normal'};
  flex-direction: ${direction || 'row'};
`

export const fontFamily = (fontFamily: TFontProps, important = false) => css`
  font-family: ${fontFamily}, sans-serif ${important ? '!important' : undefined};
`
