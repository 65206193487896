import styled, { css } from 'styled-components/macro'
import { fontFamily } from '../../../../../core/styles/mixins'
import { CheckBoxContainer, LabelStyled } from '../styled'

export const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 50px;
  }
  
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
`

export const ContentStyled = styled.div`
  max-width: 996px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const TitleStyled = styled.div`
  width: 100%;
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.stepper?.stepperTitleMixin}
`

export const SubTitleStyled = styled.div<{ noMargin?: boolean }>`
  ${fontFamily('Open Sans')};
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

export const CardStyled = styled.div<{marginTop?: number}>`
  background: ${({ theme }) => theme.colors.white};
  margin-top: ${({ marginTop }) => marginTop || 50}px;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  max-height: 594px;
  overflow: auto;
`

export const TextStyled = styled.div<{ noMargin?: boolean, italic?: boolean, flexColumn?:boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
    
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  ${({ italic }) => italic && 'font-style: italic'};

  ${({ flexColumn }) => flexColumn && css`
      display: flex;
      gap: 4px;
      flex-direction: column;
  `}  
`

export const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.linkBlue};
`

export const CheckboxesTitleStyled = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.white};
`

export const AfterTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  margin-top: 30px;
`

export const CustomCheckBoxContainerStyled = styled(CheckBoxContainer)`
  width: 100%;
  margin-top: 30px;
`

export const CustomLabelStyledStyled = styled(LabelStyled)`
  ${fontFamily('Open Sans')};
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

export const ListStyled = styled.ul<{ numbers?: boolean }>`
  list-style-type: ${({ numbers }) => (numbers ? 'auto' : 'disc')};
  padding-left: 25px;
`

export const BoldTextStyled = styled.div<{ noMargin?: boolean, inline?: boolean }>`
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  ${fontFamily('Open Sans')};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`
