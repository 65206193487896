/* eslint-disable react/no-unescaped-entities */
import { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { TextStyled } from '../../ThankYou'

type TLocationState = {
  state: {
    isLinkExpired?: boolean
  }
}

const PlendThankYouContent = () => {
  const { state } = useLocation() as TLocationState
  const isLinkExpiredState = state?.isLinkExpired
  if (isLinkExpiredState) {
    return (
      <>
        <TextStyled>
          Thanks for applying for a Plend loan!
        </TextStyled>
        <TextStyled>
          It looks like you have already connected
          your bank account using this link and it has expired.
        </TextStyled>
        <TextStyled>
          We will come back to you shortly with a decision.
        </TextStyled>
        <TextStyled>
          For any questions, please contact
          <a target="_blank" rel="noreferrer" href="mailto:support@plend.co.uk"> support@plend.co.uk </a>
          {' '}
          or visit our
          FAQ and chat with at
          {' '}
          <a
            target="_blank"
            href="https://www.plend.co.uk/borrow/"
            rel="noreferrer">
            www.plend.co.uk/borrow/.
          </a>
        </TextStyled>
      </>
    )
  }
  return (
    <>
      <TextStyled>
        Thanks for applying for a Plend loan!
      </TextStyled>
      <TextStyled>
        You're one step closer to achieving that goal.
      </TextStyled>
      <TextStyled>
        We've received your application and will come back to you shortly with a decision.
      </TextStyled>
      <TextStyled>
        For any questions, please contact
        <a target="_blank" rel="noreferrer" href="mailto:support@plend.co.uk"> support@plend.co.uk </a>
        {' '}
        or visit our
        FAQ and chat with at
        {' '}
        <a
          target="_blank"
          href="https://www.plend.co.uk/borrow/"
          rel="noreferrer">
          www.plend.co.uk/borrow/.
        </a>
      </TextStyled>
      <TextStyled>
        Please note our hours of operation are 9-5, Monday-Friday.
        If you contact us outside these hours we'll respond when we're back!
      </TextStyled>
      <TextStyled>
        <div>Regards,</div>
        <div>Plend team</div>
      </TextStyled>
    </>
  )
}

export default memo(PlendThankYouContent)
