import { useCallback, useEffect } from 'react'
import { PlaidLinkOnExit, PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'
import { useMultiStepStateContext } from '../../userInfoForm/state/useToken'
import { useCreateTokenQuery, useLinkCorrelation, useLoanerMutation } from '../queries'
import { usePlaidMenuContext } from '../state/usePlaidMenuContext'
import { useGoogleAnalyticsMetrics } from '../../googleAnalytics/hooks/googleAnalyticsHoooks'
import { useLinkCorrelationId } from '../../../core/hooks/usePlaidHooks'

function useCreateToken() {
  const { setState } = useMultiStepStateContext()
  const { token, ...rest } = useCreateTokenQuery()

  useEffect(() => {
    if (token) {
      setState({
        publicToken: token
      })
    }
  }, [token])

  return {
    token,
    ...rest
  }
}

const PlaidMenu = () => {
  const linkCorrelationId = useLinkCorrelationId()
  const { setIsPlaidMenu } = usePlaidMenuContext()
  const navigate = useNavigate()
  const { mutateAsync: loanerMutationAsync } = useLoanerMutation()
  const { isSuccess, token } = useCreateToken()
  const { sendSuccessfullyConnectingBankAccountMetric } = useGoogleAnalyticsMetrics()
  const { data: linkCorrelationData } = useLinkCorrelation()
  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (tokenUser) => {
    sendSuccessfullyConnectingBankAccountMetric()
    if (!linkCorrelationId) {
      await loanerMutationAsync({ public_token: tokenUser })
    }

    navigate('/accounts')
  }, [linkCorrelationId])

  const onExit = useCallback<PlaidLinkOnExit>(() => setIsPlaidMenu(false), [])
  const { open, ready } = usePlaidLink({
    token: linkCorrelationData?.token || token || '',
    receivedRedirectUri: linkCorrelationId ? window.location.href : undefined,
    onSuccess,
    onExit
  })

  useEffect(() => {
    if (!ready) {
      return
    }
    open()
  }, [ready, open])

  if (!isSuccess) {
    return null
  }

  return null
}
export default PlaidMenu
