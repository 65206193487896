import styled from 'styled-components/macro'
import { memo } from 'react'
import { fontFamily } from '../../../../../core/styles/mixins'

const ContainerStyled = styled.div`
  ${fontFamily('Open Sans')};
  color: ${({ theme }) => theme.colors.blackDarkBlue};
`

const TitleStyled = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  margin-bottom: 20px;
`

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
`

const RobertOwenThankYouContent = () => (
  <ContainerStyled>
    <TitleStyled>Thank You</TitleStyled>
    <TextStyled>
      We’ve received your application and will be back with
      you as soon as we’ve completed a few checks.
    </TextStyled>
  </ContainerStyled>
)
export default memo(RobertOwenThankYouContent)
