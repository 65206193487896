import React, { FC, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useSearchParams } from 'react-router-dom'
import { fontFamily } from '../../../core/styles/mixins'
import CustomLabel, { LabelStyled } from '../../../core/components/CustomLabel'
import CustomInput from '../../../core/components/CustomInput'
import { requiredRule, validateMaxNumber } from '../../../core/utils/formRules'
import CustomSelect, { TOption } from '../../../core/components/CustomSelect'
import { CymruPersonalInformationProvider, useCymruPersonalInformationContext } from '../state/useCymruPersonalInformationState'
import { TCymruLoanMutationData, TFormData } from '../types'
import { integerFormat } from '../../../core/utils/formFormat'
import { SocialCreditStepperButton } from '../../../core/components/buttons/SocialCreditStepperButton'
import { prepareOptions } from '../utils/options'
import Benefits from './Benefits'
import Errors from './Errors'
import { useSocialCreditLoanCalculatorContext } from '../../loanCalculator/state/useSocialCreditLoanCalculatorState'
import { useStepperContext } from '../../../core/components/stepper/StepperContext'
import { EmptyFieldStyled } from './EmptyFieldStyled'
import { useCymruPartnerLoanerMutation, useSaveBorrowerPersonalInformationMutation } from '../querries'
import { useOrganizationId } from '../../userInfoForm/hooks/useOrganizationId'
import { useGetLoanLocaleStorage } from '../../userInfoForm/components/UserInfo'
import { preparePersonalInformation } from '../../loanCalculator/utils/preparePersonalInformation'
import { useLoanerMutation } from '../../userInfoForm/queries'
import { useMultiStepStateContext } from '../../userInfoForm/state/useToken'
import { useIsConnectSocialCreditCommunity } from '../../../core/hooks/useIsConnectSocialCreditCommunity'

const WrapperStyled = styled.div`
  margin-top: 30px;
  max-width: 1000px;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  position: relative;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }

  &&& {
    .MuiFormControl-root, .MuiSelect-select, .MuiPaper-root {
      border-radius: 0;
    }
  }
`

const TextStyled = styled.div`
  grid-column: 1 / 3;
  ${fontFamily('Open Sans')};
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  
  p + p {
    margin-top: 12px;
  }

  ${({ theme }) => theme.mixins?.stepper?.textFontMixin}
`

const WrapStyled = styled.div`
  background: ${({ theme }) => theme.colors.deepSeaGreen};
  padding: 50px 20px;
  ${fontFamily('Open Sans')};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    padding: 30px 10px 50px;
  }
  
  ${LabelStyled} {
    ${fontFamily('Open Sans')};
    height: auto;
    font-size: 22px;
  }
  ${({ theme }) => theme.mixins?.mainBackgroundMixin}
  ${({ theme }) => theme.mixins?.stepper?.labelsMixin}
`

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const TitleStyled = styled.div`
  width: 100%;
  ${fontFamily('Open Sans')};
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins?.stepper?.stepperTitleMixin}
`

const ButtonWrapStyled = styled.ul`
  display: flex;
  justify-content: flex-end;
`

function useContinuousEducationStatus() {
  const {
    useFormReturn: { watch },
    nilsDictionariesQuery: { data }
  } = useCymruPersonalInformationContext()
  const value = watch('education')

  return {
    showAgeContinuousEducationCompleteField: value === 'COMPLETED_EDUCATION',
    options: prepareOptions(data?.EDUCATION)
  }
}

function useReligion() {
  const {
    useFormReturn: { watch },
    nilsDictionariesQuery: { data }
  } = useCymruPersonalInformationContext()
  const value = watch('religion')

  return {
    showOtherField: value === 'OTHER',
    options: prepareOptions(data?.RELIGION)
  }
}

function useSexualOrientation() {
  const {
    useFormReturn: { watch },
    nilsDictionariesQuery: { data }
  } = useCymruPersonalInformationContext()
  const value = watch('orientation')

  return {
    showOtherField: value === 'OTHER',
    options: prepareOptions(data?.ORIENTATION)
  }
}

function useMaritalStatusOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.MARITAL_STATUS)
}

function useDisabilityOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.DISABILITY)
}

function useCaringResponsibilitiesOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.CARING)
}

function useGenderOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.GENDER)
}

function useEthnicityOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.ETHNICITY)
}

function useQualificationOptions(): TOption[] {
  const { nilsDictionariesQuery: { data } } = useCymruPersonalInformationContext()
  return prepareOptions(data?.QUALIFICATION)
}

const SCC_SOURCE = 'SCC'

function usePrepareSocialOpenConnectMutationData() {
  const {
    loanPurpose,
    debtConsolidation
  } = useSocialCreditLoanCalculatorContext()
  const loanValues = useGetLoanLocaleStorage()
  const organizationId = useOrganizationId()
  const { state } = useMultiStepStateContext()

  return {
    cymuSocialOpenConnectLoanerData: useMemo(() => ({
      loaner: {
        borrowerId: Number(state.borrowerId || ''),
        interestRate: 0,
        loanLength: Number(loanValues?.period || 0),
        loanAmount: Number(loanValues?.loanAmount || 0),
        monthlyRepayment: loanValues?.monthlyPaymentCalc.toString() || '',
        loanPurpose: loanPurpose || '',
        source: SCC_SOURCE
      }
    }), [organizationId, loanValues]),
    cymuSocialOpenConnectPersonalInformation: useCallback(
      (data: TFormData) => preparePersonalInformation(data, debtConsolidation),
      [debtConsolidation]
    )
  }
}

function usePreparePartnerLoanerMutationData() {
  const {
    loanPurpose,
    debtConsolidation
  } = useSocialCreditLoanCalculatorContext()
  const [searchParams] = useSearchParams()
  const organizationId = useOrganizationId()
  const loanValues = useGetLoanLocaleStorage()
  const { state } = useMultiStepStateContext()
  const stateBorrowerId = state?.borrowerId?.toString() ?? ''
  return useCallback((data: TFormData): TCymruLoanMutationData => ({
    params: {
      borrowerId: searchParams.get('borrowerId') || stateBorrowerId || '',
      loanPurpose: loanPurpose!,
      loanLength: loanValues?.period.toString() || '',
      loanAmount: loanValues?.loanAmount.toString() || '',
      monthlyRepayment: loanValues?.monthlyPaymentCalc.toString() || '',
      organizationId: organizationId.toString()
    },
    data: preparePersonalInformation(data, debtConsolidation)
  }), [organizationId, loanValues, loanPurpose, debtConsolidation])
}

function useSubmit() {
  const {
    setPersonalInformation,
    isCymruPartner,
    isSocialOpenConnect
  } = useSocialCreditLoanCalculatorContext()
  const { nextStep } = useStepperContext()
  const isConnectSocialCreditCommunity = useIsConnectSocialCreditCommunity()

  const cymruPartnerLoanerMutation = useCymruPartnerLoanerMutation()
  const { mutateAsync: cymruParnerLoanerMutateAsync } = cymruPartnerLoanerMutation

  const cymruSocialOpenConnectLoanerMutation = useLoanerMutation(true)
  const {
    mutateAsync: cymruSocialOpenConnectLoanerMutateAsync
  } = cymruSocialOpenConnectLoanerMutation

  const borrowerPersonalInformationMutation = useSaveBorrowerPersonalInformationMutation()
  const preparePartnerLoanerMutationData = usePreparePartnerLoanerMutationData()
  const {
    cymuSocialOpenConnectLoanerData,
    cymuSocialOpenConnectPersonalInformation
  } = usePrepareSocialOpenConnectMutationData()

  const [searchParams] = useSearchParams()
  const source = searchParams.get('utm_source') || undefined

  return {
    onSubmit: useCallback(async (data: TFormData) => {
      if (isCymruPartner) {
        await cymruParnerLoanerMutateAsync(preparePartnerLoanerMutationData(data))
        return
      }
      if (isSocialOpenConnect || isConnectSocialCreditCommunity) {
        const {
          id: loanApplicationId
        } = await cymruSocialOpenConnectLoanerMutateAsync({
          loaner: {
            ...cymuSocialOpenConnectLoanerData.loaner,
            source: source || cymuSocialOpenConnectLoanerData.loaner.source }
        })
        await borrowerPersonalInformationMutation.mutateAsync({
          borrowerInformation: cymuSocialOpenConnectPersonalInformation(data),
          loanApplicationId
        })
        return
      }

      setPersonalInformation(data)
      nextStep()
    }, []),
    cymruLoanerMutation: isSocialOpenConnect
      ? cymruSocialOpenConnectLoanerMutation
      : cymruPartnerLoanerMutation,
    borrowerPersonalInformationMutation
  }
}

const SocialCreditPersonalInformation: FC = () => {
  const { useFormReturn: { control, handleSubmit } } = useCymruPersonalInformationContext()

  const showSexualOrientationOtherField = useSexualOrientation().showOtherField
  const showReligionOtherField = useReligion().showOtherField
  const { onSubmit, cymruLoanerMutation, borrowerPersonalInformationMutation } = useSubmit()
  return (
    <WrapStyled>
      <ContentStyled>
        <TitleStyled>
          Personal information
        </TitleStyled>

        <WrapperStyled>
          <TextStyled>
            <p>
              We understand that some of these questions might feel a
              bit personal, and you may wonder why we’re asking them.
              We want to assure you that none of this information is used
              to determine your eligibility for a loan.
            </p>
            <p>
              Our purpose is to make financial services more inclusive,
              and to know if we’re really achieving that goal, we need
              to understand the demographics of our applicants. This
              information helps us to better understand if we’re making
              a difference in the lives of people who might not have easy
              access to financial services.
            </p>
          </TextStyled>

          <CustomSelect
            name="maritalStatus"
            label={<CustomLabel label="Marital status *"/>}
            placeholder=""
            control={control}
            options={useMaritalStatusOptions()}
          />

          <div>
            <CustomLabel label="Number of dependants *"/>
            <CustomInput
              name="numberOfDependant"
              control={control}
              handleChange={integerFormat}
              placeholder=""
              rules={{
                required: requiredRule(),
                validate: validateMaxNumber(100, 'Please provide a valid number of dependants value')
              }}
            />
          </div>

          <CustomSelect
            name="gender"
            placeholder=""
            label={<CustomLabel label="Gender *"/>}
            control={control}
            options={useGenderOptions()}
          />

          <CustomSelect
            name="ethnicity"
            placeholder=""
            label={<CustomLabel label="Ethnicity *"/>}
            control={control}
            options={useEthnicityOptions()}
          />

          <CustomSelect
            name="orientation"
            placeholder=""
            label={<CustomLabel label="Sexual Orientation *"/>}
            control={control}
            options={useSexualOrientation().options}
          />

          <CustomSelect
            name="religion"
            placeholder=""
            label={<CustomLabel label="Religion *"/>}
            control={control}
            options={useReligion().options}
          />

          {(showSexualOrientationOtherField || showReligionOtherField) && (
            <>
              {showSexualOrientationOtherField
                ? (
                  <div>
                    <CustomLabel label="Sexual Orientation - please specify *"/>
                    <CustomInput
                      name="orientationDescription"
                      control={control}
                      rules={{
                        required: requiredRule()
                      }}
                    />
                  </div>
                )
                : <EmptyFieldStyled />}

              {showReligionOtherField
                ? (
                  <div>
                    <CustomLabel label="Religion - please specify *"/>
                    <CustomInput
                      name="religionDescription"
                      control={control}
                      rules={{
                        required: requiredRule()
                      }}
                    />
                  </div>
                )
                : <EmptyFieldStyled />}
            </>
          )}

          <CustomSelect
            name="education"
            placeholder=""
            label={<CustomLabel label="What is your continuous full-time education status? *"/>}
            control={control}
            options={useContinuousEducationStatus().options}
          />

          <CustomSelect
            name="qualification"
            placeholder=""
            label={<CustomLabel label="Highest Qualification *"/>}
            control={control}
            options={useQualificationOptions()}
          />

          {useContinuousEducationStatus().showAgeContinuousEducationCompleteField
            ? (
              <>
                <div>
                  <CustomLabel label="At what age did you complete continuous full-time education? *"/>
                  <CustomInput
                    name="educationCompleteAge"
                    control={control}
                    handleChange={integerFormat}
                    rules={{
                      required: requiredRule(),
                      validate: validateMaxNumber(100, 'Please provide a valid education complete age value')
                    }}
                  />
                </div>
                <div />
              </>
            )
            : null}

          <div>
            <CustomLabel label="How many years of work experience do you have? *"/>
            <CustomInput
              name="workExperience"
              control={control}
              handleChange={integerFormat}
              rules={{
                required: requiredRule(),
                validate: validateMaxNumber(100, 'Please provide a valid work experience value')
              }}
            />
          </div>

          <CustomSelect
            name="disability"
            placeholder=""
            label={<CustomLabel label="Disabilities *"/>}
            control={control}
            options={useDisabilityOptions()}
          />

          <CustomSelect
            name="caringResponsibility"
            placeholder=""
            label={<CustomLabel label="Caring Responsibilities *"/>}
            control={control}
            options={useCaringResponsibilitiesOptions()}
          />

          <Benefits />
        </WrapperStyled>

        <Errors />

        <ButtonWrapStyled>
          <SocialCreditStepperButton
            key="CymruPersonalInformation"
            text="Continue"
            disabled={cymruLoanerMutation.isLoading
              || borrowerPersonalInformationMutation.isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonWrapStyled>
      </ContentStyled>
    </WrapStyled>
  )
}

export default () => (
  <CymruPersonalInformationProvider>
    <SocialCreditPersonalInformation />
  </CymruPersonalInformationProvider>
)
