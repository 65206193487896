import { useMemo } from 'react'

export function useIsConnectSocialCreditCommunity() {
  return useMemo(() => {
    const { hostname } = window.location

    return hostname.startsWith('connect.socialcredit.community')
      || hostname.startsWith('connect-dev.socialcredit.community')
      || hostname.startsWith('connect-stage.socialcredit.community')
  }, [])
}
