/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components/macro'
import { FC, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { TPendingLoadProps, TState } from '../../../types'

const TextStyled = styled.div`
  margin-top: 30px;
`

const PendingLoan: FC<TPendingLoadProps> = ({ orgName, url, teamName }) => {
  const { state } = useLocation() as TState
  const organizationName = orgName || 'Plend'
  const organizationUrl = url || 'https://plend.co.uk/borrow/'
  const organizationTeamName = teamName || 'Team Plend'
  return (
    <>
      <TextStyled>
        <div>
          Thank you for your recent interest in a
          {' '}
          {organizationName}
          {' '}
          loan. Unfortunately,
          it doesn't look like your previous submission was completed.
        </div>
      </TextStyled>
      <TextStyled>
        Please
        {' '}
        <a href={state?.pendingLoanUrl}>
          click here
        </a>
        {' '}
        to finish your previous application.
      </TextStyled>
      <TextStyled>
        If you're unsure about the process or want some more information about open-banking,
        please read our borrower FAQs at
        {' '}
        <a href={organizationUrl}>{organizationUrl}</a>
      </TextStyled>
      <TextStyled>
        <p>Regards,</p>
        <p>{organizationTeamName}</p>
      </TextStyled>
    </>
  )
}

export default memo(PendingLoan)
