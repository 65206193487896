import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components/macro'
import { flex } from '../../../core/styles/mixins'
import { TOrganization, useOrganizationContext } from '../../../core/state/useOrganization'
import MakeMyHouseGreenHeader from './header/MakeMyHouseGreenHeader'
import RobertOwenContent from './content/RobertOwenContent'
import DefaultContent from './content/DefaultContent'
import PlendHeader from './header/PlendHeader'
import { useGoogleAnalyticsMetrics } from '../../googleAnalytics/hooks/googleAnalyticsHoooks'
import SocialCreditContent from './content/SocialCreditContent'

const ContainerStyled = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center' })};
  overflow-x: hidden;
`

const organizationHeader: { [key in TOrganization]?: ReactNode } = {
  plend: <PlendHeader />,
  makemyhousegreen: <MakeMyHouseGreenHeader/>
}

const organizationContent: { [key in TOrganization]?: ReactNode } = {
  robertowen: <RobertOwenContent/>,
  socialcreditcymru: <SocialCreditContent/>,
  socialcreditplend: <SocialCreditContent/>
}

function useSendGoogleAnalyticsMetric() {
  const { sendLandingOnFirstPageMetric } = useGoogleAnalyticsMetrics()

  useEffect(() => {
    setTimeout(() => {
      sendLandingOnFirstPageMetric()
    })
  }, [])
}

const Home = () => {
  const { currentOrganization } = useOrganizationContext()
  useSendGoogleAnalyticsMetric()

  return (
    <ContainerStyled>
      {organizationHeader[currentOrganization]}
      {organizationContent[currentOrganization] || <DefaultContent />}
    </ContainerStyled>
  )
}

export default Home
