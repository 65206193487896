import React, { FC, useMemo } from 'react'
import { useEmploymentStatusQuery } from '../../../features/userInfoForm/queries'
import { formatToOption } from '../../utils/formFormat'
import CustomSelect from '../CustomSelect'
import CustomLabel from '../CustomLabel'
import { TControls } from '../../../features/userInfoForm/types'

const EmploymentStatusSelect: FC<TControls> = ({ control }) => {
  const { data: employmentStatus } = useEmploymentStatusQuery()
  const employmentStatusOption = useMemo(
    () => (employmentStatus ? formatToOption(employmentStatus) : []),
    [employmentStatus]
  )
  return (
    <CustomSelect
      name="employmentStatusId"
      label={<CustomLabel label="Employment Status *"/>}
      control={control}
      options={employmentStatusOption}
    />
  )
}
export default EmploymentStatusSelect
