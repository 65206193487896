import { useQuery } from 'react-query'
import { apiClient } from '../../core/api/apiClient'
import { TUtmIntermediary } from './types'

enum QueryKey {
  UtmIntermediary = 'ResubmitAddress'
}

async function utmIntermediary(utmSource: string): Promise<TUtmIntermediary> {
  try {
    const response = await apiClient.get(`/loans/intermediary/utm-source?utmSource=${utmSource}`)
    return response.data
  } catch (err: any) {
    throw new Error(err)
  }
}

export function useUtmIntermediaryQuery(utmSource?: string) {
  return useQuery<TUtmIntermediary, Error>(
    [QueryKey.UtmIntermediary],
    () => utmIntermediary(utmSource!),
    {
      enabled: !!utmSource
    }
  )
}
