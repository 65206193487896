import { RegisterOptions, ValidateResult } from 'react-hook-form'
import { differenceInYears } from 'date-fns'
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../const/regexes'

export const requiredRule: (message?: string) => RegisterOptions['required'] = (message?: string) => message || 'Please fill out all mandatory fields'

export const validateFloat = (message?: string) => (value: string) => {
  const regex = /^[0-9]+([.,][0-9]{1,2})?$/
  if (!regex.test(value)) {
    return message || 'Please enter a valid number with up to 2 decimal places.'
  }
  return true
}

export const validateMaxNumber = (num: number, message?: string) => (value: any) => {
  if (Number(value) > num) {
    return message || `Value cannot exceed ${num}.`
  }
  return true
}

export const minLengthRule: (num: number, msg?: string) => RegisterOptions['minLength'] = (num, msg) => ({
  value: num,
  message: msg || `Length should be more than ${num}`
})

export const maxLengthRule: (num: number, msg?: string) => RegisterOptions['maxLength'] = (num, msg) => ({
  value: num,
  message: msg || `Length should be less than ${num}`
})

export const minRule: (num: number, msg: string) => RegisterOptions['min'] = (num, msg) => ({
  value: num,
  message: msg
})

export const maxRule: (num: number, msg: string) => RegisterOptions['max'] = (num, msg: string) => ({
  value: num,
  message: msg
})

export const emailValidation: RegisterOptions['validate'] = (email: string): ValidateResult => EMAIL_VALIDATION.test(email) || 'Incorrect email format'

export const passwordValidation: RegisterOptions['validate'] = (password: string): ValidateResult => PASSWORD_VALIDATION.test(password)

export const dateValidation: RegisterOptions['validate'] = (value: Date): ValidateResult => {
  const result = differenceInYears(new Date(), value && new Date(value))

  return (!(result >= 18) && ' Please provide valid date of birth value') || result >= 18
}
