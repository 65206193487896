import { UseFormReturn, useWatch } from 'react-hook-form'
import React, { FC, useState } from 'react'
import {
  ContentStyled, EmptyRowStyled,
  PeriodStyled,
  RadioButtonContainer, RadioButtonStyled,
  RadioWrapperStyled, SecondAddressStyled,
  SelectContainerStyled,
  StatusWrapperStyled,
  WrapperStyled
} from '../../styled'
import FirstName from '../../../../core/components/controls/FirstName'
import LastName from '../../../../core/components/controls/LastName'
import Email from '../../../../core/components/controls/Email'
import DatePicker from '../../../../core/components/controls/DatePicker'
import EmploymentLength from '../../../../core/components/controls/EmploymentLength'
import IncomeAnnual from '../../../../core/components/controls/IncomeAnnual'
import BuildingName from '../../../../core/components/controls/BuildingName'
import BuildingNumber from '../../../../core/components/controls/BuildingNumber'
import Country from '../../../../core/components/controls/Country'
import Town from '../../../../core/components/controls/Town'
import LivingStatusSelect from '../../../../core/components/controls/LivingStatusSelect'
import EmploymentStatusSelect from '../../../../core/components/controls/EmploymentStatusSelect'
import Employer from '../../../../core/components/controls/Employer'
import LoanPurposeSelect, {
  debtConsolidationLoanPurpose,
  otherLoanPurpose
} from '../../../../core/components/controls/LoanPurposeSelect'
import LoanSize from '../../../../core/components/controls/LoanSize'
import LoanLength from '../../../../core/components/controls/LoanLength'
import LoanPurposeDescription from '../../../../core/components/controls/LoanPurposeDescription'
import CheckBox from '../../../../core/components/controls/CheckBox'
import CustomLabel from '../../../../core/components/CustomLabel'
import CustomRadioButton from '../../../../core/components/CustomRadioButton'
import { RadioIcon } from '../../../../core/components/icons/RadioIcon'
import PrevBuildingNumber from '../../../../core/components/controls/PrevBuildingNumber'
import PrevBuildingName from '../../../../core/components/controls/PrevBuildingName'
import PrevStreet from '../../../../core/components/controls/PrevStreet'
import PrevTown from '../../../../core/components/controls/PrevTown'
import PrevCountry from '../../../../core/components/controls/PrevCountry'
import Street from '../../../../core/components/controls/Street'
import PostCodeLoqate from '../../../../core/components/controls/PostCode/PostCodeLoqate'
import PrevPostCodeLoqate from '../../../../core/components/controls/PostCode/PrevPostCodeLoqate'
import PrimaryPhoneNumber from '../../../../core/components/controls/PrimaryPhoneNumber'
import SecondaryPhoneNumber from '../../../../core/components/controls/SecondaryPhoneNumber'
import { TUser } from '../../types'
import { useReconnectingContext } from '../../../reconnecting/state/useReconnectingState'
import { useStatusEmployer } from '../../hooks/useStatusEmployer'

type TProps = {
  formMethods: UseFormReturn<Partial<TUser>>
}

const PlendForm: FC<TProps> = ({ formMethods: { control, setValue, getValues, watch } }) => {
  const [isSelectedValue, setIsSelectedValue] = useState(!getValues().prevPostCode)
  const loanPurpose = useWatch({ control, name: 'loanPurpose' })
  const employerShowFlag = useStatusEmployer()
  const { isReconnectingProcess } = useReconnectingContext()

  const handleChange = () => {
    setIsSelectedValue(!isSelectedValue)
  }

  return (
    <>
      <WrapperStyled>
        <FirstName disabled={isReconnectingProcess} control={control}/>
        <LastName disabled={isReconnectingProcess} control={control}/>
        <Email disabled={isReconnectingProcess} control={control}/>
        <DatePicker disabled={isReconnectingProcess} control={control}/>
        <EmploymentLength control={control}/>
        <PostCodeLoqate control={control} setValue={setValue}/>
        <IncomeAnnual control={control}/>
        <BuildingNumber control={control}/>
        <BuildingName control={control}/>
        <Street control={control}/>
        <Town control={control}/>
        <Country control={control}/>
      </WrapperStyled>
      <RadioButtonContainer>
        <CustomLabel label="Length of time at Address *"/>
        <RadioButtonStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-short"
              checked={!isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>0-1 years</PeriodStyled>
          </RadioWrapperStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-long"
              checked={isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>1+ years</PeriodStyled>
          </RadioWrapperStyled>
        </RadioButtonStyled>
      </RadioButtonContainer>
      <ContentStyled marginBottom={30}>
        <PrimaryPhoneNumber control={control}/>
        <SecondaryPhoneNumber control={control}/>
      </ContentStyled>
      {!isSelectedValue
        && (
          <SecondAddressStyled>
            <PrevPostCodeLoqate control={control} setValue={setValue}/>
            <PrevBuildingNumber control={control}/>
            <PrevBuildingName control={control}/>
            <PrevStreet control={control}/>
            <PrevTown control={control}/>
            <PrevCountry control={control}/>
          </SecondAddressStyled>
        )}
      <StatusWrapperStyled>
        <SelectContainerStyled>
          <LivingStatusSelect control={control}/>
          <EmploymentStatusSelect control={control}/>
        </SelectContainerStyled>
      </StatusWrapperStyled>
      <ContentStyled>
        {employerShowFlag.includes(watch('employmentStatusId') as number)
          ? (
            <Employer control={control}/>
          )
          : <EmptyRowStyled />}
        {!isReconnectingProcess && <LoanPurposeSelect control={control}/>}
        {loanPurpose === debtConsolidationLoanPurpose && (
          <LoanPurposeDescription
            label="Debt consolidation description *"
            placeholder="Please list the debts you intend to consolidate with your Plend loan"
            control={control}/>
        )}
        {loanPurpose === otherLoanPurpose && (
          <LoanPurposeDescription
            label="Other (please specify) *"
            placeholder="Please specify what you want to use the Plend loan for"
            control={control}/>
        )}
        <LoanSize control={control}/>
        <LoanLength control={control}/>
      </ContentStyled>
      <CheckBox control={control}/>
    </>
  )
}
export default PlendForm
