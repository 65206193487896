import { Control, Controller } from 'react-hook-form'
import { FC, useState } from 'react'
import styled from 'styled-components/macro'
import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateValidation } from '../utils/formRules'
import { fontFamily } from '../styles/mixins'

const CustomDatePickerField = styled(TextField)`
&&{
  margin: 0;
  background:${({ theme }) => theme.colors.white};
  border-radius: 4px;

  && input{
    padding: 6.75px 0;
  }

  && .MuiOutlinedInput-root{
    ${fontFamily('Neue')}
    padding: 0 8px;
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.blackDarkBlue};
    ${({ theme }) => theme?.mixins?.inputMixin};
  }
  
  & .MuiInputAdornment-root{
    display: none;
  }
}
`

type TProps = {
  disabled?: boolean
  control: Control<any>
}
const CustomDatePicker: FC<TProps> = ({ control, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name="dateOfBirth"
        rules={{
          required: 'Please fill out all mandatory fields',
          validate: dateValidation
        }}
        render={({
          field: { onChange, value },
          fieldState: { invalid }
        }) => (
          <DatePicker
            value={value}
            open={isOpen}
            disabled={disabled}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            inputFormat="dd-MM-yyyy"
            views={['day', 'month', 'year']}
            onChange={onChange}
            renderInput={(params) => (
              <CustomDatePickerField
                onClick={() => !disabled && setIsOpen(true)}
                onKeyDown={(e: any) => e.preventDefault()}
                fullWidth
                sx={{
                  marginTop: 1
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'dateOfBirth-input',
                  placeholder: ''
                }}
                error={invalid}
              />
            )}/>
        )}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
