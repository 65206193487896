import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { memo } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'
import CustomCheckBox from '../../../../core/components/CustomCheckBox'
import CustomButton from '../../../../core/components/buttons/CustomButton'
import { CheckBoxContainer, ContentAgreementStyled, FormStyled, LabelStyled } from './styled'
import { useStepperContext } from '../../../../core/components/stepper/StepperContext'

const ContentStyled = styled.div`
  ${fontFamily('Open Sans')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  overflow-y: auto;
  padding: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blackDarkBlue};
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 30px;
  height: auto;
  &>div{
    margin-bottom: 16px;
  }
`

const CaptionStyled = styled.div`
  ${fontFamily('Open Sans')}
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.white};
  margin:30px 0 50px 0;
`

const TitleStyled = styled.h1`
  ${fontFamily('Open Sans')};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
`
type TForm = {
  checkbox1: boolean
  checkbox2: boolean
  checkbox3: boolean
  checkbox4: boolean
}
const RobertOwensTerms = () => {
  const { nextStep } = useStepperContext()
  const { control, watch } = useForm<TForm>({
    mode: 'onChange',
    defaultValues: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false
    }
  })
  const isButtonDisabled = !watch('checkbox1') || !watch('checkbox2') || !watch('checkbox3') || !watch('checkbox4')
  return (
    <>
      <ContentStyled>
        <div>
          Firstly, thanks for your interest in Robert Owen Community Banking,
          we’re providing loans for renewable energy systems and home improvements on
          behalf of your Local Authority.
        </div>
        <div>
          We’re delighted to be testing a new application process with our friends at Plend and we’d
          like you to be happy to take part. There are a few Terms of Use (these “Terms”) here
          that we’d like you to accept before you enter any of your details and they include
          the rules for the PLEND Score® Data Pilot (the “Plend Pilot”).
        </div>
        <div>
          The purpose of the Plend Pilot is to obtain feedback on software performance and
          identification of defects. The Plend Pilot is still in its testing phase and is
          provided on an “as is” and “as available basis” and may contain a few minor defects.
          These Terms are between Plend Limited (“Plend”), Plaid Technologies, Inc (“Plaid”), Robert
          Owen Community Banking Fund Ltd and anyone accessing or using the system (the “User”).
        </div>
        <div>
          By clicking “Accept”, you indicate your assent to be bound by these Terms.
          If you do not agree to these Terms, do not use or access the Plend Pilot.
        </div>
        <div>
          These Terms contain mandatory arbitration provisions that require the use of arbitration
          to resolve disputes. All data collection subject to the ‘Terms of Use’ provided by
          Plaid:
          {' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://plaid.com/legal/terms-of-use/">
            {' '}
            https://plaid.com/legal/terms-of-use/.
            {' '}
          </a>
        </div>
        <div>
          These Terms will continue in effect unless terminated in accordance with these Terms.
          Either party may terminate these Terms in the event the other party materially breaches
          the terms of these Terms and fails to cure such breach within ten (10) days from receipt
          of written notice thereof. In addition, Plend may immediately suspend the Services in the
          event it determines or believes that (a) there is unauthorized access to the Services via
          Client’s account, (b) continued provision of the Services may do material harm to Plend or
          its networks or systems or reputation and subject Plend to liability. Plend may terminate
          these Terms for any reason and without cause upon written notice to Client. User Data
          will be held for a maximum period of thirty (30) days following the submission by the User
          and will be held in the United Kingdom for the duration of the Plend Pilot.
        </div>
        <div>
          For any questions or concerns regarding the Plend Pilot, contact
          <a href="mailto:support@plend.co.uk"> support@plend.co.uk.</a>
        </div>
        <div>
          Happy to proceed with your application? If so, click ACCEPT.
        </div>
      </ContentStyled>
      <ContentAgreementStyled>
        <FormStyled>
          <TitleStyled>
            A few things to check, you must be:
          </TitleStyled>
          <CheckBoxContainer>
            <LabelStyled>
              <CustomCheckBox control={control} name="checkbox1"/>
              Aged over 18
            </LabelStyled>
            <LabelStyled marginTop>
              <CustomCheckBox
                control={control}
                name="checkbox2"/>
              Be employed with no probationary period, self-employed or
              have regular income, e.g., pension, regular benefits
            </LabelStyled>
            <LabelStyled marginTop>
              <CustomCheckBox
                control={control}
                name="checkbox3"/>
              A UK resident and only have tax residency in the UK
            </LabelStyled>
            <LabelStyled marginTop>
              <CustomCheckBox
                control={control}
                name="checkbox4"/>
              Using this loan for personal (not business) purposes
            </LabelStyled>
          </CheckBoxContainer>
          <CustomButton text="Accept" onClick={nextStep} disabled={isButtonDisabled}/>
        </FormStyled>
      </ContentAgreementStyled>
      <CaptionStyled>
        Please contact Plend’s Data Protection Officer (“DPO”) dpo@plend.co.uk in
        relation to any questions or concerns regarding UK GDPR considerations of the
        Plend Pilot. Plend Limited is registered with the Information Commissioner’s Office
        in compliance with the Data Protection Regulations 2018 under registration ZB037977.
      </CaptionStyled>
    </>
  )
}

export default memo(RobertOwensTerms)
