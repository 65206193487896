import { useMemo } from 'react'
import { useOrganizationContext } from '../../../core/state/useOrganization'
import { useAuthOrganizationQuery } from '../queries'

export function useOrganizationId() {
  const { currentOrganization } = useOrganizationContext()
  const { data: authOrganizations } = useAuthOrganizationQuery()

  return useMemo(
    () =>
      authOrganizations?.find(
        (organization) => organization.subdomain === currentOrganization
      )?.id || 1,
    [authOrganizations]
  )
}
